
function transformFieldAndValue(f:any, fieldsToTransform:string[]) {
    let field:string = f.field;
    let value:any = f.value;
    let nameBase:string | undefined = fieldsToTransform.find(r => field === r + "Name" );
    if (nameBase) {
      field = nameBase + "Id";
      value = value.id;
    }
    else if (typeof value == "object" && value.name != null) {
      value = value.name;
    }
    return {...f,
      field: field,
      value: value
    };
};

export default transformFieldAndValue;