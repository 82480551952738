import { ClassificationKey, Service } from "./enums";
import { iClassificationItem } from "./interfaces";

export type ClassificationContainer = {
  list: iClassificationItem[];
  tree: iClassificationItem[];
  selected: iClassificationItem[];
};

export type ConfigProvider = {
  service: Service;
  url: string;
  key: string;
};

// export type FilterProvider = {
//   showType: boolean;
//   showLevel: boolean;
//   showEducation: boolean;
//   filterClassificationIds: string[];
// };

export type FilterProvider = {
  showType: boolean;
  filterTypeData: FilterData[];
  showLevel: boolean;
  filterLevelData: FilterData[];
  showEducation: boolean;
  filterClassificationIds: string[];
  showOnlyNotIsUsed: boolean;
  defaultValueNotIsUsed: boolean;
  configClassificationProvider?: ConfigProvider;
  classificationKey?: ClassificationKey;
};

export type FilterData = {
  id: number;
  name: string;
};

export type FilterValues = {
  type: number | null;
  level: number | null;
  showOnlyNotIsUsed: boolean;
  LearningObjectiveClassifications: string[];
  // education: string;
};

export const filterLearningObjective: FilterProvider = {
  showType: true,
  filterTypeData: [
    { id: 1, name: "Teoretisk" },
    { id: 2, name: "Praktisk" },
    { id: 3, name: "Självvärdering" },
  ],
  showLevel: true,
  filterLevelData: [
    { id: 1, name: "Allmän" },
    { id: 2, name: "Mellan" },
    { id: 3, name: "Detalj" },
  ],
  showEducation: true,
  filterClassificationIds: [],
  showOnlyNotIsUsed: false,
  defaultValueNotIsUsed: false,
};
