import { appConfig } from "../../appConfig";
import { iImageLink } from "../../components/optima_image/interfaces";
// import { iImageLink } from "../../interfaces/iMediaLink";
import { MediaDto, QuestionMediaDto } from "../learnable_question/QuestionClient";
import { GetAssetResponse, OptimaImageClient } from "./OptimaImageClient";

export const fetchMediaLinks = async (
  mediaLinks: iImageLink[] | undefined,
  questionMedia: QuestionMediaDto[],
  imageWidth: number = 180
): Promise<iImageLink[]> => {
  let newMediaLinks: iImageLink[] = mediaLinks ? [...mediaLinks] : [];

  for (var i = 0; i < questionMedia!.length; i++) {
    let media: MediaDto[] = questionMedia![i].media!;

    for (var mediaIndex = 0; mediaIndex < media.length; mediaIndex++) {
      let medium: MediaDto = media[mediaIndex];
      //if linktype is not image, skip
      if (medium.linkTypeId !== 1) {
        continue;
      }

      if (medium.link === undefined || medium.link.length === 0) {
        continue;
      }

      let mediaLink: iImageLink = JSON.parse(medium.link!);
      if (newMediaLinks.find((x) => x.ContentId === mediaLink.ContentId)) {
        // vi beh�ver inte h�mta l�nken igen...
        continue;
      }

      if (mediaLink.ContentId == undefined || mediaLink.VersionId == undefined) {
        continue;
      }

      if (
        mediaLink.ContentId === "00000000-0000-0000-0000-000000000000" ||
        mediaLink.VersionId === "00000000-0000-0000-0000-000000000000"
      ) {
        continue;
      }
      let client = new OptimaImageClient(appConfig.REACT_APP_MEDIA_API_URL);
      // let response: GetAssetResponse = await client.mediaGet(mediaLink.ContentId, mediaLink.VersionId);

      // mediaLink.Url = response.url! + "?auto=format,compress&w=" + imageWidth;
      // mediaLink.Title = response.properties?.find((x) => x.name === "Filename")?.value!;
      let response: GetAssetResponse | undefined = undefined;
      try {
        response = await client.mediaGet(mediaLink.ContentId, mediaLink.VersionId);
        mediaLink.Url = response.url! + "?auto=format,compress&w=" + imageWidth;
        mediaLink.Title = response.properties?.find((x) => x.name === "Filename")?.value!;
      } catch (error) {
        mediaLink.Url = "images/image-not-found.png";
        mediaLink.Title = "Bild saknas";
      }
      newMediaLinks.push(mediaLink);
    }
  }

  //console.log("fetchMediaLinks", newMediaLinks);
  return newMediaLinks;
};
