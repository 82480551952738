import { useEffect, useState } from "react";
import Moment from "moment";
import { ListViewItemProps } from "@progress/kendo-react-listview";
import { appConfig } from "../../appConfig";
import { Client, ContainerDto } from "../../api/Client";
import iLearningObjectMetadata from "../../interfaces/iLearningObjectMetadata";
import iEducationMetadata from "../../interfaces/iEducationMetadata";
import { useAppContext } from "../common/AppContext";

const MetadataPanel = (props: any) => {
  const appObject = useAppContext();
  const dataItem = props.dataItem;
  console.log("MetadataPanel props", props);
  const [metadata, setMetadata] = useState<iEducationMetadata | null>(null);

  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    await fetchEducationMetadata();
  };
  const getStatusName = (statusId: number | undefined): string => {
    if (!statusId) return "";
    const status = appObject.statuses.find((x) => x.id === statusId);
    return status && status.name ? status.name : "";
  };
  const fetchEducationMetadata = async () => {
    const lcmsApiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
    const lcmsClient = new Client(lcmsApiUrl);

    // var containerDto: ContainerDto = await lcmsClient.containerGet(dataItem.id);

    //var containerDto: ContainerDto = containerDtos[0];

    var educationMetadata = await lcmsClient.educationGetMetadata(dataItem.id, appConfig.REACT_APP_VERSION_NO);

    const metadata: iEducationMetadata = {
      customerName: educationMetadata.customerName ?? "",
      status: getStatusName(educationMetadata.statusId),
      created: educationMetadata.createdDate!,
      createdBy: educationMetadata.createdBy!,
      updated: educationMetadata.updatedDate,
      updatedBy: educationMetadata.updatedBy ?? "",
    };

    setMetadata(metadata);
  };

  const GetUserName = (userId: number | undefined): number | null => {
    if (!userId) return null;
    //TODO: Sl� upp anv�ndarnamn n�r det blir m�jligt.
    return userId;
  };

  const MyOptionsRender = (props: ListViewItemProps) => {
    let option = props.dataItem;
    return (
      <div className="k-listview-item row p-2 border-bottom align-middle" style={{ margin: 0 }}>
        <div className="col-2">
          <span
            style={{ color: option.isCorrect ? "#0E8518" : "#D90707" }}
            className={option.isCorrect ? "k-icon k-i-checkbox-checked" : "k-icon k-i-checkbox k-i-shape-rect"}
          ></span>
        </div>
        <div className="col-10">
          <div style={{ fontSize: 12, color: option.isCorrect ? "#002410" : "#680000" }}>
            {option.texts && option.texts[0].text}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="cols-10">
      <div className="row">
        <div className="col">
          <div style={{ fontWeight: "bold" }}>Organisation:</div>
          {metadata && metadata.customerName}
        </div>
        <div className="col">
          <div style={{ fontWeight: "bold" }}>Status:</div>
          {metadata && metadata.status}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div style={{ fontWeight: "bold" }}>Skapades:</div>
          {metadata && Moment(metadata.created).format("yyyy-MM-DD hh:mm:ss")}

          <div style={{ fontWeight: "bold" }}>Skapades av:</div>
          {metadata && metadata.createdBy}
        </div>

        <div className="col">
          <div style={{ fontWeight: "bold" }}>Uppdaterades:</div>
          {metadata && metadata.updated && Moment(metadata.updated).format("yyyy-MM-DD hh:mm:ss")}

          <div style={{ fontWeight: "bold" }}>Uppdaterades av:</div>
          {metadata && metadata.updatedBy}
        </div>
      </div>
    </div>
  );
};

export default MetadataPanel;
