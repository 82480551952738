import { useMsal } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

/**
 * Logout from application and clear local- and session storage
 */
export const useLogout = () => {
  const { instance } = useMsal();

  const logout = () => {
    sessionStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    });
  };

  return { logout };
};
