import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { AssessmentDto } from "../../../api/Client";

const WhereIsUsedDialog = (props: any) => {
  //const [newSelectedImages, setNewSelectedImages] = React.useState<ImagesDto[]>([]);

  const handleClose = () => {
    props.setShowWhereIsUsedDialog(false);
  };

  return (
    <>
      <Dialog title={props.question.id + " används i:"} onClose={handleClose}>
        <div
          style={{
            borderStyle: "dotted",
            borderWidth: "1px",
            borderRadius: 10,
            borderColor: "grey",
            minWidth: "200px",
            height: "300px",
            overflow: "auto",
          }}
          className="row m-2"
        >
          <div className="col-12">
            {props.whereIsUsedAssessments &&
              props.whereIsUsedAssessments.map((assessment: AssessmentDto) => (
                <div className="row">
                  <div className="col-10 m-1">{assessment.name}</div>
                </div>
              ))}
          </div>
        </div>

        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={handleClose}
          >
            Ok
          </button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default WhereIsUsedDialog;
