import { Client, WhereUsedDto, WhereUsedLearningObjectImageRequest } from "../../../api/Client";
import { QuestionClient } from "../../../api/learnable_question/QuestionClient";
import { appConfig } from "../../../appConfig";
import { WhereUsedCaller } from "./enums";
import { IWhereUsedResult, IWhereUsed } from "./Interfaces";

export class ImageWhereUsed implements IWhereUsed {
  fetchWhereUsed = async (
    whereUsedCaller: WhereUsedCaller,
    id: number,
    contentId: string,
    versionId: string
  ): Promise<IWhereUsedResult> => {
    const [learningObjects, questions] = await Promise.all([
      this.fetchWhereUsedLearningObjects(id, contentId, versionId),
      this.fetchWhereUsedQuestions(id, contentId, versionId),
    ]);

    return {
      learningObjects:
        whereUsedCaller == WhereUsedCaller.LearningObject
          ? learningObjects.filter((x) => x.id !== id)
          : learningObjects,
      questions: whereUsedCaller == WhereUsedCaller.Question ? questions.filter((x) => x.id !== id) : questions,
    } as IWhereUsedResult;
  };

  fetchWhereUsedLearningObjects = async (id: number, contentId: string, versionId: string): Promise<WhereUsedDto[]> => {
    const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
    const client = new Client(apiUrl);

    let whereUsedRequest: WhereUsedLearningObjectImageRequest = {
      contentId: contentId,
      versionId: versionId,
    } as WhereUsedLearningObjectImageRequest;

    let whereUsedDtos: WhereUsedDto[] = await client.whereUsedGetWhereUsedLearningObjectImage(whereUsedRequest);
    return whereUsedDtos;
  };

  fetchWhereUsedQuestions = async (id: number, contentId: string, versionId: string): Promise<WhereUsedDto[]> => {
    const apiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
    const client = new QuestionClient(apiUrl);

    let whereUsedRequest: WhereUsedLearningObjectImageRequest = {
      contentId: contentId,
      versionId: versionId,
    } as WhereUsedLearningObjectImageRequest;

    let whereUsedDtos: WhereUsedDto[] = await client.whereUsedGetWhereUsedQuestionImage("1", whereUsedRequest);
    return whereUsedDtos;
  };
}
