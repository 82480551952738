import { Breadcrumb, BreadcrumbLinkMouseEvent } from "@progress/kendo-react-layout";
import { IWhereUsedResult } from "./Interfaces";

export interface WhereUsedResultPanelProps {
  whereUsedResult: IWhereUsedResult;
}
const WhereUsedResultPanel = ({ whereUsedResult }: WhereUsedResultPanelProps) => {
  const getBreadcrumbs = (whereUsedDto: any): any[] => {
    return [
      {
        text: `${whereUsedDto.id} - ${whereUsedDto.name}`,
        id: whereUsedDto.id.toString(),
      },
    ] as any[];
  };

  return (
    <>
      <div className="mt-2" style={{ minWidth: "300px", display: "inline-block", verticalAlign: "top" }}>
        {whereUsedResult && <span>Lärobjekt:</span>}
        {whereUsedResult &&
          whereUsedResult.learningObjects.map((x) => (
            <Breadcrumb
              key={x.id}
              data={getBreadcrumbs(x)}
              onItemSelect={(e: BreadcrumbLinkMouseEvent) => window.open(`LearningObject?id=${x.id}`, "_blank")}
            />
          ))}
      </div>
      <div className="mt-2 ms-2" style={{ minWidth: "300px", display: "inline-block", verticalAlign: "top" }}>
        {whereUsedResult && <span>Frågor:</span>}
        {whereUsedResult &&
          whereUsedResult.questions.map((x) => (
            <Breadcrumb
              key={x.id}
              data={getBreadcrumbs(x)}
              onItemSelect={(e: BreadcrumbLinkMouseEvent) =>
                window.open(`NewQuestion?id=${x.id}&templateId=0`, "_blank")
              }
            />
          ))}
      </div>
    </>
  );
};

export default WhereUsedResultPanel;
