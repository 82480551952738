import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, GridColumn, GridDetailRowProps, GridExpandChangeEvent } from "@progress/kendo-react-grid";
import { appConfig } from "../appConfig";

const Media = (props: any) => {
  const { state } = useLocation();
  const { item } = state || {};
  console.log("props media", item);

  const GridColumns = [
    { field: "id", width: 50, title: "Id" },
    { field: "name", width: 100, title: "Namn" },
    { field: "age", width: 100, title: "�lder" },
  ];

  const [fakeData, setFakeData] = useState([
    { id: 1, name: "Film", mediatype: 1, updated: "2023-01-01", thumbnail: "icon.png" },
    { id: 2, name: "Bok", mediatype: 2, updated: "2023-01-02", thumbnail: "icon.png" },
    { id: 3, name: "DVD", mediatype: 3, updated: "2023-01-03", thumbnail: "icon.png" },
    { id: 4, name: "YouTube", mediatype: 4, updated: "2023-01-04", thumbnail: "icon.png" },
  ]);

  useEffect(() => {
    const configValue: string = appConfig.REACT_APP_LCMS_API_URL as string;
  }, []);

  const DetailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem;
    return (
      <section>
        <p>
          <strong>In Stock:</strong> {dataItem.id} units
        </p>
        <p>
          <strong>On Order:</strong> {dataItem.name} units
        </p>
        <p>
          <strong>Reorder Level:</strong> {dataItem.mediatype} units
        </p>
        <p>
          <strong>Discontinued:</strong> {dataItem.updated}
        </p>
        <p>
          <strong>Category:</strong> {dataItem.thumbnail} - {dataItem.thumbnail}
        </p>
      </section>
    );
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    let newData = fakeData.map((item: any) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setFakeData(newData);
  };

  return (
    <div className="container">
      <Grid
        onExpandChange={expandChange}
        expandField="expanded"
        style={{ maxHeight: "600px" }}
        data={fakeData}
        detail={DetailComponent}
        rowHeight={1}
        pageable={true}
        filterable={true}
        editField="inEdit"
      >
        <GridColumn field="id" title="ID" width="40px" />
        <GridColumn field="name" title="Title" width="250px" />
        <GridColumn field="mediatype" title="Mediatyp" />
        <GridColumn field="updated" title="Senast editerad" />
        <GridColumn field="thumbnail" title="thumbnail" />
      </Grid>
    </div>
  );
};

export default Media;
