import { LogLevel } from "@azure/msal-browser";
import { appConfig } from "../appConfig";

const authorityBaseUrl = `https://login.microsoftonline.com/${appConfig.authTenantId}`;

export const ADB2C_ERROR_CODES = {
  USER_CANCELLATION: "AADB2C90091",
  FORGOTTEN_PASSWORD: "AADB2C90118",
};

export const msalConfig = {
  auth: {
    clientId: `${appConfig.authClientId}`, //"a312c1bd-4ccd-4578-ba4b-2876d87fd3f7", // This is the ONLY mandatory field that you need to supply.
    authority: authorityBaseUrl, // Choose B2C_1_lc_sign_in as your default authority.
    // knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/auth-redirect", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
        }

        if (appConfig.authUseVerboseLogging === "true") {
          switch (level) {
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  // apiCDS: {
  //   scopes: [appConfig.authScopesCdsRead, appConfig.authScopesCdsWrite],
  // },
  apiCLS: {
    scopes: [appConfig.authScopesClsRead],
  },
  // apiLAS: {
  //   scopes: [appConfig.authScopesLasRead, appConfig.authScopesLasWrite],
  // },
  apiLCMS: {
    scopes: [appConfig.authScopesLcmsRead, appConfig.authScopesLcmsWrite],
  },
  apiLOS: {
    scopes: [appConfig.authScopesLosRead],
  },
  apiMES: {
    scopes: [appConfig.authScopesMesRead],
  },
  apiQuS: {
    scopes: [appConfig.authScopesQusRead, appConfig.authScopesQusWrite],
  },
  apiSES: {
    scopes: [appConfig.authScopesSesRead],
  },
  apiFF: {
    scopes: [appConfig.authScopesFfsRead],
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [], // No extra scopes needed here at the moment
};
