import React from 'react';
import { TextBox, TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import AddRemoveButton from './AddRemoveButton';
import { Label } from 'reactstrap';
import { iTextMediaContainerProps } from '../../../interfaces/iTemplateDataItem';
import { v4 as uuidv4 } from 'uuid';
import { QuestionTextDto } from '../../../api/learnable_question/QuestionClient';

const QuestionTextItem = (props: any) => {
    const { questionText, templateDataItem, handleTextChange } = props;


    const getQuestionTextText = (questionText: QuestionTextDto): string => {
        if (questionText == undefined) {
            return "";
        }

        return questionText!.texts![0].text!;
    }

    return (
        <>
            {templateDataItem &&
                <div className={props.col} >
                    <TextArea
                        value={getQuestionTextText(questionText)}
                        onChange={(event: TextAreaChangeEvent) => handleTextChange(event, questionText)}
                    />
                </div>
            }
        </>
    );
}

export default QuestionTextItem;