import { Button } from "@progress/kendo-react-buttons";
import { iClassificationItem, iConfigurationClassification, iConfigurationLearningObjective } from "./interfaces";
import { useEffect, useState } from "react";
import { ClassificationDto } from "../../../api/learnable_question/QuestionClient";
import { ClassificationContainer, ConfigProvider, FilterProvider } from "./types";
import {
  createClassificationContainerFromClassificationDtos,
  createClassificationContainerFromLearningObjectiveDtos,
  createClassificationRequest,
  getClassificationContainerCopy,
  getChipListItems,
  fetchConfigurationClassifications,
} from "./helperFunctions";
import { fetchClassifications } from "../../../api/learnable_classification/apiFunctions";
import { ClassificationsDialog } from "./ClassificationDialog";
import { ClassificationKey } from "./enums";
// import { fetchLearningObjectives } from "../../../api/learningObjective/apiFunctions";

import { WhereUsedDto } from "../../../api/Client";
import ItemViewer from "./ItemViewer";

interface ClassificationButtonProps {
  configProvider: ConfigProvider | undefined;
  classificationKey: ClassificationKey;
  title?: string;
  configurationClassificationOptional: iConfigurationLearningObjective | undefined;
  readOnly: boolean;
  selectedIds: string[];
  filterProvider: FilterProvider | undefined;
  whereUsed?: WhereUsedDto[];
  onOkClick?: (items: iClassificationItem[], rootIds: string[]) => void;
}

const ClassificationButton = ({
  configProvider,
  readOnly,
  selectedIds,
  title,
  classificationKey,
  configurationClassificationOptional,
  filterProvider,
  whereUsed,
  onOkClick,
}: ClassificationButtonProps) => {
  const [classificationConfig, setClassificationConfig] = useState<iConfigurationClassification | undefined>(undefined);
  const [classificationContainer, setClassificationContainer] = useState<ClassificationContainer | undefined>(
    undefined
  );
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [initDone, setInitDone] = useState<boolean>(false);

  // const fetchConfiguration = async (): Promise<iConfigurationClassification | undefined> => {
  //   let result: IConfigDto | undefined = undefined;

  //   if (configProvider!.service === Service.lcms) {
  //     const client = new Client(configProvider!.url);
  //     result = await client.configGet(configProvider!.key);
  //   }
  //   if (configProvider!.service === Service.question) {
  //     const client = new QuestionClient(configProvider!.url);
  //     result = await client.configGet(configProvider!.key);
  //   }
  //   // const result: IConfigDto = await client.configGet(props.configKey);
  //   const configuration = JSON.parse(result!.value!);
  //   const classifications: iConfigurationClassification[] = configuration.classifications;
  //   const classification = classifications.find((x) => x.key === ClassificationKey[classificationKey]);

  //   return classification ?? undefined;
  // };

  const init = async () => {
    //get classification configuration from api

    if (configurationClassificationOptional) {
      setInitDone(false);

      const learningObjectives = await configurationClassificationOptional.clientGet!();

      const classificationContainer: ClassificationContainer = createClassificationContainerFromLearningObjectiveDtos(
        learningObjectives,
        selectedIds
      );

      setInitDone(true);
      setClassificationContainer(classificationContainer);
      // console.log("classificationContainer", classificationContainer);
    } else {
      const classificationConfig = await fetchConfigurationClassifications(configProvider!, classificationKey);
      setClassificationConfig(classificationConfig);
      //fetch classifications by rootIds
      let classifications: ClassificationDto[][] = await fetchClassifications(
        createClassificationRequest(classificationConfig!.rootIds)
      );

      // console.log("classifications", classifications);
      const classificationContainer = await createClassificationContainerFromClassificationDtos(
        classifications,
        selectedIds,
        classificationConfig!.rootIds
      );
      setInitDone(true);
      setClassificationContainer(classificationContainer);
    }

    // console.log("useEffect done", classificationKey);
  };

  useEffect(() => {
    // console.log("useEffect classificationButton");
    init();
  }, []);

  useEffect(() => {
    if (classificationContainer) {
      let newClassificationContainer = getClassificationContainerCopy(classificationContainer!);
      newClassificationContainer.selected = newClassificationContainer.list.filter((x) =>
        selectedIds.includes(x.identifier!)
      );
      setClassificationContainer(newClassificationContainer);
    }
  }, [selectedIds]);

  const handleOnApply = (data: iClassificationItem[]) => {
    const rootIds = classificationConfig ? classificationConfig.rootIds : [configurationClassificationOptional!.rootId];
    setVisibleDialog(false);
    onOkClick!(data, rootIds);
  };

  const getButtonTitle = () => {
    if (title) {
      return title;
    }
    if (!configurationClassificationOptional && configProvider) {
      return classificationConfig!.title;
    }
    if (configurationClassificationOptional) {
      return configurationClassificationOptional.title;
    }
    return "";
  };

  const showSelected = () => {
    if (classificationConfig && classificationConfig.showSelected) {
      return true;
    }
    if (configurationClassificationOptional && configurationClassificationOptional.showSelected) {
      return true;
    }
    return false;
  };

  return (
    <>
      {/* {!initDone && LoadingPanel} */}
      {(classificationConfig || configurationClassificationOptional) && (
        <Button themeColor={"primary"} onClick={() => setVisibleDialog(true)}>
          {getButtonTitle()}
        </Button>
      )}

      {visibleDialog && (
        <ClassificationsDialog
          key={"ClassificationsDialog_" + classificationKey}
          readOnly={readOnly}
          onApply={handleOnApply}
          checkChildren={classificationConfig?.checkChildren ?? false}
          onClose={() => setVisibleDialog(false)}
          title="Välj"
          classificationContainer={classificationContainer!}
          filterProvider={filterProvider}
        />
      )}
      {showSelected() && classificationContainer && classificationContainer.selected && (
        <div className="mt-2">
          <ItemViewer data={getChipListItems(classificationContainer.selected, whereUsed ?? [])} type="chip" />
          {/* <ul>
            {classificationContainer.selected.map((i) => (
              <Button style={{ backgroundColor: "blue" }} size={"small"}>
                {i.name}
              </Button>
            ))}
          </ul> */}
        </div>
      )}
    </>
  );
};

export default ClassificationButton;
