import { IConfigDto, QuestionClient, QuestionDto, QuestionPublishRequest, ValidationDto } from "./QuestionClient";
import { appConfig } from "../../appConfig";
import { iQuestionDtoExtended } from "../../interfaces/iQuestionDtoExtended";
import {
  setInteractionPropertiesToPositive,
  setOptionIdsToPositive,
  validateOnSave,
} from "../../components/question/edit/functions/questionDtoFunctions";
import { Client } from "../Client";
import { iConfiguration } from "../../components/common/classifications/interfaces";

export const fetchQuestion = async (questionId: number) => {
  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);
  let question = (await questionClient.questionGet(questionId)) as iQuestionDtoExtended;
  question.isModified = false;

  return question;
};

export const fetchValidationDto = async (questionDto: iQuestionDtoExtended) => {
  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);
  let validationDto: ValidationDto = await questionClient.questionValidate(questionDto);

  return validationDto;
};
export const fetchConfiguration = async (configKey: string): Promise<iConfiguration> => {
  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);
  const result: IConfigDto = await questionClient.configGet(configKey);

  const configuration = JSON.parse(result.value!);
  let configurationData: iConfiguration = {
    classifications: configuration.classifications,
    learningObjectives: null,
    // learningObjectives: { title: "Lärandemål", rootId: LEARNING_OBJECTIVES_ROOT_ID, showSelected: true },
  };

  return configurationData;
};
export const saveQuestionDto = async (question: iQuestionDtoExtended) => {
  const lcmsApiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
  const lcmsClient = new Client(lcmsApiUrl);
  let questionId: number = question!.id!;
  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);

  let savedQuestion: QuestionDto | undefined = undefined;

  const questionValidated = validateOnSave(question!);

  setInteractionPropertiesToPositive(questionValidated);
  setOptionIdsToPositive(questionValidated!);

  // DEBUG to prevent save on new
  // if (questionId == 0) {
  //   console.log("SaveQuestionDto:", questionValidated);
  //   return -1;
  // }

  if (questionId == 0) {
    savedQuestion = await questionClient.questionPost(questionValidated);
  } else {
    savedQuestion = await questionClient.questionPut(question!.id!, questionValidated);
  }

  questionId = savedQuestion.id!;
  await lcmsClient.settingSetLatestQuestions(questionId);
  return questionId;
};

export const copyQuestionDto = async (question: iQuestionDtoExtended) => {
  const lcmsApiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
  const lcmsClient = new Client(lcmsApiUrl);
  let questionId: number = question!.id!;
  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);

  let copiedQuestion: QuestionDto | undefined = undefined;

  if (questionId > 0) {
    copiedQuestion = await questionClient.questionCopyQuestion(questionId);
  }
  questionId = copiedQuestion!.id!;
  //await lcmsClient.settingSetLatestQuestions(questionId, userId);
  return questionId;
};
export const publishQuestion = async (question: iQuestionDtoExtended) => {
  let questionId: number = question!.id!;
  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);

  let publishRequest: QuestionPublishRequest = new QuestionPublishRequest();
  publishRequest.questionId = questionId;
  publishRequest.semanticVersion = question.semanticVersion;
  publishRequest.ignoreValidation = false;

  const publishSuccess = await questionClient.publishPublish("1", publishRequest);

  return publishSuccess;
};
