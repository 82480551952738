import { Dispatch, SetStateAction } from "react";
import {
  SearchClient,
  DynamicLinqRequest,
  Pageing,
  Parameter,
  SearchResponse,
} from "../../api/learnable_search/SearchClient";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { CustomFilterDto } from "../../api/Client";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import { iConfiguration } from "./classifications/interfaces";
import transformFieldAndValue from "./transformFieldAndValue";
import getLinqOperatorMethodWithNamedParam from "./getLinqOperatorMethodWithNamedParam";

export class EducationsUtilities implements iSearchUtilities {
  search = async (
    client: SearchClient,
    gridFilter: CompositeFilterDescriptor | undefined,
    customFilter: CustomFilterDto | undefined,
    sort: any,
    page: any,
    currentCondition: string,
    setCurrentCondition: Dispatch<SetStateAction<string>>,
    configuration: iConfiguration
  ): Promise<SearchResponse> => {
    const statuses = await this.getStatusar(client);
    const searchRequest = new DynamicLinqRequest({
      pageing: new Pageing({ skip: page.skip, take: page.take }),
      parameters: {},
      //dynamicLinqString: "_lcmsContext.Containers",
      dynamicLinqString: "_optimaLearningStructureContext.Trees",
    });

    //we are only intrested in containers with type 2 (LearningObject)
    // let whereExpression1 = `c.ContainerType == ${containerType}`;
    let whereExpression1 = "";

    if (customFilter) {
      //   if (customFilter.containerSubType) {
      //     if (whereExpression1.length > 0) whereExpression1 += " && ";
      //     whereExpression1 += "c.ContainerSubtype == @@containerSubtypeParam@@";
      //     searchRequest.parameters!["containerSubtypeParam"] = new Parameter({ intValue: customFilter.containerSubType });
      //   }

      // Organisation
      if (customFilter.organistaionsId) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.CustomerId == @@customerIdParam@@";
        searchRequest.parameters!["customerIdParam"] = new Parameter({
          intValue: parseInt(customFilter.organistaionsId),
        });
      }

      //   //// Ämne filtreras via en extra filtrering på ärandemål som tillfällig lösning eftersom det ännu inte gjorts någon klassificering av frågorna på ämne. /Magnus 2023-08-15
      //   if (customFilter.amneGuids!.length > 0) {
      //     var larandemalViaAmneGuids = (await this.getLarandemalGuidsFromAmneGuids(
      //       client,
      //       customFilter.amneGuids!
      //     )) as SearchResponse;
      //     if (whereExpression1.length > 0) whereExpression1 += " && ";
      //     whereExpression1 +=
      //       "c.ContainerLearningObjectives.Any(a => @@larandemalViaAmneGuidsParam@@.Any(b => a.LearningObjectiveId == b))";
      //     searchRequest.parameters!["larandemalViaAmneGuidsParam"] = new Parameter({
      //       guidValues: larandemalViaAmneGuids.rows,
      //     });
      //   }

      // Fritext
      if (customFilter.fritext) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";

        whereExpression1 += "c.Title.Contains(@@fritextParam@@) || c.Description.Contains(@@fritextParam@@)";
        searchRequest.parameters!["fritextParam"] = new Parameter({ stringValue: customFilter.fritext });
      }

      if (!customFilter.includeDeprecated) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.Deprecated == false";
      }
    }
    // Add Where
    if (whereExpression1.length > 0) {
      searchRequest.dynamicLinqString += "\n" + `.Where("c => ` + whereExpression1 + `")`;
    }

    // (searchRequest.dynamicLinqString +=
    //   "\n" + `.Select("c => new {c.Id, c.Title, c.Description, @@defaultDateParam@@ as SenastEditerad`),
    //   +`}")`;

    searchRequest.dynamicLinqString +=
      "\n" +
      `.Select("c => new {c.Id, c.Title, c.Description, c.CustomerId,c.StatusId,c.SemanticVersion,c.PublishStatus, ` +
      `c.Customer.Name as CustomerName, ` +
      `c.Updated != null ? c.Updated : c.Created as SenastEditerad ` +
      `}")`;

    searchRequest.parameters!["defaultDateParam"] = new Parameter({ stringValue: "0001-01-01T00:00:00" });

    // console.log("searchRequest", searchRequest);
    //Grid filter

    let whereExpression2 = "";
    gridFilter &&
      gridFilter.filters.forEach((f: any) => {
        if (whereExpression2.length > 0) whereExpression2 += " && ";
        const fields = transformFieldAndValue(f, ["customer", "interactionType", "status"]);
        whereExpression2 += getLinqOperatorMethodWithNamedParam(fields, searchRequest.parameters);
      });

    if (whereExpression2.length > 0) {
      searchRequest.dynamicLinqString += "\n" + `.Where("${whereExpression2}")`;
    }

    //Order By
    let newOrderBy = "";
    sort &&
      sort.forEach((s: any) => {
        if (newOrderBy.length > 0) newOrderBy += ", ";
        let fieldName = s.field;
        newOrderBy += fieldName + " " + s.dir;
      });
    if (newOrderBy.length === 0) newOrderBy = "Id";
    searchRequest.dynamicLinqString += "\n" + `.OrderBy("` + newOrderBy + `")`;

    // Call Web API
    let newCondition = searchRequest.dynamicLinqString + JSON.stringify(searchRequest.parameters);
    searchRequest.pageing!.calcPages = currentCondition === undefined || currentCondition !== newCondition;
    setCurrentCondition(newCondition);
    console.log("Call API dynamicLinq with searchRequest", searchRequest);

    try {
      const reply = await client.dynamicLinqSearch(searchRequest);
      console.log("API dynamicLinq reply", reply);
      return reply;
    } catch (error) {
      console.error(error);
      //TODO: Bättre felhantering!
      return new SearchResponse();
    }
  };

  getLarandemalGuidsFromAmneGuids = async (client: SearchClient, amneGuids: string[]) => {
    const searchRequest = new DynamicLinqRequest({
      parameters: {},
      dynamicLinqString: `
            _learningObjectiveContext.LearningObjectiveClassifications
            .Where("o => @@amneGuidsParam@@.Contains(o.ClassificationId)")
            .Select("s => s.LearningObjective.Identifier")`,
    });

    searchRequest.parameters!["amneGuidsParam"] = new Parameter({ guidValues: amneGuids });
    // console.log("Call API dynamicLinq with searchRequest", searchRequest);
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    // console.log("API dynamicLinq reply", reply);
    return reply;
  };

  getMediaWhere = (addFilter: boolean, addOr: boolean, mediaTypeId: number = 0) => {
    throw Error("Not implemented!");
  };

  addClassification1 = (
    paramName: string,
    guids: string[],
    whereExpression: string,
    searchRequest: DynamicLinqRequest
  ) => {
    let newWhere = whereExpression;
    if (guids.length > 0) {
      if (newWhere.length > 0) newWhere += " && ";
      newWhere += "c.ContainerClassifications.Any(a => @@" + paramName + "Param@@.Any(b => b == a.ClassificationId))";
      searchRequest.parameters![paramName + "Param"] = new Parameter({ guidValues: guids });
    }
    return newWhere;
  };

  addClassification2 = (
    fieldName: string,
    guids: string[],
    whereExpression: string,
    searchRequest: DynamicLinqRequest
  ) => {
    let newWhere = whereExpression;
    if (guids.length > 0) {
      if (newWhere.length > 0) newWhere += " && ";
      newWhere += fieldName + ".Any(a => (@@" + fieldName + "Param@@).Any(b => b == a))";
      searchRequest.parameters![fieldName + "Param"] = new Parameter({ guidValues: guids });
    }
    return newWhere;
  };

  getOrganisationer = async (client: SearchClient) => {
    const searchRequest = new DynamicLinqRequest({
      dynamicLinqString: `
        _optimaLearningStructureContext.Customers
        .Select("c => new { c.Id, c.Name}")
        .OrderBy("Name")
    `,
    });
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };

  getInteraktionstyper = async (client: SearchClient) => {
    throw Error("Not implemented!");
  };

  getStatusar = async (client: SearchClient) => {
    const searchRequest = new DynamicLinqRequest({
      dynamicLinqString: `
      _lcmsContext.Statuses
        .Select("c => new { c.Id, c.Name}")
        .OrderBy("Name")
    `,
    });
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };
  getStatusName = (statusId: number) => {};
}
