import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const CustomDialog = (props: any) => {
  const isConfirm = props.isConfirm ? props.isConfirm : false;
  const title = props.title ? props.title : isConfirm ? "Confirm" : "Message";
  const question = props.question ? props.question : isConfirm ? "Are you sure you want to continue?" : "";
  const message = props.message;
  const okButtonText = props.okButtonText ? props.okButtonText : "OK";
  return isConfirm ? (
    <Dialog title={title} onClose={props.onClose}>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        {question}
      </p>
      <DialogActionsBar>
        <Button
          themeColor={"primary"}
          className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
          onClick={props.onConfirm}
        >
          Ok
        </Button>
        <Button
          themeColor={"secondary"}
          className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
          onClick={props.onClose}
        >
          Avbryt
        </Button>
      </DialogActionsBar>
    </Dialog>
  ) : (
    <Dialog title={title} onClose={props.onClose}>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        {message}
      </p>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
          onClick={props.onClose}
        >
          {okButtonText}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default CustomDialog;
