import { ProseMirror } from "@progress/kendo-react-editor";
import {
  infoClassName,
  GetHtmlFromInfoComponent,
  GetInfoComponentFromHTML,
  InfoComponent,
} from "../InfoComponent/InfoComponentTool";
import {
  imageClassName,
  GetHtmlFromImageComponent,
  GetImageComponentFromHTML,
  ImageComponent,
} from "../ImageComponent/ImageComponentTool";
import {
  videoClassName,
  GetHtmlFromVideoComponent,
  GetVideoComponentFromHTML,
  VideoComponent,
} from "../VideoComponent/VideoComponentTool";
import { IsComponent } from "../../../../common/RichTextEditorTools";
import { appConfig } from "../../../../../appConfig";
import { Component, Header as HeaderComponent, InteractionResponse } from "../../../../../api/Client";
import { GetAssetResponse, OptimaImageClient } from "../../../../../api/optima_image/OptimaImageClient";
import {
  CarouselComponent,
  GetCarouselComponentFromHTML,
  GetHtmlFromCarouselComponent,
  carouselClassName,
} from "../CarouselComponent/CarouselComponentTool";
import {
  GetInteractionComponentFromHTML,
  GetHtmlFromInteractionComponent,
  interactionClassName,
  InteractionComponent,
} from "../InteractionComponent/InteractionComponentTool";
import { base64Encode, base64Decode } from "../../../../common/base64encoding";
import { OptionDto, QuestionDto } from "../../../../../api/learnable_question/QuestionClient";
import { orderBy } from "@progress/kendo-data-query";
import * as cheerio from "cheerio";

const { DOMParser: DOMParserObj } = ProseMirror;

export const missingImage = "/images/Placeholder.png";
export const editorImageWidth = "100";
export const formImageWidth = "300";
export const iconImageWidth = "100";

export const fixImageSrc = (src: string) => {
  if (src.indexOf("?") === -1) src += "?auto=format,compress&amp;w=" + editorImageWidth;
  return src;
};

export const getImageUrl = (link?: string) => {
  let linkObj = link && JSON.parse(link);
  if (!linkObj || !linkObj.src) return missingImage;
  return fixImageSrc(linkObj.src);
};

export const convertComponentsToHtml = async (components?: Component[]) => {
  let html = "";

  // Loop through data components and create html
  if (components) {
    for (const component of components) {
      let newHtml = "";
      switch (component.componentType) {
        case "html":
          // newHtml = component.html!;
          newHtml = `<p>${component.html}</p>`;
          break;
        case "list":
          newHtml = component.list!;
          break;
        case "image":
          newHtml = await GetHtmlFromImageComponent(new ImageComponent(component.image!));
          break;
        case "carousel":
          newHtml = await GetHtmlFromCarouselComponent(new CarouselComponent(component.carousel!));
          break;
        case "video":
          newHtml = GetHtmlFromVideoComponent(new VideoComponent(component.video!));
          break;
        case "informationbox":
          newHtml = GetHtmlFromInfoComponent(new InfoComponent(component.informationBox!));
          break;
        case "interaction":
          newHtml = await GetHtmlFromInteractionComponent(new InteractionComponent(component.interaction!));
          break;
        case "header":
          const l = component.header!.level;
          newHtml = `<h${l}>${component.header!.text}</h${l}>`;
          break;
        default:
          newHtml = `<p style="color:red;">FEL! Inget stöd för komponenttyp "${component.componentType}"!</p>`;
          break;
      }
      html += newHtml;
    }
  }
  return html;
};

export const convertHtmlToDataComponents = (html: string, schema: any) => {
  // Loop through html document and create an array of compoenents
  const parserObj = DOMParserObj.fromSchema(schema);
  const components: Component[] = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const nodes = doc.body.childNodes;
  let sortOrder = 1;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (!(node instanceof HTMLElement)) continue;
    const tag = node.nodeName.toLowerCase();
    const component = new Component();
    switch (tag) {
      case "ul":
      case "ol":
        component.componentType = "list";
        component.list = node.outerHTML;
        break;
      case "div":
        const container = document.createElement("div");
        container.appendChild(node.cloneNode(true));
        const proseMirrorNode = parserObj.parse(container);
        if (
          IsComponent(
            proseMirrorNode,
            proseMirrorNode.content.size - 1,
            proseMirrorNode.content.size - 1,
            carouselClassName
          )
        ) {
          component.componentType = "carousel";
          component.carousel = GetCarouselComponentFromHTML(node.outerHTML, true);
          break;
        }
        if (
          IsComponent(
            proseMirrorNode,
            proseMirrorNode.content.size - 1,
            proseMirrorNode.content.size - 1,
            imageClassName
          )
        ) {
          component.componentType = "image";
          component.image = GetImageComponentFromHTML(node.outerHTML, true);
          break;
        }
        if (
          IsComponent(
            proseMirrorNode,
            proseMirrorNode.content.size - 1,
            proseMirrorNode.content.size - 1,
            videoClassName
          )
        ) {
          component.componentType = "video";
          component.video = GetVideoComponentFromHTML(node.outerHTML);
          break;
        }
        if (
          IsComponent(
            proseMirrorNode,
            proseMirrorNode.content.size - 1,
            proseMirrorNode.content.size - 1,
            infoClassName
          )
        ) {
          component.componentType = "informationbox";
          component.informationBox = GetInfoComponentFromHTML(node.outerHTML);
          break;
        }
        if (
          IsComponent(
            proseMirrorNode,
            proseMirrorNode.content.size - 1,
            proseMirrorNode.content.size - 1,
            interactionClassName
          )
        ) {
          component.componentType = "interaction";
          // const { questionDto, ...filteredObject } = GetInteractionComponentFromHTML(node.outerHTML);
          // component.interaction = new Interaction(filteredObject);
          component.interaction = GetInteractionComponentFromHTML(node.outerHTML);
          component.interaction.title = component.interaction.title || "";
          component.interaction.description = component.interaction.description || "";
          console.log("component.interaction", component.interaction);
          break;
        }
        break;
      default:
        break;
    }
    if (!component.componentType) {
      if (tag.length === 2 && tag.startsWith("h")) {
        const l = parseInt(tag.substring(1));
        component.componentType = "header";
        component.header = new HeaderComponent({ level: l, text: node.innerText });
      } else {
        component.componentType = "html";
        component.html = node.innerHTML;
      }
    }
    component.sortOrder = sortOrder++;
    components.push(component);
  }
  return components;
};

export const isValidJSON = (jsonString?: string): boolean => {
  try {
    if (!jsonString) return false;
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};

export const GetImageInfoFromContentIdAndVersionId = async (contentId: string, versionId: string) => {
  const client = new OptimaImageClient(appConfig.REACT_APP_MEDIA_API_URL);
  try {
    const response: GetAssetResponse = await client.mediaGet(contentId, versionId);
    return response;
  } catch (e) {
    console.error(`Kunde inte hitta bilde med contentId: "${contentId}" och versionId: "${versionId}".`, e);
    return new GetAssetResponse({});
  }
  // const response: GetAssetResponse = await client.mediaGet(contentId, versionId);
  // return response;
};

export const CreateComponentHtml = (className: string, item: object, visualTemplate: string) => {
  const html = `<div class="str-component str-component-${className}" id="||object||"><div>${visualTemplate}</div></div>`;
  // Add class str-component-part to all childen and grand children
  let $ = cheerio.load(html);
  $("div > *").addClass("str-component-part");
  return $.html().replace("|object|", base64Encode(JSON.stringify(item)));
};

export const GetObjectFromHtml = (html: string) => {
  const parts = html.split("|");
  if (parts.length < 2 || !parts[1]) return {};
  const decodedString = base64Decode(parts[1]);
  return JSON.parse(decodedString);
};

export const GetQuestionText = (question?: QuestionDto) => {
  if (!question || !question.questionTexts) return "";
  const q = question.questionTexts.filter((qt) => qt.textTypeId == 1);
  // if (q.length == 0 || !q[0].texts || q[0].texts.length == 0) return "";
  const questions = q.map((qt) => qt.texts!.map((t) => t.text).join(", ")).join(", ");
  return question.id + ": " + questions;
};

export const GetOptionsInOrder = (question: QuestionDto) => {
  if (!question.options?.length) return [];
  return orderBy(question.options, [{ field: "sortOrder", dir: "asc" }]);
};

// Temporare template list
export interface ITemplate {
  id: number;
  name: string;
}

export const Templates: ITemplate[] = [
  { id: 1, name: "Success/Failure" },
  { id: 2, name: "Options" },
];

export const oldAreAllPropertiesUndefined = (obj: Record<string, any>, exclude?: string[]): boolean => {
  const ex = exclude || [];
  const result = Object.values(obj).every((value, i) => value === undefined || ex.includes(obj.keys[i]));
  return result;
};

export const AreAllPropertiesUndefined = (obj?: Record<string, any>, ignoreProps: string[] = []): boolean => {
  if (!obj) return true;
  return Object.entries(obj).every(([key, value]) => ignoreProps.includes(key) || value === undefined);
};
export const IsEmptyInteractionResponse = (interactionResponse?: InteractionResponse): boolean => {
  return AreAllPropertiesUndefined(interactionResponse, ["optionId"]);
};

export const MatchInteractionResponsesWithQuestionOptions = (
  interactionResponses: InteractionResponse[] | undefined,
  questionOptions: OptionDto[] | undefined
): InteractionResponse[] => {
  const result: InteractionResponse[] = [];
  const firstArrayMap = new Map<string, InteractionResponse>();

  if (!questionOptions) return interactionResponses || [];

  if (!interactionResponses) return questionOptions.map((item) => new InteractionResponse({ optionId: item.optionId }));

  // Create a map for quick look-up
  interactionResponses.forEach((item) => {
    firstArrayMap.set(item.optionId!, item);
  });

  // Add or create objects based on second array
  questionOptions.forEach((item) => {
    if (firstArrayMap.has(item.optionId!)) {
      result.push(firstArrayMap.get(item.optionId!)!);
      firstArrayMap.delete(item.optionId!);
    } else {
      result.push(new InteractionResponse({ optionId: item.optionId }));
    }
  });

  // Add remaining items from the first array
  firstArrayMap.forEach((value) => {
    result.push(value);
  });

  return result;
};

export const GetQuestionTypeName = (
  interactionTypeId?: number,
  questionTypeId?: number,
  addBrackets?: boolean
): string => {
  if (!interactionTypeId) return "";
  var reply: string;
  //TODO: Bättre lösning för att slå upp namn på interaktionstyp och frågetyp
  switch (interactionTypeId) {
    case 1:
      reply = questionTypeId === 3 ? "Klick-i-bild" : "Choice";
      break;
    case 2:
      reply = "Sequencing";
      break;
    case 4:
      reply = "Matching";
      break;
    default:
      reply = "Okänd";
      break;
  }
  if (addBrackets) reply = "[" + reply + "]";
  return reply;
};

// Handle lists

export const ConvertEmpty = (htmlString: string | undefined, direction: "toHtml" | "fromHtml"): string | undefined => {
  if (direction === "toHtml") {
    if (htmlString === "") return "<p></p>";
  } else {
    if (htmlString === "<p></p>") return "";
  }
  return htmlString;
};

export const TransformHTMLtoListOrParagraphs = (htmlString: string): string => {
  if (!htmlString) return htmlString;
  if (isOnlyParagraphs(htmlString) || isOnlyOneList(htmlString)) return htmlString;
  const reply = mergeLists(keepOnlyListTags(htmlString));
  return reply;
};

export const TransformHTMLtoList = (htmlString: string): string => {
  if (!htmlString) return htmlString;
  if (isOnlyOneList(htmlString)) return mergeParagraphsInListItems(htmlString);
  return paragraphsToList(htmlString);
};

function isOnlyParagraphs(htmlString: string): boolean {
  const $ = cheerio.load(htmlString);
  const allElements = $("*").not("html, head, body"); // Select all elements except html, head, body
  const paragraphElements = $("p");
  const nonParagraphElements = allElements.length - paragraphElements.length;
  // True if there are no non-paragraph elements and at least one paragraph element
  return nonParagraphElements === 0 && paragraphElements.length > 0;
}

function isOnlyOneList(htmlString: string): boolean {
  const $ = cheerio.load(htmlString);
  const allElements = $("body").children(); // Attempt to select all top-level children
  // Adjust the selection method to account for fragment structure without 'body'
  const lists = allElements.filter("ol, ul"); // Select only the list elements

  // Ensure there's exactly one list and it's the only element
  return lists.length === 1 && allElements.length === 1;
}

function keepOnlyListTags(htmlString: string): string {
  const $ = cheerio.load(htmlString);
  // Remove all elements except for ul, ol, li, and p within li
  $("body").children().not("ul, ol").remove();
  $("li").children().not("p").remove();
  return $("body").html() || "";
}

function mergeLists(htmlString: string): string {
  const $ = cheerio.load(htmlString);
  const lists = $("ul, ol"); // This selects all the lists

  if (lists.length > 1) {
    lists.each(function (index) {
      if (index === 0) return; // Skip the first list

      // Append each list item from subsequent lists to the first list
      $(this)
        .children("li")
        .each(function () {
          lists.first().append($(this));
        });

      // Remove the now empty list
      $(this).remove();
    });
  }

  return $.html();
}

const mergeParagraphsInListItems = (htmlString: string): string => {
  const $ = cheerio.load(htmlString);

  $("li").each(function () {
    // Check if the list item contains <p> tags
    if ($(this).find("p").length > 0) {
      // Merge all <p> contents into a single string
      const combinedText = $(this)
        .find("p")
        .map((i, el) => $(el).text())
        .get()
        .join("");
      // Replace the <li> contents with the combined text
      $(this).text(combinedText);
    }
    // If no <p> tags are present, leave the <li> as is
  });

  // Return the modified HTML, ensuring we're only returning the modified list(s)
  return $("body").html() || ""; // Adjust based on the need to return the full structure or just the list
};

const paragraphsToList = (htmlString: string): string => {
  const $ = cheerio.load(htmlString);
  const paragraphs = $("p");
  const newList = $("<ul></ul>");

  paragraphs.each(function () {
    const listItem = $("<li></li>").html($(this).html() || "");
    newList.append(listItem);
  });

  // Clear original paragraphs and replace with the new list
  $("body").empty().append(newList);

  return $.html(newList);
};

// End handle lists
