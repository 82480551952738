import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Editor,
  EditorTools,
  ProseMirror,
  EditorMountEvent,
  EditorChangeEvent,
  EditorExecuteEvent,
  EditorPasteEvent,
} from "@progress/kendo-react-editor";
import { TextSelection, EditorView } from "@progress/kendo-editor-common";
import { useState } from "react";
import { GetParentNode } from "../../common/RichTextEditorTools";
import { EditNodeToolInfoComponent } from "./tools/InfoComponent/InfoComponentTool";
import { EditNodeToolVideoComponent } from "./tools/VideoComponent/VideoComponentTool";
import { HtmlFilterWithTags, HtmlFilterWithoutTags, IMetaDataFromDocument, IsHTMLWithComponents } from "./HtmlFilter";
import { convertComponentsToHtml, convertHtmlToDataComponents } from "./tools/common/utilities";
import {
  Client,
  Component,
  ContainerPublishRequest,
  PublishContainerResponse,
  PublishValidationResponse,
} from "../../../api/Client";
import LoadingPanel from "../../common/LoadingPanel";
import { iInfo } from "../../../interfaces/iInfo";
import { EditNodeToolCarouselComponent } from "./tools/CarouselComponent/CarouselComponentTool";
import { useLocation } from "react-router-dom";
import { getCurrentDateTime } from "../utilities";
import * as cheerio from "cheerio";
import {
  EditNodeToolInteractionComponent,
  InteractionComponent,
} from "./tools/InteractionComponent/InteractionComponentTool";
import PublishDialog from "../../common/PublishDialog";
import { appConfig } from "../../../appConfig";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { FF_PUBLISH } from "../../common/Constants";
import { PublishStatusType, StatusType } from "../../common/Enums";
import { EditNodeToolImageComponent } from "./tools/ImageComponent/ImageComponentTool";

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
  FindAndReplace,
} = EditorTools;

export interface ISaveData {
  components: Component[];
  metaData: IMetaDataFromDocument | undefined;
}

interface ILearningObjectEditorProps {
  // onEditorChanged?: (html: string, editorSchema: any) => void;
  reload?: () => void;
  onEditorBlur?: (components: Component[]) => void;
  hideSaveCancelButtons: boolean;
  hideInteractionButton?: boolean;
  hideCarouselButton?: boolean;
  hidePublishButton?: boolean;
  cancelAlwaysEnabled?: boolean;
  height: string;
  info: iInfo | undefined;
  components?: Component[];
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
  learningObjectId?: number;
  currentSemanticVersion?: string;
  publishStatus?: number;
  publishContainerResponse?: PublishContainerResponse | undefined;
  onPastedMetaData?: (metaData: IMetaDataFromDocument) => void;
  onSave: (data: ISaveData) => Promise<void>;
  onPublish: (semanticVersion: string, containerId: number) => Promise<PublishContainerResponse>;
  onCancel?: () => void;
  onCancelPublish: () => void;
}

const LearningObjectEditor = ({
  reload,
  onEditorBlur,
  hideSaveCancelButtons,
  hideInteractionButton,
  hideCarouselButton,
  hidePublishButton,
  cancelAlwaysEnabled,
  height,
  info,
  components,
  dirty,
  setDirty,
  learningObjectId,
  currentSemanticVersion,
  publishStatus,
  publishContainerResponse,
  onPastedMetaData,
  onSave,
  onPublish,
  onCancel,
  onCancelPublish,
}: ILearningObjectEditorProps) => {
  const editorRef = React.createRef<Editor>();
  const [currentHtml, setCurrentHtml] = React.useState<string | undefined>(undefined);
  const [editorView, setEditorView] = React.useState<EditorView | undefined>(undefined);
  const [editorSchema, setEditorSchema] = React.useState<any>(undefined);
  const [lastSelection, setLastSelection] = React.useState<any>(undefined);
  const [metaData, setMetaData] = React.useState<IMetaDataFromDocument | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isDev = queryParams.get("dev") !== null;

  const [publishValidationResponse, setPublishValidationResponse] = useState<PublishValidationResponse>(
    new PublishValidationResponse({ success: false })
  );

  const usePublish = useFeatureFlag(FF_PUBLISH);
  const [userIsPublisher, setUserIsPublisher] = useState<boolean>(false);

  const init = async () => {
    //Convert data to html and set it in the editor

    let userIsPublisher: boolean = await isPublisher();
    setUserIsPublisher(userIsPublisher);

    if (components) {
      // console.log(" useEffect components", components);
      convertComponentsToHtml(components).then((html) => {
        setCurrentHtml(html);
        // console.log(" useEffect components", html);
        setLoading(false);
      });
    }

    await validateForPublish();
    console.log("init validateForPublish");
  };

  const isPublisher = async (): Promise<boolean> => {
    const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
    const client = new Client(apiUrl);

    let isPublisher: boolean = await client.userIsPublisher();
    return isPublisher;
  };

  const validateForPublish = async () => {
    const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
    const client = new Client(apiUrl);

    let request = new ContainerPublishRequest();
    request.containerId = learningObjectId;
    let publishContainerResponse: PublishContainerResponse = await client.publishValidForPublish("1", request);

    setPublishValidationResponse(publishContainerResponse);
  };

  React.useEffect(() => {
    init();
  }, [components]);

  const onMount = (event: EditorMountEvent) => {
    const { viewProps } = event;
    const { schema } = viewProps.state;
    setEditorSchema(schema);
  };

  const onExecute = (event: EditorExecuteEvent) => {
    // console.log("event", event);
    const state = event.state;
    const trans = event.transaction;
    // const isEditing: boolean = !state.doc.eq(trans.doc);

    if (!editorView) {
      const { view } = event.target;
      setEditorView(view);
    }

    const nextState = ProseMirror.EditorState.create({
      doc: trans.doc,
      selection: trans.selection,
    }); // this will create the next state

    const trNew = nextState.tr;
    const foundNode = GetParentNode(trNew.doc, trNew.selection.from, trNew.selection.to, "str-component", true);
    if (foundNode) {
      //TODO: Nedanstående skulle förhindra att man ändrar i en komponent men då går det inte att uppdatera komponenten alls.
      //      Hittar inget sätt att tillfälligt stänga av detta.
      // if (isEditing) {
      //   setLastSelection(undefined);
      //   console.log("STOPP!");
      //   return false;
      // }
      const from: number = Math.min(foundNode.pos, trans.selection.from);
      const to: number = Math.max(foundNode.pos + foundNode.node.nodeSize, trans.selection.to);
      let selection = TextSelection.create(trNew.doc, from, to);
      // TODO: Nedanstående är en nödlösning som gör det möjligt att navigera uppåt med piltangenterna.
      // Har dock bieffekten att markören ibland hoppar uppåt fast den inte borde.
      if (selection.eq(lastSelection) && from > 0) selection = TextSelection.create(trNew.doc, from - 1, from - 1);
      setLastSelection(selection);
      trans.setSelection(selection);
    } else {
      setLastSelection(undefined);
    }
  };

  const handlePastedMetaData = (metaData: IMetaDataFromDocument) => {
    setMetaData(metaData);
    if (onPastedMetaData) onPastedMetaData(metaData);
  };

  const onPasteHtml = (event: EditorPasteEvent) => {
    if (event.pastedHtml.includes("##")) {
      setLoading(true);
      HtmlFilterWithTags(event, handlePastedMetaData).then((html) => {
        setCurrentHtml(html);
        setLoading(false);
      });
    } else {
      if (!IsHTMLWithComponents(event)) {
        return HtmlFilterWithoutTags(event);
      }
    }
  };

  const isValidHtml = (html: string): boolean => {
    // Check that html doesn't contain any tag with class "str-component-part" outside of a "str-component" tag
    let $ = cheerio.load(html);
    // Select all elements with the class 'str-component-part'
    const parts = $(".str-component-part");

    // Filter those elements to find ones not inside '.str-component'
    const outsideParts = parts.filter((_i, el) => {
      // Return true if the current element does not have an ancestor with class 'str-component'
      return !$(el).parents(".str-component").length;
    });
    let isValid: boolean = !outsideParts.length;
    if (isValid) {
      // Check that html doesn't contain any tag with class "str-component" and a parent tag with "str-component"
      const $ = cheerio.load(html);

      // Select all elements with the class 'str-component'
      const components = $(".str-component");

      // Filter those elements to find ones that have an ancestor with the same class
      const nestedComponents = components.filter((i, el) => {
        // Check if this element has an ancestor with the class 'str-component'
        // The .parents() method traverses up the DOM tree
        // Exclude the current element itself from the search by using .parents() instead of .closest()
        return $(el).parents(".str-component").length > 0;
      });
      isValid = !nestedComponents.length;
    }
    return isValid;
  };

  const onChange = (event: EditorChangeEvent) => {
    const { html } = event;
    if (!isValidHtml(html)) return;

    // if (onEditorChanged) {
    //   const components = convertHtmlToDataComponents(html!, editorSchema);
    //   onEditorChanged(components);
    // }

    // Nedanstående funkade men markören hoppar runt.
    // const components = convertHtmlToDataComponents(html, editorSchema);
    // let newHtml = await convertComponentsToHtml(components);

    // This console entry should be kept for debugging purposes
    console.info(`>>> ${getCurrentDateTime()}: learningObjectId="${learningObjectId}", html`, html);
    if (onEditorBlur) {
      const components = convertHtmlToDataComponents(html!, editorSchema);
      onEditorBlur(components);
    }
    setCurrentHtml(html);
    setDirty(true);
  };

  const onBlur = (event: any) => {
    // if (onEditorBlur) {
    //   const components = convertHtmlToDataComponents(currentHtml!, editorSchema);
    //   onEditorBlur(components);
    // }
  };

  const handleSave = async () => {
    setLoading(true);
    const components = convertHtmlToDataComponents(currentHtml!, editorSchema);

    // console.log("handleSave components", editorSchema);
    // components.forEach((component) => {
    //   console.log("handleSave", component);
    // });

    await onSave({ components, metaData });
    // console.log("handleSave setDirty(false)");
    setDirty(false);
    await validateForPublish();
  };

  const handlePublish = async (newSemanticVersion: string) => {
    // setLoading(true);
    // let success = await onPublish(newSemanticVersion);
    // setLoading(false);
    // setDirty(false);
    // await validateForPublish();
    // setShowPublishDialog(!success);

    setLoading(true);
    let success = await onPublish(newSemanticVersion, learningObjectId!);
    if (success && reload) {
      reload!();
      return;
    }

    setLoading(true);
  };

  const handleCancel = async () => {
    if (!onCancel) {
      return;
    }

    onCancel();
    setDirty(false);
  };

  const [editImageComponent, setEditImageComponent] = useState<any>(null);

  const getTools = () => {
    if (!editorView) {
      return [];
    }

    let components = [
      FormatBlock,
      EditNodeToolInfoComponent,
      //EditNodeToolImageComponent,
      EditNodeToolImageComponent({ learningObjectId: learningObjectId, editImageComponent, setEditImageComponent }), //https://www.telerik.com/forums/kendoreact-editor---how-to-pass-data-to-a-custom-tool
      EditNodeToolVideoComponent,
    ];

    if (!hideCarouselButton) {
      components.push(EditNodeToolCarouselComponent);
    }

    if (!hideInteractionButton) {
      components.push(EditNodeToolInteractionComponent);
    }

    return [
      components,
      [Bold, Italic],
      [OrderedList, UnorderedList],
      [Undo, Redo],
      [FindAndReplace],
      isDev ? [ViewHtml] : [],
    ];
  };

  const [showPublishDialog, setShowPublishDialog] = useState<boolean>(false);
  // console.log("publishContainerResponse", publishContainerResponse);

  const getUnpublishedQuestions = (): any[] => {
    let illegalQuestions: any[] = [];
    const components = convertHtmlToDataComponents(currentHtml!, editorSchema);
    components.forEach((component) => {
      if (component.interaction) {
        let interaction: InteractionComponent = component.interaction;
        if (interaction.questionDto) {
          // console.log("learningObjectCanBePublished-component.interaction", interaction.questionDto!);

          if (interaction.questionDto.publishStatus !== PublishStatusType.Success) {
            illegalQuestions.push(interaction.questionDto);
          }
        }
      }
    });

    return illegalQuestions;
  };

  const getPublishError = (): string => {
    // OBS! GÖR INGA ÄNDRINGAR HÄR UTAN ATT VARA HELT SÄKER!

    if (!userIsPublisher) {
      //console.log("learningObjectCanBePublished userIsPublisher = false");
      return "Du har inte behörighet att publicera";
    }

    if (dirty) {
      //console.log("learningObjectCanBePublished dirty = true");
      return "Det finns osparade ändringar";
    }

    if (info!.statusId !== StatusType.Final) {
      //console.log("learningObjectCanBePublished statusId !== StatusType.Final");
      return "Status måste vara slutlig";
    }

    // TODO: TEMP HACK
    // TODO: detta är ett temp hack då MSAL kommunikationen mellan LCMS och Question INTE fungerar (publishValidationResponse)
    if (!currentHtml || !editorSchema) {
      // console.log("learningObjectCanBePublished !currentHtml || !editorSchema");
      return "Layouten är inte laddad";
    }

    let unpublishedQuestions: any[] = getUnpublishedQuestions();
    if (unpublishedQuestions.length > 0) {
      //console.log("learningObjectCanBePublished illegalQuestions", unpublishedQuestions);
      return "Det finns frågor som inte är publicerade";
    }
    // TODO: SLUT TEMP HACK

    // TODO: denna ska aktiveras så fort MSAL kommunikationen mellan LCMS och Question fungerar
    // if (!publishValidationResponse.success) {
    //   console.log("learningObjectCanBePublished publishValidationResponse.success = false");
    //   return false; // validation of question, interaction, etc. failed or didn't run
    // }

    if (publishStatus === PublishStatusType.None) {
      return "";
    } else if (publishStatus === PublishStatusType.Dirty) {
      return "";
    } else if (publishStatus === PublishStatusType.Failure) {
      return "";
    }

    //console.log("learningObjectCanBePublished publishStatus invalid");
    return "Ogiltig publieringsstatus";
  };

  const handleCancelPublishClick = () => {
    setShowPublishDialog(false);
    onCancelPublish();
  };

  return (
    <div style={{ height: "100%" }}>
      {loading && LoadingPanel}

      <Editor
        ref={editorRef}
        defaultEditMode="div"
        onMount={onMount}
        onExecute={onExecute}
        onPasteHtml={onPasteHtml}
        onChange={onChange}
        tools={getTools()}
        value={currentHtml}
        style={{ height: height }}
        onBlur={onBlur}
      />

      <div className="row">
        <div className="col-12">
          <div className="me-2" style={{ float: "right" }}>
            {!hideSaveCancelButtons && (
              <>
                {!hidePublishButton && usePublish && (
                  <div style={{ display: "inline" }} title={getPublishError()}>
                    <Button
                      disabled={getPublishError() !== ""}
                      onClick={() => setShowPublishDialog(true)}
                      themeColor={"primary"}
                      size="medium"
                      style={{ width: 100 }}
                    >
                      Publicera
                    </Button>
                  </div>
                )}
                <Button
                  disabled={loading || !dirty || !info || !info.customerId}
                  className="m-2"
                  onClick={handleSave}
                  themeColor={"primary"}
                  style={{ width: 100 }}
                  size="medium"
                >
                  Spara
                </Button>
                <Button
                  disabled={!dirty && !cancelAlwaysEnabled}
                  themeColor={"primary"}
                  style={{ width: 100 }}
                  onClick={handleCancel}
                  size="medium"
                >
                  Avbryt
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {((publishContainerResponse && !publishContainerResponse.success) || showPublishDialog) && (
        <PublishDialog
          loading={loading}
          semanticVersion={currentSemanticVersion}
          publishStatus={publishStatus}
          onOkClick={handlePublish}
          onCancelClick={handleCancelPublishClick}
          publishResponse={publishContainerResponse}
        />
      )}
    </div>
  );
};

export default LearningObjectEditor;
