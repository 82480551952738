import React, { ReactNode, useContext, useEffect, useState } from "react";
import {
  InteractionTypeDto,
  QuestionClient,
  StatusDto,
  TemplateDto,
  TextAndMediaTypesDto,
} from "../../api/learnable_question/QuestionClient";
import { appConfig } from "../../appConfig";
import { iCustomer } from "../../interfaces/iCustomer";
import { Client } from "../../api/Client";

export type AppObject = {
  roles: string[];
  statuses: StatusDto[];
  customers: iCustomer[];
  interactionTypes: InteractionTypeDto[];
  textAndMediaTypes: TextAndMediaTypesDto[];
  templates: TemplateDto[];
};

const AppContext = React.createContext<AppObject | undefined>(undefined);
export default AppContext;

type AppProviderProps = {
  children: ReactNode;
};

export const AppContextProvider = ({ children }: AppProviderProps) => {
  const [appContext, setAppContext] = useState<AppObject | undefined>(undefined);

  const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
  const questionClient = new QuestionClient(appConfig.REACT_APP_QUESTION_API_URL);

  const fetchUser = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    return await client.userGet();
  };

  const fetchStatuses = async () => {
    return await questionClient.lookupGetStatuses(appConfig.REACT_APP_VERSION_NO);
  };

  const fetchCustomers = async (): Promise<iCustomer[]> => {
    const result = await questionClient.lookupGetCustomers(appConfig.REACT_APP_VERSION_NO);

    const customers = Object.entries(result).map(([key, value]) => {
      return { id: key, name: value } as iCustomer;
    });

    return customers;
  };

  const fetchInteractionTypes = async () => {
    return await questionClient.lookupGetInteractionTypes(appConfig.REACT_APP_VERSION_NO);
  };

  const fetchTextAndMediaTypes = async (): Promise<TextAndMediaTypesDto[]> => {
    var result = await questionClient.lookupGetTextAndMediaTypes(appConfig.REACT_APP_VERSION_NO);
    return result;
  };

  const fetchTemplates = async (): Promise<TemplateDto[]> => {
    var result = await questionClient.templateGetTemplates(undefined, undefined, "1");

    return result; //.sort((a, b) => a.name!.localeCompare(b.name!));
  };

  useEffect(() => {
    const init = async () => {
      const [user, statuses, customers, interactionTypes, textAndMediaTypes, templates] = await Promise.all([
        fetchUser(),
        fetchStatuses(),
        fetchCustomers(),
        fetchInteractionTypes(),
        fetchTextAndMediaTypes(),
        fetchTemplates(),
      ]);

      const appObject: AppObject = {
        roles: user.roles ?? [],
        statuses: statuses,
        customers: customers,
        interactionTypes: interactionTypes,
        textAndMediaTypes: textAndMediaTypes,
        templates: templates,
      };

      console.log("appObject", appObject);
      setAppContext(appObject);
    };

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!appContext) return <div>Initializing context data...</div>;

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) throw new TypeError("useAppContext must be used within a AppContext.Provider");
  return context;
};
