// import { get } from "services/httpService";
// import { API_URL_FFS } from "utils/constants";
// import { msalInstance } from "utils/helpers/msal/AppMsalProvider";
import axios from "axios";
import { msalInstance } from "..";
import { appConfig } from "../appConfig";
import { API_URL_FFS } from "../components/common/Constants";
import { FeatureFlagClient, FeatureFlagResponse } from "../api/optima_featureFlag/FeatureFlagClient";
let retryInitialFetch = 5;

export const fetchFeatureFlags = async (): Promise<FeatureFlagResponse[]> => {
  const account = msalInstance.getActiveAccount();

  // Check to see if we have an account (eg. user is logged in), if not, retry
  // This check due to the app structure and we might be in the middle of logging in
  if (!account) {
    if (retryInitialFetch > 0) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          retryInitialFetch--;
          resolve(fetchFeatureFlags());
        }, 750);
      });
    } else {
      return [];
    }
  }
  let client = new FeatureFlagClient(appConfig.REACT_APP_FEATUREFLAGS_API_URL);
  const response = await client.featureFlagsListFeatureFlags(appConfig.APP_ID);
  // console.log("Feature flags fetched", response);
  return response;
};

export const fetchFeatureFlag = async (flag: string): Promise<FeatureFlagResponse> => {
  let client = new FeatureFlagClient(appConfig.REACT_APP_FEATUREFLAGS_API_URL);
  const response = await client.featureFlagsGetFeatureFlag(appConfig.APP_ID, flag);
  // console.log("Feature flag fetched", response);
  return response;
};
