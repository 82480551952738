import AddRemoveButton from "./AddRemoveButton";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import ImageDialog from "../../../../optima_image/ImageDialog";
import { GetImageInfoFromContentIdAndVersionId, iconImageWidth, getImageUrl } from "../common/utilities";
import { CarouselComponent } from "./CarouselComponentTool";
import { MediaContainer } from "../../../../optima_image/types";

const CarouselItemComponent = (props: any) => {
  const carouselComponent: CarouselComponent = props.carouselComponent;
  // console.log("props", props);
  // console.log("carouselComponent", carouselComponent);
  const itemIndex: number = props.index;
  const item = carouselComponent.items![itemIndex];
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);

  const addVisible = () => {
    return isLastElement();
  };

  const removeVisible = () => {
    return !isFirstElement();
  };

  const isFirstElement = () => {
    return itemIndex == 0;
  };

  const isLastElement = () => {
    return itemIndex == carouselComponent.items!.length - 1;
  };

  const onImageDialogClose = async (mediaContainer: MediaContainer) => {
    const mediaLink = mediaContainer.imageLinks![0];
    const imageInfo = await GetImageInfoFromContentIdAndVersionId(mediaLink.ContentId, mediaLink.VersionId);
    const url = imageInfo.url || "";
    const newFileName = imageInfo.properties?.find((p) => p.name === "Filename")?.value || "";
    const newLink = JSON.stringify({ contentId: mediaLink.ContentId, versionId: mediaLink.VersionId, src: url });
    props.handleImageChange(itemIndex, newLink, newFileName);
    setVisibleDialog(false);
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-2">
          <img onClick={() => setVisibleDialog(true)} src={getImageUrl(item.image!.link!)} width={iconImageWidth} />
          <br />
          {item.image!.fileName}
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            id={"imagetext" + itemIndex}
            value={item.text}
            onChange={(e) => props.handleTitleChange(itemIndex, e.target.value)}
          />
        </div>
        <div className="col-1">
          <AddRemoveButton
            onClickAdd={props.addElement}
            onClickRemove={() => props.removeElement(itemIndex)}
            addVisible={addVisible()}
            removeVisible={removeVisible()}
          />
        </div>
        <div className="col-1">
          {!isFirstElement() && (
            <Button onClick={() => props.handleSortOrderUpChange(itemIndex)} icon="sort-asc-sm"></Button>
          )}
          {!isLastElement() && (
            <Button onClick={() => props.handleSortOrderDownChange(itemIndex)} icon="sort-desc-sm"></Button>
          )}
        </div>
      </div>
      {visibleDialog && (
        <ImageDialog
          onOkClick={(mediaContainer: MediaContainer) => onImageDialogClose(mediaContainer)}
          onClose={() => setVisibleDialog(false)}
        />
      )}
    </>
  );
};

export default CarouselItemComponent;
