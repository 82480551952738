import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { protectedResources } from "./authConfig";
import { msalInstance } from "..";

// export const getCdsToken = async (): Promise<AuthenticationResult> => {
//   return await getAccessToken(protectedResources.apiCDS.scopes);
// };

export const getClsToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiCLS.scopes);
};

// export const getLasToken = async (): Promise<AuthenticationResult> => {
//   return await getAccessToken(protectedResources.apiLAS.scopes);
// };

export const getLcmsToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiLCMS.scopes);
};

export const getLosToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiLOS.scopes);
};

export const getMesToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiMES.scopes);
};

export const getQusToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiQuS.scopes);
};

export const getSesToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiSES.scopes);
};
export const getFfToken = async (): Promise<AuthenticationResult> => {
  return await getAccessToken(protectedResources.apiFF.scopes);
};

/**
 * Retrieves an access token for the specified scopes.
 * @param scopes - The scopes for which to acquire the access token.
 * @returns A promise that resolves to the authentication result containing the access token.
 * @throws An error if there is no active account.
 */
const getAccessToken = async (scopes: string[]): Promise<AuthenticationResult> => {
  const account = msalInstance.getActiveAccount();
  if (!account) throw new Error("No active account");

  const accessTokenRequest: SilentRequest = {
    scopes: scopes,
    account: account,
  };

  let authenticationResult: AuthenticationResult = await msalInstance.acquireTokenSilent(accessTokenRequest);
  return authenticationResult;
};

/**
 * Sets the authorization header and mode for the given options object.
 * @param options - The request options object.
 * @param token - The access token to be used for authorization.
 * @returns The modified options object with the authorization header and mode set.
 */
export const setOptionHeaders = (options: RequestInit, token: string) => {
  options.headers = {
    ...options.headers,
    authorization: `Bearer ${token}`,
    mode: "cors",
  };

  return options;
};
