import { GridCellProps } from "@progress/kendo-react-grid";
import { PublishStatusType } from "./Enums";

export const VersionCell = (props: GridCellProps) => {
  // console.log("StatusCell", props);

  const getCss = (statusType: PublishStatusType): string => {
    switch (statusType) {
      case PublishStatusType.Success:
        return "k-icon k-i-check-circle";
      default:
        return "";
    }
  };

  const getTitle = (statusType: PublishStatusType): string => {
    switch (statusType) {
      case PublishStatusType.Success:
        return "Success";
      case PublishStatusType.Failure:
        return "Failure";
      case PublishStatusType.Dirty:
        return "Dirty";
      case PublishStatusType.Pending:
        return "Pending";
      default:
        return "";
    }
  };

  return (
    <td>
      <div className="leftMiddle ">
        <span title={getTitle(props.dataItem.publishStatus)} className="me-2">
          {props.dataItem.semanticVersion}
        </span>
        <span style={{ color: "#1274AC" }} className={getCss(props.dataItem.publishStatus)}></span>
      </div>
    </td>
  );
};
