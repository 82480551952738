declare let window: any;

export const appConfig = {
  REACT_APP_LCMS_API_URL: window?.env?.REACT_APP_LCMS_API_URL,
  REACT_APP_VERSION_NO: window?.env?.REACT_APP_VERSION_NO,
  //REACT_APP_SEARCH_API_URL_local: window?.env?.REACT_APP_SEARCH_API_URL_local,
  REACT_APP_SEARCH_API_URL: window?.env?.REACT_APP_SEARCH_API_URL,
  //REACT_APP_SEARCH_API_URLX: window?.env?.REACT_APP_SEARCH_API_URLX,
  REACT_APP_QUESTION_API_URL: window?.env?.REACT_APP_QUESTION_API_URL,
  // REACT_APP_OPTIMA_FF_API_URL: window?.env?.REACT_APP_OPTIMA_FF_API_URL,
  //REACT_APP_QUESTION_API_URL_: window?.env?.REACT_APP_QUESTION_API_URL_,
  REACT_APP_CLASSIFICATION_API_URL: window?.env?.REACT_APP_CLASSIFICATION_API_URL,
  //REACT_APP_CLASSIFICATION_API_URL_local: window?.env?.REACT_APP_CLASSIFICATION_API_URL_local,
  REACT_APP_PREVIEW_CLIENT_URL: window?.env?.REACT_APP_PREVIEW_CLIENT_URL,
  REACT_APP_MEDIA_API_URL: window?.env?.REACT_APP_MEDIA_API_URL,
  //REACT_APP_OPTIMA_MEDIA_API_URL_local: window?.env?.REACT_APP_OPTIMA_MEDIA_API_URL_local,

  // REACT_APP_LEARNININGSTRUCTURE_API_URL: window?.env?.REACT_APP_LEARNININGSTRUCTURE_API_URL,
  REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL: window?.env?.REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL,
  REACT_APP_QUESTION_API_VERSION: window?.env?.REACT_APP_QUESTION_API_VERSION,
  REACT_APP_TITLE: window?.env?.REACT_APP_TITLE,
  REACT_APP_USE_QUESTION_COPY: window?.env?.REACT_APP_USE_QUESTION_COPY,
  authTenantId: window?.env?.AUTH_TENANT_ID,
  authClientId: window?.env?.AUTH_CLIENT_ID,
  authUseVerboseLogging: window?.env?.AUTH_USE_VERBOSE_LOGGING,

  // authScopesCdsRead: window?.env?.AUTH_SCOPES_CDS_READ,
  // authScopesCdsWrite: window?.env?.AUTH_SCOPES_CDS_WRITE,

  authScopesClsRead: window?.env?.AUTH_SCOPES_CLS_READ,

  // authScopesLasRead: window?.env?.AUTH_SCOPES_LAS_READ,
  // authScopesLasWrite: window?.env?.AUTH_SCOPES_LAS_WRITE,

  authScopesLcmsRead: window?.env?.AUTH_SCOPES_LCMS_READ,
  authScopesLcmsWrite: window?.env?.AUTH_SCOPES_LCMS_WRITE,

  authScopesLosRead: window?.env?.AUTH_SCOPES_LOS_READ,

  authScopesMesRead: window?.env?.AUTH_SCOPES_MES_READ,

  authScopesQusRead: window?.env?.AUTH_SCOPES_QUS_READ,
  authScopesQusWrite: window?.env?.AUTH_SCOPES_QUS_WRITE,

  authScopesSesRead: window?.env?.AUTH_SCOPES_SES_READ,
  authScopesFfsRead: window?.env?.AUTH_SCOPES_FFS_READ,

  REACT_APP_FEATUREFLAGS_API_URL: window?.env?.REACT_APP_FEATUREFLAGS_API_URL,
  APP_ID: window?.env?.APP_ID,
};

for (const [key, value] of Object.entries(appConfig)) {
  if (value === null || value === "" || value === undefined) {
    throw Error(`Missing value for env variable ${key} in env-config`);
  }
}
