import { copyQuestionDto } from "../../../../api/learnable_question/apiFunctions";
import {
  QuestionDto,
  QuestionTextDto,
  TextDto,
  MediaDto,
  QuestionMediaDto,
  InteractionPropertyDto,
  InteractionTypePropertyDto,
  LearningObjectiveDto,
  TextAndMedia,
  TemplateDto,
  InteractionTypeProperties,
  InteractionTypeDto,
  ValidationDto,
  DeviationDto,
  TemplateData,
} from "../../../../api/learnable_question/QuestionClient";
//import { ImagesDto } from '../../../../api/optima_image/OptimaImageClient';
import { iQuestionDtoExtended } from "../../../../interfaces/iQuestionDtoExtended";
import { iTemplateDataItem } from "../../../../interfaces/iTemplateDataItem";
import { getNextNegativeID, isQuestionOption } from "./helperFunctions";
import { GetAssetResponse } from "../../../../api/optima_image/OptimaImageClient";
// import { iImageLink } from "../../../../interfaces/iMediaLink";
import {
  INTERACTION_TYPE_NAME_MATCHING,
  LEARNING_OBJECTIVES_ROOT_ID,
  MEDIA_LINK_TYPE_MEDIA_SERVICE,
  MEDIA_LINK_TYPE_MEDIA_VIDEO,
  MEDIA_LINK_TYPE_RECTANGLE,
  QUESTION_OPTION_SOURCE_TYPE_ID,
  QUESTION_OPTION_TARGET_TYPE_ID,
  SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE,
} from "../../../common/Constants";
import iMediumPreview from "../../../../interfaces/iMediumPreview";
import { iImageLink } from "../../../optima_image/interfaces";
import { MediaContainer } from "../../../optima_image/types";
import { iClassificationItem } from "../../../common/classifications/interfaces";

export const createQuestionDto = (templateDto: TemplateDto): iQuestionDtoExtended => {
  let question = new QuestionDto() as iQuestionDtoExtended;
  question.customerId = templateDto.customerId;
  question.interactionTypeId = templateDto.template!.interactionType;
  question.subQuestionTypeId = templateDto.template!.subQuestionType;
  // question.mediumImageAreas = [];
  question.statusId = 1; //draft
  question.id = 0;
  question.templateId = templateDto.id;
  question.questionTexts = [];
  question.classifications = undefined;
  question.interactionProperties = [];
  question.learningObjectiveDtos = [];
  question.options = [];
  question.questionMedia = [];
  question.properties = [];

  return question;
};
// export const syncQuestionClassificationsWithTemplate = (question: QuestionDto, templateClassifications: ClassificationDto[]) => {
//   templateClassifications.forEach((templateClassification) => {
//     let found = question.classifications?.find((x) => x.classificationId === templateClassification.classificationId);
//     if (!found) {
//       question.classifications?.push(templateClassification);
//     }
//   });
//   // templateDataItems
//   //   .filter((x) => x.text && x.text.min! > 0)
//   //   .forEach((templateDataItem) => {
//   //     if (isQuestionOption(templateDataItem)) return;
//   //     let expectedCount = templateDataItem.text!.min!;
//   //     let actualCount = question.questionTexts!.filter((x) => x.textTypeId == templateDataItem.textMediaTypeId).length;

//   //     while (actualCount < expectedCount) {
//   //       actualCount++;

//   //       let questionText: QuestionTextDto = createQuestionTextDto(question, templateDataItem, undefined);
//   //       question.questionTexts!.push(questionText);
//   //     }
//   //   });
// };
export const syncQuestionTextsWithTemplate = (question: QuestionDto, templateDataItems: TextAndMedia[]) => {
  templateDataItems
    .filter((x) => x.text && x.text.min! > 0)
    .forEach((templateDataItem) => {
      if (isQuestionOption(templateDataItem)) return;
      let expectedCount = templateDataItem.text!.min!;
      let actualCount = question.questionTexts!.filter((x) => x.textTypeId == templateDataItem.textMediaTypeId).length;

      while (actualCount < expectedCount) {
        actualCount++;

        let questionText: QuestionTextDto = createQuestionTextDto(question, templateDataItem, undefined);
        question.questionTexts!.push(questionText);
      }
    });
};

export const syncQuestionMediaWithTemplate = (question: QuestionDto, templateDataItems: TextAndMedia[]) => {
  templateDataItems
    .filter((x) => x.media && x.media.min! > 0)
    .forEach((templateDataItem) => {
      if (isQuestionOption(templateDataItem)) return;
      let expectedCount = templateDataItem.media!.min!;
      let actualCount = question.questionMedia!.filter((x) => x.typeId == templateDataItem.textMediaTypeId).length;

      while (actualCount < expectedCount) {
        actualCount++;
        let questionMedia: QuestionMediaDto = createQuestionMediaDto(question, templateDataItem, undefined);
        question.questionMedia!.push(questionMedia);
      }
    });
};

export const syncQuestionOptionWithTemplate = (question: iQuestionDtoExtended, templateDataItems: TextAndMedia[]) => {
  templateDataItems
    .filter((x) => isQuestionOption(x))
    .forEach((templateDataItem) => {
      if (isMedia(templateDataItem)) {
        syncOptionMedia(question, templateDataItem);
      }

      if (isText(templateDataItem)) {
        syncOptionText(question, templateDataItem);
      }

      setQuestionOptionSortOrder(question, templateDataItem);
    });
};

export const setQuestionOptionSortOrder = (question: QuestionDto, templateDataItem: TextAndMedia) => {
  let questionTexts = question
    .questionTexts!.filter((x) => x.textTypeId === templateDataItem.textMediaTypeId)!
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });
  let questionMedia = question
    .questionMedia!.filter((x) => x.typeId === templateDataItem.textMediaTypeId)!
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });

  // Make sure the same optionId has the same sortOrder in text and media
  if (templateDataItem.text!.min! >= templateDataItem.media!.min!) {
    let newSortOrder = 0;
    questionTexts.forEach((x) => {
      x.sortOrder = ++newSortOrder;

      let questionMedium = questionMedia.find((y) => y.optionId === x.optionId);
      if (questionMedium) {
        questionMedium.sortOrder = x.sortOrder;
      }
    });
  } else if (templateDataItem.media!.min! > templateDataItem.text!.min!) {
    let newSortOrder = 0;
    questionMedia.forEach((x) => {
      x.sortOrder = ++newSortOrder; //findFirstAvailableSortOrder(questionMedia, x.sortOrder!);

      let questionText = questionTexts.find((y) => y.optionId === x.optionId);
      if (questionText) {
        questionText.sortOrder = x.sortOrder;
      }
    });
  }
};

const syncOptionMedia = (question: iQuestionDtoExtended, templateDataItem: TextAndMedia) => {
  let actualCount = getUniqeMediaOptionIds(
    getQuestionMedia(question, templateDataItem.textMediaTypeId!),
    templateDataItem.textMediaTypeId!
  ).length;

  //let expectedCount = templateDataItem.media!.min!;
  let expectedCount = Math.max(
    templateDataItem.media!.min!,
    getQuestionTexts(question, templateDataItem.textMediaTypeId!).length
  );

  while (actualCount < expectedCount) {
    actualCount++;

    let questionTexts: QuestionTextDto[] = getQuestionTexts(question, templateDataItem.textMediaTypeId!);
    let questionMedia: QuestionMediaDto[] = getQuestionMedia(question, templateDataItem.textMediaTypeId!);

    let uniqueMediaOptionIds: string[] = getUniqeMediaOptionIds(questionMedia, templateDataItem.textMediaTypeId!);
    let uniqueTextOptionIds: string[] = getUniqeTextOptionIds(questionTexts, templateDataItem.textMediaTypeId!);
    let inTextsButNotMedia: string[] = uniqueTextOptionIds.filter((x) => !uniqueMediaOptionIds.includes(x));

    // om vi hittar ett optionId i text som inte finns i media så använder vi det, annars nytt
    let optionMediaId: string =
      inTextsButNotMedia.length > 0 ? inTextsButNotMedia[0] : getNextNegativeOptionId(question).toString(); // assume new
    let questionMedium: QuestionTextDto = createQuestionMediaDto(question, templateDataItem, optionMediaId);
    question.questionMedia!.push(questionMedium);
  }
};

const syncOptionText = (question: iQuestionDtoExtended, templateDataItem: TextAndMedia) => {
  let actualCount = getQuestionTexts(question, templateDataItem.textMediaTypeId!).length;
  //let expectedCount = templateDataItem.text!.min!;

  let expectedCount = Math.max(
    templateDataItem.text!.min!,
    getQuestionMedia(question, templateDataItem.textMediaTypeId!).length
  );

  while (actualCount < expectedCount) {
    actualCount++;

    let questionTexts: QuestionTextDto[] = getQuestionTexts(question, templateDataItem.textMediaTypeId!);
    let questionMedia: QuestionMediaDto[] = getQuestionMedia(question, templateDataItem.textMediaTypeId!);

    let uniqueMediaOptionIds: string[] = getUniqeMediaOptionIds(questionMedia, templateDataItem.textMediaTypeId!);
    let uniqueTextOptionIds: string[] = getUniqeTextOptionIds(questionTexts, templateDataItem.textMediaTypeId!);
    let inMediaButNotTexts: string[] = uniqueMediaOptionIds.filter((x) => !uniqueTextOptionIds.includes(x));

    // om vi hittar ett optionId i media som inte finns i text s� anv�nder vi det, annars nytt
    let optionTextId: string =
      inMediaButNotTexts.length > 0 ? inMediaButNotTexts[0] : getNextNegativeOptionId(question).toString();
    let questionText: QuestionTextDto = createQuestionTextDto(question, templateDataItem, optionTextId);
    question.questionTexts!.push(questionText);
  }
};

export const syncQuestionInteractionPropertiesWithTemplate = (
  question: QuestionDto,
  templateInteractionProperties: InteractionTypeProperties[],
  interactionTypeProperties: InteractionTypePropertyDto[]
) => {
  templateInteractionProperties.forEach((templateInteractionProperty) => {
    let interactionTypeProperty = interactionTypeProperties.find(
      (x) => x.property == templateInteractionProperty.property && x.interactionTypeId == question.interactionTypeId
    );
    if (!interactionTypeProperty) return; // || !templateInteractionProperty.render) return;

    let defaultValue = templateInteractionProperty.defaultValue;
    if (interactionTypeProperty) {
      //TODO: get datatype as string, instead of id, from WebApi
      if (interactionTypeProperty!.dataTypeId === 4) {
        defaultValue = defaultValue === "true" ? "1" : "0";
      }
    }

    let found: InteractionPropertyDto | undefined = question.interactionProperties?.find(
      (x) => x.interactionPropertyId == interactionTypeProperty?.id
    );
    if (!found) {
      let newInteractionProperty = createInteractionPropertyDto(defaultValue, interactionTypeProperty!);
      question.interactionProperties!.push(newInteractionProperty);
    }
  });
};

export const syncMediaTextOptions = (question: QuestionDto, templateDataItem: TextAndMedia) => {
  let mediaOptionIds: string[] = getUniqeMediaOptionIds(question.questionMedia!, templateDataItem.textMediaTypeId!);
  let textOptionIds: string[] = getUniqeTextOptionIds(question.questionTexts!, templateDataItem.textMediaTypeId!);

  mediaOptionIds.forEach((x) => {
    if (!textOptionIds.includes(x)) {
      let questionText: QuestionTextDto = createQuestionTextDto(question, templateDataItem, x);
      question.questionTexts!.push(questionText);
    }
  });

  textOptionIds.forEach((x) => {
    if (!mediaOptionIds.includes(x)) {
      let questionMedia: QuestionMediaDto = createQuestionMediaDto(question, templateDataItem, x);
      question.questionMedia!.push(questionMedia);
    }
  });
};

//export const getElementsCount = (question: QuestionDto, level: number = 1) => {
//    // "1" = level 1
//    // "1.1" = level 2
//    // "1.2" = level 3 etc (currently not used)

//    if (level < 1) {
//        throw new Error('Minimum level is 1');
//    }

//    let count: number = 0;;
//    let mediaIds = getUniqeMediaOptionIds(question.questionMedia!);
//    let textIds = getUniqeTextOptionIds(question.questionTexts!);

//    let mediaCount: number = 0;
//    mediaIds.forEach(x => {
//        if (x.split(".").length === level) {
//            mediaCount++;
//        }
//    })

//    let textCount: number = 0;
//    textIds.forEach(x => {
//        if (x.split(".").length === level) {
//            textCount++;
//        }
//    })

//    count = Math.max(mediaCount, textCount);

//    return count;
//}

export const getUniqueOptionIdsSorted = (question: QuestionDto, textMediaTypeId: number) => {
  let optionIds: string[] = [];
  getUniqeTextOptionIds(question.questionTexts!, textMediaTypeId).forEach((x) => {
    if (x.length > 0 && !optionIds.includes(x)) {
      optionIds.push(x);
    }
  });

  getUniqeMediaOptionIds(question.questionMedia!, textMediaTypeId).forEach((x) => {
    if (x.length > 0 && !optionIds.includes(x)) {
      optionIds.push(x);
    }
  });

  return optionIds;
};

export const getOptionElementsCount = (question: QuestionDto, textMediaTypeId: number) => {
  let mediaLength = getUniqeMediaOptionIds(question.questionMedia!, textMediaTypeId).length;
  let textLength = getUniqeTextOptionIds(question.questionTexts!, textMediaTypeId).length;
  let count = Math.max(textLength, mediaLength);

  return count;
};

export const getUniqeMediaOptionIds = (questionMedia: QuestionMediaDto[], textMediaTypeId: number) => {
  let optionIds: string[] = [];
  questionMedia
    .filter((x) => x.typeId === textMediaTypeId)
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    })
    .forEach((x) => {
      if (x.optionId && !optionIds.includes(x.optionId)) {
        optionIds.push(x.optionId);
      }
    });

  return optionIds;
};

export const getUniqeTextOptionIds = (questionText: QuestionTextDto[], textMediaTypeId: number) => {
  let optionIds: string[] = [];
  questionText
    .filter((x) => x.textTypeId === textMediaTypeId)
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    })
    .forEach((x) => {
      if (x.optionId && !optionIds.includes(x.optionId)) {
        optionIds.push(x.optionId);
      }
    });

  return optionIds;
};

export const createQuestionTextDto = (
  question: QuestionDto,
  templateDataItem: TextAndMedia,
  optionId: string | undefined,
  sortOrder: number = 0
): QuestionDto => {
  let questionTexts: QuestionTextDto[] = question!.questionTexts ?? [];

  let newQuestionText: QuestionTextDto = new QuestionTextDto();
  newQuestionText.textTypeId = templateDataItem.textMediaTypeId;
  newQuestionText.optionId = optionId ?? "";
  newQuestionText.sortOrder = sortOrder;
  newQuestionText.id = getNextNegativeID(questionTexts.map((x) => x.id));
  newQuestionText.texts = [createTextDto(question)];

  return newQuestionText;
};

export const createQuestionMediaDto = (
  question: QuestionDto,
  templateDataItem: TextAndMedia,
  optionId: string | undefined,
  sortOrder: number = 0
): QuestionMediaDto => {
  let questionMedia: QuestionMediaDto[] = question!.questionMedia ?? [];

  let newQuestionMedium: QuestionMediaDto = new QuestionMediaDto();
  newQuestionMedium.typeId = templateDataItem.textMediaTypeId;
  newQuestionMedium.optionId = optionId ?? "";
  newQuestionMedium.sortOrder = sortOrder;
  newQuestionMedium.mediaTypeId = 1;
  newQuestionMedium.id = getNextNegativeID(questionMedia.map((x) => x.id));
  newQuestionMedium.media = [];

  return newQuestionMedium;
};

export const createCorrectResponsesPatternInteractionPropertyDto = (
  optionId: string,
  interactionPropertyId: number
): InteractionPropertyDto => {
  let interactionPropertyDto = new InteractionPropertyDto();

  interactionPropertyDto.dataType = 1;
  interactionPropertyDto.value = optionId;
  interactionPropertyDto.property = "correctResponsesPattern";
  interactionPropertyDto.interactionPropertyId = interactionPropertyId;

  return interactionPropertyDto;
};

export const createInteractionPropertyDto = (
  value: any,
  interactionTypeProperty: InteractionTypePropertyDto
): InteractionPropertyDto => {
  let newInteractionPropertyDto = new InteractionPropertyDto();

  newInteractionPropertyDto.value = value;
  newInteractionPropertyDto.property = interactionTypeProperty.property;
  newInteractionPropertyDto.interactionPropertyId = interactionTypeProperty.id;

  return newInteractionPropertyDto;
};
export const getInteractionPropertyFromTemplate = (templateData: TemplateData, property: string) => {
  let interactionTypeProperty = templateData.interactionProperties!.find((x) => x.property === property);
  return interactionTypeProperty;
};
export const createTextDto = (question: QuestionDto): TextDto => {
  let text: TextDto = new TextDto();
  text.customerId = question!.customerId;
  text.formatId = 1; //TODO: plain text = 1
  text.text = "";
  return text;
};

export const getMediaLinkAsJson = (image: GetAssetResponse) => {
  let media: iImageLink = {
    ContentId: image.contentId!,
    VersionId: image.versionId!,
    Url: image.url!,
    Title: image.properties?.find((x) => x.name === "Filename")?.value!,
    toJSON() {
      // vi ignorerar URL n�r vi sparar till databasen, ska alltid h�mtas via media servicen
      return {
        ContentId: this.ContentId,
        VersionId: this.VersionId,
      };
    },
  };
  return media;
};

// const getMediaLink = (linkTypeId: number, image: GetAssetResponse | undefined) => {
//   switch (linkTypeId) {
//     case 1:
//       return JSON.stringify(getMediaLinkAsJson(image!));
//     case 2:
//       return JSON.stringify([{}]);
//     default:
//       return JSON.stringify([{}]);
//   }
// };
const getMediaLink = (mediaContainer: MediaContainer) => {
  if (mediaContainer.areas) {
    return JSON.stringify(mediaContainer.areas[0]);
  }
  if (mediaContainer.imageLinks) {
    return JSON.stringify(mediaContainer.imageLinks[0]);
  }
  if (mediaContainer.videos) {
    return mediaContainer.videos[0].source;
  }
};
const getMediaName = (mediaContainer: MediaContainer) => {
  if (mediaContainer.areas) {
    return "";
  }
  if (mediaContainer.imageLinks) {
    return mediaContainer.imageLinks![0].VersionId;
  }
  if (mediaContainer.videos) {
    return mediaContainer.videos[0].name;
  }
};
export const createMediaDto = (
  question: QuestionDto,
  mediaContainer: MediaContainer,
  templateDataItem: TextAndMedia
): MediaDto => {
  const linkTypeId: number =
    question.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE &&
    templateDataItem.textMediaTypeId === QUESTION_OPTION_SOURCE_TYPE_ID
      ? MEDIA_LINK_TYPE_RECTANGLE
      : MEDIA_LINK_TYPE_MEDIA_SERVICE;
  let medium: MediaDto = new MediaDto();
  medium.customerId = question!.customerId;
  medium.link = getMediaLink(mediaContainer);
  medium.linkTypeId = mediaContainer.videos ? mediaContainer.videos[0].linkTypeId : linkTypeId;
  medium.name = getMediaName(mediaContainer);
  medium.customerId = question!.customerId;
  return medium;
};

export const getQuestionText = (
  question: QuestionDto,
  questionTextId: number,
  textMediaTypeId: number
): QuestionTextDto | undefined => {
  let questionTexts = question!.questionTexts as QuestionTextDto[];
  return questionTexts.filter((x) => x.id == questionTextId && x.textTypeId == textMediaTypeId)[0];
};

export const getQuestionTexts = (question: QuestionDto, textMediaTypeId: number): QuestionTextDto[] => {
  if (!question!.questionTexts) {
    return [];
  }

  let questionTexts = question!.questionTexts as QuestionTextDto[];
  return questionTexts.filter((x) => x.textTypeId == textMediaTypeId);
};

export const getQuestionOptionText = (
  question: QuestionDto,
  optionId: string,
  textMediaTypeId: number
): QuestionTextDto | undefined => {
  let questionTexts = question!.questionTexts as QuestionTextDto[];
  return questionTexts.filter((x) => x.optionId == optionId && x.textTypeId == textMediaTypeId)[0];
};

export const getQuestionMedium = (
  question: QuestionDto,
  questionMediumId: number,
  textMediaTypeId: number
): QuestionMediaDto | undefined => {
  let questionMedia = question!.questionMedia as QuestionMediaDto[];
  return questionMedia.filter((x) => x.id == questionMediumId && x.typeId == textMediaTypeId)[0];
};

export const getQuestionMedia = (question: QuestionDto, textMediaTypeId: number): QuestionMediaDto[] => {
  if (!question!.questionMedia) {
    return [];
  }

  let questionMedia = question!.questionMedia as QuestionMediaDto[];
  return questionMedia.filter((x) => x.typeId == textMediaTypeId);
};

export const getQuestionInteractionProperties = (question: iQuestionDtoExtended) => {
  if (!question!.interactionProperties) {
    return [];
  }

  let interactionProperties = question!.interactionProperties as InteractionPropertyDto[];
  return interactionProperties.filter((x) => x.interactionPropertyId === question.correctResponsePatternId);
};

export const syncSequencingValuesForAllTypes = (
  interactionTypes: InteractionTypeDto[],
  question: iQuestionDtoExtended,
  templateDataItems: TextAndMedia[]
) => {
  templateDataItems
    .filter((x) => isQuestionOption(x))
    .forEach((templateDataItem) => {
      syncOptionValues(interactionTypes, question, templateDataItem);
    });
};

export const syncOptionValues = (
  interactionTypes: InteractionTypeDto[],
  question: iQuestionDtoExtended,
  templateDataItem: TextAndMedia
) => {
  let interactionTypeName: string | undefined = getQuestionInteractionTypeName(interactionTypes, question);

  switch (interactionTypeName) {
    case "choice":
      syncChoiceValues(question, templateDataItem);
      break;
    case "sequencing":
      syncSequencingValues(question, templateDataItem);
      break;
    case "matching":
      syncMatchingValues(question, templateDataItem);
      break;
    default:
      return;
  }
};

const syncChoiceValues = (question: iQuestionDtoExtended, templateDataItem: TextAndMedia) => {
  let correctResponsePatternInteractionProperty: InteractionPropertyDto | undefined =
    question?.interactionProperties!.find((x) => x.interactionPropertyId === question?.correctResponsePatternId)!;

  let optionIdsSorted = getUniqueOptionIdsSorted(question, templateDataItem.textMediaTypeId!);
  //temporary fix
  let newCorrectResponse: string[][] = [];
  const correctResponses: string[][] = safeParse(correctResponsePatternInteractionProperty.value!);

  correctResponses.forEach((x) => {
    const found = optionIdsSorted.find((y) => y === x.toString());
    if (found) {
      newCorrectResponse.push(x);
    }
  });
  //assign correct response.
  //correctResponsePatternInteractionProperty.value = newCorrectResponse.length > 0 ? newCorrectResponse.join(",") : "";
  correctResponsePatternInteractionProperty.value =
    newCorrectResponse.length > 0 ? JSON.stringify([newCorrectResponse]) : JSON.stringify(emptypCorrectResponsePattern);
};

const syncMatchingValues = (question: iQuestionDtoExtended, templateDataItem: TextAndMedia) => {
  let sourceOptionsIds: string[] = question
    .questionTexts!.filter((x) => x.textTypeId === QUESTION_OPTION_SOURCE_TYPE_ID)!
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    })
    .map((x) => x.optionId) as string[];
  if (sourceOptionsIds.length === 0) {
    sourceOptionsIds = question
      .questionMedia!.filter((x) => x.typeId === QUESTION_OPTION_SOURCE_TYPE_ID)!
      .sort(function (a, b) {
        return a.sortOrder! - b.sortOrder!;
      })
      .map((x) => x.optionId) as string[];
  }

  let targetOptionIds = question
    .questionTexts!.filter((x) => x.textTypeId === QUESTION_OPTION_TARGET_TYPE_ID)!
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    })
    .map((x) => x.optionId) as string[];
  if (targetOptionIds.length === 0) {
    targetOptionIds = question
      .questionMedia!.filter((x) => x.typeId === QUESTION_OPTION_TARGET_TYPE_ID)!
      .sort(function (a, b) {
        return a.sortOrder! - b.sortOrder!;
      })
      .map((x) => x.optionId) as string[];
  }

  let count: number = Math.max(sourceOptionsIds.length, targetOptionIds.length);
  let optionIds: number[][] = [];
  for (var i = 0; i < count; i++) {
    let pair: number[] = [];
    if (i < sourceOptionsIds.length && i < targetOptionIds.length) {
      pair.push(parseInt(sourceOptionsIds[i]));
      pair.push(parseInt(targetOptionIds[i]));

      optionIds.push(pair);
    }
  }

  //console.log("syncMatchingValues", JSON.stringify(optionIds));
  let correctResponsePatternInteractionProperty: InteractionPropertyDto | undefined =
    question?.interactionProperties!.find((x) => x.interactionPropertyId === question?.correctResponsePatternId)!;
  correctResponsePatternInteractionProperty.value = JSON.stringify([optionIds]);
};

const syncSequencingValues = (question: iQuestionDtoExtended, templateDataItem: TextAndMedia) => {
  let questionTexts = getQuestionTexts(question!, templateDataItem.textMediaTypeId!)!.sort(function (a, b) {
    return a.sortOrder! - b.sortOrder!;
  });
  let questionMedia = getQuestionMedia(question!, templateDataItem.textMediaTypeId!)!.sort(function (a, b) {
    return a.sortOrder! - b.sortOrder!;
  });

  var optionIds: number[] = [];
  for (let i = 0; i < getOptionElementsCount(question, templateDataItem.textMediaTypeId!); i++) {
    if (questionTexts!.length > i) {
      optionIds.push(parseInt(questionTexts![i].optionId!));
    } else if (questionMedia!.length > i) {
      optionIds.push(parseInt(questionMedia![i].optionId!));
    }
  }

  let correctResponsePatternInteractionProperty: InteractionPropertyDto | undefined =
    question?.interactionProperties!.find((x) => x.interactionPropertyId === question?.correctResponsePatternId)!;
  // console.log("syncSequencingValues", JSON.stringify([optionIds]));
  correctResponsePatternInteractionProperty.value = JSON.stringify([optionIds]); //optionIds.join(",");
};

export const getNumberOfTextElements = (question: QuestionDto, templateDataItem: iTemplateDataItem) => {
  if (question == undefined) {
    return templateDataItem.min!;
  }

  if (question.questionTexts == undefined) {
    return templateDataItem.min!;
  }

  let questionTexts = question.questionTexts.filter((x) => x.textTypeId == templateDataItem.textMediaTypeId);

  return questionTexts.length < templateDataItem.min! ? templateDataItem.min! : questionTexts.length;
};

export const getNumberOfMediaElements = (question: QuestionDto, templateDataItem: iTemplateDataItem) => {
  if (question == undefined) {
    return templateDataItem.min!;
  }

  if (question.questionMedia == undefined) {
    return templateDataItem.min!;
  }

  let questionMedias = question.questionMedia.filter((x) => x.typeId == templateDataItem.textMediaTypeId);

  return questionMedias.length < templateDataItem.min! ? templateDataItem.min! : questionMedias.length;
};

export const getNumberOfTextMediaElements = (question: QuestionDto, templateDataItem: iTemplateDataItem) => {
  if (question == undefined) {
    return templateDataItem.min!;
  }

  if (question.questionMedia == undefined) {
    return templateDataItem.min!;
  }

  let questionText = question.questionTexts!.filter((x) => x.textTypeId == templateDataItem.textMediaTypeId);
  let questionMedias = question.questionMedia.filter((x) => x.typeId == templateDataItem.textMediaTypeId);

  return questionText.length < templateDataItem.min! ? templateDataItem.min! : questionText.length;
};

export const addQuestionText = (question: QuestionDto, templateDataItem: TextAndMedia) => {
  let newQuestionText: QuestionTextDto = createQuestionTextDto(question!, templateDataItem, undefined);
  question!.questionTexts!.push(newQuestionText);
};

export const removeQuestionText = (question: QuestionDto, questionTextMediaId: number) => {
  let questionTexts: QuestionTextDto[] = question!.questionTexts as QuestionTextDto[];
  let index = questionTexts.findIndex((x) => x.id === questionTextMediaId);
  question!.questionTexts!.splice(index, 1);
};

export const removeQuestionMedium = (question: QuestionDto, questionTextMediaId: number) => {
  let questionMedia: QuestionTextDto[] = question!.questionMedia as QuestionTextDto[];
  let index = questionMedia.findIndex((x) => x.id === questionTextMediaId);
  question!.questionMedia!.splice(index, 1);
};

export const updateQuestionTextText = (
  question: iQuestionDtoExtended,
  questionText: QuestionTextDto,
  newValue: string
) => {
  let questionTexts: QuestionTextDto[] = question!.questionTexts as QuestionTextDto[];
  let index = questionTexts.findIndex((x) => x.id == questionText.id);
  question!.questionTexts![index].texts![0].text = newValue;
};

export const getQuestionCopy = (question: iQuestionDtoExtended): iQuestionDtoExtended => {
  let newQuestion = { ...question! } as iQuestionDtoExtended;
  let newQuestionTexts: QuestionTextDto[] = question!.questionTexts!.map((x) => ({ ...x })) as QuestionTextDto[]; //[...question!.questionTexts as QuestionTextDto[]];
  let newQuestionMedia: QuestionMediaDto[] = question!.questionMedia!.map((x) => ({ ...x })) as QuestionMediaDto[]; //[...question!.questionMedia as QuestionMediaDto[]];
  // copy interactionProperties
  let newInterationProperties = question!.interactionProperties!.map((x) => ({ ...x })) as InteractionPropertyDto[]; //[...question!.interactionProperties as InteractionPropertyDto[]];
  newQuestion.interactionProperties = newInterationProperties;

  //copyt learningObjectives
  let newLearningObjectives = question!.learningObjectiveDtos!.map((x) => ({ ...x })) as LearningObjectiveDto[]; //[...question!.learningObjectiveDtos as LearningObjectiveDto[]];
  newQuestion.learningObjectiveDtos = newLearningObjectives;

  // copy questionTexts
  for (var i = 0; i < newQuestionTexts.length; i++) {
    newQuestionTexts[i].texts = newQuestionTexts[i].texts!.map((x) => ({ ...x })) as TextDto[]; //[...question!.questionTexts![i]!.texts!];
  }
  newQuestion.questionTexts = newQuestionTexts;

  // copy questionMedia
  for (var i = 0; i < newQuestionMedia.length; i++) {
    newQuestionMedia[i].media = newQuestionMedia[i].media!.map((x) => ({ ...x })) as MediaDto[]; //[...question!.questionMedia![i]!.media!];
  }
  newQuestion.questionMedia = newQuestionMedia;

  // newQuestion.mediumImageAreas = newQuestion.mediumImageAreas!.map((x) => ({ ...x })) as Region[];

  return newQuestion;
};

export const validateOnSave = (question: iQuestionDtoExtended) => {
  let newQuestion = getQuestionCopy(question);
  if (question.questionMedia && question.questionMedia.length > 0) {
    for (var i = 0; i < question.questionMedia!.length; i++) {
      if (question.questionMedia![i].media?.length === 0) {
        removeQuestionMedium(newQuestion, question.questionMedia![i].id!);
      }
    }
  }

  return newQuestion;
};

export const isArray = (a: string): boolean => {
  return a.includes(",");
};

// const transformInteractionPropertiesToPositiveArray = (interactionProperty: InteractionPropertyDto) => {
//   let newValues: string[] = [];
//   let interactionPropertyValues = interactionProperty!.value!.split(",");
//   interactionPropertyValues.forEach((x) => {
//     if (!isNaN(Number(x)) && Number(x) < 0) {
//       newValues.push(Math.abs(parseInt(x)).toString());
//     } else {
//       newValues.push(x);
//     }
//   });

//   return newValues.join(",");
// };

const transformCorrectResponsePatternToPositiveArray = (value: string) => {
  let newValues: number[] = [];
  let interactionPropertyValues: string[] = JSON.parse(value!)[0];
  interactionPropertyValues.forEach((x) => {
    if (!isNaN(Number(x)) && Number(x) < 0) {
      newValues.push(Math.abs(parseInt(x)));
    } else {
      newValues.push(parseInt(x));
    }
  });

  return JSON.stringify([newValues]);
};

const transformInteractionPropertiesToPositiveArrayMatching = (interactionProperty: InteractionPropertyDto) => {
  // [	mainArray
  //   [	mainArray[0]
  //     [1,6],[2,7],[3,8],[4,9],[5,10]
  //   ]
  // ]

  const mainArray: number[][][] = JSON.parse(interactionProperty!.value!);

  mainArray[0].forEach((subArray: number[], mainIndex: number) => {
    subArray.forEach((value: number, subIndex: number) => {
      if (value < 0) {
        mainArray[0][mainIndex][subIndex] = Math.abs(value);
      }
    });
  });

  return JSON.stringify(mainArray);
};

const emptypCorrectResponsePattern: string[][] = [[]];

export const safeParse = (value: string) => {
  let parsed: string[][] = [];
  try {
    parsed = JSON.parse(value)[0];
  } catch (error) {
    // console.log(error);
    parsed = emptypCorrectResponsePattern;
  }
  return parsed ?? emptypCorrectResponsePattern;
};

export const setInteractionPropertiesToPositive = (question: iQuestionDtoExtended) => {
  let interactionProperty: InteractionPropertyDto | undefined = question?.interactionProperties?.find(
    (x) => x.interactionPropertyId === question.correctResponsePatternId
  ); // && !isNaN(Number(x.value)) && Number(x.value) < 0);

  if (!interactionProperty) {
    return;
  }
  if (question.interactionTypeName === INTERACTION_TYPE_NAME_MATCHING) {
    interactionProperty!.value = transformInteractionPropertiesToPositiveArrayMatching(interactionProperty);
    return;
  }

  let correctResponsePatterns = interactionProperty!.value!;
  // console.log("setInteractionPropertiesToPositive", correctResponsePatterns);
  if (safeParse(correctResponsePatterns).length == 0) {
    interactionProperty!.value = JSON.stringify(emptypCorrectResponsePattern);
    return;
  }

  if (
    !isArray(interactionProperty!.value!) &&
    !isNaN(Number(interactionProperty!.value)) &&
    Number(interactionProperty!.value) < 0
  ) {
    //ska väl inte kunna hända?
    //interactionProperty!.value = Math.abs(parseInt(interactionProperty!.value!)).toString();
    throw new Error("InteractionProperty value is not an array!");
  }

  //if (isArray(interactionProperty!.value!)) {
  //if (isArray(interactionProperty!.value!)) {

  //interactionProperty!.value = transformInteractionPropertiesToPositiveArray(interactionProperty);
  interactionProperty!.value = transformCorrectResponsePatternToPositiveArray(interactionProperty.value!);
  //}
};

export const setOptionIdsToPositive = (question: iQuestionDtoExtended) => {
  let questionTexts = question?.questionTexts?.filter((x) => !isNaN(Number(x.optionId)) && Number(x.optionId) < 0);
  let questionMedia = question?.questionMedia?.filter((x) => !isNaN(Number(x.optionId)) && Number(x.optionId) < 0);

  questionTexts?.forEach((x) => (x.optionId = Math.abs(parseInt(x.optionId!)).toString()));
  questionMedia?.forEach((x) => (x.optionId = Math.abs(parseInt(x.optionId!)).toString()));
};

export const getNextNegativeOptionId = (question: QuestionDto) => {
  let optionIds: string[] = getUniqueOptionIdsSorted(question, QUESTION_OPTION_SOURCE_TYPE_ID).concat(
    getUniqueOptionIdsSorted(question, QUESTION_OPTION_TARGET_TYPE_ID)
  );

  let min: number = 1;
  let max: number = 99;

  // hämta befintliga som positiva
  const positiveNumbersArray = optionIds.map((numString) => Math.abs(parseInt(numString)));

  // hämta ett ledigt tal
  let randomInteger: number = getRandomIntegerExcluding(min, max, positiveNumbersArray);

  // gör om till negativt
  randomInteger = -Math.abs(randomInteger);

  return randomInteger;
};

function getRandomIntegerExcluding(min: number, max: number, exclude: number[]) {
  let randomNumber: number;
  do {
    randomNumber = Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
  } while (exclude.includes(randomNumber));

  return randomNumber;
}

export const isText = (templateDataItem: TextAndMedia) => {
  return templateDataItem.text!.max == undefined || templateDataItem.text!.max! > 0;
};

export const isMedia = (templateDataItem: TextAndMedia) => {
  return templateDataItem.media!.max == undefined || templateDataItem.media!.max! > 0;
};

export const getNumberOfOptionsWithImage = (questionMedia: QuestionMediaDto[], textMediaTypeId: number) => {
  let count: number = 0;

  let uniqueMediaOptionIds: string[] = getUniqeMediaOptionIds(questionMedia, textMediaTypeId);

  uniqueMediaOptionIds.forEach((x) => {
    let found: QuestionDto | undefined = questionMedia.find((y) => y.optionId === x && y.media!.length > 0);

    if (found) {
      count++;
    }
  });

  return count;
};

export const isOptionMediaVisible = (
  question: iQuestionDtoExtended,
  questionMedium: QuestionMediaDto,
  templateDataItem: TextAndMedia
) => {
  let questionMedia: QuestionMediaDto[] = getQuestionMedia(question, templateDataItem.textMediaTypeId!);
  let numberOfOptionsWithImage: number = getNumberOfOptionsWithImage(questionMedia, templateDataItem.textMediaTypeId!);

  return numberOfOptionsWithImage < templateDataItem.media!.max! || questionMedium.media!.length > 0;
};

export const copyQuestion = async (question: iQuestionDtoExtended) => {
  let newQuestionId: number = await copyQuestionDto(question);
  return newQuestionId;
};

export const getQuestionInteractionTypeName = (interactionTypes: InteractionTypeDto[], question: QuestionDto) => {
  return interactionTypes.find((x) => x.id === question.interactionTypeId)?.name;
};

// export const setMediumImageAreas = (question: iQuestionDtoExtended, templateDataItems: TextAndMedia[]) => {
//   //should be handled elsewhere
//   question.mediumImageAreas = [];

//   if (question!.subQuestionTypeId === subQuestionTypeIdClickableImage) {
//     let sourceOptionTextAndMedia: TextAndMedia | undefined = templateDataItems.find((x) => isQuestionOption(x));
//     if (sourceOptionTextAndMedia) {
//       let optionMedia: QuestionMediaDto[] = question.questionMedia!.filter(
//         (x) => x.typeId === sourceOptionTextAndMedia?.textMediaTypeId
//       ) as QuestionMediaDto[];

//       optionMedia.forEach((x) => {
//         let area: iAreasExtended = JSON.parse(x.media![0].link!) as iAreasExtended;
//         area.optionId = x.optionId!;
//         // if (area.width > 0 && area.height > 0) {
//         question.mediumImageAreas.push(area);
//         // }
//       });
//     }
//   }
// };

export const addOptionElement = (
  newQuestion: iQuestionDtoExtended,
  templateDataItem: TextAndMedia,
  interactionTypes: InteractionTypeDto[]
): string => {
  const newOptionId = getNextNegativeOptionId(newQuestion!);

  let questionTexts = newQuestion
    .questionTexts!.filter((x) => x.textTypeId === templateDataItem.textMediaTypeId)!
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });
  let questionMedia = newQuestion
    .questionMedia!.filter((x) => x.typeId === templateDataItem.textMediaTypeId)!
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });

  let newSortOrder = 0;
  if (isText(templateDataItem)) {
    newSortOrder = questionTexts.length > 0 ? questionTexts[questionTexts.length - 1].sortOrder! + 1 : 0;
    let newQuestionText = createQuestionTextDto(newQuestion, templateDataItem, newOptionId.toString(), newSortOrder); //addQuestionText(newQuestion, templateDataItem);
    newQuestion.questionTexts?.push(newQuestionText);
  }
  if (isMedia(templateDataItem)) {
    if (newSortOrder === 0) {
      newSortOrder = questionMedia.length > 0 ? questionMedia[questionMedia.length - 1].sortOrder! + 1 : 0;
    }
    let newQuestionMedium: QuestionMediaDto = createQuestionMediaDto(
      newQuestion!,
      templateDataItem,
      newOptionId.toString(),
      newSortOrder
    );
    newQuestion.questionMedia?.push(newQuestionMedium);
  }

  syncOptionValues(interactionTypes, newQuestion, templateDataItem);
  return newOptionId.toString();
};

export const addOptionMedium = async (
  newQuestion: iQuestionDtoExtended,
  templateDataItem: TextAndMedia,
  optionId: string,
  mediaContainer: MediaContainer
) => {
  const questionMediaSortOrder: number = newQuestion.questionMedia!.filter(
    (x) => x.typeId === templateDataItem.textMediaTypeId && x.optionId === optionId
  )[0].sortOrder!;

  //does the question already have a questionMedia with the same optionId and textMediaTypeId?
  let foundQuestionMedia = newQuestion.questionMedia!.filter(
    (x) => x.typeId === templateDataItem.textMediaTypeId && x.optionId === optionId
  )[0];
  let newQuestionMedia =
    foundQuestionMedia ?? createQuestionMediaDto(newQuestion, templateDataItem, optionId, questionMediaSortOrder);

  //option can only have one media
  newQuestionMedia.media = [];
  let mediaDto: MediaDto = createMediaDto(newQuestion, mediaContainer, templateDataItem);
  newQuestionMedia.media!.push(mediaDto);

  if (!foundQuestionMedia) {
    newQuestion.questionMedia!.push(newQuestionMedia);
  }
};

export const getQuestionOptionMediaSorted = (question: iQuestionDtoExtended): QuestionMediaDto[] => {
  let questionOptionMedia: QuestionMediaDto[] = question
    .questionMedia!.filter((x) => x.typeId === QUESTION_OPTION_SOURCE_TYPE_ID)
    .sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });

  return questionOptionMedia;
};

export const getAreaNumber = (question: iQuestionDtoExtended, optionId: string): string => {
  /*
    Detta fungerar när sortering INTE är möjligt. Om sortering ska aktiveras bör vi visa optionId istf index.
    Ska vi visa optionId måste vi då även hantera 'luckor' dvs om man har 1, 2, 3, 4 och tar bort #3 så bör optionId 4 ändras till 3.
    Detta stöds inte idag, nästa optionId idag blir 5.
  */

  let questionOptionMedia: QuestionMediaDto[] = getQuestionOptionMediaSorted(question);
  return (questionOptionMedia.findIndex((x) => x.optionId === optionId) + 1).toString();
};

export const handleMediumPreviewClick = (medium: MediaDto, url: string) => {
  let media: iMediumPreview = {
    link: medium.link!,
    linkTypeId: medium.linkTypeId!,
    srcUrl: url,
  };
  let newWindowUrl: string = "";
  if (medium.linkTypeId === MEDIA_LINK_TYPE_MEDIA_VIDEO) {
    newWindowUrl = media.link!;
  } else {
    localStorage.setItem("mediumPreview", JSON.stringify(media));
    newWindowUrl = "/MediumPreview";
  }
  window.open(newWindowUrl, "_blank", "noreferrer");
};

export const getFakeValidationDto = () => {
  let validationDto: ValidationDto = new ValidationDto();
  validationDto.success = false;
  validationDto.deviations = [];
  let deviationDto = new DeviationDto();
  deviationDto.textMediaTypeId = 1;
  deviationDto.textDeviation = -1;
  deviationDto.mediaDeviation = -1;
  validationDto.deviations.push(deviationDto);
  deviationDto = new DeviationDto();
  deviationDto.textMediaTypeId = 6;
  deviationDto.textDeviation = -1;
  validationDto.deviations.push(deviationDto);
  return validationDto;
};

export const getSelectedClassificationIds = (questionDto: iQuestionDtoExtended): iClassificationItem[] => {
  let selectedItems: iClassificationItem[] = [];
  let questionClassifications = questionDto!.classifications ?? [];

  const selectedClassifications = questionClassifications.map((x) => {
    return { identifier: x.classificationId, rootId: x.rootId } as iClassificationItem;
  });

  selectedItems.push(...selectedClassifications);
  const questionLearningObjectives = questionDto!.learningObjectiveDtos ?? [];
  const selectedLearningObjectives = questionLearningObjectives.map((x) => {
    return { identifier: x.id, rootId: LEARNING_OBJECTIVES_ROOT_ID } as iClassificationItem;
  });
  selectedItems.push(...selectedLearningObjectives);

  console.log("getSelectedClassificationIds", selectedItems);
  return selectedItems;
};
