import { useEffect } from "react";
import { appConfig } from "../../appConfig";

const Keywords = (props: any) => {
  useEffect(() => {
    const configValue: string = appConfig.REACT_APP_LCMS_API_URL as string;
  }, []);

  return <p>Keywords</p>;
};

export default Keywords;
