import React, { useEffect } from "react";
import {
  InteractionPropertyDto,
  InteractionTypeProperties,
  InteractionTypePropertyDto,
} from "../../../api/learnable_question/QuestionClient";
import {
  TextBox,
  TextArea,
  NumericTextBox,
  TextBoxChangeEvent,
  Checkbox,
  CheckboxChangeEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { FloatingLabel, Label, Hint, Error } from "@progress/kendo-react-labels";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { createInteractionPropertyDto, getQuestionCopy } from "./functions/questionDtoFunctions";
import { useAppContext } from "../../common/AppContext";
import {
  TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP,
  TEMPLATE_INTERACTION_PROPERTY_SINGLE_CHOICE,
} from "../../common/Constants";
const InteractionTypeProperty = (props: any) => {
  const appObject = useAppContext();
  const { data, question } = props;
  const questionTemplate = appObject.templates.find((x) => x.id === question.templateId)?.template;
  const interactionTypeProperties: InteractionTypePropertyDto[] = data.filter(
    (item: any) => item.interactionTypeId === question.interactionTypeId
  ); // && item.property !== 'correctResponsesPattern');
  const templateInteractionProperties = props.templateInteractionProperties as InteractionTypeProperties[];

  const onValueChanged = (event: any, interactionPropertyId: number) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    let questionInteractionProperty = newQuestion.interactionProperties!.find(
      (qitv: any) => qitv.interactionPropertyId === interactionPropertyId
    );
    if (questionInteractionProperty !== undefined) {
      questionInteractionProperty.value = isBoolean(interactionPropertyId)
        ? event.target.value
          ? "1"
          : "0"
        : event.target.value;
    } else {
      //get interactionTypeProperty by id
      const interactionTypeProperty = getInteractionTypeProperty(interactionPropertyId);
      const newValue: string = isBoolean(interactionPropertyId) ? (event.target.value ? "1" : "0") : event.target.value;
      const newQuestionInteractionProperty: InteractionPropertyDto = createInteractionPropertyDto(
        newValue,
        interactionTypeProperty
      );

      newQuestion.interactionProperties?.push(newQuestionInteractionProperty);
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion);
  };

  const getInteractionTypeProperty = (interactionPropertyId: number) => {
    return interactionTypeProperties.filter((x) => x.id === interactionPropertyId)[0];
  };

  const isBoolean = (interactionPropertyId: number) => {
    return interactionTypeProperties.filter((x) => x.id === interactionPropertyId)[0].dataTypeId === 4;
  };
  const getEmptyCorrectResponsePatternValue = (): string | undefined => {
    if (
      questionTemplate!.configuration &&
      questionTemplate!.configuration?.find((x) => x.key === TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP)
    ) {
      return questionTemplate!.configuration!.find((x) => x.key === TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP)!.value;
    }
    return undefined;
  };
  const isVisible = (property: string) => {
    const foundItem = templateInteractionProperties.find((x) => x.property === property);

    //if property is singlechoice then we check if template has configuration keys that allow empty correct response pattern.
    //If empty correct response pattern is allowed then it should not be rendered. Otherwise the rendered property decides outcome
    if (foundItem?.property === TEMPLATE_INTERACTION_PROPERTY_SINGLE_CHOICE) {
      const emptyCorrectResponsePatternValue = getEmptyCorrectResponsePatternValue();
      if (emptyCorrectResponsePatternValue == "true") return false;
    }

    return foundItem !== undefined && foundItem.render ? true : false;
  };

  const isReadOnly = (property: string) => {
    const foundItem = templateInteractionProperties.find((x) => x.property === property);
    return foundItem && foundItem.locked ? true : false;
  };

  useEffect(() => {}, []);
  const getInputElement = (item: InteractionTypePropertyDto) => {
    //return element depending on datatypeid
    const readOnly = isReadOnly(item.property!);
    switch (item.dataTypeId) {
      case 1: //string
        return (
          <TextBox
            disabled={readOnly}
            style={{ width: 300 }}
            value={getDefaultValue(item)}
            onChange={(event: TextBoxChangeEvent) => onValueChanged(event, item.id!)}
          />
        );
      case 2: //integer
        return (
          <NumericTextBox
            disabled={readOnly}
            value={getDefaultValue(item)}
            onChange={(event: NumericTextBoxChangeEvent) => onValueChanged(event, item.id!)}
          />
        );
      case 3: //float
        return (
          <NumericTextBox
            disabled={readOnly}
            format="n2"
            value={getDefaultValue(item)}
            onChange={(event: NumericTextBoxChangeEvent) => onValueChanged(event, item.id!)}
          />
        );
      case 4: //boolean
        return (
          <span style={{ marginLeft: 5 }}>
            <Checkbox
              disabled={readOnly}
              type="checkbox"
              checked={getDefaultValue(item)}
              onChange={(event: CheckboxChangeEvent) => onValueChanged(event, item.id!)}
            />
          </span>
        );
    }
  };

  const getDefaultValue = (item: InteractionTypePropertyDto): any => {
    const questionInteractionTypeValue = question.interactionProperties.find(
      (qitv: any) => qitv.interactionPropertyId === item.id
    );
    switch (item.dataTypeId) {
      case 1: //string
        if (questionInteractionTypeValue != undefined) {
          return questionInteractionTypeValue.value;
        }
        return "";
      case 2: //integer
        if (questionInteractionTypeValue != undefined) {
          return Number(questionInteractionTypeValue.value);
        }
        return 0;
      case 3: //float
        if (questionInteractionTypeValue != undefined) {
          return Number(questionInteractionTypeValue.value);
        }
        return 0;
      case 4: {
        //boolean
        if (questionInteractionTypeValue != undefined) {
          return questionInteractionTypeValue.value === "1" || questionInteractionTypeValue.value === "true"
            ? true
            : false;
        }
        return false;
      }
    }
  };

  return (
    <div>
      {interactionTypeProperties.map((item: InteractionTypePropertyDto, index: number) => {
        return isVisible(item.property!) ? (
          <div key={index}>
            <div className="col-12 col-md-6 example-col">
              <Label key={item.id}>{item.property}</Label>
            </div>
            <div className="col-12 col-md-6 example-col">{getInputElement(item)}</div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default InteractionTypeProperty;
