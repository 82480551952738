import { AreaSelector, IArea, IAreaRendererProps } from "@bmunozg/react-image-area";
import { iQuestionDtoExtended } from "../../interfaces/iQuestionDtoExtended";
import { QuestionMediaDto, TextAndMedia } from "../../api/learnable_question/QuestionClient";
import { isQuestionOption } from "../question/edit/functions/helperFunctions";
import {
  getQuestionCopy,
  getUniqueOptionIdsSorted,
  addOptionElement,
  addOptionMedium,
  getQuestionOptionMediaSorted,
  getAreaNumber,
} from "../question/edit/functions/questionDtoFunctions";
import { useAppContext } from "./AppContext";
import { QUESTION_OPTION_SOURCE_TYPE_ID } from "./Constants";
import { MediaContainer } from "../optima_image/types";

export type ClickableImageProps = {
  question: iQuestionDtoExtended;
  setQuestion: (question: iQuestionDtoExtended) => void;
  templateDataItems: TextAndMedia[];
  maxAreas: number | undefined;
  src: string;
};

export const ClickableImage: React.FC<ClickableImageProps> = ({
  question,
  setQuestion,
  templateDataItems,
  maxAreas,
  src,
}) => {
  // console.log("ClickableImage", question);
  const appObject = useAppContext();

  const customRender = (questionOptionMedia: QuestionMediaDto[], areaProps: IAreaRendererProps) => {
    let optionId: string = questionOptionMedia[areaProps.areaNumber - 1].optionId!;

    if (!areaProps.isChanging) {
      return (
        <div
          key={areaProps.areaNumber}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#80808085",
          }}
        >
          <span style={{ opacity: "0.7", backgroundColor: "white", padding: 8, fontWeight: "bold" }}>
            {getAreaNumber(question, optionId)}
          </span>
        </div>
      );
    }
  };

  const onChangeHandler = (areas: IArea[]) => {
    let newQuestion: iQuestionDtoExtended = getQuestionCopy(question);
    let uniqueOptionIds: string[] = getUniqueOptionIdsSorted(newQuestion, QUESTION_OPTION_SOURCE_TYPE_ID);
    let optionTemplateDataItem: TextAndMedia = templateDataItems.find((x) => isQuestionOption(x))!;

    areas.forEach((area, index) => {
      const newOptionId: string =
        typeof uniqueOptionIds[index] !== "undefined"
          ? uniqueOptionIds[index]
          : addOptionElement(newQuestion, optionTemplateDataItem, appObject.interactionTypes);
      const newMediaContainer: MediaContainer = {
        areas: [area],
        imageLinks: undefined,
        videos: undefined,
      } as MediaContainer;
      addOptionMedium(newQuestion, optionTemplateDataItem, newOptionId, newMediaContainer);
    });

    setQuestion(newQuestion);
  };

  const getAreas = (question: iQuestionDtoExtended): IArea[] => {
    let areas: IArea[] = [];

    getQuestionOptionMediaSorted(question).forEach((medium) => {
      medium.media!.forEach((media) => {
        let area: IArea = JSON.parse(media.link!) as IArea;
        areas.push(area);
      });
    });

    return areas;
  };

  return (
    <div>
      <AreaSelector
        maxAreas={maxAreas}
        areas={getAreas(question)}
        onChange={onChangeHandler}
        unit="percentage"
        customAreaRenderer={(props: IAreaRendererProps) => customRender(getQuestionOptionMediaSorted(question), props)}
        minWidth={20}
        minHeight={20}
      >
        <img src={src} alt="" width="100%" />
      </AreaSelector>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#80808085",
        }}
      ></div>
    </div>
  );
};
