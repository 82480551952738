import { Checkbox, Input } from "@progress/kendo-react-inputs";

import { ComboBox } from "@progress/kendo-react-dropdowns";

import React, { useState, useEffect } from "react";
import FilterExpansionPanel from "../common/FilterExpansionPanel";

import MainGrid from "../common/MainGrid";
import SearchContainer from "../common/SearchContainer";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { SearchClient } from "../../api/learnable_search/SearchClient";
import { ClassificationDto, GetClassificationsRequest } from "../../api/learnable_classification/ClassificationClient";
import { GridFilterCellProps, GridRowClickEvent } from "@progress/kendo-react-grid";
import { DropdownFilterCell } from "../common/DropdownFilterCell";
import { iColumn } from "../../interfaces/iColumn";
import ClassificationButton from "../common/classifications/ClassificationButton";

import { Button } from "@progress/kendo-react-buttons";
import { appConfig } from "../../appConfig";

import {
  iClassificationItem,
  iConfiguration,
  iConfigurationLearningObjective,
} from "../common/classifications/interfaces";

import {
  fetchClassifications,
  fetchContainerSubTypes,
  fetchLearningObjectives,
} from "../../api/learnable_classification/apiFunctions";
import {
  Client,
  CustomFilterDto,
  GetMyLatestItemsResponse,
  GetFilterRequest,
  GetLearningObjectRequest,
  ContainerSubTypeDto,
} from "../../api/Client";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";
import { useAppContext } from "../common/AppContext";
import { LearningObjectUtilities } from "../common/SearchLearningObjectsUtilities";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import { openLearningObject, openLearningObjectPreview } from "../question/edit/functions/helperFunctions";
import {
  getClassificationByRootIds,
  getLearningObjectives,
  getSelectedItems,
} from "../common/classifications/helperFunctions";
import {
  // AMNE_CLASSIFICATION_ID,
  // BEHORERHET_CLASSIFICATION_ID,
  // FRAGESTYP_CLASSIFICATION_ID,
  // BEHORERHET_FM_CLASSIFICATION_ID,
  CONFIG_AMNEN_CLASSIFICATION_KEY,
  CONFIG_BEHORIGHET_CLASSIFICATION_KEY,
  CONFIG_FRAGETYP_CLASSIFICATION_KEY,
  CONTAINER_SUB_TYPE_INLEDNING,
  CONTAINER_SUB_TYPE_SAMMANFATTNING,
  CONTAINER_TYPE_LEARNING_OBJECT,
} from "../common/Constants";

import { copyContainer, fetchConfiguration } from "../../api/learnable_lcms/apiFunctions";
import { getClassificationRootIds, getConfigRootIdsByClassificationKey } from "../common/helperFunctions";
import CustomDialog from "../common/CustomDialog";
import { MenuIndex } from "../common/Enums";

import { ClassificationKey, Service } from "../common/classifications/enums";
import { ClassificationContainer, ConfigProvider, filterLearningObjective } from "../common/classifications/types";
import { StatusCell } from "../common/Status";
import { VersionCell } from "../common/Version";
import MetadataPanel from "./MetadataPanel";
import { iContainerSubTypeDtoExtended } from "../../interfaces/iContainerSubTypeDtoExtended";

const LearningObjects = (props: any) => {
  const [organisationer, setOrganisationer] = useState<any[] | undefined>(undefined);
  const [interaktionstyper, setInteraktionstyper] = useState<any[] | undefined>(undefined);
  const appObject = useAppContext();
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);
  const [selectedClassifications, setSelectedClassifications] = useState<iClassificationItem[]>([]);

  // console.log("appObject", appObject);

  const searchUtilities: iSearchUtilities = new LearningObjectUtilities();

  // Custom filter
  const getInitialCustomFilter = () => {
    const customFilter: iCustomFilterDtoExtended = new CustomFilterDto() as iCustomFilterDtoExtended;
    customFilter.amneGuids = [];
    customFilter.behorighetGuids = [];
    customFilter.fragetypGuids = [];
    customFilter.larandemalGuids = [];
    customFilter.name = "";
    customFilter.fritext = "";
    customFilter.organistaionsId = "";
    customFilter.interaktionstypId = 0;
    customFilter.harBild = false;
    customFilter.harVideo = false;
    customFilter.ingenMedia = false;
    customFilter.isNew = true;
    customFilter.endastFragetext = false;
    customFilter.endastOpublicerade = false;
    customFilter.includeDeprecated = false;
    customFilter.externalId = "";

    return customFilter;
  };

  const [containerSubTypesLearningObject, setContainerSubTypesLearningObject] = useState<
    ContainerSubTypeDto[] | undefined
  >([]);

  const [customFilters, setCustomFilters] = useState<iCustomFilterDtoExtended[]>([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<iCustomFilterDtoExtended>(getInitialCustomFilter());

  const [refreshGrid, setRefreshGrid] = useState<number>(1);

  // Dropdown control for status filter
  const [statusar, setStatusar] = useState<any[] | undefined>(undefined);

  const StatusFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell {...props} data={statusar ? statusar : []} defaultItem={""} dataItemKey="id" textField="name" />
  );

  const copyLearningObjectButtonClick = async (learningObjectId: number) => {
    const newContainerId: number | undefined = await copyContainer(learningObjectId);
    let success: boolean = newContainerId > 0;
    if (success) {
      openLearningObject(newContainerId);
    } else {
      console.log("Lärobjektet kunde inte kopieras");
    }
    //openLearningObjectP(questionId, 0);
  };

  const hasComment = (comment: string | undefined) => {
    return comment && comment.length > 0;
  };

  const ColumnButtons: any = (props: any) => {
    return (
      <td>
        <div className="centerMiddle ">
          <Button
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="copy"
            onClick={() => copyLearningObjectButtonClick(props.dataItem.id)}
          ></Button>

          <Button
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="preview"
            className="ms-1"
            onClick={() => openLearningObjectPreview(props.dataItem.id)}
          ></Button>

          <Button
            title={props.dataItem.comment}
            style={{ opacity: hasComment(props.dataItem.comment) ? 1 : 0.2 }}
            disabled={!hasComment(props.dataItem.comment)}
            onClick={() => {
              setShowCustomDialog({
                title: "Kommentar",
                message: props.dataItem.comment,
                onClose: () => setShowCustomDialog(null),
              });
            }}
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="comment"
            className="ms-1"
          ></Button>
        </div>
      </td>
    );
  };

  const GridColumns: iColumn[] = [
    { field: "friendlyId", filter: "text", title: "ID", width: 170, show: true },
    { field: "containerSubtype", filterable: false, title: "containerSubtype", width: 100, show: false },
    { field: "title", title: "Titel", filterable: false, show: true },
    { field: "containerSubtypeName", filterable: false, title: "Objekttyp", width: 140, show: true },
    // { field: "description", title: "Beskrivning", filterable: false, show: true },

    { field: "semanticVersion", title: "Version", filterable: false, width: 70, show: true, cell: VersionCell },
    { field: "status", title: "Status Id", width: 160, show: false },
    { field: "statusName", title: "Status", filterCell: StatusFilterCell, width: 140, show: true, cell: StatusCell },
    { field: "senastEditerad", title: "Senast ändrad", filter: "date", format: "{0:d}", width: 260, show: true },
    { field: "senastEditeradAv", title: "Senast ändrad av", filterable: false, width: 120, show: true },
    { field: "", title: "", width: 100, show: true, filterable: false, cell: ColumnButtons },
  ];

  const [gridData, setGridData] = React.useState<any[]>([]);

  const initialSort: Array<SortDescriptor> = [{ field: "senastEditerad", dir: "desc" }];

  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  const [filterModel, setFilterModel] = React.useState({
    subClassifications: [],
    behClassifications: [],
    typClassifications: [],
    media: [],
    sampleTextbox: "aa",
    senastEditerad: "2023-01-13",
    sampleCombobox: { id: 2, name: "Tennis" },
    sampleCheckbox: false,
  });

  const getFilterAttribute = (attr: string, value: string) => {
    let newAttribute = { [attr]: value };

    return newAttribute;
  };

  const getSelectedValue = (id: any | undefined, list: any[] | undefined) => {
    let value: any | undefined = id && list && list.find((e) => e.id === id);
    if (value === undefined) {
      return null;
    }

    return value;
  };

  // const [classificationContainerAmne, setClassificationContainerAmne] = useState<ClassificationContainer | undefined>(
  //   undefined
  // );

  // const [classificationContainerBehorighet, setClassificationContainerBehorighet] = useState<
  //   ClassificationContainer | undefined
  // >(undefined);

  // const [classificationContainerBehorighetFilter, setClassificationContainerBehorighetFilter] = useState<
  //   ClassificationContainer | undefined
  // >(undefined);

  // const [classificationContainerFragetyp, setClassificationContainerFragetyp] = useState<
  //   ClassificationContainer | undefined
  // >(undefined);

  // const [containerLearningObjectives, setContainerLearningObjectives] = useState<ClassificationContainer | undefined>(
  //   undefined
  // );

  const onApply = (classificationType: string, selectedItems: iClassificationItem[]) => {
    const selectedGuids = selectedItems.map((i) => i.identifier);
    switch (classificationType) {
      case CONFIG_AMNEN_CLASSIFICATION_KEY:
        // setClassificationContainerAmne({ ...classificationContainerAmne!, selected: selectedItems });
        onElementValueChanged("amneGuids", selectedGuids);
        break;
      case CONFIG_BEHORIGHET_CLASSIFICATION_KEY:
        console.log("behorighetClassificationId", selectedGuids);
        // setClassificationContainerBehorighet({ ...classificationContainerBehorighet!, selected: selectedItems });
        onElementValueChanged("behorighetGuids", selectedGuids);
        break;
      case CONFIG_FRAGETYP_CLASSIFICATION_KEY:
        // setClassificationContainerFragetyp({ ...classificationContainerFragetyp!, selected: selectedItems });
        onElementValueChanged("fragetypGuids", selectedGuids);
        break;
      case "learningObjectives":
        // setContainerLearningObjectives({ ...containerLearningObjectives!, selected: selectedItems });
        onElementValueChanged("larandemalGuids", selectedGuids);
        break;
    }
  };
  const defaultMediaCheckboxes = {
    harVideo: false,
    harBild: false,
    ingenMedia: false,
  };
  const [mediaCheckboxes, setMediaCheckboxes] = useState(defaultMediaCheckboxes);
  // let filterC = getFilterControlsLearningObjective(undefined);

  // const filterRefLevel = React.useRef(null);
  // const filterRefType = React.useRef(null);

  // const filterLevelData = [
  //   { id: 1, name: "Allmän" },
  //   { id: 2, name: "Mellan" },
  //   { id: 3, name: "Detalj" },
  // ];

  // const filterTypeData = [
  //   { id: 1, name: "Teoretisk" },
  //   { id: 2, name: "Praktisk" },
  //   { id: 3, name: "Självvärdering" },
  // ];

  // const filterControlsLearningObjectiveDialog = [
  //   <ComboBox
  //     name="Level"
  //     textField="name"
  //     dataItemKey="id"
  //     data={filterLevelData}
  //     {...getFilterAttribute("searchfilterlabel", "Nivå")}
  //     ref={filterRefLevel}
  //   />,
  //   <ComboBox
  //     name="Type"
  //     textField="name"
  //     dataItemKey="id"
  //     data={filterTypeData}
  //     {...getFilterAttribute("searchfilterlabel", "Typ")}
  //     ref={filterRefType}
  //   />,
  // ];

  // const filterControlsLearningObjectiveDialog = getFilterControlsLearningObjective();
  const configProviderClassification: ConfigProvider = {
    service: Service.lcms,
    url: appConfig.REACT_APP_LCMS_API_URL as string,
    key: "classificationList",
  };
  const learningObjectiveConfiguration: iConfigurationLearningObjective = {
    title: "Lärandemål",
    rootId: "learningObjectivesRootId",
    showSelected: false,
    clientGet: getLearningObjectives,
  };
  let filterProvider = filterLearningObjective;
  filterProvider.configClassificationProvider = configProviderClassification;
  filterProvider.classificationKey = ClassificationKey.behorigheter;

  const filterControls = [
    <ClassificationButton
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.amnen}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_AMNEN_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.andamal}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_FRAGETYP_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.behorigheter}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_BEHORIGHET_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      configProvider={undefined}
      filterProvider={filterProvider}
      classificationKey={ClassificationKey.larandemal}
      configurationClassificationOptional={learningObjectiveConfiguration}
      readOnly={false}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply("learningObjectives", selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, "learningObjectives") ?? []}
    />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
    //   configurations={getQuestionAmnenConfigurations("ClassificationList")}
    //   selectedClassificationIds={getSelectedItems(selectedCustomFilter, CONFIG_AMNEN_CLASSIFICATION_KEY)}
    //   dialogTitle="Välj ämnen"
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   configurations={getQuestionAndamalConfigurations("ClassificationList")}
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
    //   selectedClassificationIds={getSelectedItems(selectedCustomFilter, CONFIG_FRAGETYP_CLASSIFICATION_KEY)}
    //   dialogTitle="Välj ändamål"
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   configurations={getQuestionEductionConfigurations("ClassificationList")}
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
    //   selectedClassificationIds={getSelectedItems(selectedCustomFilter, CONFIG_BEHORIGHET_CLASSIFICATION_KEY)}
    //   dialogTitle="Välj utbildningar"
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Ämnen"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
    //   displaySelected={false}
    //   // classificationContainer={classificationContainerAmne}
    //   dialogTitle="Välj ämnen"
    //   // {...getFilterAttribute("searchfilterlabel", "Ämne")}
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Ändamål"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
    //   displaySelected={false}
    //   // classificationContainer={classificationContainerFragetyp}
    //   dialogTitle="Välj ändamål"
    //   // {...getFilterAttribute("searchfilterlabel", "Ändamål")}
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Utbildningar"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
    //   displaySelected={false}
    //   dialogTitle="Välj utbildningar"
    //   // classificationContainer={classificationContainerBehorighet}
    //   // {...getFilterAttribute("searchfilterlabel", "Utbildningar")}
    // />,
    // <ClassificationButton
    //   name={"classificationButton"}
    //   text="Lärandemål"
    //   onApply={(selectedItems: iClassificationItem[]) => onApply("learningObjectives", selectedItems)}
    //   displaySelected={false}
    //   dialogTitle="Välj lärandemål"
    //   // classificationContainer={containerLearningObjectives}
    //   filterControls={true}
    //   // filterClassification={classificationContainerBehorighetFilter}
    //   configurationData={configData}
    //   // {...getFilterAttribute("searchfilterlabel", "Lärandemål")}
    // />,
    <div {...getFilterAttribute("searchfilterlabel", "Fritext")}>
      <Input
        name="fritext"
        value={selectedCustomFilter.fritext}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        onKeyDown={(e: any) => onSearchKeyDown(e)}
        {...getFilterAttribute("searchfilterlabel", "Fritext")}
      />
    </div>,
    // <div {...getFilterAttribute("searchfilterlabel", "Externt ID")}>
    //   <Input
    //     name="externalId"
    //     value={selectedCustomFilter.externalId}
    //     onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
    //     onKeyDown={(e: any) => onSearchKeyDown(e)}
    //     {...getFilterAttribute("searchfilterlabel", "Externt ID")}
    //   />
    // </div>,
    <ComboBox
      name="organistaionsId"
      textField="name"
      dataItemKey="id"
      value={getSelectedValue(selectedCustomFilter.organistaionsId, organisationer)}
      data={organisationer}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Organisation")}
    />,
    <ComboBox
      name="containerSubType"
      textField="name"
      dataItemKey="id"
      data={containerSubTypesLearningObject}
      value={getSelectedValue(selectedCustomFilter.containerSubType, containerSubTypesLearningObject)}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Typ")}
    />,
    <div>
      <div style={{ height: "20px" }}> </div>
      <Checkbox
        name="endastOpublicerade"
        value={selectedCustomFilter.endastOpublicerade}
        label={"Visa endast opublicerade"}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
      />
      <br />
      <Checkbox
        name="includeDeprecated"
        value={selectedCustomFilter.includeDeprecated}
        label={"Inkludera borttagna"}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
      />
    </div>,
    //<DatePicker name="lastEdited"
    //    value={new Date(filterModel.lastEdited)} {...getFilterAttribute("searchfilterlabel", "Senast ändrad")}
    //    onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
    //    format="yyyy-MM-dd" weekNumber={true}
    ///>,

    //<TextBox name="sampleTextbox" {...getFilterAttribute("searchfilterlabel", "En textbox")}
    //    value={filterModel.sampleTextbox}
    //    onChange={(e) => onElementValueChanged(e.target.element!.name!, e.target.value)}
    ///>,
    //<ComboBox
    //    name="sampleCombobox"
    //    textField="name"
    //    dataItemKey="id"
    //    value={filterModel.sampleCombobox}
    //    data={[{ id: 1, name: "Fotboll" }, { id: 2, name: "Tennis" }, { id: 3, name: "Basket" }]}
    //    onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
    //    searchfilterlabel="En combobox"
    ///>,

    //<Checkbox
    //    name="sampleCheckbox"
    //    checked={filterModel.sampleCheckbox}
    //    onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
    //    searchfilterlabel="Checkbox appearance" />,

    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />
  ];

  const [amnen, setAmnen] = useState<ClassificationDto[] | null>(null);
  const [behorigheter, setBehorigheter] = useState<ClassificationDto[] | null>(null);
  const [fragetyper, setFragetyper] = useState<ClassificationDto[] | null>(null);

  const getClassificationsRequest = (configData: iConfiguration): GetClassificationsRequest => {
    let getClassificationsRequest = new GetClassificationsRequest();
    getClassificationsRequest.classificationIds = getClassificationRootIds(configData);
    getClassificationsRequest.includeDescendants = true;
    getClassificationsRequest.cultureName = "sv-SE";

    return getClassificationsRequest;
  };

  const getClassifications = async (configData: iConfiguration): Promise<ClassificationDto[][]> => {
    var result = await fetchClassifications(getClassificationsRequest(configData));
    return result;

    //Hantera ämnen
    // let classificationContainer: ClassificationContainer = getEmptyClassificationContainer();
    // let rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_AMNEN_CLASSIFICATION_KEY);
    // let classificationAmnen = getClassificationByRootIds(result, rootIds);
    // classificationContainer.list = classificationAmnen as iClassificationItem[];
    // syncClassificationContainer(classificationContainer);
    // setClassificationContainerAmne(classificationContainer);

    // //Hantera behörigheter
    // classificationContainer = getEmptyClassificationContainer();
    // rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_BEHORIGHET_CLASSIFICATION_KEY);
    // let classificationBehorigheter = getClassificationByRootIds(result, rootIds);
    // classificationContainer.list = classificationBehorigheter as iClassificationItem[];
    // syncClassificationContainer(classificationContainer);
    // setClassificationContainerBehorighet(classificationContainer);

    // // Behörighetsfilter
    // classificationContainer = getEmptyClassificationContainer();
    // rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_BEHORIGHET_CLASSIFICATION_KEY);
    // let classificationBehorigheterFilter = getClassificationByRootIds(result, rootIds);
    // classificationContainer.list = classificationBehorigheterFilter as iClassificationItem[];
    // syncClassificationContainer(classificationContainer);
    // setClassificationContainerBehorighetFilter(classificationContainer);

    // //Hantera frågetyper
    // classificationContainer = getEmptyClassificationContainer();
    // //TODO: Remove this filter hack and implement it in the API
    // rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_FRAGETYP_CLASSIFICATION_KEY);
    // let classificationsFrageTyp = getClassificationByRootIds(result, rootIds);
    // classificationContainer.list = classificationsFrageTyp as iClassificationItem[];
    // syncClassificationContainer(classificationContainer);

    // setClassificationContainerFragetyp(classificationContainer);
  };

  const fetchFilters = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL as string);

    let filters: iCustomFilterDtoExtended[] =
      ((await client.settingGetLearningObjectFilters()) as iCustomFilterDtoExtended[]) ?? [];
    filters.forEach((x) => (x.isNew = false));
    setCustomFilters(filters);

    return filters;
  };

  const [learningObjectives, setLearningObjectives] = useState<any[] | undefined>(undefined);

  const init = async () => {
    await fetchFilters();

    let learningObjectives: any[] = await fetchLearningObjectives();
    setLearningObjectives(learningObjectives);

    let configurationData: iConfiguration = await fetchConfiguration("ClassificationList");
    configurationData.learningObjectives = null;
    setConfigData(configurationData);

    let subTypes: iContainerSubTypeDtoExtended[] = await fetchContainerSubTypes(CONTAINER_TYPE_LEARNING_OBJECT);
    setContainerSubTypesLearningObject(
      subTypes.filter((x) => x.id != CONTAINER_SUB_TYPE_SAMMANFATTNING && x.id != CONTAINER_SUB_TYPE_INLEDNING)
    );

    const classifications = await getClassifications(configurationData);

    // let classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "amnen")
    // );
    // setAmnen(classification);

    let classificationBehorigheter = getClassificationByRootIds(
      classifications,
      getConfigRootIdsByClassificationKey(configurationData, "behorigheter")
    );
    setBehorigheter(classificationBehorigheter);
    // classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "andamal")
    // );
    // setFragetyper(classification);

    // // setAmnen(classifications[0]);
    // // setBehorigheter(classifications[1]);
    // // setFragetyper(classifications[2]);

    // await getLearningObjectives();
    // //console.log("learningObjectives", learningObjectives);

    const searchClient = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);
    const statusarReply = await searchUtilities.getStatusar(searchClient);
    setStatusar(statusarReply.rows);

    const organisationerreply = await searchUtilities.getOrganisationer(searchClient);
    setOrganisationer(organisationerreply.rows);

    //const interaktionstyperReply = await searchUtilities.getInteraktionstyper(searchClient);
    //setInteraktionstyper(interaktionstyperReply.rows);
  };

  useEffect(() => {
    init();
  }, []);

  const getClassificationText = (classifications: ClassificationDto[], id: string) => {
    if (classifications && id) {
      let text = classifications.find((x) => x.identifier == id)?.name ?? "";
      return text;
    }

    return "";
  };

  const fetchGetLatestLearningObjects = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let getLearningObjectRequest: GetLearningObjectRequest = new GetLearningObjectRequest();
    let getMyLatestItemsResponse: GetMyLatestItemsResponse = await client.settingGetLatestLearningObjects(
      getLearningObjectRequest
    );
    return getMyLatestItemsResponse.myLatestItemDtos!.map((x) => x.id);
  };

  const gridDataLoaded = async (data: any[]) => {
    const updatedData = data.map((item: any) => ({
      ...item, // Spread the existing properties of the object
      // behorighetClassificationText: getClassificationText(behorigheter!, item["behorighetClassificationId"][0]),
    }));

    let newUpdatedData: any[] = [];
    let latestData: any[] = [];
    let latest = await fetchGetLatestLearningObjects();

    updatedData.forEach((x) => {
      if (latest.includes(x.id)) {
        latestData.push(x);
      } else {
        newUpdatedData.push(x);
      }
    });

    newUpdatedData = latestData.concat(newUpdatedData);

    // hämta namnen för containerSubtype
    newUpdatedData.forEach((d) => {
      let name: string =
        containerSubTypesLearningObject!.find((x) => x.id == d.containerSubtype!)?.name ??
        d.containerSubtype.toString();
      d.containerSubtypeName = name;
    });

    setGridData(newUpdatedData);
  };

  const onSearchKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterClick(e);
    }
  };

  const filterClick = (event: any) => {
    setRefreshGrid(refreshGrid + 1);
  };

  const clearFilterClick = (event: any) => {
    let newCustomFilter = getInitialCustomFilter();

    console.log("clear customFilter", newCustomFilter);

    setSelectedCustomFilter(newCustomFilter);
    setSelectedClassifications([]);
    setMediaCheckboxes(defaultMediaCheckboxes);
    setRefreshGrid(refreshGrid + 1);
  };

  const saveFilterClick = async (event: any) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let request: GetFilterRequest = new GetFilterRequest();
    request.customQuestionFilterDto = selectedCustomFilter;

    await client.settingAddLearningObjectFilter(request);
    let filters: iCustomFilterDtoExtended[] = await fetchFilters();

    let newSelected: iCustomFilterDtoExtended = filters.find((x) => x.name == selectedCustomFilter.name)!;
    setSelectedCustomFilter(newSelected);
  };

  const deleteFilterClick = async (event: any) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);

    await client.settingDeleteLearningObjectFilter(selectedCustomFilter.name);
    await fetchFilters();

    setSelectedCustomFilter(getInitialCustomFilter());
  };

  const onElementValueChanged = (name: string, value: any) => {
    // console.log("onElementValue", value);
    setFilterModel({
      ...filterModel,
      [name]: value,
    });
    console.log("name", name);
    console.log("value", value);
    if (name == "media") {
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          ...value,
        }) as iCustomFilterDtoExtended
      );
    } else {
      //TODO: Varför funkar inte "const newValue = value?.id ?? value"?
      const newValue = value?.id ?? value;
      //const newValue = (value ? (value.id ? value.id : value) : value);
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          [name]: newValue,
        }) as iCustomFilterDtoExtended
      );
    }
  };

  const getUpdatedClassificationCopy = (classificationContainer: ClassificationContainer, selected: string[]) => {
    let copy: ClassificationContainer = { ...classificationContainer };
    copy.selected = [];

    selected.forEach((x) => {
      copy.selected.push(copy.list.find((y) => y.identifier == x)!);
    });

    return copy;
  };

  const onSelectedFilterChanged = (newFilter: iCustomFilterDtoExtended) => {
    if (newFilter == null) {
      newFilter = getInitialCustomFilter();
    }

    setSelectedCustomFilter(newFilter);

    // uppdatera checkboxarna
    setMediaCheckboxes({
      harBild: newFilter.harBild!,
      harVideo: newFilter.harVideo!,
      ingenMedia: newFilter.ingenMedia!,
    });
  };

  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);

  const onRowClick = (row: any) => {
    if (row.containerSubtypeName.toUpperCase() == "TEST") {
      setShowCustomDialog({
        title: "Lärobjekt: Test",
        message: 'Lärobjekt av typen "Test" kan inte redigeras i denna version.',
        onClose: () => setShowCustomDialog(null),
      });
      return;
    }

    openLearningObject(row.id);
  };

  const searchExpansionPanel = (
    <FilterExpansionPanel
      controlsPerRow={4}
      elements={filterControls}
      onFilterClick={filterClick}
      onClearFilterClick={clearFilterClick}
      onSaveFilterClick={saveFilterClick}
      onDeleteFilterClick={deleteFilterClick}
      customFilters={customFilters}
      selectedCustomFilter={selectedCustomFilter}
      onSelectedFilterChanged={onSelectedFilterChanged}
    />
  );

  const renderMetadataPanel = (dataItem: any) => {
    return (
      <MetadataPanel
        {...dataItem} // Pass the row-specific data
        behorigheter={behorigheter}
        learningObjectives={learningObjectives ?? []}
      />
    );
  };

  const mainGrid = () => (
    <MainGrid
      onRowClick={(event: GridRowClickEvent) => onRowClick(event.dataItem)}
      searchUtilities={searchUtilities}
      configuration={configData}
      initialSort={initialSort}
      initialFilter={initialFilter}
      gridDataLoaded={gridDataLoaded}
      gridData={gridData}
      columns={GridColumns}
      metadataPanel={renderMetadataPanel}
      setGridData={setGridData}
      customFilter={selectedCustomFilter}
      autoSubmitGridFilter={true}
      refresh={refreshGrid}
    />
  );

  return (
    <div>
      {showCustomDialog && <CustomDialog {...showCustomDialog} />}
      {behorigheter && (
        <SearchContainer
          searchType={MenuIndex.LearningObjects}
          mainGrid={mainGrid()}
          searchExpansionPanel={searchExpansionPanel}
        />
      )}
    </div>
  );
};

export default LearningObjects;
