import { Button } from "@progress/kendo-react-buttons";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { iVideo } from "./interfaces";
import React, { useEffect } from "react";
import { MediaContainer } from "./types";

const VideoSelector = (props: any) => {
  const textWidth = "500px";

  const video: iVideo = props.video;

  const handleNameChange = (e: TextBoxChangeEvent) => {
    let newVideo: iVideo = { ...video, name: e.value } as iVideo;
    // newVideo.name = e.value as string;
    props.setVideo(newVideo);
  };

  const handleSourceChange = (e: TextBoxChangeEvent) => {
    let newVideo: iVideo = { ...video, source: e.value } as iVideo;
    // newVideo.name = e.value as string;
    props.setVideo(newVideo);
  };
  const handleOkClick = () => {
    let newMediaContainer: MediaContainer = { imageLinks: undefined, videos: [video] } as MediaContainer;
    props.onOkClick(newMediaContainer);
  };
  const isDisabled = () => {
    if (video?.name === undefined || video?.name === "") {
      return true;
    }
    if (video?.source === undefined || video?.source === "") {
      return true;
    }
    return false;
  };
  return (
    <div>
      <div className="row m-2">
        <div className="col-12">
          <div style={{ display: "inline-block" }}>
            <div>Länk</div>
            <div>
              <TextBox
                id="videoSrc"
                style={{ width: textWidth }}
                className="k-textbox"
                rounded={"medium"}
                onChange={handleSourceChange}
                value={video?.source ?? ""}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row m-2">
        <div className="col-12">
          <div style={{ display: "inline-block" }}>
            <div>Namn</div>
            <div>
              <TextBox
                id="videoName"
                style={{ width: textWidth }}
                onChange={handleNameChange}
                className="k-textbox"
                rounded={"medium"}
                value={video?.name ?? ""}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row m-2">
        <div className="col-12">
          <div style={{ display: "inline-block" }}>
            <div>Länk</div>
            <div>
              <TextBox id="videoLink" style={{ width: textWidth }} className="k-textbox" rounded={"medium"} />
            </div>
          </div>
        </div>
      </div> */}

      <div className="row m-2">
        <div className="col-12">
          <div style={{ display: "inline-block" }}>
            <div>
              <Button className="btn btn-primary" onClick={handleOkClick} disabled={isDisabled()}>
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoSelector;
