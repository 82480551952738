export enum MenuIndex {
  None = 0,
  Home = 1,
  Questions = 1,
  LearningObjects = 2,
  Interactions = 3,
  Educations = 4,
}

export enum ClassificationType {
  behorigheter,
  andamal,
  amnen,
  larandemal,
  // behorigheterLcms,
  // andamalLcms,
  // amnnenLcms,
  // larandemalLcms,
  // behorigheterQuestion,
  // andamalQuestion,
  // amnnenQuestion,
  // larandemalQuestion,
}

export enum StatusType {
  None = 0,
  Draft = 1,
  Final = 2,
  Review = 3,
}

export enum PublishStatusType {
  None = 0,
  Dirty = 1,
  Pending = 2,
  Success = 3,
  Failure = 4,
}
