import { appConfig } from "../../appConfig";
import { iConfiguration } from "../../components/common/classifications/interfaces";
import { Client, ContainerDto, IConfigDto } from "../Client";

export const fetchConfiguration = async (configKey: string): Promise<iConfiguration> => {
  const questionApiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
  const questionClient = new Client(questionApiUrl);
  const result: IConfigDto = await questionClient.configGet(configKey);

  const configuration = JSON.parse(result.value!);
  let configurationData: iConfiguration = {
    classifications: configuration.classifications,
    learningObjectives: null,
  };

  return configurationData;
};

export const copyContainer = async (learningObjectiveId: number) => {
  const lcmsApiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;

  const lcmsClient = new Client(lcmsApiUrl);

  let copiedContainer: ContainerDto | undefined = undefined;

  if (learningObjectiveId > 0) {
    copiedContainer = await lcmsClient.containerCopyContainer(learningObjectiveId);
  }

  if (copiedContainer) {
    return copiedContainer.id;
  }
  //await lcmsClient.settingSetLatestQuestions(questionId, userId);
  return 0;
};

export const isPublisher = async (): Promise<boolean> => {
  const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
  const client = new Client(apiUrl);

  let isPublisher: boolean = await client.userIsPublisher();
  return isPublisher;
};

export const getContainerId = async (contentId: string): Promise<number> => {
  const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
  const client = new Client(apiUrl);
  let containerId: number = await client.publishGetContainerId(contentId, "1");
  return containerId;
};
