import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Video } from "../../../../../api/Client";
import { base64Decode, base64Encode } from "../../../../common/base64encoding";
import { EditorView } from "@progress/kendo-editor-common";
import { GetParentNode, ComponentMode } from "../../../../common/RichTextEditorTools";
import { Button } from "@progress/kendo-react-buttons";
import { VideoComponentDialog } from "./VideoComponentDialog";
import { CreateComponentHtml } from "../common/utilities";

const videoPlaceholder = "/images/VideoPlaceholder.png";

export const videoClassName = "video";

export class VideoComponent extends Video {}

// Tool Button
export const EditNodeToolVideoComponent = (props: any) => {
  const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);
  const [editorSchema, setEditorSchema] = useState<any>(undefined);
  const [editVideoComponent, setEditVideoComponent] = useState<any>(null);
  const { view } = props;
  useEffect(() => {
    if (view) {
      setEditorView(view);
      const schema = view.state.schema;
      setEditorSchema(schema);
    }
  }, [view]);
  const mode = ComponentMode(view, videoClassName);
  const handleSubmitEditVideoComponent = (props: any) => {
    const { newNode, nodeAndPos } = props;
    const state = editorView!.state;
    const tr = state.tr.replaceWith(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize, newNode);
    editorView!.dispatch(tr);
    setEditVideoComponent(null);
  };
  const handleCancelEditVideoComponent = () => {
    setEditVideoComponent(null);
  };
  return (
    <>
      <Button
        onClick={() => {
          const state = view.state;
          const tr = state.tr;
          const nodeAndPos = GetParentNode(
            tr.doc,
            tr.selection.from,
            tr.selection.to,
            "str-component-" + videoClassName,
            false
          );
          if (nodeAndPos) {
            setEditVideoComponent({ nodeAndPos });
          }
        }}
        togglable={true}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={mode === 1 ? "Ändra video" : "Infoga video"}
        icon="hd"
        selected={mode === 1}
        disabled={mode === -1}
      />
      {editVideoComponent &&
        createPortal(
          <VideoComponentDialog
            {...editVideoComponent}
            editorSchema={editorSchema}
            onSubmit={handleSubmitEditVideoComponent}
            onCancel={handleCancelEditVideoComponent}
          />,
          document.body
        )}
    </>
  );
};

// Create component
export const GetVideoComponentFromHTML = (html: string): VideoComponent => {
  const parts = html.split("|");
  const decodedString = base64Decode(parts[1]);
  return new VideoComponent(JSON.parse(decodedString));
};

// Create HTML
export const GetHtmlFromVideoComponent = (videoComponent: VideoComponent): string => {
  const visualTemplate = `<p><img src="@src@" width="40px" style="float:left;margin-right:3px"><span style="font-style: oblique;">@link@</span><br></p><p>Namn: @name@<br>Källa: @provider@</p>`;
  const html = visualTemplate
    .replace("@src@", videoPlaceholder)
    .replace("@link@", videoComponent.link || "")
    .replace("@name@", videoComponent.name || "")
    .replace("@provider@", videoComponent.provider || "");
  return CreateComponentHtml(videoClassName, videoComponent, html);
};
