// export function base64Encode(str: string): string {
//     return window.btoa(unescape(str));
// }

// export function base64Decode(str: string): string {
//     return escape(window.atob(str));
// }

// export function base64Encode(str: string): string {
//     try {
//         return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
//             return String.fromCharCode(parseInt(p1, 16));
//         }));
//     }
//     catch (e) {
//         console.error(e);
//         return "";
//     }
// }

// export function base64Decode(str: string): string {
//     try {
//         return decodeURIComponent(Array.prototype.map.call(atob(str), (c: string) => {
//             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//         }).join(''));
//     }
//     catch (e) {
//         console.error(e);
//         return "";
//     }
// }

export function base64Encode(str: string): string {
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(str);
    return btoa(String.fromCharCode(...uint8Array));
}

export function base64Decode(str: string): string {
    const binaryString = atob(str);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(uint8Array);
}
