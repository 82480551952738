import { InteractionResponse } from "../../api/Client";

export interface tab {
  title: string;
  content: any;
  id: number;
}

export interface IInteractionMetaData {
  title: string;
  description: string;
}

export enum ContainerSubTypeResponse {
  Unspecified = 0,
  Success = 1,
  Failure = 2,
  Option = 3,
}

export enum ContainerSubTypeInteraction {
  Unspecified = 0,
  SuccessFailure = 1,
  Responses = 2,
}

export const createInteractionResponse = (subType: number, optionId: string | undefined) => {
  const interactionResponseStructure = new InteractionResponse({
    id: 0,
    subType: subType,
    optionId: optionId,
    components: [],
  });

  return interactionResponseStructure;
};
