import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Carousel, CarouselItem, Image } from "../../../../../api/Client";
import {
  CreateComponentHtml,
  GetObjectFromHtml,
  fixImageSrc,
  iconImageWidth,
  isValidJSON,
  missingImage,
} from "../common/utilities";
import { EditorView } from "@progress/kendo-editor-common";
import { GetParentNode, ComponentMode } from "../../../../common/RichTextEditorTools";
import { Button } from "@progress/kendo-react-buttons";
import { GetImageInfoFromContentIdAndVersionId } from "../common/utilities";
import { SVGIcon } from "@progress/kendo-react-common";
import CarouselIcon from "../common/icons/carousel_icon.svg";
import { CarouselComponentDialog } from "./CarouselComponentDialog";
import * as svgIcons from "@progress/kendo-svg-icons";

export const carouselClassName = "carousel";

export class CarouselComponent extends Carousel {
  static fromJS(data: any): CarouselComponent {
    return new CarouselComponent(Carousel.fromJS(data));
  }
}

// Create component
export const GetCarouselComponentFromHTML = (html: string, removeSrc: boolean): CarouselComponent => {
  const carousel = CarouselComponent.fromJS(GetObjectFromHtml(html));
  // // const parts = html.split("|");
  // // const decodedString = base64Decode(parts[1]);
  // // const jsonObj = JSON.parse(decodedString);
  // const jsonObj = GetObjectFromHtml(html);

  // //TODO: Finns det något smidigare sätt att typa om objektet?
  // const carousel = new CarouselComponent(jsonObj);
  // carousel.items = carousel.items?.map((item) => {
  //   const newCarouselItem = new CarouselItem(item);
  //   newCarouselItem.image = new Image(item.image);
  //   return new CarouselItem(newCarouselItem);
  // });
  if (removeSrc && carousel.items) {
    for (let item of carousel.items) {
      const linkObj = JSON.parse(item.image?.link!);
      if (linkObj.src)
        item.image!.link = JSON.stringify({ contentId: linkObj.contentId, versionId: linkObj.versionId });
    }
  }
  return carousel;
};

// Create HTML
export const GetHtmlFromCarouselComponent = async (carousel: CarouselComponent) => {
  const visualTemplate = `<div>@images@</div>`;
  const imageTemplate = `<div style="display:inline-block;border:1px solid;margin:3px;padding:3px"><p><img src="@src@" width="@width@" style="display: block;margin-left: auto;margin-right: auto;"><br>[ <span style="font-style: oblique;">@fileName@</span> ]</p><p>@text@</p></div>`;
  const images: Array<string> = [];
  for (let item of carousel.items!) {
    const linkObj = isValidJSON(item.image?.link) ? JSON.parse(item.image!.link!) : {};
    if (!linkObj.src) {
      linkObj.src = missingImage;
      if (linkObj.contentId && linkObj.versionId) {
        const imageInfo = await GetImageInfoFromContentIdAndVersionId(linkObj.contentId, linkObj.versionId);
        linkObj.src = imageInfo.url || "";
        item.image!.fileName = imageInfo.properties?.find((p) => p.name === "Filename")?.value || "";
      }
    }
    linkObj.src = fixImageSrc(linkObj.src);
    item.image!.link = JSON.stringify(linkObj);
    images.push(
      imageTemplate
        .replace("@src@", linkObj.src)
        .replace("@width@", iconImageWidth)
        .replace("@text@", item.text || "")
        .replace("@fileName@", item.image?.fileName!)
    );
  }
  const finalTemplate = visualTemplate
    .replace("@noOfImages@", carousel.items?.length.toString() || "0")
    .replace("@images@", images.join(""));
  return CreateComponentHtml(carouselClassName, carousel, finalTemplate);
};

// Tool Button
export const EditNodeToolCarouselComponent = (props: any) => {
  const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);
  const [editorSchema, setEditorSchema] = useState<any>(undefined);
  const [editCarouselComponent, setEditCarouselComponent] = useState<any>(null);
  const { view } = props;
  useEffect(() => {
    if (view) {
      setEditorView(view);
      const schema = view.state.schema;
      setEditorSchema(schema);
    }
  }, [view]);

  const mode = ComponentMode(view, carouselClassName);
  const handleSubmitEditCarouselComponent = (props: any) => {
    const { newNode, nodeAndPos } = props;
    const state = editorView!.state;
    //console.log("nodeAndPos", nodeAndPos);
    //console.log("newNode", newNode);
    const tr = state.tr.replaceWith(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize, newNode);
    editorView!.dispatch(tr);
    setEditCarouselComponent(null);
  };
  const handleCancelEditCarouselComponent = () => {
    setEditCarouselComponent(null);
  };
  return (
    <>
      <Button
        onClick={() => {
          const state = view.state;
          const tr = state.tr;
          const nodeAndPos = GetParentNode(
            tr.doc,
            tr.selection.from,
            tr.selection.to,
            "str-component-" + carouselClassName,
            false
          );
          if (nodeAndPos) {
            setEditCarouselComponent({ nodeAndPos });
          }
        }}
        togglable={true}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={mode === 1 ? "Ändra karusell" : "Infoga karusell"}
        svgIcon={svgIcons.imagesIcon}
        selected={mode === 1}
        disabled={mode === -1}
      ></Button>
      {editCarouselComponent &&
        createPortal(
          <CarouselComponentDialog
            {...editCarouselComponent}
            editorSchema={editorSchema}
            onSubmit={handleSubmitEditCarouselComponent}
            onCancel={handleCancelEditCarouselComponent}
          />,
          document.body
        )}
    </>
  );
};
