import { useEffect, useState } from "react";
import Moment from "moment";
import { appConfig } from "../../appConfig";
import { Client, ContainerDto, WhereUsedDto } from "../../api/Client";
import iLearningObjectMetadata from "../../interfaces/iLearningObjectMetadata";
import NamesViewer from "../common/NamesViewer";
import { ClassificationDto } from "../../api/learnable_classification/ClassificationClient";
import { iChipItem, iClassificationItem } from "../common/classifications/interfaces";
import { getSelectedClassificationIds } from "./edit/functions/containerDtoFunctions";
import { getChipListItems, getWhereUsedContainer } from "../common/classifications/helperFunctions";
import ItemViewer from "../common/classifications/ItemViewer";

const MetadataPanel = (props: any) => {
  const dataItem = props.dataItem;
  console.log("MetadataPanel props", props);
  const [metadata, setMetadata] = useState<iLearningObjectMetadata | null>(null);
  const [chipListItems, setChipListItems] = useState<iChipItem[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await fetchLearningObjectMetadata();
  };

  const fetchLearningObjectMetadata = async () => {
    const lcmsApiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
    const lcmsClient = new Client(lcmsApiUrl);

    var containerDto: ContainerDto = await lcmsClient.containerGet(dataItem.id);
    console.log("fetchLearningObjectMetadata", containerDto, dataItem);

    let behorigheter: ClassificationDto[] = props.behorigheter;
    let selectedBehoirgheter: iClassificationItem[] = [];

    let selectedClassifications: iClassificationItem[] = getSelectedClassificationIds(containerDto);

    selectedClassifications.forEach((x) => {
      let found = behorigheter.find((y) => y.identifier === x.identifier);
      if (found) {
        x.id = found.id!;
        x.name = found.name!;
        selectedBehoirgheter.push(x);
      }
    });

    let whereUsed: WhereUsedDto[] = await getWhereUsedContainer(containerDto);
    let chipListItems = getChipListItems(selectedBehoirgheter, whereUsed);
    setChipListItems(chipListItems);

    const learningObjectiveNames: string[] = containerDto.containerLearningObjectiveDtos!.map(
      (learningObjectiveDto) => {
        let found = props.learningObjectives.find(
          (x: any) => x.identifier === learningObjectiveDto.learningObjectiveId
        );
        if (found) {
          return found.name;
        }
      }
    );

    const metadata: iLearningObjectMetadata = {
      customerName: containerDto.customerDto.name,
      learningObjectives: learningObjectiveNames,
      created: containerDto.created!,
      createdBy: dataItem.createdBy,
      updated: containerDto.updated,
      updatedBy: dataItem.updatedBy,
    };

    setMetadata(metadata);
  };

  return (
    <div className="cols-12">
      <div className="row">
        <div className="col-4">
          <div className="gridContainer">
            <div>Organisation:</div>
            <div>{metadata && metadata.customerName}</div>

            <div>Lärandemål:</div>
            {metadata && <NamesViewer names={metadata.learningObjectives} />}

            <div>Ämne:</div>
            {metadata && <></>}
          </div>
        </div>

        <div className="col-4">
          <div className="gridContainer">
            <div>Utbildningar:</div>
            <div>{metadata && <ItemViewer data={chipListItems} type="chip" />}</div>
          </div>
        </div>

        <div className="col-4">
          <div className="gridContainer">
            <div>Skapades:</div>
            <div>{metadata && metadata.created && Moment(metadata.created).format("yyyy-MM-DD")}</div>

            <div>Skapades av:</div>
            <div>{metadata && metadata.createdBy}</div>

            <div>Ändrades av:</div>
            <div>{metadata && metadata.updatedBy}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetadataPanel;
