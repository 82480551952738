import { Chip, ChipList, ChipMouseEvent, ChipProps } from "@progress/kendo-react-buttons";
import { iChipItem } from "./interfaces";
import { trimString } from "./helperFunctions";
interface ItemViewerProps {
  data: iChipItem[];
  type?: string;
}
const ItemViewer = ({ type, data }: ItemViewerProps) => {
  console.log("ItemViewer", data);
  const chipItem = (props: ChipProps) => {
    const chipColor = props.dataItem.isUsed ? "#B4E5F4" : "";
    const iconClass = props.dataItem.isUsed && props.dataItem.onClick ? "k-icon k-i-hyperlink-open-sm" : "";
    const customStyle = {
      backgroundColor: chipColor,
      cursor: props.dataItem.isUsed && props.dataItem.onClick ? "pointer" : "default",
    };
    // const handleClick = (e: ChipMouseEvent) => {};
    return (
      <div className="col-12">
        <Chip {...props} style={customStyle} size={"small"} onClick={props.dataItem.onClick}>
          <span style={{ whiteSpace: "pre-wrap" }} className="k-chip-label me-2">
            {props.text}
          </span>
          <span className={iconClass} />
        </Chip>
      </div>
    );
  };
  return <>{type && type === "chip" && <ChipList data={data} chip={chipItem}></ChipList>}</>;
};
export default ItemViewer;
