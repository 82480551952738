import { TextArea, TextAreaChangeEvent, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  InteractionPropertyDto,
  QuestionMediaDto,
  QuestionTextDto,
  TextAndMedia,
} from "../../../api/learnable_question/QuestionClient";
import {
  getAreaNumber,
  getOptionElementsCount,
  getQuestionCopy,
  getQuestionInteractionProperties,
  getQuestionMedia,
  getQuestionTexts,
  isMedia,
  isText,
  safeParse,
  syncOptionValues,
} from "./functions/questionDtoFunctions";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { useAppContext } from "../../common/AppContext";
import QuestionMediaItem from "./QuestionMediaItem";
import QuestionOptionItem from "./QuestionOptionItem";
import ImageDialog from "../../optima_image/ImageDialog";
// import { iImageLink } from "../../../interfaces/iMediaLink";
import {
  MEDIA_LINK_TYPE_MEDIA_SERVICE,
  MEDIA_LINK_TYPE_RECTANGLE,
  QUESTION_FRAMESTAM_TYPE_ID,
  SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE,
} from "../../common/Constants";
import React from "react";
import QuestionOptionItemClickable from "./QuestionOptionItemClickable";
import { uuid } from "uuidv4";
import { iImageLink } from "../../optima_image/interfaces";

const QuestionOption = (props: any) => {
  const appObject = useAppContext();

  const { labelText, handleTextChange } = props;

  const templateDataItem: TextAndMedia = props.templateDataItem!;
  const question: iQuestionDtoExtended = props.question!;
  const optionId: string = props.optionId;
  const questionText: QuestionTextDto | undefined = question!.questionTexts!.find(
    (x) => x.optionId === optionId && x.textTypeId === templateDataItem.textMediaTypeId
  );

  const questionMedia: QuestionMediaDto[] = question!.questionMedia!.filter(
    (x) => x.typeId === templateDataItem.textMediaTypeId
  );
  const questionMedium: QuestionMediaDto | undefined = questionMedia!.find(
    (x) => x.optionId === optionId && x.typeId === templateDataItem.textMediaTypeId
  );

  // console.log("QuestionOption_questionMedium", questionMedium);
  const questionInteractionProperties: InteractionPropertyDto[] = getQuestionInteractionProperties(question!)!;

  const [visibleDialog, setVisibleDialog] = React.useState<boolean>(false);

  const getQuestionTextText = (questionText: QuestionTextDto): string => {
    if (questionText == undefined) {
      return "";
    }

    return questionText!.texts![0].text!;
  };
  const isCorrectResponse = () => {
    let correctResponseValue = questionInteractionProperties.map((x) => x.value!)[0];
    // console.log("isCorrectResponse1", correctResponseValue);
    if (correctResponseValue != "") {
      let correctResponse = safeParse(correctResponseValue);
      //correctResponse = correctResponse.map((x: any) => x.toString());
      // console.log("isCorrectResponse", correctResponse);
      if (isText(templateDataItem)) {
        for (let i = 0; i < correctResponse.length; i++) {
          if (correctResponse[i].toString() === optionId) {
            return true;
          }
        }
      } else if (isMedia(templateDataItem)) {
        for (let i = 0; i < correctResponse.length; i++) {
          if (correctResponse[i].toString() === optionId) {
            return true;
          }
        }
      }
    }

    return false;
  };
  // const isCorrectResponse_ = () => {
  //   // console.log("isCorrectResponse", questionInteractionProperties);
  //   if (isText(templateDataItem)) {
  //     if (questionInteractionProperties.filter((x) => x.value?.includes(optionId)).length > 0) {
  //       return true;
  //     }
  //   } else if (isMedia(templateDataItem)) {
  //     if (questionInteractionProperties.filter((x) => x.value?.includes(optionId)).length > 0) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  const handleCorrectResponseChange = (event: CheckboxChangeEvent, optionId: string) => {
    //props.setCorrectResponse(event.value ? optionId : "");
    props.setCorrectResponse(optionId);
  };

  const setSortOrder = (questionTextMedia: any[], index: number, newSortOrder: number) => {
    if (questionTextMedia && questionTextMedia.length > index) {
      questionTextMedia[index].sortOrder += newSortOrder;
    }
  };

  const handleSortOrderUpChange = (optionId: string) => {
    //if (questionIndex === 0) {
    //    return;
    //}

    //[
    //    [1(1), 1.1(1)]
    //    [4(2), 2.1(2)]
    //    [3, 3.1]
    //    [2,]
    //]

    let questionCopy = getQuestionCopy(props.question!);
    let questionTexts = getQuestionTexts(questionCopy!, templateDataItem.textMediaTypeId!)!.sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });
    let questionIndex = questionTexts.findIndex((x) => x.optionId === optionId);
    let questionMedia = getQuestionMedia(questionCopy!, templateDataItem.textMediaTypeId!)!.sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });
    if (questionIndex === -1) {
      questionIndex = questionMedia.findIndex((x) => x.optionId === optionId);
    }

    if (questionIndex === 0) {
      return;
    }
    //TODO: filtrera questionTexts och questionMedia beroende på level

    for (let i = 0; i < getOptionElementsCount(question, templateDataItem.textMediaTypeId!); i++) {
      if (i === questionIndex - 1) {
        setSortOrder(questionTexts, i, 1);
        setSortOrder(questionMedia, i, 1);
      }

      if (i === questionIndex) {
        setSortOrder(questionTexts, i, -1);
        setSortOrder(questionMedia, i, -1);
      }
    }

    syncOptionValues(appObject.interactionTypes, questionCopy, templateDataItem);
    questionCopy.isModified = true;
    props.setQuestion(questionCopy);
    console.log("after sort up", questionCopy);
    //console.log("after sort", questionCopy);
  };

  const handleSortOrderDownChange = (optionId: string) => {
    let questionCopy = getQuestionCopy(props.question!);
    let questionTexts = getQuestionTexts(questionCopy!, templateDataItem.textMediaTypeId!)!.sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });
    let questionIndex = questionTexts.findIndex((x) => x.optionId === optionId);
    let questionMedia = getQuestionMedia(questionCopy!, templateDataItem.textMediaTypeId!)!.sort(function (a, b) {
      return a.sortOrder! - b.sortOrder!;
    });
    if (questionIndex === -1) {
      questionIndex = questionMedia.findIndex((x) => x.optionId === optionId);
    }
    const elementsCount = getOptionElementsCount(question, templateDataItem.textMediaTypeId!);
    if (questionIndex === elementsCount - 1) {
      return;
    }

    for (let i = 0; i < elementsCount; i++) {
      if (i === questionIndex + 1) {
        setSortOrder(questionTexts, i, -1);
        setSortOrder(questionMedia, i, -1);
      }

      if (i === questionIndex) {
        setSortOrder(questionTexts, i, 1);
        setSortOrder(questionMedia, i, 1);
      }
    }

    syncOptionValues(appObject.interactionTypes, questionCopy, templateDataItem);
    questionCopy.isModified = true;
    props.setQuestion(questionCopy);
    console.log("after sort down", questionCopy);
  };

  //const onImageDialogClose = (image: GetAssetResponse) => {
  const onImageDialogClose = (image: string) => {
    props.addMedium(templateDataItem, optionId, image);
    setVisibleDialog(false);
  };

  const handleDeleteMedium = (questionMedium: QuestionMediaDto) => {
    switch (questionMedium.media![0].linkTypeId) {
      case MEDIA_LINK_TYPE_MEDIA_SERVICE:
        props.deleteMedium(templateDataItem.textMediaTypeId, questionMedium.optionId);
        break;
      case MEDIA_LINK_TYPE_RECTANGLE:
        props.deleteMedium(templateDataItem.textMediaTypeId, questionMedium.optionId);
        //props.deleteMedium(templateDataItem.textMediaTypeId, questionMedia[props.regionIndex].optionId);
        break;
      default:
        props.deleteMedium(templateDataItem.textMediaTypeId, questionMedium.optionId);
    }

    //props.deleteMedium(templateDataItem.textMediaTypeId, questionMedium.optionId, index);
  };

  const onRemoveRegion = () => {};

  const getClickableMediumUrl = (): string => {
    const questionMediumFound = question!.questionMedia!.filter((x) => x.typeId === QUESTION_FRAMESTAM_TYPE_ID)[0];
    if (questionMediumFound && questionMediumFound.media?.length! > 0) {
      let mediaLink: iImageLink = JSON.parse(questionMediumFound.media![0].link!);

      if (props.mediaLinks && props.mediaLinks.length > 0 && mediaLink.ContentId) {
        let found: iImageLink = props.mediaLinks!.find(
          (x: iImageLink) => x.ContentId === mediaLink.ContentId && x.VersionId === mediaLink.VersionId
        )!;
        return found?.Url;
      }
    }
    return "";
  };

  const getMediaComponent = () => (
    <>
      {question.subQuestionTypeId !== 3 && <Button onClick={() => setVisibleDialog(true)}>Lägg till media</Button>}

      <div className="row" key={"getMediaComponent_" + optionId}>
        <QuestionMediaItem
          question={question}
          mediaLinks={props.mediaLinks}
          questionMedium={questionMedium}
          templateDataItem={templateDataItem}
          deleteMedium={handleDeleteMedium}
          col={props.questionMediaItemCol}
          onRemove={onRemoveRegion}
          subQuestionTypeId={question.subQuestionTypeId}
        />
      </div>
    </>
  );

  const getTextComponent = () => (
    <TextArea
      key={"getTextComponent_" + optionId}
      value={questionText && getQuestionTextText(questionText)}
      onChange={(event: TextAreaChangeEvent) => handleTextChange(event, questionText)}
    />
  );

  return (
    <div>
      {question.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE ? (
        <QuestionOptionItemClickable
          key={"QuestionOptionItemClickable_" + optionId}
          question={question}
          labelText={labelText}
          optionId={optionId}
          uniqueOptionIdsSorted={props.uniqueOptionIdsSorted}
          templateDataItem={templateDataItem}
          imageNumber={getAreaNumber(question, questionMedium!.optionId!)}
          medium={questionMedium!.media![0]}
          mediumUrl={getClickableMediumUrl()}
          textComponent={questionText && getTextComponent()}
          addElement={props.addElement}
          removeElement={props.removeElement}
          isCorrectResponse={isCorrectResponse}
          handleCorrectResponseChange={handleCorrectResponseChange}
        />
      ) : (
        <>
          {questionText && (
            <QuestionOptionItem
              key={"QuestionOptionItemText_" + optionId}
              question={question}
              labelText={labelText}
              optionId={optionId}
              uniqueOptionIdsSorted={props.uniqueOptionIdsSorted}
              templateDataItem={templateDataItem}
              handleSortOrderUpChange={handleSortOrderUpChange}
              handleSortOrderDownChange={handleSortOrderDownChange}
              component={getTextComponent()}
              showOtherElements={true}
              addElement={props.addElement}
              removeElement={props.removeElement}
              isCorrectResponse={isCorrectResponse}
              handleCorrectResponseChange={handleCorrectResponseChange}
              showCorrectResponseCheckBoxes={props.showCorrectResponseCheckBoxes}
            />
          )}

          {questionMedium && (
            <QuestionOptionItem
              key={"QuestionOptionItemMedium_" + optionId}
              question={question}
              labelText={labelText}
              optionId={optionId}
              uniqueOptionIdsSorted={props.uniqueOptionIdsSorted}
              templateDataItem={templateDataItem}
              handleSortOrderUpChange={handleSortOrderUpChange}
              handleSortOrderDownChange={handleSortOrderDownChange}
              component={getMediaComponent()}
              showOtherElements={!questionText}
              addElement={props.addElement}
              removeElement={props.removeElement}
              isCorrectResponse={isCorrectResponse}
              handleCorrectResponseChange={handleCorrectResponseChange}
              showCorrectResponseCheckBoxes={props.showCorrectResponseCheckBoxes}
            />
          )}
        </>
      )}

      {visibleDialog && (
        <ImageDialog onOkClick={onImageDialogClose} onClose={() => setVisibleDialog(false)} showVideoSelector={false} />
      )}
    </div>
  );
};

export default QuestionOption;
