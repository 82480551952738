import React from "react";
import { Chip, ChipProps } from "@progress/kendo-react-buttons";
import { iClassificationItem } from "./interfaces";
import { LEARNING_OBJECTIVES_ROOT_ID } from "../Constants";
// import { rootNodeShouldBeHidden } from "../helperFunctions";

const SelectedChipList = (props: ChipProps) => {
  const getTextValue = () => {
    // if (rootNodeShouldBeHidden(props.dataItem)) {
    //   return props.dataItem.name;
    // }
    const rootName =
      props.dataItem.rootName && props.dataItem.rootName === LEARNING_OBJECTIVES_ROOT_ID ? "" : props.dataItem.rootName;
    const delimiter = rootName && rootName.length > 0 ? " / " : "";
    return rootName + delimiter + props.dataItem.name;
  };

  return (
    <div>
      <Chip {...props} removable={true} text={getTextValue()} onRemove={props.onRemove} />
    </div>
  );
};

export default SelectedChipList;
