import { TextAndMedia } from "../../../../api/learnable_question/QuestionClient";
import { appConfig } from "../../../../appConfig";
// import { iImageLink } from "../../../../interfaces/iMediaLink";

import { QUESTION_OPTION_SOURCE_TYPE_ID, QUESTION_OPTION_TARGET_TYPE_ID } from "../../../common/Constants";
import { iImageLink } from "../../../optima_image/interfaces";

export const getMaxId = (arr: any[], key: string) => {
  if (arr.length === 0) return 1;
  const val = Math.max(...arr.map((o) => o[key]));

  if (Number.isNaN(val)) {
    return 1;
  }

  return val + 1;
};

export const isQuestionOption = (templateDataItem: TextAndMedia): boolean => {
  return isQuestionOptionSource(templateDataItem) || isQuestionOptionTarget(templateDataItem);
};

export const isQuestionOptionSource = (templateDataItem: TextAndMedia): boolean => {
  return templateDataItem.textMediaTypeId === QUESTION_OPTION_SOURCE_TYPE_ID;
};

export const isQuestionOptionTarget = (templateDataItem: TextAndMedia): boolean => {
  return templateDataItem.textMediaTypeId === QUESTION_OPTION_TARGET_TYPE_ID;
};

export const getNextNegativeID = (arr: any) => {
  let negativeIDs = arr.filter((id: number) => id < 0);
  if (negativeIDs.length === 0) {
    return -1;
  }

  let newId = Math.min.apply(Math, negativeIDs) - 1; //Math.min(negativeIDs) - 1;
  return newId;
};

//used for trim long classification names and learning objective names
export const trimString = (name: string) => {
  if (name.length > 20) {
    return name.substring(0, 20) + "...";
  }
  return name;
};

export const openQuestion = (questionId: number, templateId: number) => {
  window.open("/NewQuestion?id=" + questionId + "&templateId=" + templateId, "_blank", "noreferrer");
};

export const openLearningObject = (learningObjectId: number) => {
  window.open("/LearningObject?id=" + learningObjectId, "_blank", "noreferrer");
};

// export const openEducation = (title: string, classifications: string[]) => {
//   let url: string = appConfig.REACT_APP_LEARNININGSTRUCTURE_CLIENT_URL as string;
//   window.open(`${url}?title=${title}&classifications=${classifications.join(",")}`, "_blank", "noreferrer");
// };

export const openEducation = (treeId: number, contentId: string | undefined) => {
  let url: string = `${appConfig.REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL as string}?id=${treeId}`;
  if (contentId) {
    url += `&contentId=${contentId}`;
  }

  window.open(url, "_blank", "noreferrer");
};

export type interactionParams = {
  interactionId: number;
  interactionType: number;
  questionId: number;
  templateId: number;
};

export const openInteraction = (interactionParams: interactionParams) => {
  if (interactionParams.interactionId > 0) {
    // existing from grid
    window.open(`/Interaction?id=${interactionParams.interactionId}`, "_blank", "noreferrer");
  } else {
    // new from dialog
    window.open(
      `/Interaction?type=${interactionParams.interactionType}&templateId=${interactionParams.templateId}&questionId=${interactionParams.questionId}`,
      "_blank",
      "noreferrer"
    );
  }
};

export const openQuestionPreview = (identifier: string) => {
  let previewUrl: string = appConfig.REACT_APP_PREVIEW_CLIENT_URL as string;
  previewUrl += "/question/" + identifier;
  window.open(previewUrl, "_blank", "noreferrer");
};

export const openLearningObjectPreview = (id: number) => {
  let previewUrl: string = appConfig.REACT_APP_PREVIEW_CLIENT_URL as string;
  previewUrl += "/learningobject/" + id;
  window.open(previewUrl, "_blank", "noreferrer");
};

export const resizeImage = (url: string | undefined, width: number): string => {
  if (!url) {
    return "";
  }

  let arr: string[] = url.split("&");
  return arr[0] + "&w=" + width.toString();
};

//export const openQuestion = (questionId : number, templateId: number) => {
//    localStorage.setItem("questionId", questionId.toString());
//    localStorage.setItem("questionTemplateId", templateId.toString());
//    //navigate('/NewQuestion');

//    window.open('/NewQuestion', "_blank", "noreferrer");
//}

export const getUrl = (link: string, mediaLinks: iImageLink[]): string => {
  let mediaLink: iImageLink = JSON.parse(link);

  if (mediaLink.ContentId) {
    let found: iImageLink = mediaLinks!.find(
      (x) => x.ContentId === mediaLink.ContentId && x.VersionId === mediaLink.VersionId
    )!;
    return found?.Url;
  }

  return "";
};

export const getImageLink = (link: string): iImageLink => {
  let mediaLink: iImageLink = JSON.parse(link);
  return mediaLink;
};

export const getTitle = (link: string, mediaLinks: iImageLink[]): string => {
  let mediaLink: iImageLink = JSON.parse(link);

  if (mediaLink.ContentId) {
    let found: iImageLink = mediaLinks!.find(
      (x) => x.ContentId === mediaLink.ContentId && x.VersionId === mediaLink.VersionId
    )!;
    return found?.Title;
  }

  return "";
};

export const templateMultiColumnComboBoxColumns = [
  { field: "id", header: "ID", width: "100px" },
  { field: "name", header: "Name", width: "300px" },
  { field: "description", header: "Beskrivning", width: "300px" },
];
// export const getClassificationRootIds = (configurationData: iConfiguration): string[] => {
//   let rootIds: string[] = [];
//   configurationData.classifications.forEach((classification) => {
//     classification.rootIds?.forEach((rootId: string) => {
//       rootIds.push(rootId);
//     });
//   });
//   // if (configurationData.learningObjectives) {
//   //   rootIds.push(configurationData.learningObjectives.rootId!);
//   // }
//   rootIds.push(LEARNING_OBJECTIVES_ROOT_ID!);
//   return rootIds;
// };
