import { appConfig } from "../../appConfig";
import { getTranslation } from "../../components/common/helperFunctions";
import { iContainerSubTypeDtoExtended } from "../../interfaces/iContainerSubTypeDtoExtended";
import { Client } from "../Client";
import { DynamicLinqRequest, SearchClient } from "../learnable_search/SearchClient";
import { ClassificationClient, ClassificationDto, GetClassificationsRequest } from "./ClassificationClient";

export const fetchClassifications = async (getClassificationRequest: GetClassificationsRequest) => {
  const classificationAPiUrl: string = appConfig.REACT_APP_CLASSIFICATION_API_URL as string;

  const classificationClient = new ClassificationClient(classificationAPiUrl);

  let result = await classificationClient.classificationGetClassificationsAsFlatList(getClassificationRequest);
  let filteredResult: ClassificationDto[][];
  filteredResult = result.map((x) => x.filter((i) => i.stateDto!.id !== 3).slice());

  return filteredResult ?? [];
};

// export const fetchLearningObjectiveContainerSubTypes = async () => {
//   const client = new Client(appConfig.REACT_APP_API_URL);
//   const subTypes = await client.containerGetContainerSubTypes(CONTAINER_TYPE_LEARNING_OBJECT);
//   return subTypes;
// };

export const fetchContainerSubTypes = async (containerTypeId: number): Promise<iContainerSubTypeDtoExtended[]> => {
  const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
  const subTypes: iContainerSubTypeDtoExtended[] = (await client.containerGetContainerSubTypes(
    containerTypeId
  )) as iContainerSubTypeDtoExtended[];

  subTypes.forEach((x) => (x.text = getTranslation(x.name!)));

  return subTypes;
};

export const fetchLearningObjectives = async () => {
  const client = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);
  const searchRequest = new DynamicLinqRequest({
    dynamicLinqString: `
                _learningObjectiveContext.LearningObjectives
				.Select("s => new {s.id, s.Identifier,s.LevelId, s.TypeId, s.Texts.FirstOrDefault(w => w.TextTypeId == 1).Value as name, s.Indicators}")
            `,
  });
  // Call Web API
  var result = await client.dynamicLinqSearch(searchRequest);
  const data = result?.rows ?? [];
  return data;
};

export const fetchLearningObjectiveIndicatorClassifications = async () => {
  const client = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);
  const searchRequest = new DynamicLinqRequest({
    dynamicLinqString: `
                _learningObjectiveContext.IndicatorClassifications
        .Select("s => new {s.IndicatorId,s.ClassificationRootId, s.ClassificationId}")
            `,
  });
  // Call Web API
  var result = await client.dynamicLinqSearch(searchRequest);
  const data = result?.rows ?? [];
  return data;
};

// export const fetchLearningObjectivesAsClassificationItems = async (): Promise<iClassificationItem[][]> => {
//   const client = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);
//   const searchRequest = new DynamicLinqRequest({
//     dynamicLinqString: `
//                 _learningObjectiveContext.LearningObjectives
// 				.Select("s => new {s.id, s.Identifier,s.LevelId, s.TypeId, s.Texts.FirstOrDefault(w => w.TextTypeId == 1).Value as name, s.LearningObjectiveClassifications, s.Indicators}")
//             `,
//   });

//   // Call Web API
//   //hämta inidicator classifications
//   const dataIndicators = await fetchLearningObjectiveIndicatorClassifications(); //result?.rows ?? [];
//   //hämta learning objectives
//   const result = await client.dynamicLinqSearch(searchRequest);

//   const data = result?.rows ?? [];
//   let classificationItems: iClassificationItem[] = data.map((item: any) => {
//     const itemIndicatorIds: string[] = item.indicators.map((x: any) => x.id);
//     const itemIndicatorClassifications = dataIndicators.filter((x: any) => itemIndicatorIds.includes(x.indicatorId));
//     //console.log("itemIndicatorIds", itemIndicatorIds, itemIndicatorClassifications);
//     let x: iClassificationItem = new ClassificationDto() as iClassificationItem;
//     x.identifier = item.identifier;
//     x.name = item.name;
//     x.rootId = LEARNING_OBJECTIVES_ROOT_ID;
//     x.rootName = "learningObjectives";
//     x.parentId = 0;
//     x.id = item.id;
//     x.items = [];
//     x.learningObjectiveClassifications = item.learningObjectiveClassifications;
//     x.learningObjectiveIndicators = item.indicators;
//     x.metadata = {
//       Level: item.levelId,
//       Type: item.typeId,
//       LearningObjectiveClassifications: itemIndicatorClassifications,
//     };
//     // x.learningObjectiveClassifications = item.learningObjectiveClassifications;
//     // learningObjectives.forEach((x) => (x.metadata = { Level: x.levelId, Type: x.typeId }));

//     return x;
//   });

//   let root: iClassificationItem = new ClassificationDto() as iClassificationItem;
//   root.identifier = LEARNING_OBJECTIVES_ROOT_ID;
//   root.name = LEARNING_OBJECTIVES_ROOT_ID;
//   root.rootId = LEARNING_OBJECTIVES_ROOT_ID;
//   root.rootName = LEARNING_OBJECTIVES_ROOT_ID;
//   root.id = 0;
//   root.parentId = undefined;

//   classificationItems = [root].concat(classificationItems);

//   return [classificationItems];
// };
