import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LearningObjects from "./components/learningobject/LearningObjects";
import LearningObjectives from "./components/LearningObjectives";
import Questions from "./components/question/Questions";
import NewQuestion from "./components/question/edit/NewQuestion";
import Media from "./components/Media";
import Keywords from "./components/admin/Keywords";
import Labels from "./components/admin/Labels";
import { AppContextProvider } from "./components/common/AppContext";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { AuthGuard } from "./auth/AuthGuard";
import LearningObject from "./components/learningobject/edit/LearningObject";
import "./App.css";
import MediumPreview from "./components/common/MediumPreview";
import Interactions from "./components/interaction/Interactions";
import Interaction from "./components/interaction/edit/Interaction";
import Test from "./Test";
import { FeatureFlagProvider } from "@strmediaochitab/optima-react-components";
import { fetchFeatureFlag, fetchFeatureFlags } from "./services/featureFlagService";
import Educations from "./components/eduction/Educations";

type AppProps = {
  msalInstance: IPublicClientApplication;
};

function App({ msalInstance }: AppProps) {
  const displayName = "LCMS";

  return (
    <>
      {
        <MsalProvider instance={msalInstance}>
          <AuthGuard>
            <AppContextProvider>
              <FeatureFlagProvider fetchFeatureFlag={fetchFeatureFlag} fetchFeatureFlags={fetchFeatureFlags}>
                <Routes>
                  <Route element={<Layout displayName={displayName} />}>
                    <Route path="/" element={<Questions />} />
                    <Route path="/LearningObjectives" element={<LearningObjectives />} />
                    <Route path="/LearningObjects" element={<LearningObjects />} />
                    <Route path="/LearningObject" element={<LearningObject />} />
                    <Route path="/MediumPreview" element={<MediumPreview />} />
                    <Route path="/Questions" element={<Questions />} />
                    <Route path="/NewQuestion" element={<NewQuestion />} />
                    <Route path="/Interactions" element={<Interactions />} />
                    <Route path="/Interaction" element={<Interaction />} />
                    <Route path="/Educations" element={<Educations />} />
                    <Route path="/Media" element={<Media />} />
                    {/* <Route path="/EditAssessment" element={<EditAssessment />} /> */}
                    <Route path="/Keywords" element={<Keywords />} />
                    <Route path="/Labels" element={<Labels />} />
                    <Route path="/Test" element={<Test />} />
                  </Route>
                </Routes>
              </FeatureFlagProvider>
            </AppContextProvider>
          </AuthGuard>
        </MsalProvider>
      }
    </>
  );
}

export default App;
