import { useEffect, useState } from "react";
import { QuestionClient } from "../../api/learnable_question/QuestionClient";
import iQuestionMetadata from "../../interfaces/iQuestionMetadata";
import Moment from "moment";
import { appConfig } from "../../appConfig";
import NamesViewer from "../common/NamesViewer";
import { getChipListItems, getWhereUsedQuestion } from "../common/classifications/helperFunctions";
import { getSelectedClassificationIds } from "./edit/functions/questionDtoFunctions";
import { iChipItem, iClassificationItem } from "../common/classifications/interfaces";
import { iQuestionDtoExtended } from "../../interfaces/iQuestionDtoExtended";
import { WhereUsedDto } from "../../api/Client";
import ItemViewer from "../common/classifications/ItemViewer";
import { ClassificationDto } from "../../api/learnable_classification/ClassificationClient";

const MetadataPanel = (props: any) => {
  // console.log("MetadataPanel_props", props);
  const dataItem = props.dataItem;
  const [metadata, setMetadata] = useState<iQuestionMetadata | null>(null);
  const [chipListItems, setChipListItems] = useState<iChipItem[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await fetchQuestionMetadata();
  };

  const fetchQuestionMetadata = async () => {
    const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
    const questionClient = new QuestionClient(questionApiUrl);

    var questionDto: iQuestionDtoExtended = (await questionClient.questionGet(dataItem.id)) as iQuestionDtoExtended;

    let behorigheter: ClassificationDto[] = props.behorigheter;
    let selectedBehoirgheter: iClassificationItem[] = [];

    let selectedClassifications: iClassificationItem[] = getSelectedClassificationIds(questionDto);

    selectedClassifications.forEach((x) => {
      let found = behorigheter.find((y) => y.identifier === x.identifier);
      if (found) {
        x.id = found.id!;
        x.name = found.name!;
        selectedBehoirgheter.push(x);
      }
    });

    let whereUsed: WhereUsedDto[] = await getWhereUsedQuestion(questionDto);
    let chipListItems = getChipListItems(selectedBehoirgheter, whereUsed);
    setChipListItems(chipListItems);

    console.log("fetchQuestionMetadata", chipListItems);
    var customers = await questionClient.lookupGetCustomers("1");

    const customerDictionary = Object.entries(customers).map(([key, value]) => {
      return { key: key, value: value };
    });

    var customerName = questionDto.customerId
      ? customerDictionary.filter((x) => x.key == questionDto.customerId)[0].value
      : "";

    questionDto
      .options!.sort((a, b) => (a.group! < b.group! ? -1 : 1))
      .sort((a, b) => (a.sortOrder! < b.sortOrder! ? -1 : a.sortOrder!));

    const learningObjectiveNames: string[] = questionDto.learningObjectiveDtos!.map((learningObjectiveDto) => {
      let found = props.learningObjectives.find((x: any) => x.identifier === learningObjectiveDto.id);
      if (found) {
        return found.name;
      }
    });

    const metadata: iQuestionMetadata = {
      customerName: customerName!,
      learningObjectives: learningObjectiveNames,
      created: questionDto.created!,
      createdBy: dataItem.createdBy,
      updated: questionDto.updated,
      updatedBy: dataItem.updatedBy,
    };

    setMetadata(metadata);
  };

  return (
    <div className="cols-12">
      <div className="row">
        <div className="col-4">
          <div className="gridContainer">
            <div>Organisation:</div>
            <div>{metadata && metadata.customerName}</div>

            <div>Lärandemål:</div>
            {metadata && <NamesViewer names={metadata.learningObjectives} />}

            <div>Ämne:</div>
            {metadata && <div />}
          </div>
        </div>

        <div className="col-4">
          <div className="gridContainer">
            <div>Utbildningar:</div>
            <div>{metadata && <ItemViewer data={chipListItems} type="chip" />}</div>
          </div>
        </div>

        <div className="col-4">
          <div className="gridContainer">
            <div>Skapades:</div>
            <div>{metadata && metadata.created && Moment(metadata.created).format("yyyy-MM-DD")}</div>

            <div>Skapades av:</div>
            <div>{metadata && metadata.createdBy}</div>

            <div>Ändrades av:</div>
            <div>{metadata && metadata.updatedBy}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetadataPanel;
