import React, { useState } from 'react';

const MediaCheckboxGroup = (props: any) => {

    const checkboxes = props.checkboxes;

    //const [checkboxes, setCheckboxes] = useState({
    //    harVideo: false,
    //    harBild: false,
    //    ingenMedia: false
    //});

    const handleCheckboxChange = (event: { target: { name: any; checked: any; }; }) => {
        const { name, checked } = event.target;

        let newCheckboxes = JSON.parse(JSON.stringify(checkboxes));
        newCheckboxes = {
            ...newCheckboxes,
            [name]: checked 
        }

        props.setCheckboxes(newCheckboxes);

        props.onChange(newCheckboxes);
        //console.log("checkboxes", newCheckboxes);
    };

    return (
        <div className="col-10 centerMiddle" style={{ overflow: 'hidden', border: '1px solid lightgrey', borderRadius: '5px' }}>
            <div className="m-2">
                <div className="" style={{ float: 'left' }}><input checked={checkboxes.harVideo} onChange={handleCheckboxChange} type="checkbox" name="harVideo" value="harVideo" /> Video</div>
                <div className="ms-2" style={{ float: 'left' }}><input checked={checkboxes.harBild} onChange={handleCheckboxChange} type="checkbox" name="harBild" value="harBild" /> Bild</div>
                <div className="ms-2" style={{ float: 'left' }}><input checked={checkboxes.ingenMedia} onChange={handleCheckboxChange} type="checkbox" name="ingenMedia" value="ingenMedia" /> Ingen media</div>
            </div> 
        </div>
    );

}

export default MediaCheckboxGroup;