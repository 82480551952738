import React, { useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { QuestionClient } from "../../api/learnable_question/QuestionClient";
import { ComboBoxChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { appConfig } from "../../appConfig";
import { templateMultiColumnComboBoxColumns } from "./edit/functions/helperFunctions";
import { useAppContext } from "../common/AppContext";

const TemplateSelector = (props: any) => {
  const appObject = useAppContext();
  const [value, setValue] = React.useState(undefined);
  const { interactionType, onClick } = props;

  const toggleDialog = () => {
    props.setShowTemplatesDialog(false);
  };

  const handleChange = (event: ComboBoxChangeEvent) => {
    if (event) {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
    const questionClient = new QuestionClient(questionApiUrl);
    questionClient.templateGetTemplates(interactionType, undefined, "1").then((reply) => {});
  }, []);

  return (
    <div>
      <Dialog title={"Välj mall"} onClose={toggleDialog} width={350}>
        <div>Mallar:</div>
        <MultiColumnComboBox
          data={appObject.templates}
          columns={templateMultiColumnComboBoxColumns}
          textField={"name"}
          style={{ width: "300px" }}
          placeholder="Välj..."
          onChange={handleChange}
        />
        <DialogActionsBar layout={"center"}>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={() => onClick(value)}
          >
            Ok
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"
            onClick={toggleDialog}
          >
            Cancel
          </button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};
export default TemplateSelector;
