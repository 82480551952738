import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";

import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import { Client } from "./api/Client";
import { appConfig } from "./appConfig";
import { QuestionClient } from "./api/learnable_question/QuestionClient";

export const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

msalInstance.initialize().then(async () => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    // console.log("msalInstance.initialize: ", accounts[0]);

    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    const questionClient = new QuestionClient(appConfig.REACT_APP_QUESTION_API_URL);

    await Promise.all([client.userCreate(), questionClient.userCreate()]);

    // console.log("account", accounts[0]);
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  root.render(
    <BrowserRouter basename="/">
      <App msalInstance={msalInstance} />
    </BrowserRouter>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
