import { AuthenticationResult } from "@azure/msal-browser";
import { getMesToken, setOptionHeaders } from "../auth/getAccessToken";

export class ClientBaseMes {
  protected async transformOptions(options: RequestInit): Promise<RequestInit> {
    let token: AuthenticationResult = await getMesToken();
    setOptionHeaders(options, token.accessToken);

    return Promise.resolve(options);
  }
}
