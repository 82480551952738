import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import AddRemoveButton from "./AddRemoveButton";
import { Button } from "@progress/kendo-react-buttons";
import { useAppContext } from "../../common/AppContext";
import { TextAndMedia } from "../../../api/learnable_question/QuestionClient";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE } from "../../common/Constants";

const QuestionOptionItem = (props: any) => {
  const question: iQuestionDtoExtended = props.question;
  const optionId: string = props.optionId;
  const uniqueOptionIdsSorted: string[] = props.uniqueOptionIdsSorted;
  const templateDataItem: TextAndMedia = props.templateDataItem!;
  const showCorrectResponseCheckBoxes: boolean = props.showCorrectResponseCheckBoxes;
  const appObject = useAppContext();

  const addVisible = (optionId: string) => {
    let max: number = Math.max(templateDataItem.text!.max!, templateDataItem.media!.max!);

    if (isLastElement(optionId) && uniqueOptionIdsSorted.length < max) {
      return true;
    }

    return false;
  };

  const removeVisible = (optionId: string) => {
    let min: number = Math.max(templateDataItem.text!.min!, templateDataItem.media!.min!);
    let index: number = uniqueOptionIdsSorted.findIndex((x) => x === optionId);

    if (index + 1 > min) {
      return true;
    }

    return false;
  };

  const isFirstElement = (optionId: string) => {
    return uniqueOptionIdsSorted[0] === optionId;
  };

  const isLastElement = (optionId: string) => {
    return uniqueOptionIdsSorted[uniqueOptionIdsSorted.length - 1] === optionId;
  };

  const showSortOrderButtons = (): boolean => {
    return question.subQuestionTypeId !== SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE;
  };

  return (
    <div className="row mb-2">
      <div className="col-2" style={{ visibility: props.showOtherElements ? "visible" : "hidden" }}>
        {props.showOtherElements && <label>{props.labelText} </label>}
      </div>

      <div className="col-1">
        {props.showOtherElements && showCorrectResponseCheckBoxes && (
          <Checkbox
            checked={props.isCorrectResponse()}
            onChange={(event: CheckboxChangeEvent) => props.handleCorrectResponseChange(event, optionId)}
          />
        )}
      </div>

      <div className="col-7">{props.component}</div>

      <div className="col-1">
        {props.showOtherElements && (
          <AddRemoveButton
            onClickAdd={props.addElement}
            onClickRemove={props.removeElement}
            addVisible={addVisible(props.optionId)}
            removeVisible={removeVisible(props.optionId)}
          />
        )}
      </div>

      <div className="col-1">
        {showSortOrderButtons() && (
          <>
            {props.showOtherElements && !isFirstElement(optionId) && (
              <Button onClick={() => props.handleSortOrderUpChange(optionId)} icon="sort-asc-sm"></Button>
            )}
            {props.showOtherElements && !isLastElement(optionId) && (
              <Button onClick={() => props.handleSortOrderDownChange(optionId)} icon="sort-desc-sm"></Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionOptionItem;
