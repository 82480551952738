import { Outlet, useLocation } from "react-router-dom";
import NavMenu from "./NavMenu";

const Layout = (props: any) => {
  const location = useLocation();

  const noMenuPages = ["/NewQuestion", "/MediumPreview", "/LearningObject", "/Interaction"];

  const showNavBar = (): boolean => {
    if (noMenuPages.includes(location.pathname)) {
      return false;
    }
    return true;
  };
  return (
    <div>
      {showNavBar() && <NavMenu displayName={props.displayName} />}
      <div className="container smt-4 container-fluid" style={{ paddingTop: "0px" }}>
        {/*{props.children}*/}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
