import { useEffect, useState } from "react";
import { useAppContext } from "../common/AppContext";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import { EducationsUtilities } from "../common/SearchEducationsUtilities";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";
import { CustomFilterDto } from "../../api/Client";
import { Button } from "@progress/kendo-react-buttons";
import { iColumn } from "../../interfaces/iColumn";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { ClassificationDto } from "../../api/learnable_question/QuestionClient";
import { ClassificationContainer } from "../common/classifications/types";
import { iClassificationItem, iConfiguration } from "../common/classifications/interfaces";
import {
  CONFIG_AMNEN_CLASSIFICATION_KEY,
  CONFIG_BEHORIGHET_CLASSIFICATION_KEY,
  CONFIG_FRAGETYP_CLASSIFICATION_KEY,
} from "../common/Constants";
import FilterExpansionPanel from "../common/FilterExpansionPanel";
import MainGrid from "../common/MainGrid";
import { GridCellProps, GridFilterCellProps, GridRowClickEvent } from "@progress/kendo-react-grid";

import CustomDialog from "../common/CustomDialog";
import SearchContainer from "../common/SearchContainer";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { MenuIndex } from "../common/Enums";
import { openEducation } from "../question/edit/functions/helperFunctions";
import { ClassificationKey, Service } from "../common/classifications/enums";
import { getLearningObjectives } from "../common/classifications/helperFunctions";
import { ConfigProvider, filterLearningObjective } from "../common/classifications/types";
import { appConfig } from "../../appConfig";
import { iConfigurationLearningObjective } from "../common/classifications/interfaces";
import MetadataPanel from "./MetadataPanel";
import { StatusCell } from "../common/Status";
import { DropdownFilterCell } from "../common/DropdownFilterCell";
import { SearchClient } from "../../api/learnable_search/SearchClient";
import { VersionCell } from "../common/Version";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const Educations = (props: any) => {
  const appObject = useAppContext();
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);

  console.log("appObject", appObject);

  const searchUtilities: iSearchUtilities = new EducationsUtilities();

  // Custom filter
  const getInitialCustomFilter = () => {
    const customFilter: iCustomFilterDtoExtended = new CustomFilterDto() as iCustomFilterDtoExtended;
    customFilter.amneGuids = [];
    customFilter.behorighetGuids = [];
    customFilter.fragetypGuids = [];
    customFilter.larandemalGuids = [];
    customFilter.name = "";
    customFilter.fritext = "";
    customFilter.organistaionsId = "";
    customFilter.interaktionstypId = 0;
    customFilter.harBild = false;
    customFilter.harVideo = false;
    customFilter.ingenMedia = false;
    customFilter.isNew = true;
    customFilter.endastFragetext = false;
    customFilter.includeDeprecated = false;

    return customFilter;
  };

  const [customFilters, setCustomFilters] = useState<iCustomFilterDtoExtended[]>([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<iCustomFilterDtoExtended>(getInitialCustomFilter());

  const [refreshGrid, setRefreshGrid] = useState<number>(1);

  // Dropdown control for status filter
  const [statusar, setStatusar] = useState<any[] | undefined>(undefined);
  //Dropdown control for organisation filter
  const [organisationer, setOrganisationer] = useState<any[] | undefined>(undefined);

  const ColumnButtons: any = (props: any) => {
    return (
      <td>
        <div className="centerMiddle ">
          <Button themeColor="primary" size="small" icon="copy" className="me-1" onClick={() => {}}></Button>

          <Button themeColor="primary" size="small" icon="preview" onClick={() => {}}></Button>
        </div>
      </td>
    );
  };
  const StatusFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell {...props} data={statusar ? statusar : []} defaultItem={""} dataItemKey="id" textField="name" />
  );
  const CustomVersionCell = (props: GridCellProps) => {
    // console.log("StatusCell", props);

    const getCss = (statusType: number): string => {
      switch (statusType) {
        case 3:
          return "k-icon k-i-check-circle";
        default:
          return "";
      }
    };

    const getTitle = (statusType: number): string => {
      switch (statusType) {
        case 1:
          return "Dirty";
        case 2:
          return "Pending";
        case 3:
          return "Success";
        case 4:
          return "Failure";
        default:
          return "";
      }
    };

    return (
      <td>
        <div className="leftMiddle ">
          <span title={getTitle(props.dataItem.publishStatus)} className="me-2">
            {props.dataItem.semanticVersion}
          </span>
          <span style={{ color: "#1274AC" }} className={getCss(props.dataItem.publishStatus)}></span>
        </div>
      </td>
    );
  };
  //should use the StatusCell component in the future when the new learning structure database is up and running
  const CustomStatusCell = (props: GridCellProps) => {
    // console.log("StatusCell", props);

    const getCss = (statusId: number): string => {
      switch (statusId) {
        case 1:
          return "dotYellow";
        case 2:
          return "dotGreen";
        case 3:
          return "dotRed";
        default:
          return "dotNotSet";
      }
    };
    const getName = (statusId: number): string => {
      switch (statusId) {
        case 1:
          return "Utkast";
        case 2:
          return "Slutlig";
        case 3:
          return "Granska";
        default:
          return "Ingen";
      }
    };

    return (
      <td>
        <div className="leftMiddle ">
          <span className={getCss(props.dataItem.statusId)}></span>
          <span className="ms-2">{getName(props.dataItem.statusId)}</span>
        </div>
      </td>
    );
  };
  const GridColumns: iColumn[] = [
    { field: "id", filter: "numeric", title: "ID", width: 170, show: true },
    { field: "title", title: "Titel", width: 170, show: true },
    // { field: "containerSubtypeName", filterable: false, title: "Typ", width: 140, show: true },
    // { field: "containerSubtype", filterable: false, title: "containerSubtype", width: 100, show: false },
    { field: "description", title: "Beskrivning", filterable: false, show: true },
    { field: "customerName", title: "Organisation", filterable: false, show: true },
    // { field: "title", title: "Namn", filterable: false, show: true },
    { field: "semanticVersion", title: "Version", filterable: false, width: 70, show: true, cell: CustomVersionCell },
    { field: "status", title: "Status Id", width: 160, show: false },
    {
      field: "statusName",
      title: "Status",
      filterCell: StatusFilterCell,
      width: 140,
      show: true,
      cell: CustomStatusCell,
    },
    {
      field: "senastEditerad",
      title: "Senast ändrad",
      filter: "date",
      format: "{0:d}",
      width: 260,
      filterable: false,
      show: true,
    },
    // { field: "senastEditeradAv", title: "Senast ändrad av", filterable: false, width: 120, show: true },
    // { field: "", title: "", width: 60, show: true, filterable: false, cell: ColumnButtons },
  ];

  const [gridData, setGridData] = useState<any[]>([]);

  const initialSort: Array<SortDescriptor> = [{ field: "senastEditerad", dir: "desc" }];

  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  const [filterModel, setFilterModel] = useState({
    subClassifications: [],
    behClassifications: [],
    typClassifications: [],
    media: [],
    sampleTextbox: "aa",
    senastEditerad: "2023-01-13",
    sampleCombobox: { id: 2, name: "Tennis" },
    sampleCheckbox: false,
  });

  const getFilterAttribute = (attr: string, value: string) => {
    let newAttribute = { [attr]: value };

    return newAttribute;
  };

  const getSelectedValue = (id: any | undefined, list: any[] | undefined) => {
    let value: any | undefined = id && list && list.find((e) => e.id === id);
    if (value === undefined) {
      return null;
    }

    return value;
  };

  const [classificationContainerAmne, setClassificationContainerAmne] = useState<ClassificationContainer | undefined>(
    undefined
  );

  const [classificationContainerBehorighet, setClassificationContainerBehorighet] = useState<
    ClassificationContainer | undefined
  >(undefined);

  const [classificationContainerBehorighetFilter, setClassificationContainerBehorighetFilter] = useState<
    ClassificationContainer | undefined
  >(undefined);

  const [classificationContainerFragetyp, setClassificationContainerFragetyp] = useState<
    ClassificationContainer | undefined
  >(undefined);

  const [containerLearningObjectives, setContainerLearningObjectives] = useState<ClassificationContainer | undefined>(
    undefined
  );

  // const onApply = (classificationType: string, selectedItems: iClassificationItem[]) => {
  //   switch (classificationType) {
  //     case CONFIG_AMNEN_CLASSIFICATION_KEY:
  //       setClassificationContainerAmne({ ...classificationContainerAmne!, selected: selectedItems });
  //       onElementValueChanged(
  //         "amneGuids",
  //         selectedItems.map((i) => i.identifier)
  //       );
  //       break;
  //     case CONFIG_BEHORIGHET_CLASSIFICATION_KEY:
  //       console.log("behorighetClassificationId", selectedItems);
  //       setClassificationContainerBehorighet({ ...classificationContainerBehorighet!, selected: selectedItems });
  //       onElementValueChanged(
  //         "behorighetGuids",
  //         selectedItems.map((i) => i.identifier)
  //       );
  //       break;
  //     case CONFIG_FRAGETYP_CLASSIFICATION_KEY:
  //       setClassificationContainerFragetyp({ ...classificationContainerFragetyp!, selected: selectedItems });
  //       onElementValueChanged(
  //         "fragetypGuids",
  //         selectedItems.map((i) => i.identifier)
  //       );
  //       break;
  //     case "learningObjectives":
  //       setContainerLearningObjectives({ ...containerLearningObjectives!, selected: selectedItems });
  //       onElementValueChanged(
  //         "larandemalGuids",
  //         selectedItems.map((i) => i.identifier)
  //       );
  //       break;
  //   }
  // };

  const defaultMediaCheckboxes = {
    harVideo: false,
    harBild: false,
    ingenMedia: false,
  };

  const [mediaCheckboxes, setMediaCheckboxes] = useState(defaultMediaCheckboxes);
  // let filterC = getFilterControlsLearningObjective(undefined);

  // const filterRefLevel = React.useRef(null);
  // const filterRefType = React.useRef(null);

  // const filterLevelData = [
  //   { id: 1, name: "Allmän" },
  //   { id: 2, name: "Mellan" },
  //   { id: 3, name: "Detalj" },
  // ];

  // const filterTypeData = [
  //   { id: 1, name: "Teoretisk" },
  //   { id: 2, name: "Praktisk" },
  //   { id: 3, name: "Självvärdering" },
  // ];

  // const filterControlsLearningObjectiveDialog = [
  //   <ComboBox
  //     name="Level"
  //     textField="name"
  //     dataItemKey="id"
  //     data={filterLevelData}
  //     {...getFilterAttribute("searchfilterlabel", "Nivå")}
  //     ref={filterRefLevel}
  //   />,
  //   <ComboBox
  //     name="Type"
  //     textField="name"
  //     dataItemKey="id"
  //     data={filterTypeData}
  //     {...getFilterAttribute("searchfilterlabel", "Typ")}
  //     ref={filterRefType}
  //   />,
  // ];

  // const filterControlsLearningObjectiveDialog = getFilterControlsLearningObjective();

  const onApply = (classificationType: string, selectedItems: iClassificationItem[]) => {
    const selectedGuids = selectedItems.map((i) => i.identifier);
    // setSelectedClassifications(selectedItems);
    switch (classificationType) {
      case CONFIG_AMNEN_CLASSIFICATION_KEY:
        // setClassificationContainerAmne({ ...classificationContainerAmne!, selected: selectedItems });
        onElementValueChanged("amneGuids", selectedGuids);
        break;
      case CONFIG_BEHORIGHET_CLASSIFICATION_KEY:
        console.log("behorighetClassificationId", selectedGuids);
        // setClassificationContainerBehorighet({ ...classificationContainerBehorighet!, selected: selectedItems });
        onElementValueChanged("behorighetGuids", selectedGuids);
        break;
      case CONFIG_FRAGETYP_CLASSIFICATION_KEY:
        // setClassificationContainerFragetyp({ ...classificationContainerFragetyp!, selected: selectedItems });
        onElementValueChanged("fragetypGuids", selectedGuids);
        break;
      case "learningObjectives":
        // setContainerLearningObjectives({ ...containerLearningObjectives!, selected: selectedItems });
        onElementValueChanged("larandemalGuids", selectedGuids);
        break;
    }
  };
  const configProviderClassification: ConfigProvider = {
    service: Service.lcms,
    url: appConfig.REACT_APP_LCMS_API_URL as string,
    key: "classificationList",
  };
  const learningObjectiveConfiguration: iConfigurationLearningObjective = {
    title: "Lärandemål",
    rootId: "learningObjectivesRootId",
    showSelected: false,
    clientGet: getLearningObjectives,
  };
  let filterProvider = filterLearningObjective;
  filterProvider.configClassificationProvider = configProviderClassification;
  filterProvider.classificationKey = ClassificationKey.behorigheter;
  const filterControls = [
    // <ClassificationButton
    //   configProvider={configProviderClassification}
    //   filterProvider={undefined}
    //   classificationKey={ClassificationKey.amnen}
    //   configurationClassificationOptional={undefined}
    //   onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
    //   selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_AMNEN_CLASSIFICATION_KEY) ?? []}
    //   readOnly={false}
    // />,
    // <ClassificationButton
    //   configProvider={configProviderClassification}
    //   filterProvider={undefined}
    //   classificationKey={ClassificationKey.andamal}
    //   configurationClassificationOptional={undefined}
    //   onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
    //   selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_FRAGETYP_CLASSIFICATION_KEY) ?? []}
    //   readOnly={false}
    // />,
    // <ClassificationButton
    //   configProvider={configProviderClassification}
    //   filterProvider={undefined}
    //   classificationKey={ClassificationKey.behorigheter}
    //   configurationClassificationOptional={undefined}
    //   onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
    //   selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_BEHORIGHET_CLASSIFICATION_KEY) ?? []}
    //   readOnly={false}
    // />,
    // <ClassificationButton
    //   configProvider={undefined}
    //   filterProvider={filterProvider}
    //   classificationKey={ClassificationKey.larandemal}
    //   configurationClassificationOptional={learningObjectiveConfiguration}
    //   readOnly={false}
    //   onOkClick={(selectedItems: iClassificationItem[]) => onApply("learningObjectives", selectedItems)}
    //   selectedIds={getSelectedItems(selectedCustomFilter, "learningObjectives") ?? []}
    // />,
    <div {...getFilterAttribute("searchfilterlabel", "Fritext")}>
      <Input
        name="fritext"
        value={selectedCustomFilter.fritext}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        onKeyDown={(e: any) => onSearchKeyDown(e)}
        {...getFilterAttribute("searchfilterlabel", "Fritext")}
      />
    </div>,
    <ComboBox
      name="organistaionsId"
      textField="name"
      dataItemKey="id"
      value={getSelectedValue(selectedCustomFilter.organistaionsId, organisationer)}
      data={organisationer}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Organisation")}
    />,
    // <ComboBox
    //   name="containerSubType"
    //   textField="name"
    //   dataItemKey="id"
    //   data={containerSubTypesLearningObject}
    //   value={getSelectedValue(selectedCustomFilter.containerSubType, containerSubTypesLearningObject)}
    //   onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
    //   {...getFilterAttribute("searchfilterlabel", "Typ")}
    // />,
    // <></>,
    // <></>,
    // <></>,
    // <></>,
    <div>
      <div style={{ height: "20px" }}> </div>
      <Checkbox
        name="includeDeprecated"
        value={selectedCustomFilter.includeDeprecated}
        label={"Inkludera borttagna"}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
      />
    </div>,
    //<DatePicker name="lastEdited"
    //    value={new Date(filterModel.lastEdited)} {...getFilterAttribute("searchfilterlabel", "Senast ändrad")}
    //    onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
    //    format="yyyy-MM-dd" weekNumber={true}
    ///>,

    //<TextBox name="sampleTextbox" {...getFilterAttribute("searchfilterlabel", "En textbox")}
    //    value={filterModel.sampleTextbox}
    //    onChange={(e) => onElementValueChanged(e.target.element!.name!, e.target.value)}
    ///>,
    //<ComboBox
    //    name="sampleCombobox"
    //    textField="name"
    //    dataItemKey="id"
    //    value={filterModel.sampleCombobox}
    //    data={[{ id: 1, name: "Fotboll" }, { id: 2, name: "Tennis" }, { id: 3, name: "Basket" }]}
    //    onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
    //    searchfilterlabel="En combobox"
    ///>,

    //<Checkbox
    //    name="sampleCheckbox"
    //    checked={filterModel.sampleCheckbox}
    //    onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
    //    searchfilterlabel="Checkbox appearance" />,

    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />,
    //<DatePicker searchfilterlabel="En datepicker" defaultValue={new Date()} format="yyyy-MM-dd" weekNumber={true} />
  ];

  const [amnen, setAmnen] = useState<ClassificationDto[] | null>(null);
  const [behorigheter, setBehorigheter] = useState<ClassificationDto[] | null>(null);
  const [fragetyper, setFragetyper] = useState<ClassificationDto[] | null>(null);

  // const getClassificationsRequest = (configData: iConfiguration): GetClassificationsRequest => {
  //   let getClassificationsRequest = new GetClassificationsRequest();
  //   getClassificationsRequest.classificationIds = getClassificationRootIds(configData);
  //   getClassificationsRequest.includeDescendants = true;
  //   getClassificationsRequest.cultureName = "sv-SE";

  //   return getClassificationsRequest;
  // };

  // const getClassifications = async (configData: iConfiguration): Promise<ClassificationDto[][]> => {
  //   var result = await fetchClassifications(getClassificationsRequest(configData));

  //   //Hantera ämnen
  //   let classificationContainer: ClassificationContainer = getEmptyClassificationContainer();
  //   let rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_AMNEN_CLASSIFICATION_KEY);
  //   let classificationAmnen = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationAmnen as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);
  //   setClassificationContainerAmne(classificationContainer);

  //   //Hantera behörigheter
  //   classificationContainer = getEmptyClassificationContainer();
  //   rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_BEHORIGHET_CLASSIFICATION_KEY);
  //   let classificationBehorigheter = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationBehorigheter as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);
  //   setClassificationContainerBehorighet(classificationContainer);

  //   // Behörighetsfilter
  //   classificationContainer = getEmptyClassificationContainer();
  //   rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_BEHORIGHET_CLASSIFICATION_KEY);
  //   let classificationBehorigheterFilter = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationBehorigheterFilter as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);
  //   setClassificationContainerBehorighetFilter(classificationContainer);

  //   //Hantera frågetyper
  //   classificationContainer = getEmptyClassificationContainer();
  //   //TODO: Remove this filter hack and implement it in the API
  //   rootIds = getConfigRootIdsByClassificationKey(configData, CONFIG_FRAGETYP_CLASSIFICATION_KEY);
  //   let classificationsFrageTyp = getClassificationByRootIds(result, rootIds);
  //   classificationContainer.list = classificationsFrageTyp as iClassificationItem[];
  //   syncClassificationContainer(classificationContainer);

  //   setClassificationContainerFragetyp(classificationContainer);

  //   return result;
  // };

  // const getLearningObjectives = async () => {
  //   const data = await fetchLearningObjectives();
  //   const dataIndicators = await fetchLearningObjectiveIndicatorClassifications();
  //   let classificationContainer: ClassificationContainer = getEmptyClassificationContainer();
  //   classificationContainer.list = data as iClassificationItem[];
  //   // data.forEach((x) => (x.metadata = { Level: x.levelId, Type: x.typeId }));
  //   data.forEach((x) => {
  //     const itemIndicatorIds: string[] = x.indicators.map((i: any) => i.id);
  //     const itemIndicatorClassifications = dataIndicators.filter((x: any) => itemIndicatorIds.includes(x.indicatorId));
  //     x.metadata = {
  //       Level: x.levelId,
  //       Type: x.typeId,
  //       LearningObjectiveClassifications: itemIndicatorClassifications,
  //     };
  //   });
  //   syncClassificationContainer(classificationContainer, undefined, false);
  //   setContainerLearningObjectives(classificationContainer);

  //   return data;
  // };

  // const fetchFilters = async () => {
  //   const client = new Client(appConfig.REACT_APP_API_URL as string);

  //   let filters: iCustomFilterDtoExtended[] =
  //     ((await client.settingGetLearningObjectFilters(userId)) as iCustomFilterDtoExtended[]) ?? [];
  //   filters.forEach((x) => (x.isNew = false));
  //   setCustomFilters(filters);

  //   return filters;
  // };

  const init = async () => {
    // await fetchFilters();
    // let subTypes = await fetchContainerSubTypes(CONTAINER_TYPE_LEARNING_OBJECT);
    // setContainerSubTypesLearningObject(subTypes);
    // let configurationData: iConfiguration = await fetchConfiguration("ClassificationList");
    // configurationData.learningObjectives = null;
    // setConfigData(configurationData);
    // const classifications = await getClassifications(configurationData);
    // let classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "amnen")
    // );
    // setAmnen(classification);
    // classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "behorigheter")
    // );
    // setBehorigheter(classification);
    // classification = getClassificationByRootIds(
    //   classifications,
    //   getConfigRootIdsByClassificationKey(configurationData, "andamal")
    // );
    // setFragetyper(classification);
    // await getLearningObjectives();
    const searchClient = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);
    const statusarReply = await searchUtilities.getStatusar(searchClient);
    if (statusarReply.rows !== undefined) {
      statusarReply.rows.forEach((x: any) => {
        //update name to swedish
        if (x.id === 1) {
          x.name = "Utkast";
        } else if (x.id === 2) {
          x.name = "Slutlig";
        } else if (x.id === 3) {
          x.name = "Granska";
        } else {
          x.name = "Ingen";
        }
      });
    }

    setStatusar(statusarReply.rows);
    const organisationerreply = await searchUtilities.getOrganisationer(searchClient);
    setOrganisationer(organisationerreply.rows);
  };

  useEffect(() => {
    init();
  }, []);

  // const getClassificationText = (classifications: ClassificationDto[], id: string) => {
  //   if (classifications && id) {
  //     let text = classifications.find((x) => x.identifier == id)?.name ?? "";
  //     return text;
  //   }

  //   return "";
  // };

  // const fetchGetLatestLearningObjects = async () => {
  //   const client = new Client(appConfig.REACT_APP_API_URL);
  //   let getLearningObjectRequest: GetLearningObjectRequest = new GetLearningObjectRequest();
  //   let getMyLatestItemsResponse: GetMyLatestItemsResponse = await client.settingGetLatestLearningObjects(
  //     getLearningObjectRequest
  //   );
  //   return getMyLatestItemsResponse.myLatestItemDtos!.map((x) => x.id);
  // };

  const gridDataLoaded = async (data: any[]) => {
    const updatedData = data.map((item: any) => ({
      ...item, // Spread the existing properties of the object
    }));

    let newUpdatedData: any[] = [];
    // let latestData: any[] = [];
    // let latest = await fetchGetLatestLearningObjects();

    // updatedData.forEach((x) => {
    //   if (latest.includes(x.id)) {
    //     latestData.push(x);
    //   } else {
    //     newUpdatedData.push(x);
    //   }
    // });

    // newUpdatedData = latestData.concat(newUpdatedData);

    // // hämta namnen för containerSubtype
    // newUpdatedData.forEach((d) => {
    //   let name: string =
    //     containerSubTypesLearningObject!.find((x) => x.id == d.containerSubtype!)?.name ??
    //     d.containerSubtype.toString();
    //   d.containerSubtypeName = name;
    // });

    //setGridData(newUpdatedData);
    setGridData(data);
  };

  const onSearchKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterClick(e);
    }
  };

  const filterClick = (event: any) => {
    setRefreshGrid(refreshGrid + 1);
  };

  const clearFilterClick = (event: any) => {
    let newCustomFilter = getInitialCustomFilter();

    setSelectedCustomFilter(newCustomFilter);

    setRefreshGrid(refreshGrid + 1);
  };

  const saveFilterClick = async (event: any) => {
    // let client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    // let request: GetFilterRequest = new GetFilterRequest();
    // request.customQuestionFilterDto = selectedCustomFilter;
    // request.userId = userId;
    // await client.settingAddLearningObjectFilter(request);
    // let filters: iCustomFilterDtoExtended[] = await fetchFilters();
    // let newSelected: iCustomFilterDtoExtended = filters.find((x) => x.name == selectedCustomFilter.name)!;
    // setSelectedCustomFilter(newSelected);
  };

  const deleteFilterClick = async (event: any) => {
    // let client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    // await client.settingDeleteLearningObjectFilter(userId, selectedCustomFilter.name);
    // await fetchFilters();
    // setSelectedCustomFilter(getInitialCustomFilter());
  };

  const onElementValueChanged = (name: string, value: any) => {
    console.log("onElementValue", value);
    setFilterModel({
      ...filterModel,
      [name]: value,
    });
    console.log("name", name);
    console.log("value", value);
    if (name == "media") {
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          ...value,
        }) as iCustomFilterDtoExtended
      );
    } else {
      //TODO: Varför funkar inte "const newValue = value?.id ?? value"?
      const newValue = value?.id ?? value;
      //const newValue = (value ? (value.id ? value.id : value) : value);
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          [name]: newValue,
        }) as iCustomFilterDtoExtended
      );
    }
  };

  const getUpdatedClassificationCopy = (classificationContainer: ClassificationContainer, selected: string[]) => {
    let copy: ClassificationContainer = { ...classificationContainer };
    copy.selected = [];

    selected.forEach((x) => {
      copy.selected.push(copy.list.find((y) => y.identifier == x)!);
    });

    return copy;
  };

  const onSelectedFilterChanged = (newFilter: iCustomFilterDtoExtended) => {
    if (newFilter == null) {
      newFilter = getInitialCustomFilter();
    }
    setSelectedCustomFilter(newFilter);
    // // uppdatera träddialogerna
    // setClassificationContainerAmne(getUpdatedClassificationCopy(classificationContainerAmne!, newFilter.amneGuids!));
    // setClassificationContainerBehorighet(
    //   getUpdatedClassificationCopy(classificationContainerBehorighet!, newFilter.behorighetGuids!)
    // );
    // setClassificationContainerFragetyp(
    //   getUpdatedClassificationCopy(classificationContainerFragetyp!, newFilter.fragetypGuids!)
    // );
    // setContainerLearningObjectives(
    //   getUpdatedClassificationCopy(containerLearningObjectives!, newFilter.larandemalGuids!)
    // );
    // // uppdatera checkboxarna
    // setMediaCheckboxes({
    //   harBild: newFilter.harBild!,
    //   harVideo: newFilter.harVideo!,
    //   ingenMedia: newFilter.ingenMedia!,
    // });
  };

  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);

  const onRowClick = (row: any) => {
    let treeId: number = row.id;
    openEducation(treeId, undefined);
  };

  const searchExpansionPanel = (
    <FilterExpansionPanel
      controlsPerRow={4}
      elements={filterControls}
      onFilterClick={filterClick}
      onClearFilterClick={clearFilterClick}
      onSaveFilterClick={saveFilterClick}
      onDeleteFilterClick={deleteFilterClick}
      customFilters={customFilters}
      selectedCustomFilter={selectedCustomFilter}
      onSelectedFilterChanged={onSelectedFilterChanged}
    />
  );

  const mainGrid = () => (
    <MainGrid
      onRowClick={(event: GridRowClickEvent) => onRowClick(event.dataItem)}
      searchUtilities={searchUtilities}
      configuration={configData}
      initialSort={initialSort}
      initialFilter={initialFilter}
      gridDataLoaded={gridDataLoaded}
      gridData={gridData}
      columns={GridColumns}
      metadataPanel={MetadataPanel}
      setGridData={setGridData}
      customFilter={selectedCustomFilter}
      autoSubmitGridFilter={true}
      refresh={refreshGrid}
    />
  );

  return (
    <div>
      {showCustomDialog && <CustomDialog {...showCustomDialog} />}

      {/* tillsvidare då Search API går mot devmiljön */}
      <SearchContainer
        searchType={MenuIndex.Educations}
        mainGrid={mainGrid()}
        searchExpansionPanel={searchExpansionPanel}
      />

      {/* tillsvidare då Search API går mot devmiljön */}
      {/* <SearchContainer searchType={MenuIndex.Educations} mainGrid={<></>} searchExpansionPanel={searchExpansionPanel} /> */}
    </div>
  );
};

export default Educations;
function fetchFilters(): iCustomFilterDtoExtended[] | PromiseLike<iCustomFilterDtoExtended[]> {
  throw new Error("Function not implemented.");
}
