import { useEffect, useState } from "react";
// import { iImageLink } from "../../interfaces/iMediaLink";
import iMediumPreview from "../../interfaces/iMediumPreview";
import { MEDIA_LINK_TYPE_MEDIA_SERVICE, MEDIA_LINK_TYPE_RECTANGLE } from "./Constants";
import { resizeImage } from "../question/edit/functions/helperFunctions";

const MediumPreview = (props: any) => {
  const mediumJson: string = localStorage.getItem("mediumPreview") as string;
  const mediumPreview: iMediumPreview = JSON.parse(mediumJson);

  const MediumOptimaImageComponent = () => {
    const src = mediumPreview!.srcUrl!.includes("&")
      ? resizeImage(mediumPreview!.srcUrl!, 800)
      : mediumPreview!.srcUrl!;
    return <img style={{ paddingTop: 10 }} src={src} />;
  };
  const MediumRegionComponent = () => {
    const link: any = JSON.parse(mediumPreview!.link!);
    return (
      <div style={{ width: "25%", maxWidth: 200, margin: 10, border: "solid" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: `url(${mediumPreview!.srcUrl})`,
            backgroundPositionX: `${link.x * (100 / (100 - link.width))}%`,
            backgroundPositionY: `${link.y * (100 / (100 - link.height))}%`,
            backgroundSize: `${100 / (link.width / 100)}% ${100 / (link.height / 100)}%`,
            width: "100%",
            paddingTop: `${((1 * link.height) / (1 * link.width)) * 100}%`,
          }}
        >
          {/*<button onClick={() => onRemove(index)}>Radera {index + 1}</button>*/}
        </div>
      </div>
    );
  };
  return (
    <>
      {mediumPreview && mediumPreview.linkTypeId === MEDIA_LINK_TYPE_MEDIA_SERVICE && <MediumOptimaImageComponent />}
      {mediumPreview && mediumPreview.linkTypeId === MEDIA_LINK_TYPE_RECTANGLE && <MediumRegionComponent />}
    </>
  );
};
export default MediumPreview;
