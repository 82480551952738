import { Dispatch, SetStateAction } from "react";
import {
  SearchClient,
  DynamicLinqRequest,
  Pageing,
  Parameter,
  SearchResponse,
} from "../../api/learnable_search/SearchClient";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import getLinqOperatorMethodWithNamedParam from "./getLinqOperatorMethodWithNamedParam";
import transformFieldAndValue from "./transformFieldAndValue";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import { createClassificationsQuery } from "./helperFunctions";
import { iConfiguration } from "./classifications/interfaces";
import { CONTAINER_SUB_TYPE_INLEDNING, CONTAINER_SUB_TYPE_SAMMANFATTNING } from "./Constants";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";

export class LearningObjectUtilities implements iSearchUtilities {
  search = async (
    client: SearchClient,
    gridFilter: CompositeFilterDescriptor | undefined,
    customFilter: iCustomFilterDtoExtended | undefined,
    sort: any,
    page: any,
    currentCondition: string,
    setCurrentCondition: Dispatch<SetStateAction<string>>,
    configuration: iConfiguration
  ): Promise<SearchResponse> => {
    const searchRequest = new DynamicLinqRequest({
      pageing: new Pageing({ skip: page.skip, take: page.take }),
      parameters: {},
      dynamicLinqString: "_lcmsContext.Containers",
    });

    //we are only intrested in containers with type 2 (LearningObject)
    let whereExpression1 = `c.ContainerType == 2 && c.ContainerSubtype != ${CONTAINER_SUB_TYPE_SAMMANFATTNING} && c.ContainerSubtype != ${CONTAINER_SUB_TYPE_INLEDNING}`;
    if (customFilter) {
      console.log("customFilter", customFilter);
      //let whereExpression1 = "";
      //// Media (Bild, Video, Ingen media)
      //if (customFilter.harBild && customFilter.harVideo && customFilter.ingenMedia) {
      //    // No filter
      //}
      //else if (!customFilter.harBild && !customFilter.harVideo && !customFilter.ingenMedia) {
      //    // No filter
      //    // whereExpression1 = "false";
      //}
      //else {
      //    whereExpression1 += this.getMediaWhere(customFilter.harBild!, whereExpression1.length > 0, 1);
      //    whereExpression1 += this.getMediaWhere(customFilter.harVideo!, whereExpression1.length > 0, 2);
      //    whereExpression1 += this.getMediaWhere(customFilter.ingenMedia!, whereExpression1.length > 0);
      //}
      //if (whereExpression1.length > 0) whereExpression1 = `(${whereExpression1})`;
      //// Interaktionstyp
      //if (customFilter.interaktionstypId) {
      //    if (whereExpression1.length > 0) whereExpression1 += ' && ';
      //    whereExpression1 += "c.InteractionTypeId == @@interactionTypeIdParam@@";
      //    searchRequest.parameters!["interactionTypeIdParam"] = new Parameter({ intValue: customFilter.interaktionstypId });
      //}

      if (customFilter.containerSubType) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.ContainerSubtype == @@containerSubtypeParam@@";
        searchRequest.parameters!["containerSubtypeParam"] = new Parameter({ intValue: customFilter.containerSubType });
      }

      // Organisation
      if (customFilter.organistaionsId) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.CustomerId == @@customerIdParam@@";
        searchRequest.parameters!["customerIdParam"] = new Parameter({ guidValue: customFilter.organistaionsId });
      }

      // Lärandemål
      if (customFilter.larandemalGuids!.length > 0) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 +=
          "c.ContainerLearningObjectives.Any(a => @@larandemalGuidsParam@@.Any(b => a.LearningObjectiveId == b))";
        searchRequest.parameters!["larandemalGuidsParam"] = new Parameter({ guidValues: customFilter.larandemalGuids });
      }

      // Frågetyp
      whereExpression1 = this.addClassification1(
        "Fragetyp",
        customFilter.fragetypGuids!,
        whereExpression1,
        searchRequest
      );

      // Behörighet
      whereExpression1 = this.addClassification1(
        "Behorighet",
        customFilter.behorighetGuids!,
        whereExpression1,
        searchRequest
      );

      //// Ämne filtreras via en extra filtrering på ärandemål som tillfällig lösning eftersom det ännu inte gjorts någon klassificering av frågorna på ämne. /Magnus 2023-08-15
      if (customFilter.amneGuids!.length > 0) {
        var larandemalViaAmneGuids = (await this.getLarandemalGuidsFromAmneGuids(
          client,
          customFilter.amneGuids!
        )) as SearchResponse;
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 +=
          "c.ContainerLearningObjectives.Any(a => @@larandemalViaAmneGuidsParam@@.Any(b => a.LearningObjectiveId == b))";
        searchRequest.parameters!["larandemalViaAmneGuidsParam"] = new Parameter({
          guidValues: larandemalViaAmneGuids.rows,
        });
      }

      // Fritext
      if (customFilter.fritext) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";

        whereExpression1 += "c.Title.Contains(@@fritextParam@@) || c.Description.Contains(@@fritextParam@@)";
        searchRequest.parameters!["fritextParam"] = new Parameter({ stringValue: customFilter.fritext.substring(1) });

        //if (!customFilter.endastFragetext && customFilter.fritext.startsWith("#")) {
        //    // ExternalId
        //    whereExpression1 += "c.QuestionProperties.Any(m => m.Key==@@bestNrKeyParam@@ && m.Value.StartsWith(@@fritextParam@@))";
        //    searchRequest.parameters!["bestNrKeyParam"] = new Parameter({ stringValue: "ExternalId" });
        //    searchRequest.parameters!["fritextParam"] = new Parameter({ stringValue: customFilter.fritext.substring(1) });
        //}
        //else {
        //    // Text
        //    whereExpression1 += "c.QuestionTexts.Any(m =>" + (customFilter.endastFragetext ? " m.TypeId == 1 && " : " ") + "m.Texts.Any(t => t.Text1.Contains(@@fritextParam@@)))";
        //    searchRequest.parameters!["fritextParam"] = new Parameter({ stringValue: customFilter.fritext });
        //}
      }

      // External id (filterering på friendly id)
      // if (customFilter.externalId) {
      //   if (whereExpression1.length > 0) whereExpression1 += " && ";
      //   whereExpression1 += "c.FriendlyId.substring(2) == (@@externalIdParam@@)";
      //   searchRequest.parameters!["externalIdParam"] = new Parameter({ stringValue: customFilter.externalId });
      // }

      if (!customFilter.includeDeprecated) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.Deprecated == false";
      }

      if (customFilter.endastOpublicerade) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.PublishStatus <= 1";
      }
    }

    // Add Where
    if (whereExpression1.length > 0) {
      searchRequest.dynamicLinqString += "\n" + `.Where("c => ` + whereExpression1 + `")`;
    }
    // console.log("configuration", configuration);
    searchRequest.dynamicLinqString +=
      "\n" +
      `.Select("c => new {c.Id, c.Title, c.Description, c.ContainerSubtype, c.Comment, c.PublishStatus,` +
      `c.UpdatedByNavigation.Username as UpdatedBy, c.CreatedByNavigation.Username as CreatedBy,` +
      `c.Publishes.SingleOrDefault(x => x.ContainerId == c.Id && x.LatestVersion==true).SemanticVersion as SemanticVersion,c.FriendlyId,` +
      // `c.ContainerClassifications.Where(w => w.ClassificationRoot == @@amneRootParam@@).Select(s => s.ClassificationId) as AmneClassificationId,` +
      `${createClassificationsQuery(
        configuration,
        "ContainerClassifications",
        "ClassificationRoot",
        "amnen",
        "AmneClassificationId",
        searchRequest
      )}` +
      `${createClassificationsQuery(
        configuration,
        "ContainerClassifications",
        "ClassificationRoot",
        "behorigheter",
        "BehorighetClassificationId",
        searchRequest
      )}` +
      `c.LifeCycleStatus as StatusId,` +
      `c.LifeCycleStatusNavigation.Name as StatusName,` +
      `c.Updated != @@defaultDateParam@@ ? c.Updated : c.Created as SenastEditerad,` +
      `c.UpdatedById > 0 ? c.UpdatedByNavigation.Username : c.CreatedByNavigation.Username as SenastEditeradAv,` +
      `c.Deprecated` +
      `}")`;

    // searchRequest.parameters!["amneRootParam"] = new Parameter({ guidValue: "1884cc8c-8bc3-4d32-8ca5-e58950b8da49" });
    searchRequest.parameters!["defaultDateParam"] = new Parameter({ stringValue: "0001-01-01T00:00:00" });

    //searchRequest.dynamicLinqString += "\n" + whereExpression1 + `")`;
    //Grid filter
    //let whereExpression1 = "c.ContainerType == 2";
    let whereExpression2 = "";
    gridFilter &&
      gridFilter.filters.forEach((f: any) => {
        if (whereExpression2.length > 0) whereExpression2 += " && ";
        const fields = transformFieldAndValue(f, ["customer", "interactionType", "status"]);
        whereExpression2 += getLinqOperatorMethodWithNamedParam(fields, searchRequest.parameters);
      });

    if (whereExpression2.length > 0) {
      searchRequest.dynamicLinqString += "\n" + `.Where("${whereExpression2}")`;
    }

    //Order By
    let newOrderBy = "";
    sort &&
      sort.forEach((s: any) => {
        if (newOrderBy.length > 0) newOrderBy += ", ";
        let fieldName = s.field;
        newOrderBy += fieldName + " " + s.dir;
      });
    if (newOrderBy.length === 0) newOrderBy = "Id";
    searchRequest.dynamicLinqString += "\n" + `.OrderBy("` + newOrderBy + `")`;

    // Call Web API
    // console.log("gridFilter", gridFilter);
    // console.log("customFilter", customFilter);
    let newCondition = searchRequest.dynamicLinqString + JSON.stringify(searchRequest.parameters);
    searchRequest.pageing!.calcPages = currentCondition === undefined || currentCondition !== newCondition;
    setCurrentCondition(newCondition);
    console.log("Call API dynamicLinq with searchRequest", searchRequest);

    try {
      const reply = await client.dynamicLinqSearch(searchRequest);
      console.log("API dynamicLinq reply", reply);
      return reply;
    } catch (error) {
      console.error(error);
      //TODO: Bättre felhantering!
      return new SearchResponse();
    }
  };

  getLarandemalGuidsFromAmneGuids = async (client: SearchClient, amneGuids: string[]) => {
    const searchRequest = new DynamicLinqRequest({
      parameters: {},
      dynamicLinqString: `
            _learningObjectiveContext.LearningObjectiveClassifications
            .Where("o => @@amneGuidsParam@@.Contains(o.ClassificationId)")
            .Select("s => s.LearningObjective.Identifier")`,
    });
    searchRequest.parameters!["amneGuidsParam"] = new Parameter({ guidValues: amneGuids });
    // console.log("Call API dynamicLinq with searchRequest", searchRequest);
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    // console.log("API dynamicLinq reply", reply);
    return reply;
  };

  getMediaWhere = (addFilter: boolean, addOr: boolean, mediaTypeId: number = 0) => {
    throw Error("Not implemented!");
  };

  addClassification1 = (
    paramName: string,
    guids: string[],
    whereExpression: string,
    searchRequest: DynamicLinqRequest
  ) => {
    let newWhere = whereExpression;
    if (guids.length > 0) {
      if (newWhere.length > 0) newWhere += " && ";
      newWhere += "c.ContainerClassifications.Any(a => @@" + paramName + "Param@@.Any(b => b == a.ClassificationId))";
      searchRequest.parameters![paramName + "Param"] = new Parameter({ guidValues: guids });
    }
    return newWhere;
  };

  addClassification2 = (
    fieldName: string,
    guids: string[],
    whereExpression: string,
    searchRequest: DynamicLinqRequest
  ) => {
    let newWhere = whereExpression;
    if (guids.length > 0) {
      if (newWhere.length > 0) newWhere += " && ";
      newWhere += fieldName + ".Any(a => (@@" + fieldName + "Param@@).Any(b => b == a))";
      searchRequest.parameters![fieldName + "Param"] = new Parameter({ guidValues: guids });
    }
    return newWhere;
  };

  getOrganisationer = async (client: SearchClient) => {
    const searchRequest = new DynamicLinqRequest({
      dynamicLinqString: `
        _questionContext.Customers
        .Select("c => new { c.Id, c.Name}")
        .OrderBy("Name")
    `,
    });
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };

  getInteraktionstyper = async (client: SearchClient) => {
    throw Error("Not implemented!");
  };

  getStatusar = async (client: SearchClient) => {
    const searchRequest = new DynamicLinqRequest({
      dynamicLinqString: `
      _lcmsContext.Statuses
        .Select("c => new { c.Id, c.Name}")
        .OrderBy("Name")
    `,
    });
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };
}
