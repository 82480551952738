import React from 'react';
import AddRemoveButton from './AddRemoveButton';
import { QuestionTextDto } from '../../../api/learnable_question/QuestionClient';
import QuestionTextItem from './QuestionTextItem';

const QuestionText = (props : any) => {
    const { labelText, addElement, removeElement, templateDataItem, handleTextChange } = props;  
    const questionTexts: QuestionTextDto[] = props.questionTexts;

    //console.log("questiontext", elementIndex, templateDataItem)
    const isRemoveVisible = (index: number) =>
    {
        if (index + 1 > templateDataItem.text.min)
            return true;

        return false;
    }

    //{ "textMediaTypeId": 3, "textMediaName": "", "text": true, "media": true, "min": 2, "max": undefined, "sortOrder": 6 }
    const isAddVisible = (index: number) => {

        let lastElementIndex = questionTexts.length - 1;
        let max = templateDataItem.text.max;

        if (index < lastElementIndex) {
            return false;
        }

        //max = 1, index = 0
        if (max == undefined || max > lastElementIndex + 1) {
            return true;
        }

        return false;
    }


    return (
        <div>
            {questionTexts && questionTexts.map((questionText: QuestionTextDto, index: number) => (
                <div className="row mb-2" key={"QuestionText_" + index}>
                    <div key={"label_" + index} className="col-2">
                        <label>{labelText}</label>
                    </div>

                    <QuestionTextItem key={"QuestionTextItem_" + index}
                        col={"col-8"}
                        index={index}
                        questionText={questionText}
                        lastElementIndex={questionTexts.filter(x => x.textTypeId === templateDataItem.id).length - 1}
                        labelText={labelText}
                        templateDataItem={templateDataItem}
                        handleTextChange={handleTextChange}
                    />

                    <div key={"AddRemoveButton_" + index} className="col-2">
                        <AddRemoveButton
                            onClickAdd={addElement}
                            removeVisible={isRemoveVisible(index)}
                            addVisible={isAddVisible(index)}
                            onClickRemove={() => removeElement(templateDataItem, questionTexts[index])}
                        />
                    </div>
                </div>
          ))}
        </div>
    );
}

export default QuestionText;