import React, { useEffect } from "react";
import { ExpansionPanel, ExpansionPanelContent, ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { CustomFilterDto } from "../../api/Client";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";

const FilterExpansionPanel = (props: any) => {
  /*https://www.telerik.com/kendo-react-ui/components/layout/expansionpanel/*/

  const elements: JSX.Element[] = props.elements;
  const controlsPerRow = props.controlsPerRow;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const onSelectedFilterChanged = props.onSelectedFilterChanged;
  const customFilters: iCustomFilterDtoExtended[] = props.customFilters;

  const selectedCustomFilter: iCustomFilterDtoExtended = props.selectedCustomFilter;

  //console.log("elements", props);

  useEffect(() => {}, []);

  const chunkedData = [];

  for (let i = 0; i < elements.length; i += controlsPerRow) {
    var sliced = elements.slice(i, i + controlsPerRow);

    while (sliced.length < controlsPerRow) {
      sliced.push(<div className="col-sm-12"></div>);
    }

    chunkedData.push(sliced);
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onFilterClick();
  };

  const handleClearFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClearFilterClick();
  };

  const handleSaveFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onSaveFilterClick();
  };

  //https://stackblitz.com/edit/react-t9bgvc-u8fc8d?file=app%2Fmain.jsx
  //https://www.telerik.com/account/support-center/view-ticket/1622914
  const comboRef = React.useRef<any>(null);
  const clearComboBox = (ev: any) => {
    comboRef!.current!.clearButtonClick(ev);
  };

  const handleDeleteFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onDeleteFilterClick();
    clearComboBox(event);
  };

  const handleFilterFilterChanged = (event: ComboBoxFilterChangeEvent) => {
    //event.nativeEvent.stopPropagation();
    selectedCustomFilter.name = event.filter.value;
    let copy = new CustomFilterDto({ ...selectedCustomFilter }) as iCustomFilterDtoExtended;

    onSelectedFilterChanged(copy);
  };

  const handleFilterChanged = (event: ComboBoxChangeEvent) => {
    onSelectedFilterChanged(event.target.value);
  };

  const getFilterItems = () => {
    let chosenFilter = [];
    if (selectedCustomFilter.amneGuids!.length > 0) {
      selectedCustomFilter.amneGuids!.length === 1
        ? chosenFilter.push("1 ämne")
        : chosenFilter.push(`${selectedCustomFilter.amneGuids!.length} ämnen`);
    }
    if (selectedCustomFilter.behorighetGuids!.length > 0) {
      selectedCustomFilter.behorighetGuids!.length === 1
        ? chosenFilter.push("1 utbildning")
        : chosenFilter.push(`${selectedCustomFilter.behorighetGuids!.length} utbildningar`);
    }
    if (selectedCustomFilter.fragetypGuids!.length > 0) {
      selectedCustomFilter.fragetypGuids!.length === 1
        ? chosenFilter.push("1 ändamål")
        : chosenFilter.push(`${selectedCustomFilter.fragetypGuids!.length} ändamål`);
    }
    if (selectedCustomFilter.larandemalGuids!.length > 0) {
      chosenFilter.push(`${selectedCustomFilter.larandemalGuids!.length} lärandemål`);
    }
    if (selectedCustomFilter.harVideo) {
      chosenFilter.push("har video");
    }
    if (selectedCustomFilter.harBild) {
      chosenFilter.push("har bild");
    }
    if (selectedCustomFilter.ingenMedia) {
      chosenFilter.push("ingen media");
    }
    // if (selectedCustomFilter.interaktionstypId) {
    //   chosenFilter.push("interaktionstyp");
    // }
    if (selectedCustomFilter.containerSubType) {
      chosenFilter.push("typ");
    }
    if (selectedCustomFilter.templateId) {
      chosenFilter.push("mall");
    }
    if (selectedCustomFilter.organistaionsId) {
      chosenFilter.push("organisation");
    }
    if (selectedCustomFilter.fritext!.length > 0) {
      chosenFilter.push("fritext");
    }
    // if (selectedCustomFilter.externalId! && selectedCustomFilter.externalId!.length > 0) {
    //   chosenFilter.push("Externt ID");
    // }
    if (selectedCustomFilter.includeDeprecated) {
      chosenFilter.push("inkludera borttagna");
    }
    if (selectedCustomFilter.endastOpublicerade) {
      chosenFilter.push("visa endast opublicerade");
    }
    return chosenFilter;
  };

  const filteredItems: string[] = getFilterItems();

  return (
    <div>
      <ExpansionPanel
        key={"ExpansionPanel"}
        style={{ borderRadius: "5px" }}
        title={"Filter övrigt"}
        expanded={expanded}
        tabIndex={0}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded(!event.expanded);
        }}
      >
        <Reveal key={"Reveal"}>
          {expanded && (
            <ExpansionPanelContent key={"ExpansionPanelContent"} onKeyDown={(e) => e.stopPropagation()}>
              <div>
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <div className="row" style={{ backgroundColor: "" }}>
                      {chunkedData.map((chunk, index) => (
                        <div className="row mb-2" key={"chunkedData_" + index}>
                          {chunk.map((element, subIndex) => (
                            <div className="col" key={"chunkedData_" + index + "_" + subIndex}>
                              <span title={element.props.type} style={{ display: "block" }}>
                                {element.props.searchfilterlabel && element.props.searchfilterlabel}
                              </span>
                              {element}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <Button
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleFilterClick(e)}
                      className="ms-2 mt-2 mb-2"
                      themeColor={"primary"}
                    >
                      Filtrera
                    </Button>
                    <Button
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClearFilterClick(e)}
                      className="ms-2 mt-2 mb-2"
                      themeColor={"primary"}
                    >
                      Rensa filter
                    </Button>
                  </div>

                  <div className="col-sm-10">
                    <ComboBox
                      ref={comboRef}
                      style={{ width: "300px" }}
                      data={customFilters}
                      textField="name"
                      onChange={(e: ComboBoxChangeEvent) => handleFilterChanged(e)}
                      onFilterChange={(e: ComboBoxFilterChangeEvent) => handleFilterFilterChanged(e)}
                      filterable={true}
                      value={selectedCustomFilter}
                      allowCustom={true}
                      clearButton={false}
                      placeholder={"Välj filter eller skapa ett nytt"}
                    />

                    <Button
                      disabled={selectedCustomFilter?.name?.length === 0}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleSaveFilterClick(e)}
                      className="ms-2 mt-2 mb-2"
                      themeColor={"primary"}
                    >
                      Spara filter
                    </Button>
                    <Button
                      disabled={selectedCustomFilter?.isNew}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDeleteFilterClick(e)}
                      className="ms-2 mt-2 mb-2"
                      themeColor={"primary"}
                    >
                      Radera filter
                    </Button>
                  </div>
                </div>
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>

      <div className="filterResultPanel mt-1 mb-3">
        <div className="row mt-2 mb-2 me-3 ms-3">
          {filteredItems.length > 0 ? (
            <>
              <span>Valda filter:</span>
              <ButtonGroup className="meals">
                {filteredItems.map((m, index) => {
                  return (
                    <div key={index} className="m-1">
                      <Button>{m}</Button>
                    </div>
                  );
                })}
              </ButtonGroup>
            </>
          ) : (
            <span>Inga filter valda</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterExpansionPanel;
