import { GridCellProps } from "@progress/kendo-react-grid";
import { StatusType } from "./Enums";

export const StatusCell = (props: GridCellProps) => {
  // console.log("StatusCell", props);

  const getCss = (statusType: StatusType): string => {
    switch (statusType) {
      case StatusType.Final:
        return "dotGreen";
      case StatusType.Draft:
        return "dotYellow";
      case StatusType.Review:
        return "dotRed";
      default:
        return "dotNotSet";
    }
  };

  return (
    <td>
      <div className="leftMiddle ">
        <span className={getCss(props.dataItem.statusId)}></span>
        <span className="ms-2">{props.dataItem.statusName}</span>
      </div>
    </td>
  );
};
