export enum ClassificationKey {
  behorigheter,
  andamal,
  amnen,
  larandemal,
}
export enum Service {
  lcms,
  question,
}
export enum LearningObjectiveType {
  None,
  Teoretisk,
  Praktisk,
  Självvärdering,
}
export enum LearningObjectiveTypeColor {
  White,
  Green,
  Red,
  Purple,
}
