import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Informationbox } from "../../../../../api/Client";
import { EditorView } from "@progress/kendo-editor-common";
import { GetParentNode, ComponentMode } from "../../../../common/RichTextEditorTools";
import { Button } from "@progress/kendo-react-buttons";
import { InfoComponentDialog } from "./InfoComponentDialog";
import { CreateComponentHtml, GetObjectFromHtml } from "../common/utilities";

export const infoClassName = "info";

export class InfoComponent extends Informationbox {
  static fromJS(data: any): InfoComponent {
    return new InfoComponent(Informationbox.fromJS(data));
  }
}

// Create component
export const GetInfoComponentFromHTML = (html: string): InfoComponent => {
  return InfoComponent.fromJS(GetObjectFromHtml(html));
};

// Create HTML
export const GetHtmlFromInfoComponent = (item: InfoComponent): string => {
  const visualTemplate = `<h1>@title@</h1><p>@text@</p>`.replace("@title@", item.title!).replace("@text@", item.text!);
  return CreateComponentHtml(infoClassName, item, visualTemplate);
};

// Tool Button
export const EditNodeToolInfoComponent = (props: any) => {
  const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);
  const [editorSchema, setEditorSchema] = useState<any>(undefined);
  const [editInfoComponent, setEditInfoComponent] = useState<any>(null);
  const { view } = props;
  useEffect(() => {
    if (view) {
      setEditorView(view);
      const schema = view.state.schema;
      setEditorSchema(schema);
    }
  }, [view]);
  const mode = ComponentMode(view, infoClassName);
  const handleSubmitEditInfoComponent = (props: any) => {
    const { newNode, nodeAndPos } = props;
    const state = editorView!.state;
    const tr = state.tr.replaceWith(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize, newNode);
    editorView!.dispatch(tr);
    setEditInfoComponent(null);
  };
  const handleCancelEditInfoComponent = () => {
    setEditInfoComponent(null);
  };
  return (
    <>
      <Button
        onClick={() => {
          const state = view.state;
          const tr = state.tr;
          const nodeAndPos = GetParentNode(
            tr.doc,
            tr.selection.from,
            tr.selection.to,
            "str-component-" + infoClassName,
            false
          );
          if (nodeAndPos) {
            setEditInfoComponent({ nodeAndPos });
          }
        }}
        togglable={true}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={mode === 1 ? "Ändra informationsruta" : "Infoga informationsruta"}
        icon="foreground-color"
        selected={mode === 1}
        disabled={mode === -1}
      />
      {editInfoComponent &&
        createPortal(
          <InfoComponentDialog
            {...editInfoComponent}
            editorSchema={editorSchema}
            onSubmit={handleSubmitEditInfoComponent}
            onCancel={handleCancelEditInfoComponent}
          />,
          document.body
        )}
    </>
  );
};
