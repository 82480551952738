import { useEffect } from "react";
import { appConfig } from "../../appConfig";

const Labels = (props: any) => {
  useEffect(() => {
    const configValue: string = appConfig.REACT_APP_LCMS_API_URL as string;
  }, []);

  return <p>Ettiketter</p>;
};

export default Labels;
