import React from 'react';
import { Button } from '@progress/kendo-react-buttons';


const AddRemoveButton = (props: any) => {

    const {onClickAdd, onClickRemove, addVisible, removeVisible} = props;
    return (
        <>
            {addVisible && <Button onClick={onClickAdd} icon="plus"></Button>}
            {removeVisible && <Button onClick={onClickRemove} icon="minus"></Button>}
        </>      
    );
}

export default AddRemoveButton;