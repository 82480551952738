import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { TemplateDto } from "../../api/learnable_question/QuestionClient";
import { openEducation, openLearningObject, openQuestion } from "../question/edit/functions/helperFunctions";
import TemplateSelector from "../question/TemplateSelector";
import InteractionSelector from "../interaction/InteractionSelector";
import { MenuIndex } from "./Enums";
import EducationSelector from "../eduction/EducationSelector";
import { appConfig } from "../../appConfig";
import { Client, ContainerEducationRequest } from "../../api/Client";

function SearchContainer(props: any) {
  const searchType: MenuIndex = props.searchType;
  const [showTemplatesDialog, setShowTemplatesDialog] = React.useState(false);
  const [showInteractionDialog, setShowInteractionDialog] = React.useState(false);
  const [showEducationDialog, setShowEducationDialog] = React.useState(false);

  const handleOnTemplateClick = (template: TemplateDto) => {
    console.log("template", template);
    if (template) {
      openQuestion(0, template.id!);
    }
    setShowTemplatesDialog(false);
  };

  const handleOnInteractionClick = () => {
    setShowInteractionDialog(false);
  };

  const handleOnEducationClick = async (title: string, classifcationIdentifiers: string[]) => {
    let containerEducationRequest: ContainerEducationRequest = new ContainerEducationRequest({
      title: title,
      classificationIds: classifcationIdentifiers,
    });
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL as string);
    let treeId: number = await client.educationAdd(appConfig.REACT_APP_VERSION_NO, containerEducationRequest);

    setShowEducationDialog(false);

    openEducation(treeId, undefined);
  };

  return (
    <>
      {showTemplatesDialog && (
        <TemplateSelector setShowTemplatesDialog={setShowTemplatesDialog} onClick={handleOnTemplateClick} />
      )}

      {showInteractionDialog && (
        <InteractionSelector setShowInteractionDialog={setShowInteractionDialog} onClick={handleOnInteractionClick} />
      )}
      {showEducationDialog && (
        <EducationSelector setShowEducationDialog={setShowEducationDialog} onClick={handleOnEducationClick} />
      )}

      <div className="col-12">
        <div className="m-2" key={"searchExpansionPanel"}>
          {props.searchExpansionPanel}
        </div>

        <div>
          {searchType == MenuIndex.LearningObjects && (
            <Button onClick={() => openLearningObject(0)} className="ms-2 mt-2 mb-2" themeColor={"primary"}>
              Nytt lärobjekt
            </Button>
          )}

          {searchType == MenuIndex.Questions && !props.hideNewQuestionButton && (
            <Button onClick={() => setShowTemplatesDialog(true)} className="ms-2 mt-2 mb-2" themeColor={"primary"}>
              Ny fråga
            </Button>
          )}

          {searchType == MenuIndex.Interactions && !props.hideNewInteractionButton && (
            <Button onClick={() => setShowInteractionDialog(true)} className="ms-2 mt-2 mb-2" themeColor={"primary"}>
              Ny interaktion
            </Button>
          )}

          {searchType == MenuIndex.Educations && (
            <Button onClick={() => setShowEducationDialog(true)} className="ms-2 mt-2 mb-2" themeColor={"primary"}>
              Ny utbildning
            </Button>
          )}
        </div>

        <div className="m-2">{props.mainGrid}</div>
      </div>
    </>
  );
}

export default SearchContainer;
