import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import AddRemoveButton from "./AddRemoveButton";
import { MediaDto, TextAndMedia } from "../../../api/learnable_question/QuestionClient";
import { handleMediumPreviewClick } from "./functions/questionDtoFunctions";

const QuestionOptionItemClickable = (props: any) => {
  const medium: MediaDto = props.medium;
  const optionId: string = props.optionId;
  const uniqueOptionIdsSorted: string[] = props.uniqueOptionIdsSorted;
  const templateDataItem: TextAndMedia = props.templateDataItem!;

  const addVisible = (optionId: string) => {
    let max: number = Math.max(templateDataItem.text!.max!, templateDataItem.media!.max!);

    if (isLastElement(optionId) && uniqueOptionIdsSorted.length < max) {
      return true;
    }

    return false;
  };

  const removeVisible = (optionId: string) => {
    let min: number = Math.max(templateDataItem.text!.min!, templateDataItem.media!.min!);
    let index: number = uniqueOptionIdsSorted.findIndex((x) => x === optionId);

    if (index + 1 > min) {
      return true;
    }

    return false;
  };

  const isLastElement = (optionId: string) => {
    return uniqueOptionIdsSorted[uniqueOptionIdsSorted.length - 1] === optionId;
  };

  return (
    <div className="row mb-2">
      <div className="col-2">
        <label>{props.labelText} </label>
      </div>

      <div className="col-1">
        <Checkbox
          checked={props.isCorrectResponse()}
          onChange={(event: CheckboxChangeEvent) => props.handleCorrectResponseChange(event, optionId)}
        />
      </div>

      <div className="col-7">
        <div className="row ">
          <div
            style={{
              color: "blue",
              textDecoration: "underline",
              textAlign: "center",
              cursor: "pointer",
              border: "1px solid #ccc",
              fontSize: "1.2em",
              fontWeight: "bold",
              width: "40px",
              borderRadius: "5px",
            }}
            className="col-1 d-flex align-items-center justify-content-center"
            onClick={() => handleMediumPreviewClick(medium, props.mediumUrl)}
          >
            <span>{props.imageNumber}</span>
          </div>

          <div className="col-11">{props.textComponent} </div>
        </div>
      </div>

      <div className="col-1">
        <AddRemoveButton
          onClickAdd={props.addElement}
          onClickRemove={props.removeElement}
          addVisible={false}
          removeVisible={removeVisible(props.optionId)}
        />
      </div>

      <div className="col-1"></div>
    </div>
  );
};

export default QuestionOptionItemClickable;
