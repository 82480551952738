import React, { useState } from 'react';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

export const SaveNotificationStatus = (props: any) => {
    return (
    <NotificationGroup
        style={{
            left: 0,
            bottom: 0,
            alignItems: "flex-start",
            flexWrap: "wrap-reverse",
        }}
    >
        <Fade>
            {props.saveStatus.error && (
                <Notification
                    type={{
                        style: "error",
                        icon: true,
                    }}
                    closable={true}
                    onClose={() => props.setSaveStatus({ error: false, success: false, text: "" })}
                >
                    <span>{props.saveStatus.text}</span>
                </Notification>
            )}
        </Fade>

        <Fade>
            {props.saveStatus.success && (
                <Notification
                    type={{
                        style: "success",
                        icon: true,
                    }}
                    closable={true}
                    onClose={() => props.setSaveStatus({ error: false, success: false, text: "" })}
                >
                    <span>{props.saveStatus.text}</span>
                </Notification>
            )}
        </Fade>
        </NotificationGroup>
    )
}