import { useEffect, useState } from "react";
import { Container, Navbar, NavItem } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { appConfig } from "../appConfig";
import { useLogout } from "../auth/useLogout";
import "./NavMenu.css";
import { MenuIndex } from "./common/Enums";

export default function NavMenu(props: any) {
  //const { user } = useContext(ApplicationContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { logout } = useLogout();
  const clsLink = "fillWidth text-decoration-none text-black";
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    let currentIndex: number = getCurrentIndex();
    setCurrentIndex(currentIndex);
  }, []);

  const getCurrentIndex = () => {
    const currentPath = location.pathname;

    switch (currentPath) {
      case "/":
      case "/Questions":
        return MenuIndex.Questions;
      case "/LearningObjects":
        return MenuIndex.LearningObjects;
      case "/Interactions":
        return MenuIndex.Interactions;
      case "/Educations":
        return MenuIndex.Educations;
      default:
        return MenuIndex.None;
    }
  };

  const handleNavItemClick = (index: number) => {
    setCurrentIndex(index);
  };

  const getClasses = (index: number) => {
    if (currentIndex === index) {
      return "activeNavItem " + clsLink;
    } else {
      return clsLink;
    }
  };

  return (
    <Navbar bg="light" expand="lg">
      {/* justify-content-start gör att menyn hamnar till vänster, inte stretchas över hela sidan */}
      <Container className="justify-content-start" style={{ marginLeft: "0px" }}>
        <Navbar.Brand>
          <NavItem>
            <Link to="/" className={getClasses(MenuIndex.Home)} onClick={() => handleNavItemClick(MenuIndex.Questions)}>
              <img src="images/logo.png" height="45px" alt="logo" />
            </Link>
          </NavItem>
        </Navbar.Brand>

        <Navbar.Brand>
          <div style={{ color: "red" }}>
            <h4>{appConfig.REACT_APP_TITLE}</h4>
          </div>
        </Navbar.Brand>

        <NavItem className="ms-4">
          <Link
            to="/Questions"
            className={getClasses(MenuIndex.Questions)}
            onClick={() => handleNavItemClick(MenuIndex.Questions)}
          >
            Frågor
          </Link>
        </NavItem>

        <NavItem className="ms-4">
          <Link
            to="/Interactions"
            className={getClasses(MenuIndex.Interactions)}
            onClick={() => handleNavItemClick(MenuIndex.Interactions)}
          >
            Interaktioner
          </Link>
        </NavItem>

        <NavItem className="ms-4">
          <Link
            to="/LearningObjects"
            className={getClasses(MenuIndex.LearningObjects)}
            onClick={() => handleNavItemClick(MenuIndex.LearningObjects)}
          >
            Lärobjekt
          </Link>
        </NavItem>

        <NavItem className="ms-4">
          <Link
            to="/Educations"
            className={getClasses(MenuIndex.Educations)}
            onClick={() => handleNavItemClick(MenuIndex.Educations)}
          >
            Utbildningar
          </Link>
        </NavItem>

        {/* <NavItem className="ms-4">
          <Link to="/Media" className={getClasses(3)} onClick={() => handleNavItemClick(3)}>
            Media
          </Link>
        </NavItem> */}

        {/* <NavItem className="ms-4">
          <Link to="/EditAssessment" className={getClasses(4)} onClick={() => handleNavItemClick(4)}>
            Provbyggare
          </Link>
        </NavItem> */}

        {/* <Navbar.Collapse id="basic-navbar-nav" className="ms-4">
          <Nav className="me-auto">
            <NavDropdown title="Admin" id="admin-nav-dropdown" className={getClasses(5)}>
              <NavDropdown.Item>
                <Link to="/Keywords" className={clsLink} onClick={() => handleNavItemClick(5)}>
                  Nyckelord
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/Labels" className={clsLink} onClick={() => handleNavItemClick(5)}>
                  Etiketter
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/TestClassification" className={clsLink} onClick={() => handleNavItemClick(5)}>
                  Test classifications
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse> */}
        {/* 
        <NavItem>
          <NavLink onClick={logout} className={clsLink}>
            Logga ut
          </NavLink>
        </NavItem> */}
      </Container>
    </Navbar>
  );
}
