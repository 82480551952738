import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  GridColumn,
  GridExpandChangeEvent,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridRowClickEvent,
  GridSortChangeEvent,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { SearchClient, SearchResponse } from "../../api/learnable_search/SearchClient";
import { iColumn } from "../../interfaces/iColumn";
import LoadingPanel from "./LoadingPanel";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/sv/numbers.json";
import caGregorian from "cldr-dates-full/main/sv/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/sv/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/sv/timeZoneNames.json";
import seSeMessages from "./sv.json";
import { appConfig } from "../../appConfig";
import { CustomFilterDto } from "../../api/Client";
import { iPageState } from "../../interfaces/iPageState";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import { iConfiguration } from "./classifications/interfaces";

load(likelySubtags, currencyData, weekData, numbers, caGregorian, dateFields, timeZoneNames);

loadMessages(seSeMessages, "sv");

const MainGrid = (props: any) => {
  const configuration: iConfiguration = props.configuration;
  const searchUtilities: iSearchUtilities = props.searchUtilities;
  // console.log("maingrid configuration", configuration);
  const metadataExpandChanged = (event: GridExpandChangeEvent) => {
    let newData = props.gridData.map((item: any) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });

    console.log("metadataExpandChanged", event.dataItem, newData);
    props.setGridData(newData);
  };

  const [sort, setSort] = useState(props.initialSort);
  const [currentCondition, setCurrentCondition] = useState<string>("");
  const initialDataState: iPageState = { skip: 0, take: 10 };
  const [totalNoOfRows, setTotalNoOfRows] = useState(0);
  const [page, setPage] = useState<iPageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>();
  const [gridFilter, setGridFilter] = useState<CompositeFilterDescriptor | undefined>(props.initialFilter);
  const [executeGridFilter, setExecuteGridFilter] = useState<CompositeFilterDescriptor | undefined>(
    props.initialFilter
  );
  const [localRefresh, setLocalRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const state = { locale: "sv", value: new Date() };

  const search = async () => {
    if (!localRefresh) {
      return;
    }

    if (sort && page && executeGridFilter && props.customFilter) {
      const client = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);

      if (props.customFilter instanceof CustomFilterDto) {
        setLoading(true);
        // console.log("CALLING");

        let searchResult: SearchResponse = await searchUtilities.search(
          client,
          executeGridFilter,
          props.customFilter,
          sort,
          page,
          currentCondition,
          setCurrentCondition,
          configuration
        );

        let newData: any[] | undefined = searchResult && searchResult.rows ? searchResult.rows : undefined;
        if (newData) {
          //TODO: Måste finnas ett sätt att få fältet som ett datum från API:et istället.
          newData = newData.map((r) => ({
            ...r,
            senastEditerad: new Date(r.senastEditerad),
          }));
          // console.log("newData", newData);
          props.gridDataLoaded(newData);
          if (searchResult.totalNoOfRows != undefined && searchResult.totalNoOfRows >= 0) {
            setTotalNoOfRows(searchResult.totalNoOfRows);
            // console.log("Setting number of rows in grid.");
          }
          setLoading(false);
          // console.log("...end loading.");
        }
      } else {
        alert(
          "Property customFilter in MainGrid has no support for the class '" +
            (props.customFilter ? props.customFilter.constructor.name : "") +
            "'!"
        );
      }
    }

    setLocalRefresh(false);
  };

  useEffect(() => {
    // console.log("-localRefresh", localRefresh);
    // console.log("-sort", sort);
    // console.log("-page", page);
    // console.log("-executeGridFilter", executeGridFilter);
    // console.log("-props.customFilter", props.customFilter);

    search();
  }, [localRefresh, executeGridFilter, props.customFilter, sort, page, currentCondition]);

  const pageRef = useRef(page);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    // when filter changes we need to reset the page to 0
    setPage({
      ...pageRef.current,
      skip: 0,
    });
  }, [props.customFilter, executeGridFilter]);

  useEffect(() => {
    setLocalRefresh(true);
  }, [props.refresh]);

  useEffect(() => {
    if (props.autoSubmitGridFilter && executeGridFilter) {
      //console.log("> autoSubmitGridFilter submitting")
      setLocalRefresh(true);
    }
  }, [props.autoSubmitGridFilter, executeGridFilter]);

  useEffect(() => {
    if (props.autoSubmitCustomFilter && props.gridFilter) {
      setLocalRefresh(true);
    }
  }, [props.autoSubmitCustomFilter, props.customFilter]);

  // Callback
  const pageChange = useCallback(
    (event: GridPageChangeEvent) => {
      const targetEvent = event.targetEvent as PagerTargetEvent;
      const take = targetEvent.value === "Alla" ? totalNoOfRows : event.page.take;
      // console.log("event.page", event.page);
      if (targetEvent.value) {
        setPageSizeValue(targetEvent.value);
      }
      setPage({
        ...event.page,
        take,
      });
      setLocalRefresh(true);
    },
    [page, pageSizeValue, totalNoOfRows]
  );

  const onRowClick = (event: GridRowClickEvent) => {
    props.onRowClick(event);
  };

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
  const onGridFilterChange = useCallback(
    (event: GridFilterChangeEvent) => {
      // console.log("onGridFilterChange", event.filter);
      setGridFilter(event.filter);
      //Execute filter after one second to avoid too many calls to the API.
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      let newTimeoutId = setTimeout(() => {
        // When filter is cleared by buttons it will be null. We need to set it to initialFilter.
        if (event.filter == null) {
          setExecuteGridFilter(props.initialFilter);
        } else {
          setExecuteGridFilter(event.filter);
        }
      }, 1000);
      setTimeoutId(newTimeoutId);
    },
    [timeoutId]
  );

  const rowRender = (trElement: React.ReactElement, props: GridRowProps) => {
    const isDeprecated = props.dataItem.deprecated as boolean;
    const row = React.cloneElement(
      trElement,
      { style: { ...trElement.props.style, backgroundColor: isDeprecated ? "#fae6e6" : "" } },
      trElement.props.children
    );

    return row;
  };

  return (
    <LocalizationProvider language={state.locale}>
      <IntlProvider locale={state.locale}>
        {loading && LoadingPanel}
        <Grid
          style={{ maxHeight: "600px" }}
          rowRender={rowRender}
          onExpandChange={metadataExpandChanged}
          expandField="expanded"
          resizable={true}
          data={props.gridData}
          detail={props.metadataPanel}
          rowHeight={1}
          onPageChange={pageChange}
          skip={page.skip}
          take={page.take}
          total={totalNoOfRows}
          onRowClick={onRowClick}
          pageable={{
            buttonCount: 4,
            pageSizes: [10, 20, 30],
            pageSizeValue: pageSizeValue,
          }}
          sortable={{
            allowUnsort: true,
            mode: "single",
          }}
          sort={sort}
          filterable={true}
          filter={gridFilter}
          onFilterChange={onGridFilterChange}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            setLocalRefresh(true);
          }}
          editField="inEdit"
        >
          {props.columns &&
            props.columns.map((column: iColumn, index: number) => {
              return column.show && <GridColumn className="truncate" key={index} {...column} />;
            })}
        </Grid>
        {/* TODO: Fixa så denna fungerar! /MF 2023-09-07
                    <FilterChangeHandler
                    filter={gridFilter!}
                    columns={props.columns}
                /> */}
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default MainGrid;
