import { appConfig } from "../../appConfig";

// Constants for Info component
export const KEY_INFO_CUSTOMER: string = "keyInfoCustomer";
export const KEY_INFO_STATUS: string = "keyInfoStatus";
export const KEY_INFO_KNOWLEDGE_LEVEL: string = "keyInfoKnowledgeLevel";
export const KEY_INFO_DEPRECATED: string = "keyInfoDeprecated";
export const KEY_INFO_FRAGETYP_CLASSIFICATIONS: string = "keyInfoFragetypClassifications";
export const KEY_INFO_BEHORIGHET_CLASSIFICATIONS: string = "keyInfoBehorighetClassifications";
export const KEY_INFO_AMNEN_CLASSIFICATIONS: string = "keyInfoAmnenClassifications";
export const KEY_INFO_LEARNING_OBJECTIVES: string = "keyInfoLearningObjectives";
export const KEY_INFO_SUBTYPE: string = "keyInfoSubtype";
export const KEY_INFO_COMMENT: string = "keyInfoComment";
export const KEY_INFO_WHERE_USED_LEARNINGOBJECTS: string = "keyInfoWhereUsedLearningObjects";
export const KEY_INFO_WHERE_USED_INTERACTIONS: string = "keyInfoWhereUsedInteractions";
export const KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES: string = "keyInfoWhereUsedLearningStructures";
export const KEY_INFO_EXTERNAL_ID: string = "keyInfoExternalId";

export const INTERACTION_TYPE_NAME_MATCHING: string = "matching";
export const TEXT_MEDIA_TYPE_ID_STEM: number = 1;

export const SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE: number = 3;

export const INTERACTION_TYPE_ID_SINGLE_CHOICE: number = 1;
export const INTERACTION_TYPE_ID_SEQUENCING: number = 2;
export const INTERACTION_TYPE_ID_MATCHING: number = 4;

export const MEDIA_LINK_TYPE_MEDIA_SERVICE: number = 1;
export const MEDIA_LINK_TYPE_MEDIA_VIDEO: number = 2;
export const MEDIA_LINK_TYPE_RECTANGLE: number = 3;

//Classifications
// export const AMNE_CLASSIFICATION_ID = "1884cc8c-8bc3-4d32-8ca5-e58950b8da49";
// export const BEHORERHET_CLASSIFICATION_ID = "511fe8ee-2751-43c4-980b-6f66ef4a1e73";
// export const BEHORERHET_FM_CLASSIFICATION_ID = "1d5305cf-09e2-45dc-adfe-fac74c17af56";
// export const FRAGESTYP_CLASSIFICATION_ID = "31c898f5-f3b1-4ffc-8867-21b7d71a5da5";
export const CONFIG_FRAGETYP_CLASSIFICATION_KEY = "andamal";
export const CONFIG_BEHORIGHET_CLASSIFICATION_KEY = "behorigheter";
export const CONFIG_AMNEN_CLASSIFICATION_KEY = "amnen";

export const LEARNING_OBJECTIVES_ROOT_ID: string = "learningObjectivesRootId";
export const HIDDEN_ROOT_NODES: string[] = [LEARNING_OBJECTIVES_ROOT_ID];
///Classifications

export const QUESTION_FRAMESTAM_TYPE_ID: number = 1;
export const QUESTION_OPTION_SOURCE_TYPE_ID: number = 3;
export const QUESTION_OPTION_TARGET_TYPE_ID: number = 4;

export const CONTAINER_TYPE_LEARNING_OBJECT: number = 2;
export const CONTAINER_TYPE_INTERACTION: number = 7;

export const LEARNING_OBJECT_NEW_DEFAULT_STATUS: number = 1;
export const LEARNING_OBJECT_FINAL_STATUS: number = 2;
export const LEARNING_OBJECT_NEW_DEFAULT_TYPE: number = 1;
export const LEARNING_OBJECT_NEW_DEFAULT_ORGANISATION: string = "222ce962-d58c-4857-a7bb-bdd647a8bde2";

//template configuration keys
export const TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP: string = "allowEmptyCorrectResponsePattern";

export const TEMPLATE_INTERACTION_PROPERTY_SINGLE_CHOICE: string = "SingleChoice";

export const API_URL_FFS = appConfig.REACT_APP_FEATUREFLAGS_API_URL + "/v1/FeatureFlags/" + appConfig.APP_ID;

export const FF_PUBLISH = "Publish";

export const CONTAINER_SUB_TYPE_INLEDNING = 4;
export const CONTAINER_SUB_TYPE_SAMMANFATTNING = 2;
