import { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { TextBox } from "@progress/kendo-react-inputs";
import { ProseMirror } from "@progress/kendo-react-editor";
import {
  GetHtmlFromVideoComponent,
  GetVideoComponentFromHTML,
  VideoComponent,
  videoClassName,
} from "./VideoComponentTool";

const { DOMParser: DOMParserObj, DOMSerializer: DOMSerializerObj } = ProseMirror;
const videoPlaceholder = "/images/VideoPlaceholder.png";

export const VideoComponentDialog = (props: any) => {
  const [item, setItem] = useState<VideoComponent | undefined>(undefined);
  const [isNew, setIsNew] = useState(false);
  const { nodeAndPos, editorSchema } = props;
  // Read
  useEffect(() => {
    const serializer = DOMSerializerObj.fromSchema(editorSchema);
    const htmlDom = serializer!.serializeNode(nodeAndPos.node);
    let s = new XMLSerializer();
    let htmlContent = s.serializeToString(htmlDom);
    let tagIsNew = false;
    let infoComponent: VideoComponent = new VideoComponent({ link: "", name: "", provider: "" });
    // New component
    if (
      !nodeAndPos.node.attrs?.class ||
      !nodeAndPos.node.attrs.class.split(" ").includes("str-component-" + videoClassName)
    ) {
      if (nodeAndPos.node.type.isBlock) htmlContent = "";
      infoComponent.link = htmlContent;
      tagIsNew = true;
    } else {
      // Update component
      infoComponent = GetVideoComponentFromHTML(htmlContent);
    }
    setItem(infoComponent);
    setIsNew(tagIsNew);
  }, [nodeAndPos, editorSchema]);

  // Submit
  const submit = (updatedItem: any) => {
    const item = updatedItem as VideoComponent;
    const domParser = new DOMParser();
    // Create a new DOM node from the HTML string
    const domNode = domParser.parseFromString(GetHtmlFromVideoComponent(item), "text/html");
    // Create a new `DOMParser` instance with the schema
    const parser = DOMParserObj.fromSchema(editorSchema);
    // Parse the DOM node and create a ProseMirror node from it
    const newNode = parser.parse(domNode);
    props.onSubmit({ newNode, nodeAndPos });
  };

  // Delete
  const deleteClick = () => {
    props.onSubmit({ htmlContent: "", nodeAndPos });
  };

  return (
    <>
      <Form
        onSubmit={submit}
        ignoreModified={isNew}
        initialValues={item}
        key={JSON.stringify(item)}
        render={(formRenderProps) => {
          return (
            <Dialog title={"Video"} onClose={props.onCancel} width="80vw" height="350px">
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  Länk
                  <Field name={"link"} component={TextBox} label={"Länk"} autoFocus={true} />
                </fieldset>
                <fieldset className={"k-form-fieldset"}>
                  Namn
                  <Field name={"name"} component={TextBox} label={"Namn"} autoFocus={true} />
                </fieldset>
                <fieldset className={"k-form-fieldset"}>
                  Källa
                  <Field name={"provider"} component={TextBox} label={"Källa"} />
                </fieldset>
              </FormElement>
              <DialogActionsBar>
                <div style={{ textAlign: "right" }}>
                  {!isNew && (
                    <button
                      type="button"
                      className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-danger k-rounded-md"
                      onClick={deleteClick}
                    >
                      Ta bort
                    </button>
                  )}
                  <button
                    type={"button"}
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps.allowSubmit}
                    className="k-button k-button-md k-button-rectangle k-primary k-button-solid k-button-solid-primary k-rounded-md"
                  >
                    {isNew ? "Add" : "Update"}
                  </button>
                  <button
                    className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
                    onClick={props.onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </DialogActionsBar>
            </Dialog>
          );
        }}
      />
    </>
  );
};
