import { useState, useEffect } from "react";

import { QuestionMediaDto } from "../../api/learnable_question/QuestionClient";
import { iPageState } from "../../interfaces/iPageState";
import { GetAssetResponse } from "../../api/optima_image/OptimaImageClient";
import { iPageHeader } from "../../interfaces/iPageHeader";
import { getMediaLinkAsJson, handleMediumPreviewClick } from "../question/edit/functions/questionDtoFunctions";
import { IMediaDto, MediaDto } from "../../api/Client";
import { iImageLink } from "./interfaces";
// import { iImageLink } from "../../interfaces/iMediaLink";

const ImageSelector = (props: any) => {
  //TODO: Media Service
  //const [images, setImages] = React.useState<Array<ImagesDto>>([]);
  //const client = new OptimaImageClient(appConfig.REACT_APP_OPTIMA_MEDIA_API_URL);
  //const searchValue = { "name": "string", "keywords": ["string"], "limit": 0 } as ImagesRequest;
  //const selectedImages = props.selectedImages as ImagesDto[];
  //const newSelectedImages = props.newSelectedImages as ImagesDto[];

  const pageHeader: iPageHeader = props.pageHeader;
  const images: GetAssetResponse[] = props.images;
  const [pagedImages, setPagedImages] = useState<GetAssetResponse[]>([]);

  const initialDataState: iPageState = { skip: 0, take: 10 };
  const [totalNoOfRows, setTotalNoOfRows] = useState(0);
  const [page, setPage] = useState<iPageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>();

  const questionMedia: QuestionMediaDto[] = props.questionMedia;

  const fetchImages = async () => {
    //const reply = await client.images(searchValue);
    //return reply.images;
  };

  const syncSelectedImages = (imageDtos: any[]) => {};

  //const syncSelectedImages = (imageDtos: ImagesDto[]) => {

  //    const filteredImages = imageDtos.filter(x => questionMedia.filter(y => y.media!.filter(z => z.link === x.thumbnailUri).length > 0).length > 0);
  //    props.setSelectedImages(filteredImages);

  //}

  const init = async () => {
    //const imageDtos: ImagesDto[] | undefined = await fetchImages();
    //syncSelectedImages(imageDtos!);
    //setImages(imageDtos!);
  };
  const getTitle = (image: GetAssetResponse): string => {
    //get title property
    const found = image.properties?.find((x) => x.name === "Filename");

    if (found) {
      return found?.value!;
    }

    return "";
  };
  useEffect(() => {
    init();
  }, []);

  const onImageClick = (image: GetAssetResponse) => {
    props.onImageClick(image);
  };
  const onImagePreviewClick = (image: GetAssetResponse) => {
    const mediaLink: iImageLink = getMediaLinkAsJson(image);

    let medium: MediaDto = { link: JSON.stringify(mediaLink), linkTypeId: 1 } as MediaDto;
    let url = image.url!;
    handleMediumPreviewClick(medium, url);
  };

  return (
    <>
      <div className="containerImage" style={{ columnCount: props.cols }}>
        {images.map((x: GetAssetResponse, index: number) => (
          <figure key={index + "_" + x.contentId}>
            <img onClick={() => onImageClick(x)} src={x.url} style={{ cursor: "pointer" }} />
            <figcaption key={x.contentId}>{getTitle(x)}</figcaption>
            <div key={x.versionId}>
              <div key={"btndiv_" + x.contentId}>
                <button
                  className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                  onClick={() => onImagePreviewClick(x)}
                  style={{ marginRight: "auto" }}
                >
                  <span className={"k-icon k-i-eye"} />
                </button>
              </div>
              {/* <div>{getTitle(x)}</div> */}
            </div>
          </figure>
        ))}
        {/* <div className="row::" style={{ content: "", clear: "both", display: "table" }}>
          {images.map((x: GetAssetResponse, index: number) => (
            // <div key={x.contentId} className="m-1 " style={{ display: "inline" }}>
            //   <img onClick={() => onImageClick(x)} src={x.url} style={{ width: 300, cursor: "pointer" }} />
            // </div>
            <div key={x.contentId} className="column m-1" style={{ float: "left", padding: "5px" }}>
              <div>
                <img
                  className="img-fluid"
                  onClick={() => onImageClick(x)}
                  src={x.url}
                  style={{ width: props.selectedImageSize + "px", cursor: "pointer" }}
                />
              </div>
              <div>
                <div>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                    onClick={() => onImagePreviewClick(x)}
                    style={{ marginRight: "auto" }}
                  >
                    <span className={"k-icon k-i-eye"} />
                  </button>
                </div>
                <div>{getTitle(x)}</div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default ImageSelector;
