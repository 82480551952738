import { useEffect, useState } from "react";
import { TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  QuestionTextDto,
  TextAndMedia,
  ValidationDto,
  TemplateDto,
  QuestionMediaDto,
} from "../../../api/learnable_question/QuestionClient";
import {
  createQuestionTextDto,
  removeQuestionText,
  getQuestionMedia,
  getQuestionCopy,
  createQuestionMediaDto,
  getUniqeMediaOptionIds,
  getQuestionTexts,
  isMedia,
  isText,
  syncQuestionOptionWithTemplate,
} from "./functions/questionDtoFunctions";
import { isQuestionOption, isQuestionOptionTarget } from "./functions/helperFunctions";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import ValidationSummary from "./ValidationSummary";
// import { iImageLink } from "../../../interfaces/iMediaLink";
import { useAppContext } from "../../common/AppContext";
import { fetchMediaLinks } from "../../../api/optima_image/apiFunctions";
import QuestionOptions from "./QuestionOptions";
import {
  SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE,
  TEXT_MEDIA_TYPE_ID_STEM,
  QUESTION_OPTION_SOURCE_TYPE_ID,
  QUESTION_OPTION_TARGET_TYPE_ID,
} from "../../common/Constants";
import QuestionMedia from "./QuestionMedia";
import QuestionText from "./QuestionText";
import { iImageLink } from "../../optima_image/interfaces";

const TextMediaContainer = (props: any) => {
  const appObject = useAppContext();

  const question: iQuestionDtoExtended | undefined = props.question;
  const templateDataItems: TextAndMedia[] = props.templateDataItems;
  const templates: TemplateDto[] = props.templates;
  // const [regions, setRegions] = useState<IArea[]>([]);
  const getElementsCount = (templateDataItem: TextAndMedia) => {
    if (isQuestionOptionTarget(templateDataItem)) {
      return 0;
    }

    let questionTexts = question!.questionTexts!.filter(
      (x) => x.textTypeId == templateDataItem.textMediaTypeId
    ) as QuestionTextDto[];
    let questionMedia = question!.questionMedia!.filter(
      (x) => x.typeId == templateDataItem.textMediaTypeId
    ) as QuestionMediaDto[];

    let mediaLength = isQuestionOption(templateDataItem)
      ? getUniqeMediaOptionIds(questionMedia, templateDataItem.textMediaTypeId!).length
      : questionMedia.length;
    let count = Math.max(questionTexts.length, mediaLength);

    return count;
  };

  const getElements = (templateDataItem: TextAndMedia): JSX.Element[] => {
    if (isQuestionOptionTarget(templateDataItem)) {
      return [];
    }

    let elements: JSX.Element[] = [];
    let questionTexts = question!.questionTexts!.filter(
      (x) => x.textTypeId == templateDataItem.textMediaTypeId
    ) as QuestionTextDto[];
    let questionMedia = question!.questionMedia!.filter(
      (x) => x.typeId == templateDataItem.textMediaTypeId
    ) as QuestionMediaDto[];
    let isOption: boolean = isQuestionOption(templateDataItem);

    if (
      isOption &&
      !isQuestionOptionTarget(templateDataItem) &&
      (questionTexts.length > 0 || questionMedia.length > 0)
    ) {
      elements.push(getQuestionOptionElements(templateDataItems, 0, 0));
    }

    if (!isOption && questionTexts.length > 0) {
      elements.push(getQuestionTextsElements(templateDataItem, 0, 0));
    }

    if (!isOption && isMedia(templateDataItem)) {
      elements.push(getQuestionMediaElements(templateDataItem, 0, 0));
    }

    return elements;
  };

  const [mediaLinks, setMediaLinks] = useState<iImageLink[] | undefined>(undefined);

  const init = async () => {
    let newMediaLinks: iImageLink[] = await fetchMediaLinks(mediaLinks, question!.questionMedia!, 400);
    setMediaLinks(newMediaLinks);
  };

  useEffect(() => {
    init();
  }, []);

  const addElements = (templateDataItem: TextAndMedia) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    if (isText(templateDataItem)) {
      let newQuestionText = createQuestionTextDto(newQuestion, templateDataItem, undefined); //addQuestionText(newQuestion, templateDataItem);
      let newQuestionTexts = newQuestion.questionTexts as QuestionTextDto[];

      newQuestionTexts.push(newQuestionText);
      //addTextElement(newQuestion, templateDataItem);
    }

    if (isMedia(templateDataItem)) {
      let newQuestionMedium: QuestionMediaDto = createQuestionMediaDto(newQuestion!, templateDataItem, undefined);
      let newQuestionMedia = newQuestion.questionMedia as QuestionMediaDto[];

      newQuestionMedia.push(newQuestionMedium);
      //addMediaElement(newQuestion, templateDataItem);
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);
  };

  const addTextElement = (templateDataItem: TextAndMedia, optionId: string | undefined) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    let newQuestionText = createQuestionTextDto(newQuestion, templateDataItem, optionId); //addQuestionText(newQuestion, templateDataItem);
    let newQuestionTexts = newQuestion.questionTexts as QuestionTextDto[];

    newQuestionTexts.push(newQuestionText);

    //createTextMediaContainerProps(newQuestion);
    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);

    return newQuestion;
  };

  const addMediaElement = (templateDataItem: TextAndMedia, optionId: string | undefined) => {
    //console.log("addMediaElement")
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    let newQuestionMedium: QuestionMediaDto = createQuestionMediaDto(newQuestion!, templateDataItem, optionId);
    let newQuestionMedia = newQuestion.questionMedia as QuestionMediaDto[];

    newQuestionMedia.push(newQuestionMedium);

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);

    return newQuestion;
  };

  const removeTextElement = (templateDataItem: TextAndMedia, questionText: QuestionTextDto) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    removeQuestionText(newQuestion!, questionText.id!);

    //om man tar bort sista textboxen, så ska �ven "L�gg till media" f�rsvinna OCH media.min �r 0
    let questionTexts: QuestionTextDto[] = newQuestion.questionTexts!.filter(
      (x) => x.textTypeId == templateDataItem.textMediaTypeId
    ) as QuestionTextDto[];
    if (questionTexts.length === 0 && templateDataItem.media!.min === 0) {
      newQuestion.questionMedia = newQuestion.questionMedia!.filter(
        (x) => x.typeId !== templateDataItem.textMediaTypeId
      ) as QuestionMediaDto[];
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);
  };

  const removeMediaElement = (templateDataItem: TextAndMedia, questionMedium: QuestionMediaDto) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    newQuestion.questionMedia = newQuestion.questionMedia!.filter((x) => x.typeId !== templateDataItem.textMediaTypeId);

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);
  };

  // const removeMedium = (questionMedium: QuestionMediaDto, index: number) => {
  //   let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
  //   const questionMediumIndex = newQuestion.questionMedia!.findIndex((x) => x.id === questionMedium.id);
  //   if (questionMediumIndex > -1) {
  //     // denna rad och index blir aktuell först när det kan finnas fler än 1 bild per questionMedia (shapeshifting)
  //     //newQuestion.questionMedia![questionMediumIndex].media!.splice(index, 1);

  //     // denna rad gäller när vi bara har 1 bild per questionMedia (ej shapeshifting)
  //     newQuestion.questionMedia!.splice(questionMediumIndex, 1);

  //     newQuestion.isModified = true;
  //     props.setQuestion(newQuestion!);
  //   }
  // };

  const removeMedium = (questionMedium: QuestionMediaDto, index: number) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    const questionMediumIndex = newQuestion.questionMedia!.findIndex((x) => x.id === questionMedium.id);
    if (questionMediumIndex > -1) {
      const isTextMediaTypeStem: boolean =
        newQuestion.questionMedia![questionMediumIndex].typeId === TEXT_MEDIA_TYPE_ID_STEM;
      // denna rad och index blir aktuell först när det kan finnas fler än 1 bild per questionMedia (shapeshifting)
      //newQuestion.questionMedia![questionMediumIndex].media!.splice(index, 1);

      // denna rad gäller när vi bara har 1 bild per questionMedia (ej shapeshifting)
      newQuestion.questionMedia!.splice(questionMediumIndex, 1);

      //om det är en klick-i-bild-fråga och questionMedia avser frågesetamm, nollställer vi mediumImageAreas och  eventuella bilder i svarsalternativen tas bort
      if (newQuestion.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE && isTextMediaTypeStem) {
        // newQuestion.mediumImageAreas = [];
        newQuestion.questionMedia = newQuestion.questionMedia!.filter((x) => x.typeId !== 3);
        syncQuestionOptionWithTemplate(newQuestion, templateDataItems);
      }
      //newQuestion.questionMedia![questionMediumIndex] = newQuestion

      newQuestion.isModified = true;
      props.setQuestion(newQuestion!);
    }
  };

  const getQuestionTextsElements = (templateDataItem: TextAndMedia, index: number, lastIndex: number) => {
    var questionTexts = getQuestionTexts(question!, templateDataItem.textMediaTypeId!);

    return (
      <div key={"QuestionText" + templateDataItem.textMediaTypeId!} className={"mb-2"}>
        <QuestionText
          questionTexts={questionTexts}
          labelText={getTextMediaName(templateDataItem.textMediaTypeId!)}
          addElement={() => addTextElement(templateDataItem, undefined)}
          removeElement={removeTextElement}
          templateDataItem={templateDataItem}
          handleTextChange={handleTextChange}
        />
      </div>
    );
  };

  const getQuestionMediaElements = (templateDataItem: TextAndMedia, index: number, lastIndex: number) => {
    var questionMedia = getQuestionMedia(question!, templateDataItem.textMediaTypeId!);

    return (
      <div key={"QuestionMedia" + templateDataItem.textMediaTypeId!} className={"row mb-2"}>
        <QuestionMedia
          questionMediaItemCol={"col-10"}
          addMediaButtonVisible={isMedia(templateDataItem)}
          plusButtonVisible={false}
          minusButtonVisible={!isText(templateDataItem) && index + 1 > templateDataItem.media!.min!}
          questionMedia={questionMedia}
          mediaLinks={mediaLinks}
          labelText={getTextMediaName(templateDataItem.textMediaTypeId!)}
          addElement={() => addMediaElement(templateDataItem, undefined)}
          removeElement={() => removeMediaElement(templateDataItem, questionMedia![0])}
          templateDataItem={templateDataItem}
          templateDataItems={templateDataItems}
          updateMediaLinks={updateMediaLinks}
          deleteMedium={removeMedium}
          question={question}
          setQuestion={props.setQuestion}
        />
      </div>
    );
  };

  const getQuestionOptionElements = (templateDataItems: TextAndMedia[], index: number, lastIndex: number) => {
    let source: TextAndMedia = templateDataItems.find((x) => isQuestionOption(x))!;
    return (
      <QuestionOptions
        key={"QuestionOption_" + source.textMediaTypeId! + "_" + index}
        question={question}
        setQuestion={props.setQuestion}
        mediaLinks={mediaLinks}
        updateMediaLinks={updateMediaLinks}
        labelText={getTextMediaName(source.textMediaTypeId!)}
        templateDataItems={templateDataItems}
        deleteMedium={removeMedium}
        handleTextChange={handleTextChange}
      />
    );
  };

  const updateMediaLinks = async (question: iQuestionDtoExtended) => {
    let newMediaLinks: iImageLink[] = await fetchMediaLinks(mediaLinks, question.questionMedia!);
    setMediaLinks(newMediaLinks);
  };

  const getTextMediaName = (textMediaTypeId: number) => {
    if (typeof appObject.textAndMediaTypes.filter((x) => x.id === textMediaTypeId)[0] === "undefined") {
      console.error("TextMediaContainer_getTextMediaName_(textMediaTypeId_missing): " + textMediaTypeId);
    }

    return appObject.textAndMediaTypes.filter((x) => x.id == textMediaTypeId)[0].name!;
  };

  const handleTextChange = (event: TextAreaChangeEvent, questionText: QuestionTextDto) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    for (var i = 0; i < newQuestion.questionTexts!.length; i++) {
      if (
        newQuestion.questionTexts![i].id == questionText.id &&
        newQuestion.questionTexts![i].textTypeId == questionText.textTypeId
      ) {
        let newValue = event.target.value as string;
        newQuestion.questionTexts![i].texts![0].text = newValue;
      }
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion);
  };

  const getValidationStyle = (templateDataItem: TextAndMedia) => {
    const validationDto: ValidationDto = props.validationDto;
    const templateDataItemFound = validationDto.deviations!.filter(
      (x) => x.textMediaTypeId == templateDataItem.textMediaTypeId
    );
    if (templateDataItemFound.length > 0) {
      return { border: "solid 1px red", borderRadius: "4px" };
    }
    return { border: "solid 1px lightgray", borderRadius: "4px" };
  };

  const onTemplateChanged = (e: DropDownListChangeEvent) => {
    props.onTemplateChanged(e.value as TemplateDto);
  };

  return (
    <div>
      <div className="ms-2">
        <span>Mall: {templates.find((x) => x.id == question?.templateId)?.name}</span>
        {/* <DropDownList
          className="ms-2"
          onChange={(e: DropDownListChangeEvent) => onTemplateChanged(e)}
          data={templates}
          style={{ width: "150px" }}
          textField="name"
          dataItemKey="id"
          value={templates.find((x) => x.id == question?.templateId)}
        /> */}
      </div>

      <div>
        {templateDataItems &&
          templateDataItems
            .filter((x) => x.text!.min === 0 && x.media!.min === 0)
            .map(
              (templateDataItem: TextAndMedia, templateDataItemIndex: number) =>
                getElementsCount(templateDataItem) == 0 && (
                  <Button
                    themeColor={"primary"}
                    className="m-2"
                    onClick={() => addElements(templateDataItem)}
                    key={"Buttons_" + templateDataItemIndex}
                  >
                    {getTextMediaName(templateDataItem.textMediaTypeId!)}
                  </Button>
                )
            )}
      </div>

      {templateDataItems &&
        templateDataItems.map((templateDataItem: TextAndMedia, templateDataItemIndex: number) => (
          <div key={templateDataItemIndex}>
            {getElementsCount(templateDataItem) > 0 && (
              <div
                key={"Elements_" + templateDataItemIndex}
                className="m-2"
                style={getValidationStyle(templateDataItem)}
              >
                <div className="m-2">{getElements(templateDataItem)}</div>
                <ValidationSummary
                  templateInteractionProperties={
                    templates.find((x) => x.id == question?.templateId)?.template?.interactionProperties!
                  }
                  templateDataItem={templateDataItem}
                  validationDto={props.validationDto}
                />

                {templateDataItem.textMediaTypeId === QUESTION_OPTION_SOURCE_TYPE_ID &&
                  templateDataItems.filter((x) => x.textMediaTypeId === QUESTION_OPTION_TARGET_TYPE_ID).length > 0 && (
                    <ValidationSummary
                      templateDataItem={
                        templateDataItems.filter((x) => x.textMediaTypeId === QUESTION_OPTION_TARGET_TYPE_ID)[0]
                      }
                      validationDto={props.validationDto}
                    />
                  )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default TextMediaContainer;
