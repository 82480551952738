import { CSSProperties, useEffect } from "react";

interface NamesViewerProps {
  names: string[];
}

const NamesViewer = (props: NamesViewerProps) => {
  const names = props.names;

  useEffect(() => {}, []);

  const containerStyle: CSSProperties = {
    maxHeight: "100px",
    overflowY: "auto",
    border: "0px",
    padding: "0px",
    margin: "0px",
  };

  const renderNames = () => {
    if (!names || names.length === 0) {
      return <div></div>;
    }

    if (names.length === 1) {
      return <div>{names[0]}</div>;
    }

    return names.map((x) => {
      return <div>{x}</div>;
    });
  };

  return <div style={containerStyle}>{renderNames()}</div>;
};

export default NamesViewer;
