import { useEffect } from "react";
import { MediaDto, QuestionMediaDto, TextAndMedia } from "../../../api/learnable_question/QuestionClient";
// import { iImageLink } from "../../../interfaces/iMediaLink";
import {
  SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE,
  QUESTION_OPTION_SOURCE_TYPE_ID,
  MEDIA_LINK_TYPE_MEDIA_VIDEO,
} from "../../common/Constants";
import { ClickableImage } from "../../common/ClickableImage";
import { getImageLink, getTitle, getUrl, resizeImage } from "./functions/helperFunctions";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { handleMediumPreviewClick } from "./functions/questionDtoFunctions";
import { iImageLink } from "../../optima_image/interfaces";
import WhereUsedButton from "../../common/whereUsed/WhereUsedButton";
import { WhereUsedCaller } from "../../common/whereUsed/enums";

const QuestionMediaItem = (props: any) => {
  const question: iQuestionDtoExtended = props.question;
  const questionMedium: QuestionMediaDto = props.questionMedium;
  const mediaLinks: iImageLink[] = props.mediaLinks;
  const templateDataItem: TextAndMedia = props.templateDataItem;
  const templateDataItems: TextAndMedia[] = props.templateDataItems;

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  const linkHasEmptyContentId = (medium: MediaDto): boolean => {
    // detta inträffar bara för options (3, 4)
    if (medium.linkTypeId !== 1) return false;
    let mediaLink: iImageLink = JSON.parse(medium.link!);
    return mediaLink.ContentId === "00000000-0000-0000-0000-000000000000";
  };
  const getMediumUrl = (medium: MediaDto): string => {
    if (medium.linkTypeId === 1) {
      return getUrl(medium.link!, mediaLinks);
    }
    return medium.link ?? "";
  };

  const getMediumTitle = (medium: MediaDto, trimTitle: boolean = true): string => {
    if (medium.linkTypeId === 1) {
      return getTitle(medium.link!, mediaLinks);
    }
    return medium.name ?? "";
    // let title = "";

    // if (medium.linkTypeId === 1) {
    //   title = getTitle(medium.link!, mediaLinks);
    // } else {
    //   title = medium.name ?? "";
    // }
    // if (title) {
    //   return title.length > 20 && trimTitle ? title.substring(0, 20) + "..." : title;
    // }
    // return "";
  };
  const trimTitle = (medium: MediaDto): string => {
    let title = getMediumTitle(medium!);
    if (question.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE) {
      return title;
    }
    if (title) {
      return title.length > 15 ? title.substring(0, 15) + "..." : title;
    }
    return "";
  };
  const getMediumSource = (medium: MediaDto) => {
    if (medium.linkTypeId === 1) {
      const mediumUrl = getUrl(medium.link!, mediaLinks);
      return (
        <img
          // src={resizeImage(getUrl(medium.link!, mediaLinks), 1200)}
          src={mediumUrl === "images/image-not-found.png" ? mediumUrl : resizeImage(mediumUrl, 1200)}
          // style={{ width: "100%", height: "50%", objectFit: "contain" }}
          // style={{ objectFit: "scale-down" }}
          style={{
            // maxHeight: "150px",
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      );
    }
    // return <CardImage src="" style={{ maxHeight: "135px", objectFit: "scale-down" }} />;
    return (
      <div style={{ textAlign: "center" }}>
        <span className="k-icon k-font-icon k-i-youtube k-icon-xxxl"></span>
      </div>
    );
  };
  const getStyle = () => {
    if (question.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE) {
      return { boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)", borderRadius: "5px", marginTop: "5px" };
    }
    return { boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)", borderRadius: "5px", marginTop: "5px", width: "135px" };
  };
  return (
    <>
      {question && mediaLinks && (
        <div
        // className={props.col}
        >
          <div>
            {questionMedium.media &&
              questionMedium.media
                .filter((x) => !linkHasEmptyContentId(x))
                .map((medium: MediaDto, index: number) => (
                  <div key={index} className="m-2" style={getStyle()}>
                    {question.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE ? (
                      <div className="m-2">
                        <ClickableImage
                          maxAreas={
                            templateDataItems.find((x) => x.textMediaTypeId === QUESTION_OPTION_SOURCE_TYPE_ID)!.media!
                              .max
                          }
                          question={props.question}
                          setQuestion={props.setQuestion}
                          templateDataItems={templateDataItems}
                          src={
                            getUrl(medium.link!, mediaLinks) === "images/image-not-found.png"
                              ? getUrl(medium.link!, mediaLinks)
                              : resizeImage(getUrl(medium.link!, mediaLinks), 1200)
                          }
                          // src={getUrl(medium.link!, mediaLinks)}
                          key={index}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          height: "75px",
                          width: "auto",
                        }}
                      >
                        <div className="container">{getMediumSource(medium)}</div>
                      </div>
                    )}

                    <div
                      className="m-1"
                      style={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                        marginBottom: "2px",
                        fontSize: "12px",
                      }}
                    >
                      <span title={getMediumTitle(medium)}>{trimTitle(medium)}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                          onClick={() =>
                            handleMediumPreviewClick(
                              medium,
                              medium.linkTypeId === MEDIA_LINK_TYPE_MEDIA_VIDEO
                                ? medium.link!
                                : getUrl(medium.link!, mediaLinks)
                            )
                          }
                        >
                          <span className={"k-icon k-i-eye"} />
                        </button>

                        <WhereUsedButton
                          whereUsedType={WhereUsedCaller.Question}
                          id={question.id!}
                          contentId={getImageLink(medium.link!).ContentId}
                          versionId={getImageLink(medium.link!).VersionId}
                          flat={true}
                          useDialog={true}
                        />
                      </div>
                      <button
                        className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                        onClick={() => props.deleteMedium(questionMedium, index)}
                      >
                        <span className={"k-icon k-i-trash"} />
                      </button>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionMediaItem;
