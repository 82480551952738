import { Dispatch, SetStateAction } from "react";
import {
  SearchClient,
  DynamicLinqRequest,
  Pageing,
  Parameter,
  SearchResponse,
} from "../../api/learnable_search/SearchClient";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import getLinqOperatorMethodWithNamedParam from "./getLinqOperatorMethodWithNamedParam";
import transformFieldAndValue from "./transformFieldAndValue";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import { createClassificationsQuery } from "./helperFunctions";
import { iConfiguration } from "./classifications/interfaces";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";

export class QuestionUtilities implements iSearchUtilities {
  search = async (
    client: SearchClient,
    gridFilter: CompositeFilterDescriptor | undefined,
    customFilter: iCustomFilterDtoExtended | undefined,
    sort: any,
    page: any,
    currentCondition: string,
    setCurrentCondition: Dispatch<SetStateAction<string>>,
    configuration: iConfiguration
  ): Promise<SearchResponse> => {
    const searchRequest = new DynamicLinqRequest({
      pageing: new Pageing({ skip: page.skip, take: page.take }),
      parameters: {},
      dynamicLinqString: "_questionContext.Questions",
    });

    // Custom filter for fields not in selection
    if (customFilter) {
      console.log("customFilter", customFilter);
      let whereExpression1 = "";
      // Media (Bild, Video, Ingen media)
      if (customFilter.harBild && customFilter.harVideo && customFilter.ingenMedia) {
        // No filter
      } else if (!customFilter.harBild && !customFilter.harVideo && !customFilter.ingenMedia) {
        // No filter
        // whereExpression1 = "false";
      } else {
        whereExpression1 += this.getMediaWhere(customFilter.harBild!, whereExpression1.length > 0, 1);
        whereExpression1 += this.getMediaWhere(customFilter.harVideo!, whereExpression1.length > 0, 2);
        whereExpression1 += this.getMediaWhere(customFilter.ingenMedia!, whereExpression1.length > 0);
      }
      if (whereExpression1.length > 0) whereExpression1 = `(${whereExpression1})`;

      // // Interaktionstyp
      // if (customFilter.interaktionstypId) {
      //   if (whereExpression1.length > 0) whereExpression1 += " && ";
      //   whereExpression1 += "c.InteractionTypeId == @@interactionTypeIdParam@@";
      //   searchRequest.parameters!["interactionTypeIdParam"] = new Parameter({
      //     intValue: customFilter.interaktionstypId,
      //   });
      // }

      // Template
      if (customFilter.templateId) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.TemplateId == @@templateIdParam@@";
        searchRequest.parameters!["templateIdParam"] = new Parameter({
          intValue: customFilter.templateId,
        });
      }

      // Organisation
      if (customFilter.organistaionsId) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.CustomerId == @@customerIdParam@@";
        searchRequest.parameters!["customerIdParam"] = new Parameter({ guidValue: customFilter.organistaionsId });
      }

      // Lärandemål
      if (customFilter.larandemalGuids!.length > 0) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 +=
          "c.QuestionLearningObjectives.Any(a => @@larandemalGuidsParam@@.Any(b => a.LearningObjectiveIdentifier == b))";
        searchRequest.parameters!["larandemalGuidsParam"] = new Parameter({ guidValues: customFilter.larandemalGuids });
      }

      // Frågetyp
      whereExpression1 = this.addClassification1(
        "Fragetyp",
        customFilter.fragetypGuids!,
        whereExpression1,
        searchRequest
      );

      // Behörighet
      whereExpression1 = this.addClassification1(
        "Behorighet",
        customFilter.behorighetGuids!,
        whereExpression1,
        searchRequest
      );

      // Ämne filtreras via en extra filtrering på lärandemål som tillfällig lösning eftersom det ännu inte gjorts någon klassificering av frågorna på ämne. /Magnus 2023-08-15
      if (customFilter.amneGuids!.length > 0) {
        var larandemalViaAmneGuids = await this.getLarandemalGuidsFromAmneGuids(client, customFilter.amneGuids!);
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 +=
          "c.QuestionLearningObjectives.Any(a => @@larandemalViaAmneGuidsParam@@.Any(b => a.LearningObjectiveIdentifier == b))";
        searchRequest.parameters!["larandemalViaAmneGuidsParam"] = new Parameter({
          guidValues: larandemalViaAmneGuids.rows,
        });
      }

      // Fritext
      if (customFilter.fritext) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        if (!customFilter.endastFragetext && customFilter.fritext.startsWith("#")) {
          // ExternalId
          whereExpression1 +=
            "c.QuestionProperties.Any(m => m.Key==@@bestNrKeyParam@@ && m.Value.StartsWith(@@fritextParam@@))";
          searchRequest.parameters!["bestNrKeyParam"] = new Parameter({ stringValue: "ExternalId" });
          searchRequest.parameters!["fritextParam"] = new Parameter({ stringValue: customFilter.fritext.substring(1) });
        } else {
          // Text
          whereExpression1 +=
            "c.QuestionTexts.Any(m =>" +
            (customFilter.endastFragetext ? " m.TypeId == 1 && " : " ") +
            "m.Texts.Any(t => t.Text1.Contains(@@fritextParam@@)))";
          searchRequest.parameters!["fritextParam"] = new Parameter({ stringValue: customFilter.fritext });
        }
      }
      // External id (filterering på friendly id)
      // if (customFilter.externalId) {
      //   if (whereExpression1.length > 0) whereExpression1 += " && ";
      //   whereExpression1 += "c.FriendlyId.substring(1) == @@externalIdParam@@";
      //   searchRequest.parameters!["externalIdParam"] = new Parameter({ stringValue: customFilter.externalId });
      // }

      if (!customFilter.includeDeprecated) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.Deprecated == false";
      }
      if (customFilter.endastOpublicerade) {
        if (whereExpression1.length > 0) whereExpression1 += " && ";
        whereExpression1 += "c.PublishStatus <= 1";
      }
      // Add Where
      if (whereExpression1.length > 0) {
        searchRequest.dynamicLinqString += "\n" + `.Where("c => ` + whereExpression1 + `")`;
      }
    }
    //c.Publishes.SingleOrDefault(x => x.QuestionId == c.Id && x.LatestVersion==1) != null ? c.Publishes.SingleOrDefault(x => x.QuestionId == c.Id && x.LatestVersion==1).SemanticVersion : ''
    // Select

    searchRequest.dynamicLinqString +=
      "\n" +
      `.Select("c => new {c.Id, c.Identifier, c.Comment, c.PublishStatus, ` +
      `c.UpdatedByNavigation.Username as UpdatedBy, c.CreatedByNavigation.Username as CreatedBy,` +
      `c.Publishes.SingleOrDefault(x => x.QuestionId == c.Id && x.LatestVersion==true).SemanticVersion as SemanticVersion,` +
      `c.Publishes.SingleOrDefault(x => x.QuestionId == c.Id && x.LatestVersion==true).VersionId.ToString() as VersionId,` +
      `c.QuestionTexts.FirstOrDefault(w => w.TypeId == 1).Texts.FirstOrDefault().Text1 as Fragestallning,c.FriendlyId,` +
      // `c.QuestionClassifications.Where(w => w.ClassificationRootId == @@amneRootParam@@).Select(s => s.ClassificationId) as AmneClassificationId,` +
      `${createClassificationsQuery(
        configuration,
        "QuestionClassifications",
        "ClassificationRootId",
        "amnen",
        "AmneClassificationId",
        searchRequest
      )}` +
      //`c.QuestionClassifications.Where(w => w.ClassificationRootId == @@behorighetRootParam@@).Select(s => s.ClassificationId) as BehorighetClassificationId,` +
      `${createClassificationsQuery(
        configuration,
        "QuestionClassifications",
        "ClassificationRootId",
        "behorigheter",
        "BehorighetClassificationId",
        searchRequest
      )}` +
      //`c.QuestionClassifications.Where(w => w.ClassificationRootId == @@fragetypRootParam@@).Select(s => s.ClassificationId) as FragetypClassificationId,` +
      `${createClassificationsQuery(
        configuration,
        "QuestionClassifications",
        "ClassificationRootId",
        "andamal",
        "FragetypClassificationId",
        searchRequest
      )}` +
      `c.StatusId, c.InteractionTypeId,` +
      `c.Status.Name as StatusName,` +
      `c.Updated != null ? c.Updated : c.Created as SenastEditerad,` +
      `c.UpdatedBy != null ? c.UpdatedByNavigation.Username : c.CreatedByNavigation.Username as SenastEditeradAv,` +
      `c.Deprecated` +
      `}")`;
    // searchRequest.parameters!["amneRootParam"] = new Parameter({ guidValue: "1884cc8c-8bc3-4d32-8ca5-e58950b8da49" });
    // searchRequest.parameters!["behorighetRootParam"] = new Parameter({
    //   guidValue: "511FE8EE-2751-43C4-980B-6F66EF4A1E73",
    // });
    // searchRequest.parameters!["fragetypRootParam"] = new Parameter({
    //   guidValue: "0EDC6A50-8C07-4A28-84CA-4704B836F918",
    // });

    //Grid filter
    let whereExpression2 = "";
    gridFilter &&
      gridFilter.filters.forEach((f: any) => {
        if (whereExpression2.length > 0) whereExpression2 += " && ";
        const fields = transformFieldAndValue(f, ["customer", "interactionType", "status"]);
        whereExpression2 += getLinqOperatorMethodWithNamedParam(fields, searchRequest.parameters);
      });

    //Custom filter for fields in selection
    // if (customFilter) {
    //Filtreras nu istället i whereExpression1 eftersom det kan finnas flera rootId:n för samma klassificering. /Magnus 2023-10-05
    //whereExpression2 = addClassification2("FragetypClassificationId", customFilter.fragetypGuids!, whereExpression2, searchRequest);

    // Ämne filtreras via ärandemål som tillfällig lösning eftersom det inte ännu inte finns någon klassificering av frågorna på ämne. /Magnus 2023-08-15
    //whereExpression2 = addClassification("AmneClassificationId", customFilter.amneGuids, whereExpression2, searchRequest);

    //Filtreras nu istället i whereExpression1 eftersom det kan finnas flera rootId:n för samma klassificering. /Magnus 2023-10-05
    //whereExpression2 = addClassification2("BehorighetClassificationId", customFilter.behorighetGuids!, whereExpression2, searchRequest);
    // }

    if (whereExpression2.length > 0) {
      searchRequest.dynamicLinqString += "\n" + `.Where("${whereExpression2}")`;
    }

    //Order By
    let newOrderBy = "";
    sort &&
      sort.forEach((s: any) => {
        if (newOrderBy.length > 0) newOrderBy += ", ";
        let fieldName = s.field;
        newOrderBy += fieldName + " " + s.dir;
      });
    if (newOrderBy.length === 0) newOrderBy = "Id";
    searchRequest.dynamicLinqString += "\n" + `.OrderBy("` + newOrderBy + `")`;

    // Call Web API
    // console.log("gridFilter", gridFilter);
    // console.log("customFilter", customFilter);
    let newCondition = searchRequest.dynamicLinqString + JSON.stringify(searchRequest.parameters);
    searchRequest.pageing!.calcPages = currentCondition === undefined || currentCondition !== newCondition;
    setCurrentCondition(newCondition);
    console.log("Call API dynamicLinq with searchRequest", searchRequest);
    try {
      const reply = await client.dynamicLinqSearch(searchRequest);
      console.log("API dynamicLinq reply", reply);
      return reply;
    } catch (error) {
      console.error(error);
      //TODO: Bättre felhantering!
      return new SearchResponse();
    }
  };

  getLarandemalGuidsFromAmneGuids = async (client: SearchClient, amneGuids: string[]) => {
    const searchRequest = new DynamicLinqRequest({
      parameters: {},
      dynamicLinqString: `
            _learningObjectiveContext.LearningObjectiveClassifications
            .Where("o => @@amneGuidsParam@@.Contains(o.ClassificationId)")
            .Select("s => s.LearningObjective.Identifier")`,
    });
    searchRequest.parameters!["amneGuidsParam"] = new Parameter({ guidValues: amneGuids });
    // console.log("Call API dynamicLinq with searchRequest", searchRequest);
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };

  getMediaWhere = (addFilter: boolean, addOr: boolean, mediaTypeId: number = 0) => {
    let reply = "";
    if (addFilter) {
      if (mediaTypeId === 0) {
        reply = "!c.QuestionMedia.Any()";
      } else {
        reply = `c.QuestionMedia.Any(a => a.MediaTypeId == ${mediaTypeId})`;
      }
      if (addOr && reply.length > 0) {
        reply = " || " + reply;
      }
    }
    return reply;
  };

  addClassification1 = (
    paramName: string,
    guids: string[],
    whereExpression: string,
    searchRequest: DynamicLinqRequest
  ) => {
    let newWhere = whereExpression;
    if (guids.length > 0) {
      if (newWhere.length > 0) newWhere += " && ";
      newWhere += "c.QuestionClassifications.Any(a => @@" + paramName + "Param@@.Any(b => b == a.ClassificationId))";
      searchRequest.parameters![paramName + "Param"] = new Parameter({ guidValues: guids });
    }
    return newWhere;
  };

  addClassification2 = (
    fieldName: string,
    guids: string[],
    whereExpression: string,
    searchRequest: DynamicLinqRequest
  ) => {
    let newWhere = whereExpression;
    if (guids.length > 0) {
      if (newWhere.length > 0) newWhere += " && ";
      newWhere += fieldName + ".Any(a => (@@" + fieldName + "Param@@).Any(b => b == a))";
      searchRequest.parameters![fieldName + "Param"] = new Parameter({ guidValues: guids });
    }
    return newWhere;
  };

  getOrganisationer = async (client: SearchClient) => {
    const searchRequest = new DynamicLinqRequest({
      dynamicLinqString: `
        _questionContext.Customers
        .Select("c => new { c.Id, c.Name}")
        .OrderBy("Name")
    `,
    });
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };

  // getInteraktionstyper = async (client: SearchClient) => {
  //   const searchRequest = new DynamicLinqRequest({
  //     dynamicLinqString: `
  //       _questionContext.InteractionTypes
  //       .Select("c => new { c.Id, c.Display as Name}")
  //       .OrderBy("Name")
  //   `,
  //   });
  //   // Call Web API
  //   const reply = await client.dynamicLinqSearch(searchRequest);
  //   return reply;
  // };

  getStatusar = async (client: SearchClient) => {
    const searchRequest = new DynamicLinqRequest({
      dynamicLinqString: `
        _questionContext.Statuses
        .Select("c => new { c.Id, c.Name}")
        .OrderBy("Name")
    `,
    });
    // Call Web API
    const reply = await client.dynamicLinqSearch(searchRequest);
    return reply;
  };
}
