
export const ComponentMode = (view: any, componentName: string): number => {
    if (!view) return 0;
    const state = view.state;
    const tr = state.tr;
    if (IsComponent(tr.doc, tr.selection.from, tr.selection.to, componentName)) { // Markören står på komponenten
        return 1;
    } else if (IsComponent(tr.doc, tr.selection.from, tr.selection.to)) { // Markören står på en annan komponent
        return -1;
    } else { // Markören står inte på någon komponent
        return 0
    }
}

export const IsComponent = (doc: any, from: number, to: number, className?: string): boolean => {
    return GetComponent(doc, from, to, className) !== null;
}

export const GetComponent = (doc: any, from: number, to: number, className?: string): any => {
    let fullClassName = 'str-component' + (className ?  '-' + className : '');
    return GetParentNode(doc, from, to, fullClassName, true)
}

export const GetParentNode = (doc: any, from: number, to: number, className: string, onlyClass: boolean): any => {
    if (to > doc.content.size) {
        console.log("WARNING: to > doc.content.size");
        return null;
    }
    let nodesInRange: any = [];
    doc.nodesBetween(from, to, (node: any, pos: number) => {
        if (node) {
            nodesInRange.push({ node, pos })
        }
    }
    );
    if (nodesInRange.length > 0) {
        for (let i = nodesInRange.length - 1; i >= 0; i--) {
            const node = nodesInRange[i].node;
            if (className.length === 0 || (node.attrs?.class && node.attrs.class.split(" ").includes(className))) {
                return nodesInRange[i];
            }
        }
        if (!onlyClass) return nodesInRange[nodesInRange.length - 1];
    }
    return null;
}