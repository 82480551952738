import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { ComboBox, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import React, { useState, useEffect } from "react";
import FilterExpansionPanel from "../common/FilterExpansionPanel";
import MetadataPanel from "./MetadataPanel";
import MainGrid from "../common/MainGrid";
import SearchContainer from "../common/SearchContainer";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { SearchClient } from "../../api/learnable_search/SearchClient";
import { ClassificationDto, GetClassificationsRequest } from "../../api/learnable_classification/ClassificationClient";
import { GridFilterCellProps, GridRowClickEvent } from "@progress/kendo-react-grid";
import { DropdownFilterCell } from "../common/DropdownFilterCell";
import { iColumn } from "../../interfaces/iColumn";
import ClassificationButton from "../common/classifications/ClassificationButton";
import MediaCheckboxGroup from "../common/MediaCheckboxGroup";
import { Button } from "@progress/kendo-react-buttons";
import { appConfig } from "../../appConfig";
import {
  openQuestion,
  openQuestionPreview,
  templateMultiColumnComboBoxColumns,
} from "./edit/functions/helperFunctions";
import { copyQuestion } from "./edit/functions/questionDtoFunctions";
import { filterLearningObjective } from "../common/classifications/types";
import { iClassificationItem, iConfiguration } from "../common/classifications/interfaces";
// import {
//   getClassificationByRootIds,
//   getEmptyClassificationContainer,
//   getQuestionAmnenConfigurations,
//   getQuestionAndamalConfigurations,
//   getQuestionEductionConfigurations,
//   getSelectedItems,
//   syncClassificationContainer,
// } from "../common/newclassifications/functions";
import { fetchClassifications, fetchLearningObjectives } from "../../api/learnable_classification/apiFunctions";
import { Client, CustomFilterDto, GetFilterRequest } from "../../api/Client";
import { iCustomFilterDtoExtended } from "../../interfaces/iCustomFilterDtoExtended";
import { useAppContext } from "../common/AppContext";
import { QuestionUtilities } from "../common/SearchQuestionsUtilities";
import { iSearchUtilities } from "../../interfaces/iSearchUtilities";
import {
  CONFIG_BEHORIGHET_CLASSIFICATION_KEY,
  CONFIG_FRAGETYP_CLASSIFICATION_KEY,
  CONFIG_AMNEN_CLASSIFICATION_KEY,
} from "../common/Constants";
import { fetchConfiguration } from "../../api/learnable_question/apiFunctions";
import { getClassificationRootIds, getConfigRootIdsByClassificationKey } from "../common/helperFunctions";
import { MenuIndex } from "../common/Enums";
import { ClassificationKey, Service } from "../common/classifications/enums";
import { ConfigProvider } from "../common/classifications/types";
import { iConfigurationLearningObjective } from "../common/classifications/interfaces";
import {
  getClassificationByRootIds,
  getLearningObjectives,
  getSelectedItems,
} from "../common/classifications/helperFunctions";
import { StatusCell } from "../common/Status";
import CustomDialog from "../common/CustomDialog";
import { VersionCell } from "../common/Version";

const Questions = (props: any) => {
  const [organisationer, setOrganisationer] = useState<any[] | undefined>(undefined);
  const [interaktionstyper, setInteraktionstyper] = useState<any[] | undefined>(undefined);

  const appObject = useAppContext();
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);
  const [selectedClassifications, setSelectedClassifications] = useState<iClassificationItem[]>([]);

  const searchUtilities: iSearchUtilities = new QuestionUtilities();

  // Custom filter
  const getInitialCustomFilter = () => {
    const customFilter: iCustomFilterDtoExtended = new CustomFilterDto() as iCustomFilterDtoExtended;
    customFilter.amneGuids = [];
    customFilter.behorighetGuids = [];
    customFilter.fragetypGuids = [];
    customFilter.larandemalGuids = [];
    customFilter.name = "";
    customFilter.fritext = "";
    customFilter.organistaionsId = "";
    customFilter.interaktionstypId = 0;
    customFilter.harBild = false;
    customFilter.harVideo = false;
    customFilter.ingenMedia = false;
    customFilter.isNew = true;
    customFilter.endastFragetext = false;
    customFilter.includeDeprecated = false;
    customFilter.endastOpublicerade = false;
    customFilter.externalId = "";
    return customFilter;
  };

  const [customFilters, setCustomFilters] = useState<iCustomFilterDtoExtended[]>([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<iCustomFilterDtoExtended>(getInitialCustomFilter());

  const [refreshGrid, setRefreshGrid] = useState<number>(1);

  // Dropdown control for status filter
  const [statusar, setStatusar] = useState<any[] | undefined>(undefined);

  const StatusFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell {...props} data={statusar ? statusar : []} defaultItem={""} dataItemKey="id" textField="name" />
  );

  const copyQuestionButtonClick = async (e: any) => {
    const questionId: number = await copyQuestion(e.dataItem);
    filterClick(e);
    openQuestion(questionId, 0);
  };

  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);

  const hasComment = (comment: string | undefined) => {
    return comment && comment.length > 0;
  };

  const ColumnButtons: any = (props: any) => {
    return (
      <td>
        <div className="centerMiddle ">
          {appConfig.REACT_APP_USE_QUESTION_COPY === "true" && (
            <Button
              themeColor="primary"
              fillMode="outline"
              size="small"
              icon="copy"
              onClick={() => copyQuestionButtonClick(props)}
            ></Button>
          )}
          <Button
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="preview"
            className="ms-1"
            onClick={() => openQuestionPreview(props.dataItem.identifier)}
          ></Button>

          <Button
            title={props.dataItem.comment}
            style={{ opacity: hasComment(props.dataItem.comment) ? 1 : 0.2 }}
            disabled={!hasComment(props.dataItem.comment)}
            onClick={() => {
              setShowCustomDialog({
                title: "Kommentar",
                message: props.dataItem.comment,
                onClose: () => setShowCustomDialog(null),
              });
            }}
            themeColor="primary"
            fillMode="outline"
            size="small"
            icon="comment"
            className="ms-1"
          ></Button>
        </div>
      </td>
    );
  };

  const GridColumns: iColumn[] = [
    { field: "friendlyId", filter: "text", title: "ID", width: 170, show: true },
    { field: "interactionTypeId", filter: "numeric", title: "InteractionTypeId", width: 50, show: false },
    { field: "fragestallning", title: "Fråga", filterable: false, show: true, sortable: false },
    { field: "fragetypClassificationId", title: "Frågetyp Id", width: 200, show: false },
    {
      field: "fragetypClassificationText",
      title: "Ändamål",
      filterable: false,
      width: 100,
      show: true,
      sortable: false,
    },

    { field: "amneClassificationId", title: "Ämne Id", width: 200, show: false },
    { field: "amneClassificationText", title: "Ämne", filterable: false, width: 100, show: false, sortable: false },

    { field: "behorighetClassificationId", title: "Behörighet Id", width: 200, show: false },
    {
      field: "behorighetClassificationText",
      title: "Utbildningar",
      filterable: false,
      width: 100,
      show: true,
      sortable: false,
    },

    { field: "semanticVersion", title: "Version", filterable: false, width: 70, show: true, cell: VersionCell },
    { field: "status", title: "Status Id", width: 160, show: false },
    { field: "statusName", title: "Status", filterCell: StatusFilterCell, width: 140, show: true, cell: StatusCell },
    { field: "senastEditerad", title: "Senast ändrad", filter: "date", format: "{0:d}", width: 260, show: true },
    { field: "senastEditeradAv", title: "Senast ändrad av", filterable: false, width: 120, show: true },
    { field: "", title: "", width: 100, show: !props.onSelectQuestion, filterable: false, cell: ColumnButtons },
  ];

  const [gridData, setGridData] = React.useState<any[]>([]);

  const initialSort: Array<SortDescriptor> = [{ field: "senastEditerad", dir: "desc" }];

  const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  const [gridFilter, setGridFilter] = useState<CompositeFilterDescriptor | undefined>(initialFilter);
  const [filterModel, setFilterModel] = React.useState({
    subClassifications: [],
    behClassifications: [],
    typClassifications: [],
    media: [],
    sampleTextbox: "aa",
    senastEditerad: "2023-01-13",
    sampleCombobox: { id: 2, name: "Tennis" },
    sampleCheckbox: false,
  });

  const getFilterAttribute = (attr: string, value: string) => {
    let newAttribute = { [attr]: value };

    return newAttribute;
  };

  const getSelectedValue = (id: any | undefined, list: any[] | undefined) => {
    let value: any | undefined = id && list && list.find((e) => e.id === id);
    if (value === undefined) {
      return null;
    }

    return value;
  };

  const onApply = (classificationType: string, selectedItems: iClassificationItem[]) => {
    // console.log("onApply", classificationType, selectedItems);
    const selectedGuids = selectedItems.map((i) => i.identifier);
    // setSelectedClassifications(selectedItems);
    switch (classificationType) {
      case CONFIG_AMNEN_CLASSIFICATION_KEY:
        // setClassificationContainerAmne({ ...classificationContainerAmne!, selected: selectedItems });
        onElementValueChanged("amneGuids", selectedGuids);
        break;
      case CONFIG_BEHORIGHET_CLASSIFICATION_KEY:
        console.log("behorighetClassificationId", selectedGuids);
        // setClassificationContainerBehorighet({ ...classificationContainerBehorighet!, selected: selectedItems });
        onElementValueChanged("behorighetGuids", selectedGuids);
        break;
      case CONFIG_FRAGETYP_CLASSIFICATION_KEY:
        // setClassificationContainerFragetyp({ ...classificationContainerFragetyp!, selected: selectedItems });
        onElementValueChanged("fragetypGuids", selectedGuids);
        break;
      case "learningObjectives":
        // setContainerLearningObjectives({ ...containerLearningObjectives!, selected: selectedItems });
        onElementValueChanged("larandemalGuids", selectedGuids);
        break;
    }
  };

  const defaultMediaCheckboxes = {
    harVideo: false,
    harBild: false,
    ingenMedia: false,
  };

  const [mediaCheckboxes, setMediaCheckboxes] = useState(defaultMediaCheckboxes);

  const configProviderClassification: ConfigProvider = {
    service: Service.question,
    url: appConfig.REACT_APP_QUESTION_API_URL as string,
    key: "classificationList",
  };

  const learningObjectiveConfiguration: iConfigurationLearningObjective = {
    title: "Lärandemål",
    rootId: "learningObjectivesRootId",
    showSelected: false,
    clientGet: getLearningObjectives,
  };

  let filterProvider = filterLearningObjective;
  filterProvider.configClassificationProvider = configProviderClassification;
  filterProvider.classificationKey = ClassificationKey.behorigheter;

  const filterControls = [
    <ClassificationButton
      key={"ClassificationButton_" + ClassificationKey.amnen}
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.amnen}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_AMNEN_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_AMNEN_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      key={"ClassificationButton_" + ClassificationKey.andamal}
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.andamal}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_FRAGETYP_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_FRAGETYP_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      key={"ClassificationButton_" + ClassificationKey.behorigheter}
      configProvider={configProviderClassification}
      filterProvider={undefined}
      classificationKey={ClassificationKey.behorigheter}
      configurationClassificationOptional={undefined}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply(CONFIG_BEHORIGHET_CLASSIFICATION_KEY, selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, CONFIG_BEHORIGHET_CLASSIFICATION_KEY) ?? []}
      readOnly={false}
    />,
    <ClassificationButton
      key={"ClassificationButton_" + ClassificationKey.larandemal}
      configProvider={undefined}
      filterProvider={filterProvider}
      classificationKey={ClassificationKey.larandemal}
      configurationClassificationOptional={learningObjectiveConfiguration}
      readOnly={false}
      onOkClick={(selectedItems: iClassificationItem[]) => onApply("learningObjectives", selectedItems)}
      selectedIds={getSelectedItems(selectedCustomFilter, "learningObjectives") ?? []}
    />,

    <div {...getFilterAttribute("searchfilterlabel", "Fritext")}>
      <Input
        name="fritext"
        value={selectedCustomFilter.fritext}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        onKeyDown={(e: any) => onSearchKeyDown(e)}
        {...getFilterAttribute("searchfilterlabel", "Fritext")}
      />
      <br />

      <Checkbox
        name="endastFragetext"
        value={selectedCustomFilter.endastFragetext}
        label={"Sök endast i frågan"}
        onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        {...getFilterAttribute("searchfilterlabel", "Sök endast i frågan")}
      />
    </div>,
    // <div {...getFilterAttribute("searchfilterlabel", "Externt ID")}>
    //   <Input
    //     name="externalId"
    //     value={selectedCustomFilter.externalId}
    //     onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
    //     onKeyDown={(e: any) => onSearchKeyDown(e)}
    //     {...getFilterAttribute("searchfilterlabel", "Externt ID")}
    //   />
    // </div>,
    <ComboBox
      name="organistaionsId"
      textField="name"
      dataItemKey="id"
      value={getSelectedValue(selectedCustomFilter.organistaionsId, organisationer)}
      data={organisationer}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Organisation")}
    />,
    <MultiColumnComboBox
      name="templateId"
      textField={"name"}
      dataItemKey="id"
      value={getSelectedValue(selectedCustomFilter.templateId, appObject.templates)}
      data={appObject.templates}
      columns={templateMultiColumnComboBoxColumns}
      style={{ width: "300px" }}
      onChange={(e) => onElementValueChanged(e.target.props.name!, e.target.value)}
      {...getFilterAttribute("searchfilterlabel", "Mall")}
    />,
    <MediaCheckboxGroup
      checkboxes={mediaCheckboxes}
      setCheckboxes={setMediaCheckboxes}
      {...getFilterAttribute("searchfilterlabel", "Media")}
      onChange={(selectedItems: string[]) => onElementValueChanged("media", selectedItems)}
    />,
    <></>,
    <></>,
    <></>,
    <>
      <div>
        <div style={{ height: "20px" }}> </div>
        <Checkbox
          name="endastOpublicerade"
          value={selectedCustomFilter.endastOpublicerade}
          label={"Visa endast opublicerade"}
          onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        />
        <br />
        <Checkbox
          name="includeDeprecated"
          value={selectedCustomFilter.includeDeprecated}
          label={"Inkludera borttagna"}
          onChange={(e) => onElementValueChanged(e.target.name!, e.target.value)}
        />
      </div>
    </>,
    <>
      <div>
        <div style={{ height: "20px" }}> </div>
      </div>
    </>,
  ];

  const [amnen, setAmnen] = useState<ClassificationDto[] | null>(null);
  const [behorigheter, setBehorigheter] = useState<ClassificationDto[] | null>(null);
  const [fragetyper, setFragetyper] = useState<ClassificationDto[] | null>(null);

  const getClassificationsRequest = (configData: iConfiguration): GetClassificationsRequest => {
    let getClassificationsRequest = new GetClassificationsRequest();
    getClassificationsRequest.classificationIds = getClassificationRootIds(configData);
    getClassificationsRequest.includeDescendants = true;
    getClassificationsRequest.cultureName = "sv-SE";

    return getClassificationsRequest;
  };

  const getClassifications = async (configData: iConfiguration) => {
    var result = await fetchClassifications(getClassificationsRequest(configData));
    return result;
  };

  const fetchQuestionFilters = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL as string);

    let filters: iCustomFilterDtoExtended[] =
      ((await client.settingGetQuestionFilters()) as iCustomFilterDtoExtended[]) ?? [];
    filters.forEach((x) => (x.isNew = false));
    setCustomFilters(filters);

    return filters;
  };

  const [learningObjectives, setLearningObjectives] = useState<any[] | undefined>(undefined);

  const init = async () => {
    await fetchQuestionFilters();

    let learningObjectives: any[] = await fetchLearningObjectives();
    setLearningObjectives(learningObjectives);

    let configurationData: iConfiguration = await fetchConfiguration("ClassificationList");
    setConfigData(configurationData);

    const classifications = await getClassifications(configurationData);

    let classificationAmnen = getClassificationByRootIds(
      classifications,
      getConfigRootIdsByClassificationKey(configurationData, CONFIG_AMNEN_CLASSIFICATION_KEY)
    );

    let classificationBehorigheter = getClassificationByRootIds(
      classifications,
      getConfigRootIdsByClassificationKey(configurationData, CONFIG_BEHORIGHET_CLASSIFICATION_KEY)
    );

    let classificationAndamal = getClassificationByRootIds(
      classifications,
      getConfigRootIdsByClassificationKey(configurationData, CONFIG_FRAGETYP_CLASSIFICATION_KEY)
    );

    setAmnen(classificationAmnen);
    setBehorigheter(classificationBehorigheter);
    setFragetyper(classificationAndamal);

    // const learningObjectives = await getLearningObjectives();
    // console.log("learningObjectives", learningObjectives);

    const searchClient = new SearchClient(appConfig.REACT_APP_SEARCH_API_URL);
    const statusarReply = await searchUtilities.getStatusar(searchClient);
    setStatusar(statusarReply.rows);

    const organisationerReply = await searchUtilities.getOrganisationer(searchClient);
    setOrganisationer(organisationerReply.rows);
  };

  useEffect(() => {
    init();
  }, []);

  const getClassificationText = (classifications: ClassificationDto[], id: string) => {
    if (classifications && id) {
      let text = classifications.find((x) => x.identifier == id)?.name ?? "";
      return text;
    }

    return "";
  };

  const fetchGetLatestQuestions = async () => {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let latest: number[] = await client.settingGetLatestQuestions();
    return latest;
  };

  const gridDataLoaded = async (data: any[]) => {
    console.log("gridDataLoaded", data, fragetyper, amnen, behorigheter);
    const updatedData = data.map((item: any) => ({
      ...item, // Spread the existing properties of the object
      fragetypClassificationText: getClassificationText(fragetyper!, item["fragetypClassificationId"][0]),
      amneClassificationText: getClassificationText(amnen!, item["amneClassificationId"][0]),
      behorighetClassificationText:
        item["behorighetClassificationId"].length > 1
          ? `${item["behorighetClassificationId"].length} st`
          : getClassificationText(behorigheter!, item["behorighetClassificationId"][0]),
    }));

    let newUpdatedData: any[] = [];
    let latestData: any[] = [];
    let latest = await fetchGetLatestQuestions();

    updatedData.forEach((x) => {
      if (latest.includes(x.id)) {
        latestData.push(x);
      } else {
        newUpdatedData.push(x);
      }
    });

    newUpdatedData = latestData.concat(newUpdatedData);
    setGridData(newUpdatedData);
  };

  const onSearchKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterClick(e);
    }
  };

  const filterClick = (event: any) => {
    setRefreshGrid(refreshGrid + 1);
  };

  const clearFilterClick = (event: any) => {
    let newCustomFilter = getInitialCustomFilter();

    setSelectedCustomFilter(newCustomFilter);
    console.log("clear customFilter", newCustomFilter);
    setMediaCheckboxes(defaultMediaCheckboxes);
    setRefreshGrid(refreshGrid + 1);
  };

  const saveFilterClick = async (event: any) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let request: GetFilterRequest = new GetFilterRequest();
    request.customQuestionFilterDto = selectedCustomFilter;

    await client.settingAddQuestionFilter(request);
    let filters: iCustomFilterDtoExtended[] = await fetchQuestionFilters();

    let newSelected: iCustomFilterDtoExtended = filters.find((x) => x.name == selectedCustomFilter.name)!;
    setSelectedCustomFilter(newSelected);
  };

  const deleteFilterClick = async (event: any) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);

    await client.settingDeleteQuestionFilter(selectedCustomFilter.name);
    await fetchQuestionFilters();

    setSelectedCustomFilter(getInitialCustomFilter());
  };

  const onElementValueChanged = (name: string, value: any) => {
    console.log("onElementValue", value);
    setFilterModel({
      ...filterModel,
      [name]: value,
    });
    console.log("name", name);
    console.log("value", value);
    if (name == "media") {
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          ...value,
        }) as iCustomFilterDtoExtended
      );
    } else {
      //TODO: Varför funkar inte "const newValue = value?.id ?? value"?
      const newValue = value?.id ?? value;
      //const newValue = (value ? (value.id ? value.id : value) : value);
      setSelectedCustomFilter(
        new CustomFilterDto({
          ...selectedCustomFilter,
          [name]: newValue,
        }) as iCustomFilterDtoExtended
      );
    }
  };

  const onSelectedFilterChanged = (newFilter: iCustomFilterDtoExtended) => {
    if (newFilter == null) {
      newFilter = getInitialCustomFilter();
    }

    setSelectedCustomFilter(newFilter);

    // uppdatera checkboxarna
    setMediaCheckboxes({
      harBild: newFilter.harBild!,
      harVideo: newFilter.harVideo!,
      ingenMedia: newFilter.ingenMedia!,
    });
  };

  const onSelectQuestion = (question: any) => {
    if (props.onSelectQuestion) {
      props.onSelectQuestion(question);
    } else {
      openQuestion(question.id!, 0);
    }
  };

  const searchExpansionPanel = (
    <FilterExpansionPanel
      key={"FilterExpansionPanel"}
      controlsPerRow={4}
      elements={filterControls}
      onFilterClick={filterClick}
      onClearFilterClick={clearFilterClick}
      onSaveFilterClick={saveFilterClick}
      onDeleteFilterClick={deleteFilterClick}
      customFilters={customFilters}
      selectedCustomFilter={selectedCustomFilter}
      onSelectedFilterChanged={onSelectedFilterChanged}
    />
  );

  const renderMetadataPanel = (dataItem: any) => {
    return (
      <MetadataPanel
        {...dataItem} // Pass the row-specific data
        behorigheter={behorigheter}
        learningObjectives={learningObjectives ?? []}
      />
    );
  };

  const mainGrid = () => (
    <MainGrid
      onRowClick={(event: GridRowClickEvent) => {
        onSelectQuestion(event.dataItem);
      }}
      searchUtilities={searchUtilities}
      configuration={configData}
      initialSort={initialSort}
      initialFilter={initialFilter}
      gridDataLoaded={gridDataLoaded}
      gridData={gridData}
      columns={GridColumns}
      metadataPanel={renderMetadataPanel}
      setGridData={setGridData}
      customFilter={selectedCustomFilter}
      autoSubmitGridFilter={true}
      refresh={refreshGrid}
    />
  );

  return (
    <div>
      {learningObjectives && fragetyper && (
        <SearchContainer
          key={"SearchContainer"}
          searchType={MenuIndex.Questions}
          hideNewQuestionButton={props.onSelectQuestion}
          mainGrid={mainGrid()}
          searchExpansionPanel={searchExpansionPanel}
        />
      )}

      {showCustomDialog && <CustomDialog {...showCustomDialog} />}
    </div>
  );
};

export default Questions;
