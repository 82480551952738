import { createPortal } from "react-dom";
import { EditorView } from "prosemirror-view";
import { Image } from "../../../../../api/Client";
import { CreateComponentHtml, GetObjectFromHtml, isValidJSON } from "../common/utilities";
import { GetParentNode, ComponentMode } from "../../../../common/RichTextEditorTools";
import { Button } from "@progress/kendo-react-buttons";
import { GetImageInfoFromContentIdAndVersionId } from "../common/utilities";
import { editorImageWidth, fixImageSrc, missingImage } from "../common/utilities";
import { ImageComponentDialog } from "./ImageComponentDialog";

export const imageClassName = "image";

export class ImageComponent extends Image {
  static fromJS(data: any): ImageComponent {
    return new ImageComponent(Image.fromJS(data));
  }
}

// Create component
export const GetImageComponentFromHTML = (html: string, removeSrc: boolean): ImageComponent => {
  const image = ImageComponent.fromJS(GetObjectFromHtml(html));
  if (removeSrc && image.link) {
    const linkObj = JSON.parse(image.link);
    if (linkObj.src) image.link = JSON.stringify({ contentId: linkObj.contentId, versionId: linkObj.versionId });
  }
  return image;
};

// Create HTML
export const GetHtmlFromImageComponent = async (imageComponent: ImageComponent) => {
  const visualTemplate = `<p><img src="@src@"><br>[ <span style="font-style: oblique;">@fileName@</span> ]</p><p>@imageText@</p>`;
  // Get image information
  const linkObj = isValidJSON(imageComponent.link) ? JSON.parse(imageComponent.link!) : {};
  if (!linkObj.src) {
    linkObj.src = missingImage;
    if (linkObj.contentId && linkObj.versionId) {
      const imageInfo = await GetImageInfoFromContentIdAndVersionId(linkObj.contentId, linkObj.versionId);
      linkObj.src = imageInfo.url || "";
      imageComponent.fileName = imageInfo.properties?.find((p) => p.name === "Filename")?.value || "";
    }
  }
  // Get URL for image
  linkObj.src = fixImageSrc(linkObj.src);

  // Save image information
  imageComponent.link = JSON.stringify(linkObj);

  // Create HTML for visual part
  const html = visualTemplate
    .replace("@src@", linkObj.src)
    .replace("@fileName@", imageComponent.fileName || "")
    .replace("@width@", editorImageWidth)
    .replace("@imageText@", imageComponent.imageText || "");
  return CreateComponentHtml(imageClassName, imageComponent, html);
};

// Tool Button
export const EditNodeToolImageComponent = (settings: any) => (props: any) => {
  console.log(settings.learningObjectId);

  // view kommer från förälderns state, så borde inte behövas ytterligare en state variable här
  const view: EditorView = props.view;
  const viewStateSchema = view.state.schema;
  const mode = ComponentMode(view, imageClassName);

  // Ser ingen mening med detta, om view ändras i föräldern så kommer denna komponent att renderas om
  //   useEffect(() => {
  //     if (view) {
  //       setEditorView(view);
  //       const schema = view.state.schema;
  //       setEditorSchema(schema);
  //     }
  //   }, [view]);

  const handleSubmitEditImageComponent = (props: any) => {
    const { newNode, nodeAndPos } = props;
    const state = view.state;
    const tr = state.tr.replaceWith(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize, newNode);

    // Måste vi inte clona view och anropa setView?
    view.dispatch(tr);
    settings.setEditImageComponent(null);
  };

  const handleCancelEditImageComponent = () => {
    settings.setEditImageComponent(null);
  };

  return (
    <>
      <Button
        onClick={() => {
          const state = view.state;
          const tr = state.tr;
          const nodeAndPos = GetParentNode(
            tr.doc,
            tr.selection.from,
            tr.selection.to,
            "str-component-" + imageClassName,
            false
          );
          if (nodeAndPos) {
            settings.setEditImageComponent({ nodeAndPos });
          }
        }}
        togglable={true}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={mode === 1 ? "Ändra bild" : "Infoga bild"}
        icon="image-edit"
        selected={mode === 1}
        disabled={mode === -1}
      />
      {settings.editImageComponent &&
        createPortal(
          <ImageComponentDialog
            {...settings.editImageComponent}
            learningObjectId={settings.learningObjectId}
            editorSchema={viewStateSchema}
            onSubmit={handleSubmitEditImageComponent}
            onCancel={handleCancelEditImageComponent}
          />,
          document.body
        )}
    </>
  );
};

// export const EditNodeToolImageComponent = (props: any) => {
//   const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);
//   const [editorSchema, setEditorSchema] = useState<any>(undefined);
//   const [editImageComponent, setEditImageComponent] = useState<any>(null);
//   const { view } = props;
//   useEffect(() => {
//     if (view) {
//       setEditorView(view);
//       const schema = view.state.schema;
//       setEditorSchema(schema);
//     }
//   }, [view]);
//   const mode = ComponentMode(view, imageClassName);
//   const handleSubmitEditImageComponent = (props: any) => {
//     const { newNode, nodeAndPos } = props;
//     const state = editorView!.state;
//     const tr = state.tr.replaceWith(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize, newNode);
//     editorView!.dispatch(tr);
//     setEditImageComponent(null);
//   };
//   const handleCancelEditImageComponent = () => {
//     setEditImageComponent(null);
//   };
//   return (
//     <>
//       <Button
//         onClick={() => {
//           const state = view.state;
//           const tr = state.tr;
//           const nodeAndPos = GetParentNode(
//             tr.doc,
//             tr.selection.from,
//             tr.selection.to,
//             "str-component-" + imageClassName,
//             false
//           );
//           if (nodeAndPos) {
//             setEditImageComponent({ nodeAndPos });
//           }
//         }}
//         togglable={true}
//         onMouseDown={(e) => e.preventDefault()}
//         onPointerDown={(e) => e.preventDefault()}
//         title={mode === 1 ? "Ändra bild" : "Infoga bild"}
//         icon="image-edit"
//         selected={mode === 1}
//         disabled={mode === -1}
//       />
//       {editImageComponent &&
//         createPortal(
//           <ImageComponentDialog
//             {...editImageComponent}
//             editorSchema={editorSchema}
//             onSubmit={handleSubmitEditImageComponent}
//             onCancel={handleCancelEditImageComponent}
//           />,
//           document.body
//         )}
//     </>
//   );
// };
