import { useState, useEffect, useCallback } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ProseMirror } from "@progress/kendo-react-editor";
import { CarouselItem, Image } from "../../../../../api/Client";
import CarouselItemComponent from "./CarouselItemComponent";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
  CarouselComponent,
  GetCarouselComponentFromHTML,
  GetHtmlFromCarouselComponent,
  carouselClassName,
} from "./CarouselComponentTool";

const { DOMParser: DOMParserObj, DOMSerializer: DOMSerializerObj } = ProseMirror;
export const CarouselComponentDialog = (props: any) => {
  const [carousel, setCarousel] = useState<CarouselComponent | undefined>(undefined);
  const [isNew, setIsNew] = useState(false);
  const { nodeAndPos, editorSchema } = props;
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const sorting: Array<SortDescriptor> = [{ field: "sortorder", dir: "asc" }];

  // Read
  useEffect(() => {
    const serializer = DOMSerializerObj.fromSchema(editorSchema);
    const htmlDom = serializer!.serializeNode(nodeAndPos.node);
    let s = new XMLSerializer();
    let htmlContent = s.serializeToString(htmlDom);
    let tagIsNew = false;
    let carouselComponent = new CarouselComponent({ items: [] });
    // New component
    if (
      !nodeAndPos.node.attrs?.class ||
      !nodeAndPos.node.attrs.class.split(" ").includes("str-component-" + carouselClassName)
    ) {
      if (nodeAndPos.node.type.isBlock) htmlContent = "";
      const newImage = new Image({ link: "", fileName: "" });
      const newItem = new CarouselItem({ image: newImage, text: "", sortorder: 0 });
      carouselComponent.items?.push(newItem);
      tagIsNew = true;
    } else {
      // Update component
      carouselComponent = GetCarouselComponentFromHTML(htmlContent, false);
    }
    setCarousel(carouselComponent);
    setIsNew(tagIsNew);
  }, [nodeAndPos, editorSchema]);

  // Keep items sorted by sortorder
  useEffect(() => {
    if (!carousel?.items) return;
    // Sort items
    const newCarousel = { ...carousel } as CarouselComponent;
    newCarousel.items = orderBy(newCarousel.items!, sorting);
    // check if newItems is equal to item
    //console.log(">> newCarousel", newCarousel);
    //console.log(">> carousel", carousel);
    //console.log(">> JSON.stringify(newCarousel)", JSON.stringify(newCarousel));
    //console.log(">> JSON.stringify(carousel)", JSON.stringify(carousel));
    if (JSON.stringify(newCarousel) !== JSON.stringify(carousel)) {
      //console.log("SETTING!!!!!!!!!!!");
      setCarousel(newCarousel);
    }
    setAllowSubmit(carousel?.items?.length! > 0);
  }, [carousel]);

  // Submit
  const handleSubmit = async () => {
    const domParser = new DOMParser();
    // Create a new DOM node from the HTML string
    const domNode = domParser.parseFromString(await GetHtmlFromCarouselComponent(carousel!), "text/html");
    // Create a new `DOMParser` instance with the schema
    const parser = DOMParserObj.fromSchema(editorSchema);
    // Parse the DOM node and create a ProseMirror node from it
    const newNode = parser.parse(domNode);
    props.onSubmit({ newNode, nodeAndPos });
  };
  // Delete
  const deleteClick = () => {
    props.onSubmit({ htmlContent: "", nodeAndPos });
  };
  // Events from CarouselItemComponent
  const handleImageChange = useCallback(
    (itemIndex: number, newLink: string, newFileName: string) => {
      const newItem = { ...carousel } as CarouselComponent;
      newItem.items![itemIndex].image!.link = newLink;
      newItem.items![itemIndex].image!.fileName = newFileName;
      newItem.items![itemIndex].image!.imageText = newFileName; // This is necessary since imageText is mandatory in the API. It's not used in the UI.
      setCarousel(newItem);
    },
    [carousel]
  );
  const handleTitleChange = useCallback(
    (itemIndex: number, newText: string) => {
      const newItem = { ...carousel } as CarouselComponent;
      newItem.items![itemIndex].text = newText;
      setCarousel(newItem);
    },
    [carousel]
  );
  const handleSortOrderUpChange = (itemIndex: number) => {
    sortOrderChange(itemIndex, -1);
  };
  const handleSortOrderDownChange = (itemIndex: number) => {
    sortOrderChange(itemIndex, 1);
  };
  const sortOrderChange = useCallback(
    (itemIndex: number, direction: -1 | 1) => {
      //console.log("sortOrderChange itemindex", itemIndex);
      //console.log("sortOrderChange direction", direction);
      //console.log("Before", carousel);
      const newCarousel = { ...carousel } as CarouselComponent;
      const itemToMove = newCarousel.items![itemIndex];
      const itemToMoveTo = newCarousel.items![itemIndex + direction];
      const tempSortOrder = itemToMove.sortorder;
      itemToMove.sortorder = itemToMoveTo.sortorder;
      itemToMoveTo.sortorder = tempSortOrder;
      //console.log("After", newCarousel);
      setCarousel(newCarousel);
    },
    [carousel]
  );
  const handleRemoveElement = useCallback(
    (itemIndex: number) => {
      const newCarousel = { ...carousel } as CarouselComponent;
      newCarousel.items!.splice(itemIndex, 1);
      setCarousel(newCarousel);
    },
    [carousel]
  );

  const handleAddElement = useCallback(() => {
    const newItem = new CarouselItem({
      image: new Image({ link: "", fileName: "" }),
      text: "",
      sortorder: carousel?.items?.length,
    });
    const newCarousel = { ...carousel } as CarouselComponent;
    newCarousel.items!.push(newItem);
    setCarousel(newCarousel);
  }, [carousel]);

  return (
    <>
      <Dialog title={"Karusell"} onClose={props.onCancel} width="80vw" height="80vh" style={{ overflowY: "scroll" }}>
        <div className="row mb-2">
          <div className="col-2">
            <h6>Bild</h6>
          </div>
          <div className="col-8">
            <h6>Text</h6>
          </div>
          <div className="col-1"></div>
          <div className="col-1"></div>
        </div>
        {carousel?.items?.map((item: any, index: number) => {
          //console.log("CarouselComponentForm item", item);
          return (
            <>
              <CarouselItemComponent
                carouselComponent={carousel}
                index={index}
                key={index}
                handleImageChange={handleImageChange}
                handleTitleChange={handleTitleChange}
                handleSortOrderUpChange={handleSortOrderUpChange}
                handleSortOrderDownChange={handleSortOrderDownChange}
                addElement={handleAddElement}
                removeElement={handleRemoveElement}
              />
            </>
          );
        })}
        <DialogActionsBar>
          <div style={{ textAlign: "right" }}>
            {!isNew && (
              <button
                type="button"
                className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-danger k-rounded-md"
                onClick={deleteClick}
              >
                Ta bort
              </button>
            )}

            <button
              type={"submit"}
              disabled={!allowSubmit}
              className="k-button k-button-md k-button-rectangle k-primary k-button-solid k-button-solid-primary k-rounded-md"
              onClick={handleSubmit}
            >
              {isNew ? "Lägg till" : "Uppdatera"}
            </button>
            <button
              type="button"
              className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
              onClick={props.onCancel}
            >
              Avbryt
            </button>
          </div>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
