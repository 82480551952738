import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import SelectInteractionDialog from "./components/interaction/edit/SelectInteractionDialog";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import NewQuestion from "./components/question/edit/NewQuestion";
import LearningObjectEditor from "./components/learningobject/edit/LearningObjectEditor";
import React from "react";
import { Component } from "./api/Client";

const Test = (props: any) => {
  const [interactionId, setInteractionId] = useState<number>(0);
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);

  const onSelectInteraction = (interactionRow: any) => {
    console.log("interactionRow", interactionRow);
    setInteractionId(interactionRow.id);
    onClose();
  };

  const onClose = () => {
    setVisibleDialog(false);
  };
  const [selected, setSelected] = useState(0);
  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };
  const [editComponents, setEditComponents] = React.useState<Component[] | undefined>(undefined);

  const [testValue, setTestValue] = useState<any>({
    success: "a",
    failure: "b",
    successEditor: "s",
    failureEditor: "f",
  });
  const [testValue2, setTestValue2] = useState<any>({
    success: "o",
    failure: "p",
    successEditor: "c",
    failureEditor: "d",
  });
  useEffect(() => {
    setEditComponents([]);
  }, []);
  useEffect(() => {
    console.log("testValue", testValue);
  }, [testValue]);
  const onEditorChanged = (e: any, tab: number) => {
    // console.log("tab", tab);
    // console.log("e", e.value);
    let newEditor: any = { ...testValue };
    let newEditor2: any = { ...testValue2 };
    newEditor2.success += "o";
    newEditor2.failure += "p";
    setTestValue2(newEditor2);
    // console.log("onEditorChanged newEditor2", newEditor2);

    if (tab === 1) {
      newEditor.success = e.value;
    }
    if (tab === 2) {
      newEditor.failure = e.value;
    }
    if (tab === 3) {
      newEditor.successEditor = e;
    }
    if (tab === 4) {
      newEditor.failureEditor = e;
    }
    // console.log("onEditorChanged", newEditor);
    setTestValue(newEditor);
  };
  const onSave = () => {};
  const onCancel = () => {};
  const onPastedMetaData = (metaData: any) => {};
  return (
    <div>
      <h1>Test</h1>
      {/* <div className="row">
        <LearningObjectEditor
          hideSaveCancelButtons={true}
          hideInteractionButton={true}
          height={"400"}
          key={"success2"}
          learningObjectId={0}
          info={undefined}
          components={editComponents}
          forceDirty={false}
          onPastedMetaData={onPastedMetaData}
          onSave={onSave}
          onCancel={onCancel}
          onEditorChanged={(html: string, editorSchema: any) => onEditorChanged(html, 3)}
        />
      </div> */}
      <div className="row">
        <TabStrip className="mt-2" selected={selected} onSelect={handleSelect} keepTabsMounted={true}>
          {/* <Button className="me-2" themeColor="primary" onClick={() => setVisibleDialog(true)}>
          Välj interaktion
        </Button>
        {interactionId}
        {visibleDialog && (
          <SelectInteractionDialog onSelectInteraction={onSelectInteraction} onClose={() => setVisibleDialog(false)} />
        )} */}
          <TabStripTab title="Success" key={"tab1"}>
            <TextArea onChange={(e: TextAreaChangeEvent) => onEditorChanged(e, 1)} value={testValue.success}></TextArea>
          </TabStripTab>
          <TabStripTab title="Failure" key={"tab2"}>
            <TextArea onChange={(e: TextAreaChangeEvent) => onEditorChanged(e, 2)} value={testValue.failure}></TextArea>
          </TabStripTab>
          {/* <TabStripTab title="Tab 3" key={"tab3"}>
            <LearningObjectEditor
              hideSaveCancelButtons={true}
              hideInteractionButton={true}
              height={"400"}
              key={"success2"}
              learningObjectId={0}
              info={undefined}
              components={editComponents}
              forceDirty={false}
              onPastedMetaData={undefined}
              onSave={onSave}
              onCancel={onCancel}
              onEditorChanged={(html: string, editorSchema: any) => onEditorChanged(html, 3)}
            />
          </TabStripTab>
          <TabStripTab title="Tab 4" key={"tab4"}>
            <LearningObjectEditor
              hideSaveCancelButtons={true}
              hideInteractionButton={true}
              height={"400"}
              key={"failure2"}
              learningObjectId={0}
              info={undefined}
              components={editComponents}
              forceDirty={false}
              onPastedMetaData={undefined}
              onSave={onSave}
              onCancel={onCancel}
              onEditorChanged={(html: string, editorSchema: any) => onEditorChanged(html, 4)}
            />
          </TabStripTab> */}
        </TabStrip>
      </div>
    </div>
  );
};

export default Test;
