import { useState } from "react";
import { IWhereUsed, IWhereUsedResult } from "./Interfaces";
import WhereUsedResultPanel from "./WhereUsedResultPanel";
import { ImageWhereUsed } from "./ImageWhereUsed";
import WhereUsedDialog from "./WhereUsedDialog";
import { WhereUsedCaller } from "./enums";
import { Button } from "@progress/kendo-react-buttons";

export interface WhereUsedButtonProps {
  whereUsedType: WhereUsedCaller;
  id: number;
  contentId: string;
  versionId: string;
  useDialog?: boolean;
  flat?: boolean;
}

const WhereUsedButton = ({ whereUsedType, id, contentId, versionId, useDialog, flat }: WhereUsedButtonProps) => {
  console.log("WhereUsedButton", id, contentId, versionId, useDialog, flat);
  const [whereUsedResult, setWhereUsedResult] = useState<IWhereUsedResult | undefined>(undefined);
  const [showWhereUsedDialog, setWhereUsedDialog] = useState<boolean>(false);

  const whereUsed: IWhereUsed = new ImageWhereUsed();

  const getWhereUsed = async () => {
    const whereUsedResult: IWhereUsedResult = (await whereUsed.fetchWhereUsed(
      whereUsedType,
      id,
      contentId,
      versionId
    )) as IWhereUsedResult;

    if (useDialog) {
      setWhereUsedDialog(true);
    }

    setWhereUsedResult(whereUsedResult);
  };

  return (
    <>
      <div>
        <Button
          type="button"
          fillMode={flat ? "flat" : "solid"}
          themeColor={flat ? "base" : "primary"}
          size="small"
          title="Används i.."
          icon="find"
          onClick={getWhereUsed}
        ></Button>
      </div>

      {whereUsedResult && !useDialog && <WhereUsedResultPanel whereUsedResult={whereUsedResult} />}
      {whereUsedResult && useDialog && showWhereUsedDialog && (
        <WhereUsedDialog
          resultPanel={<WhereUsedResultPanel whereUsedResult={whereUsedResult} />}
          onClose={() => setWhereUsedDialog(false)}
        />
      )}
    </>
  );
};

export default WhereUsedButton;
