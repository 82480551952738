import { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { TextBox } from "@progress/kendo-react-inputs";
import { ProseMirror } from "@progress/kendo-react-editor";
import ImageDialog from "../../../../optima_image/ImageDialog";
import { GetImageInfoFromContentIdAndVersionId } from "../common/utilities";
import { formImageWidth, getImageUrl } from "../common/utilities";
import {
  GetHtmlFromImageComponent,
  GetImageComponentFromHTML,
  ImageComponent,
  imageClassName,
} from "./ImageComponentTool";
import { MediaContainer } from "../../../../optima_image/types";
import { Button } from "@progress/kendo-react-buttons";
import WhereUsedButton from "../../../../common/whereUsed/WhereUsedButton";
import { iImageLink } from "../../../../optima_image/interfaces";
import { WhereUsedCaller } from "../../../../common/whereUsed/enums";

const { DOMParser: DOMParserObj, DOMSerializer: DOMSerializerObj } = ProseMirror;

export const ImageComponentDialog = (props: any) => {
  console.log(props);
  const [item, setItem] = useState<ImageComponent | undefined>(undefined);
  const [isNew, setIsNew] = useState(false);
  const { nodeAndPos, editorSchema } = props;
  const [visibleImageDialog, setVisibleImageDialog] = useState<boolean>(false);

  // used by whereUsedButton
  const [imageLink, setImageLink] = useState<iImageLink | undefined>(undefined);

  // Read
  useEffect(() => {
    const serializer = DOMSerializerObj.fromSchema(editorSchema);
    const htmlDom = serializer!.serializeNode(nodeAndPos.node);
    let s = new XMLSerializer();
    let htmlContent = s.serializeToString(htmlDom);
    let tagIsNew = false;
    let imageComponent = new ImageComponent({ link: "", imageText: "" });
    // New component
    if (
      !nodeAndPos.node.attrs?.class ||
      !nodeAndPos.node.attrs.class.split(" ").includes("str-component-" + imageClassName)
    ) {
      if (nodeAndPos.node.type.isBlock) htmlContent = "";
      imageComponent.imageText = htmlContent;
      tagIsNew = true;
    } else {
      // Update component
      imageComponent = GetImageComponentFromHTML(htmlContent, false);
    }
    setItem(imageComponent);
    setIsNew(tagIsNew);

    // if we have a link, set the mediaLink
    if (imageComponent.link) {
      let deserializedLink = JSON.parse(imageComponent.link);
      let imageLink: iImageLink = {
        ContentId: deserializedLink.contentId,
        VersionId: deserializedLink.versionId,
        Url: deserializedLink.src,
        Title: "",
      };
      // console.log("useEffect->imageLink", imageLink);
      setImageLink(imageLink);
    }
  }, [nodeAndPos, editorSchema]);

  // Submit
  const submit = async (updatedItem: any) => {
    const item = updatedItem as ImageComponent;
    const domParser = new DOMParser();
    // Create a new DOM node from the HTML string
    const domNode = domParser.parseFromString(await GetHtmlFromImageComponent(item), "text/html");
    // Create a new `DOMParser` instance with the schema
    const parser = DOMParserObj.fromSchema(editorSchema);
    // Parse the DOM node and create a ProseMirror node from it
    const newNode = parser.parse(domNode);
    props.onSubmit({ newNode, nodeAndPos });
  };

  // Delete
  const deleteClick = () => {
    props.onSubmit({ htmlContent: "", nodeAndPos });
  };

  const onImageDialogClose = async (mediaContainer: MediaContainer, formRenderProps: FormRenderProps) => {
    let imageLink: iImageLink = mediaContainer.imageLinks![0];
    setImageLink(imageLink);
    // console.log("onImageDialogClose", imageLink);

    const imageInfo = await GetImageInfoFromContentIdAndVersionId(imageLink.ContentId, imageLink.VersionId);
    const url = imageInfo.url || "";
    const fileName = imageInfo.properties?.find((p) => p.name === "Filename")?.value || "";
    const newLink = JSON.stringify({ contentId: imageLink.ContentId, versionId: imageLink.VersionId, src: url });
    formRenderProps.onChange("link", { value: newLink });
    formRenderProps.onChange("fileName", { value: fileName });
    setVisibleImageDialog(false);
  };

  return (
    <>
      <Form
        onSubmit={submit}
        ignoreModified={isNew}
        initialValues={item}
        key={JSON.stringify(item)}
        render={(formRenderProps) => {
          return (
            <Dialog title={"Bild"} onClose={props.onCancel} width="80vw" height="80vh">
              <FormElement>
                <fieldset className={"k-form-fieldset"}>
                  {/* <Field name={"link"} hidden={true} component={TextBox} label={"Link"} /> */}
                  <img
                    onClick={() => setVisibleImageDialog(true)}
                    src={getImageUrl(formRenderProps.valueGetter("link"))}
                    width={formImageWidth}
                  />
                  <Field
                    name={"fileName"}
                    style={{ borderStyle: "none" }}
                    component={TextBox}
                    label={"Filnamn"}
                    readOnly={true}
                  />
                </fieldset>
                <fieldset className={"k-form-fieldset"}>
                  Bildtext
                  <Field name={"imageText"} component={TextBox} label={"Bildtext"} autoFocus={true} />
                </fieldset>

                {imageLink && (
                  <fieldset className={"k-form-fieldset"}>
                    <WhereUsedButton
                      whereUsedType={WhereUsedCaller.LearningObject}
                      key="whereUsed"
                      id={props.learningObjectId}
                      contentId={imageLink.ContentId}
                      versionId={imageLink.VersionId}
                    />
                  </fieldset>
                )}
              </FormElement>
              <DialogActionsBar>
                <div style={{ textAlign: "right" }}>
                  {!isNew && (
                    <Button className="me-2" onClick={deleteClick}>
                      Ta bort
                    </Button>
                  )}
                  <Button
                    themeColor={"primary"}
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps.allowSubmit}
                    className="me-2"
                  >
                    {isNew ? "Lägg till" : "Uppdatera"}
                  </Button>
                  <Button onClick={props.onCancel} themeColor={"secondary"}>
                    Avbryt
                  </Button>
                </div>
              </DialogActionsBar>
              {visibleImageDialog && (
                <ImageDialog
                  onOkClick={(mediaContainer: MediaContainer) => onImageDialogClose(mediaContainer, formRenderProps)}
                  onClose={() => setVisibleImageDialog(false)}
                />
              )}
            </Dialog>
          );
        }}
      />
    </>
  );
};
