import { useState, useEffect } from "react";
import { appConfig } from "../appConfig";

const LearningObjectives = (props: any) => {
  const [responseText, setResponseText] = useState("");

  console.log("hepp");
  useEffect(() => {
    const configValue: string = appConfig.REACT_APP_LCMS_API_URL as string;
  }, []);

  return <p>Lärandemål</p>;
};

export default LearningObjectives;
