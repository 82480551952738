import React from "react";
import {
  DeviationDto,
  InteractionTypeProperties,
  TextAndMedia,
  ValidationDto,
} from "../../../api/learnable_question/QuestionClient";
import { TEMPLATE_INTERACTION_PROPERTY_SINGLE_CHOICE } from "../../common/Constants";

const ValidationSummary = (props: any) => {
  const validationDto: ValidationDto = props.validationDto;
  const templateDataItem: TextAndMedia = props.templateDataItem;
  const templateInteractionProperties: InteractionTypeProperties[] = props.templateInteractionProperties;

  // console.log("ValidationSummary", templateInteractionProperties);
  const getText = (defaultText: string) => {
    if (templateDataItem.textMediaTypeId === 3) {
      return defaultText + " för svarsalternativ";
    } else if (templateDataItem.textMediaTypeId === 4) {
      return defaultText + " för matchande svarsalternativ";
    }

    return defaultText;
  };
  const getCorrectResponsePatternDeviationMessage = () => {
    const interactionPropertySingleChoice: InteractionTypeProperties = templateInteractionProperties.find(
      (x) => x.property === TEMPLATE_INTERACTION_PROPERTY_SINGLE_CHOICE
    )!;
    if (
      interactionPropertySingleChoice &&
      interactionPropertySingleChoice.locked &&
      interactionPropertySingleChoice.defaultValue === "false"
    ) {
      return "- Vald mall måste ha minst 2 korrekta svarsalternativ";
    }
    if (interactionPropertySingleChoice && !interactionPropertySingleChoice.locked) {
      return "- Vald mall måste ha minst ett korrekt svarsalternativ";
    }
    return "- Vald mall måste ha ett korrekt svarsalternativ";
    // if (interactionPropertySingleChoice.value === "true") {
    //   return "Vald mall tillåter endast ett korrekt svarsalternativ";
    // } else {
    //   return "Vald mall kräver minst ett korrekt svarsalternativ";
    // }
  };
  const getValidationSummary = () => {
    const deviations: DeviationDto[] = validationDto.deviations!.filter(
      (x) => x.textMediaTypeId == templateDataItem.textMediaTypeId
    );
    if (deviations.length > 0) {
      let mediaDeviation = deviations[0].mediaDeviation!;
      let textDeviation = deviations[0].textDeviation!;
      let correctResponsePatternDeviation = deviations[0].correctResponsePatternDeviation!;

      //let element: HTMLElement = React.createElement("div", { className: "m-2", style: { color: 'red' } }, "Felmeddelanden: ");

      var children = [];

      if (mediaDeviation > 0) {
        children.push(
          React.createElement(
            "div",
            {
              key: "mediaDeviation_greater_" + templateDataItem.textMediaTypeId,
              className: "m-2",
              style: { color: "red" },
            },
            `- Vald mall tillåter max ${templateDataItem.media!.max}  ${getText("media")}.`
          )
        );
      }

      if (mediaDeviation < 0) {
        children.push(
          React.createElement(
            "div",
            {
              key: "mediaDeviation_less_" + templateDataItem.textMediaTypeId,
              className: "m-2",
              style: { color: "red" },
            },
            `- Vald mall kräver minst ${templateDataItem.media!.min}  ${getText("media")}.`
          )
        );
      }

      if (textDeviation > 0) {
        children.push(
          React.createElement(
            "div",
            {
              key: "textDeviation_greater_" + templateDataItem.textMediaTypeId,
              className: "m-2",
              style: { color: "red" },
            },
            `- Vald mall tillåter max ${templateDataItem.text!.max} ${getText("textfält")}}.`
          )
        );
      }

      if (textDeviation < 0) {
        children.push(
          React.createElement(
            "div",
            {
              key: "textDeviation_less_" + templateDataItem.textMediaTypeId,
              className: "m-2",
              style: { color: "red" },
            },
            `- Vald mall kräver minst ${templateDataItem.text!.min}  ${getText("textfält")}.`
          )
        );
      }
      if (correctResponsePatternDeviation > 0) {
        children.push(
          React.createElement(
            "div",
            {
              key: "singleChoiceDeviation_greater_" + templateDataItem.textMediaTypeId,
              className: "m-2",
              style: { color: "red" },
            },
            `- Vald mall tillåter endast ett korrekt svarsalternativ`
          )
        );
      }
      if (correctResponsePatternDeviation < 0) {
        children.push(
          React.createElement(
            "div",
            {
              key: "singleChoiceDeviation_less_" + templateDataItem.textMediaTypeId,
              className: "m-2",
              style: { color: "red" },
            },
            getCorrectResponsePatternDeviationMessage()
          )
        );
      }

      return (
        <div className="m-2" style={{ color: "red" }}>
          {children}
        </div>
      );
    }
  };

  return <>{getValidationSummary()}</>;
};

export default ValidationSummary;
