import QuestionOption from "./QuestionOption";
import { InteractionPropertyDto, TextAndMedia } from "../../../api/learnable_question/QuestionClient";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
// import { iImageLink } from "../../../interfaces/iMediaLink";
import {
  addOptionElement,
  addOptionMedium,
  createCorrectResponsesPatternInteractionPropertyDto,
  getInteractionPropertyFromTemplate,
  getQuestionCopy,
  getQuestionInteractionTypeName,
  getUniqueOptionIdsSorted,
  safeParse,
  // setMediumImageAreas,
  setQuestionOptionSortOrder,
  syncOptionValues,
} from "./functions/questionDtoFunctions";
import { isQuestionOptionSource, isQuestionOptionTarget } from "./functions/helperFunctions";
import {
  INTERACTION_TYPE_NAME_MATCHING,
  MEDIA_LINK_TYPE_RECTANGLE,
  QUESTION_OPTION_TARGET_TYPE_ID,
  INTERACTION_TYPE_ID_MATCHING,
  TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP,
} from "../../common/Constants";
import { useAppContext } from "../../common/AppContext";
import { IArea } from "@bmunozg/react-image-area";
import { iImageLink } from "../../optima_image/interfaces";
import { MediaContainer } from "../../optima_image/types";

const QuestionOptions = (props: any) => {
  const appObject = useAppContext();
  const question: iQuestionDtoExtended = props.question;
  const questionTemplate = appObject.templates.find((x) => x.id === question.templateId)?.template;

  const templateDataItems: TextAndMedia[] = props.templateDataItems;
  const templateDataItem: TextAndMedia = templateDataItems.find((x) => isQuestionOptionSource(x))!;
  const templateDataItemTarget: TextAndMedia | undefined =
    question.interactionTypeName === "matching" ? templateDataItems.find((x) => isQuestionOptionTarget(x))! : undefined;

  const uniqueSourceOptionIdsSorted: string[] = getUniqueOptionIdsSorted(question, templateDataItem!.textMediaTypeId!);
  const uniqueTargetOptionIdsSorted =
    question.interactionTypeName === "matching"
      ? getUniqueOptionIdsSorted(question, QUESTION_OPTION_TARGET_TYPE_ID)
      : [];
  const uniqueOptionIndexes = Array.from(
    { length: Math.max(uniqueSourceOptionIdsSorted.length, uniqueTargetOptionIdsSorted.length) },
    (_, index) => index
  );

  const labelText: string = props.labelText;

  const addElement = (templateDataItem: TextAndMedia) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    addOptionElement(newQuestion, templateDataItem, appObject.interactionTypes);
    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);
  };

  const addMedium = async (templateDataItem: TextAndMedia, optionId: string, mediaContainer: MediaContainer) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    addOptionMedium(newQuestion, templateDataItem, optionId, mediaContainer);
    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);

    props.updateMediaLinks(newQuestion);
    // let newMediaLinks: iMediaLink[] = await fetchMediaLinks(props.mediaLinks, newQuestion.questionMedia!);
    // props.setMediaLinks(newMediaLinks);
  };

  const deleteMedium = (textMediaTypeId: number, optionId: string) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    let questionMedium = newQuestion.questionMedia!.filter(
      (x) => x.typeId === textMediaTypeId && x.optionId === optionId
    )[0];

    if (questionMedium.media!.length > 0) {
      if (questionMedium.media![0].linkTypeId === MEDIA_LINK_TYPE_RECTANGLE) {
        let emptyArea: IArea = JSON.parse(questionMedium.media![0].link!);
        emptyArea.width = 0;
        emptyArea.height = 0;
        questionMedium.media![0].link = JSON.stringify(emptyArea);
      } else if (newQuestion.interactionTypeId === INTERACTION_TYPE_ID_MATCHING) {
        let emptyLink: iImageLink = JSON.parse(questionMedium.media![0].link!);
        emptyLink.ContentId = "00000000-0000-0000-0000-000000000000";
        emptyLink.VersionId = "00000000-0000-0000-0000-000000000000";
        questionMedium.media![0].link = JSON.stringify(emptyLink);
      } else {
        questionMedium.media = [];
      }

      // if (questionMedium.media![0].linkTypeId === MEDIA_LINK_TYPE_MEDIA_SERVICE) {
      //   let emptyLink: iMediaLink = JSON.parse(questionMedium.media![0].link!);
      //   emptyLink.ContentId = "00000000-0000-0000-0000-000000000000";
      //   emptyLink.VersionId = "00000000-0000-0000-0000-000000000000";

      //   questionMedium.media![0].link = JSON.stringify(emptyLink);
      // } else if (questionMedium.media![0].linkTypeId === MEDIA_LINK_TYPE_RECTANGLE) {
      //   // questionMedium.media = [];
      //   let emptyArea: IArea = JSON.parse(questionMedium.media![0].link!);
      //   emptyArea.width = 0;
      //   emptyArea.height = 0;
      //   questionMedium.media![0].link = JSON.stringify(emptyArea);

      //   // setMediumImageAreas(newQuestion, templateDataItems);
      // }
      // 2023-11-30: för textMediaType 3 och 4 (options) sätter vi empty guid på contentId i Link.
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);
  };

  const removeElement = (templateDataItem: TextAndMedia, optionId: string) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    if (newQuestion.questionTexts) {
      let index: number = newQuestion.questionTexts!.findIndex(
        (x) => x.textTypeId === templateDataItem.textMediaTypeId && x.optionId == optionId
      );
      while (index > -1) {
        newQuestion.questionTexts!.splice(index, 1);
        index = newQuestion.questionTexts!.findIndex(
          (x) => x.textTypeId === templateDataItem.textMediaTypeId && x.optionId === optionId
        );
      }
    }

    if (newQuestion.questionMedia) {
      let index = newQuestion.questionMedia!.findIndex(
        (x) => x.typeId === templateDataItem.textMediaTypeId && x.optionId === optionId
      );
      while (index > -1) {
        newQuestion.questionMedia!.splice(index, 1);
        index = newQuestion.questionMedia!.findIndex(
          (x) => x.typeId === templateDataItem.textMediaTypeId && x.optionId === optionId
        );
      }
    }

    setQuestionOptionSortOrder(newQuestion, templateDataItem);
    syncOptionValues(appObject.interactionTypes, newQuestion, templateDataItem);
    newQuestion.isModified = true;

    props.setQuestion(newQuestion!);
  };
  // const questionIsSingleChoice = (question: iQuestionDtoExtended): boolean => {
  //   //check if question template allows emptyCorrectResponsePattern
  //   if (
  //     questionTemplate?.configuration &&
  //     questionTemplate?.configuration?.find((x) => x.key === TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP)?.value === "true"
  //   ) {
  //     return false;
  //   }
  //   const singleChoiceTemplate = questionTemplate?.interactionProperties?.find((x) => x.property === "SingleChoice");
  //   // console.log("singleChoiceTemplate", questionTemplate, singleChoiceTemplate);

  //   if (question.singleChoiceId) {
  //     const questionSingleChoiceInteractionProperty: InteractionPropertyDto | undefined =
  //       question.interactionProperties!.find((x) => x.interactionPropertyId === question.singleChoiceId);
  //     if (singleChoiceTemplate && singleChoiceTemplate.locked && singleChoiceTemplate.defaultValue === "true") {
  //       return true;
  //     }
  //     // if (questionSingleChoiceInteractionProperty && questionSingleChoiceInteractionProperty.value == "1") {
  //     //   return true;
  //     // }
  //   }
  //   // if (question.interactionTypeName === "choice" && question.singleChoiceId > 0) {
  //   //   return true;
  //   // }
  //   return false;
  // };

  const emptyCorrectResponsePatternAllowed = (question: iQuestionDtoExtended): boolean => {
    if (
      questionTemplate?.configuration &&
      questionTemplate?.configuration?.find((x) => x.key === TEMPLATE_CONFIGURATION_ALLOW_EMPTY_CRP)?.value === "true"
    ) {
      return true;
    }
    return false;
  };
  const multipleCorrectResponsesAllowed = (question: iQuestionDtoExtended): boolean => {
    if (question.singleChoiceId) {
      const singleChoiceTemplateProperty = getInteractionPropertyFromTemplate(questionTemplate!, "SingleChoice"); //questionTemplate?.interactionProperties?.find((x) => x.property === "SingleChoice");
      // const questionSingleChoiceInteractionProperty: InteractionPropertyDto | undefined =
      //   question.interactionProperties!.find((x) => x.interactionPropertyId === question.singleChoiceId);

      if (
        singleChoiceTemplateProperty &&
        singleChoiceTemplateProperty.locked &&
        singleChoiceTemplateProperty.defaultValue === "true"
      ) {
        return false;
      }
    }
    return true;
  };
  const getNewCorrectResponsePattern = (currentValue: any[], optionIds: string) => {
    let newCorrectResponsePattern = [];
    //newCorrectResponsePattern = currentValue ? currentValue.split(",") : [];
    newCorrectResponsePattern = [...currentValue]; // ? currentValue.split(",") : [];
    //if optionId is in array, remove it
    if (newCorrectResponsePattern.length > 0 && newCorrectResponsePattern.includes(parseInt(optionIds))) {
      //remove optionId from array
      newCorrectResponsePattern = newCorrectResponsePattern.filter((x) => x !== parseInt(optionIds));
    } else {
      if (emptyCorrectResponsePatternAllowed(question) || multipleCorrectResponsesAllowed(question)) {
        newCorrectResponsePattern.push(parseInt(optionIds));
      } else {
        newCorrectResponsePattern = [parseInt(optionIds)];
      }
      // if (!multipleCorrectResponsesAllowed(question)) {
      //   newCorrectResponsePattern = [parseInt(optionIds)];
      // } else {
      //   newCorrectResponsePattern.push(parseInt(optionIds));
      // }
    }

    return newCorrectResponsePattern;
  };
  const updateSingleChoiceValue = (newQuestion: iQuestionDtoExtended, newCorrectResponse: string[]) => {
    const singleChoiceTemplateProperty = getInteractionPropertyFromTemplate(questionTemplate!, "SingleChoice");
    let found = newQuestion.interactionProperties!.find((x) => x.interactionPropertyId === newQuestion.singleChoiceId);
    if (emptyCorrectResponsePatternAllowed(newQuestion) || !singleChoiceTemplateProperty?.locked) {
      found!.value = newCorrectResponse.length > 1 ? "0" : "1";
    } else {
      if (singleChoiceTemplateProperty!.locked && singleChoiceTemplateProperty!.defaultValue === "true") {
        found!.value = "1";
      } else {
        found!.value = "0";
      }
    }
  };
  const setCorrectResponse = (optionIds: string) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    // let newCorrectResponsePattern = [];
    let found = newQuestion.interactionProperties!.find(
      (x) => x.interactionPropertyId === newQuestion.correctResponsePatternId
    );
    if (!found) {
      let newInteractionProperty = createCorrectResponsesPatternInteractionPropertyDto(
        optionIds,
        newQuestion.correctResponsePatternId
      );

      newQuestion.interactionProperties!.push(newInteractionProperty);
    } else {
      const currentValue = found.value == "" ? [] : safeParse(found.value!);
      const newValue = getNewCorrectResponsePattern(currentValue, optionIds);
      updateSingleChoiceValue(newQuestion, newValue);
      // if (multipleCorrectResponsesAllowed(newQuestion)) {
      // }
      found.value = JSON.stringify(Array(newValue));
    }

    newQuestion.isModified = true;
    // console.log("newCorrectResponsePattern3", newQuestion);
    props.setQuestion(newQuestion!);
  };
  const setCorrectResponse_org = (optionIds: string) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    let found = newQuestion.interactionProperties!.find(
      (x) => x.interactionPropertyId === newQuestion.correctResponsePatternId
    );
    if (!found) {
      let newInteractionProperty = createCorrectResponsesPatternInteractionPropertyDto(
        optionIds,
        newQuestion.correctResponsePatternId
      );
      newQuestion.interactionProperties!.push(newInteractionProperty);
    } else {
      found.value = optionIds;
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);
  };

  const showCorrectResponseCheckBoxes = (): boolean => {
    switch (getQuestionInteractionTypeName(appObject.interactionTypes, question)) {
      case "choice": {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const getSourceOptionId = (index: number): string | undefined => {
    return index < uniqueSourceOptionIdsSorted.length ? uniqueSourceOptionIdsSorted[index] : undefined;
  };

  const getTargetOptionId = (index: number): string | undefined => {
    return index < uniqueTargetOptionIdsSorted.length ? uniqueTargetOptionIdsSorted[index] : undefined;
  };

  return (
    <>
      {question.interactionTypeName === INTERACTION_TYPE_NAME_MATCHING ? (
        <>
          {uniqueOptionIndexes.map((index) => (
            <div className="row" key={index}>
              {/*Source*/}
              <div className="col-6">
                {getSourceOptionId(index) && (
                  <QuestionOption
                    key={"QuestionOptionSource_" + getSourceOptionId(index)}
                    question={question}
                    setQuestion={props.setQuestion}
                    mediaLinks={props.mediaLinks}
                    optionId={getSourceOptionId(index)}
                    uniqueOptionIdsSorted={uniqueSourceOptionIdsSorted}
                    labelText={labelText}
                    addElement={() => addElement(templateDataItem)}
                    removeElement={() => removeElement(templateDataItem, getSourceOptionId(index)!)}
                    addMedium={addMedium}
                    deleteMedium={deleteMedium}
                    templateDataItem={templateDataItem}
                    handleTextChange={props.handleTextChange}
                    setCorrectResponse={setCorrectResponse}
                    showCorrectResponseCheckBoxes={showCorrectResponseCheckBoxes()}
                  />
                )}
              </div>

              {/*Target*/}
              <div className="col-6">
                {getTargetOptionId(index) && (
                  <QuestionOption
                    key={"QuestionOptionTarget_" + getTargetOptionId(index)}
                    question={question}
                    setQuestion={props.setQuestion}
                    mediaLinks={props.mediaLinks}
                    optionId={getTargetOptionId(index)}
                    uniqueOptionIdsSorted={uniqueTargetOptionIdsSorted}
                    labelText={appObject.textAndMediaTypes.find((x) => x.id === QUESTION_OPTION_TARGET_TYPE_ID)?.name}
                    addElement={() => addElement(templateDataItemTarget!)}
                    removeElement={() => removeElement(templateDataItemTarget!, getTargetOptionId(index)!)}
                    addMedium={addMedium}
                    deleteMedium={deleteMedium}
                    templateDataItem={templateDataItemTarget}
                    handleTextChange={props.handleTextChange}
                    setCorrectResponse={setCorrectResponse}
                    showCorrectResponseCheckBoxes={showCorrectResponseCheckBoxes()}
                  />
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {uniqueSourceOptionIdsSorted.map((optionId: string, index: number) => (
            <QuestionOption
              key={"QuestionOption_" + optionId}
              question={question}
              setQuestion={props.setQuestion}
              mediaLinks={props.mediaLinks}
              optionId={optionId}
              uniqueOptionIdsSorted={uniqueSourceOptionIdsSorted}
              labelText={labelText}
              addElement={() => addElement(templateDataItem)}
              removeElement={() => removeElement(templateDataItem, optionId)}
              addMedium={addMedium}
              deleteMedium={deleteMedium}
              templateDataItem={templateDataItem}
              handleTextChange={props.handleTextChange}
              setCorrectResponse={setCorrectResponse}
              showCorrectResponseCheckBoxes={showCorrectResponseCheckBoxes()}
            />
          ))}
        </>
      )}
    </>
  );
};
export default QuestionOptions;
