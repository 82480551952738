import { Parameter } from '../../api/learnable_search/SearchClient';
import moment from 'moment'

const getLinqOperatorMethodWithNamedParam = (f:any, params?: { [key: string]: Parameter; } | undefined) => {
    const field: string = f.field;
    const operator: string = f.operator
    const value: any = f.value;
    let isNeg = false;
    let isMethod = false;
    let hasParam = true;
    let expression = '';
    switch (operator) {
        case 'eq':
            expression = '==';
            break;        
        case 'neq':
            expression = '!=';
            break;
        case 'gt':
            expression = '>';
            break;            
        case 'gte':
            expression = '>=';
            break;            
        case 'lt':
            expression = '<';
            break;            
        case 'lte':
            expression = '<=';
            break;            
        case 'isnull':
            expression = '.Equals(null)';
            isMethod = true;
            hasParam = false;
            break;
        case 'isnotnull':
            expression = '.Equals(null)';
            isMethod = true;
            hasParam = false;
            isNeg = true;
            break;
        case 'contains':
            expression = '.Contains';
            isMethod = true;
            break;
        case 'doesnotcontain':
            expression = '.Contains';
            isMethod = true;
            isNeg = true;
            break;
        case 'startswith':
            expression = '.StartsWith';
            isMethod = true;
            break;
        case 'endswith':
            expression = '.EndsWith';
            isMethod = true;
            break;
        case 'isempty':
            expression = '.Equals("")';
            isMethod = true;
            hasParam = false;
            break;
        case 'isnotempty':
            expression = '.Equals("")';
            isMethod = true;
            hasParam = false;
            isNeg = true;
            break;
    }
    const valueType: string = typeof value;
    if (expression.length === 0 || (valueType == 'string' && !value)) {
        expression = "true";
    }
    else
    {
        if (hasParam) {
            let paramName = '@@' + field + '@@';
            let paramValue: Parameter = new Parameter();
            if (value !== null && value !== undefined) {
                 switch (valueType) {
                     case 'string':
                         paramValue.stringValue = value;
                         break;
                     case 'number':
                         paramValue.doubleValue = value;
                         break;
                     case 'bigint':
                         paramValue.intValue = value;
                         break;
                     case 'boolean':
                         paramValue.boolValue = value;
                         break;
                     case 'object':
                         if (value instanceof Date) {
                             paramValue.dateTimeValue = moment(value).utcOffset(0, true).toDate();   
                         }
                         else
                         {
                             alert("Method getLinqOperatorMethodWithNamedParam has no support for data type '" + valueType + "'");
                         }
                         break;
                     default:
                         alert("Method getLinqOperatorMethodWithNamedParam has no support for data type '" + valueType + "'");
                         break;
                 }
            }
            // const newParamValueItem: { [key: string]: Parameter; } = {};
            // newParamValueItem[field] = paramValue;
            // params = { ...params, ...newParamValueItem };
            // console.log("params", params);
            if (params == undefined) params={};
            params[field] = paramValue;
            if (isMethod)
                paramName  = '(' + paramName + ')';
            expression += paramName;
        }
        expression = field + expression;
        if (isNeg) expression = '!' + expression;

    }
    return expression;
}

export default getLinqOperatorMethodWithNamedParam;