import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import ClassificationButton from "../common/classifications/ClassificationButton";
import { iClassificationItem } from "../common/classifications/interfaces";
import { useState } from "react";
import { appConfig } from "../../appConfig";
import { ClassificationKey, Service } from "../common/classifications/enums";
import { ConfigProvider } from "../common/classifications/types";
import CustomDialog from "../common/CustomDialog";

const EducationSelector = (props: any) => {
  const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);

  const init = async () => {};

  const toggleDialog = () => {
    props.setShowEducationDialog(false);
  };

  const onOkClick = () => {
    if (selectedClassifications.length === 0) {
      setShowCustomDialog({
        title: "Utbildning",
        isConfirm: true,
        question: "Du har inte valt någon behörighet, forsätta?",
        onConfirm: () => {
          toggleDialog();
          createEducation();
        },
        onClose: () => setShowCustomDialog(null),
      });
    } else {
      createEducation();
    }
  };

  const createEducation = async () => {
    toggleDialog();
    props.onClick(title, selectedClassifications);
  };

  const handleTitleChange = (e: TextBoxChangeEvent) => {
    setTitle(e.value as string);
  };

  const handleClassificationChange = (items: iClassificationItem[]) => {
    console.log("handleClassificationChange", items);
    setSelectedClassifications(items.map((i) => i.identifier!));
  };

  const configProverEducation: ConfigProvider = {
    service: Service.lcms,
    url: appConfig.REACT_APP_LCMS_API_URL as string,
    key: "ClassificationsEducationSelector",
  };

  return (
    <div>
      {showCustomDialog && <CustomDialog {...showCustomDialog} />}

      <Dialog title={"Ny utbildning"} onClose={toggleDialog} width={350}>
        <div className="row">
          <div className="col-12">Titel:</div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <TextBox onChange={handleTitleChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ClassificationButton
              configProvider={configProverEducation}
              filterProvider={undefined}
              classificationKey={ClassificationKey.behorigheter}
              configurationClassificationOptional={undefined}
              onOkClick={handleClassificationChange}
              selectedIds={selectedClassifications ?? []}
              readOnly={false}
            />
          </div>
        </div>

        <DialogActionsBar>
          <Button
            disabled={title === ""}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={onOkClick}
          >
            Ok
          </Button>
          <Button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"
            onClick={toggleDialog}
          >
            Avbryt
          </Button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};
export default EducationSelector;
