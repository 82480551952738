import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
  TreeViewExpandChangeEvent,
  TreeViewCheckDescriptor,
} from "@progress/kendo-react-treeview";

import SelectedChipList from "./SelectedChipList";
import { ChipList, ChipProps, ChipRemoveEvent } from "@progress/kendo-react-buttons";
import { Checkbox, CheckboxChangeEvent, TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { iClassificationItem, iLearningObjectiveItem } from "./interfaces";
import { ClassificationContainer, FilterProvider, FilterValues } from "./types";
import { trimString } from "./helperFunctions";
import ClassificationButton from "./ClassificationButton";

interface ClassificationsProps {
  readOnly: boolean;
  checkChildren: boolean; // 0 = select mode, 1 = search mode
  onClose: () => void;
  onApply: (data: iClassificationItem[]) => void;
  //   onCheckChange: (item: iClassification) => void;
  //   onChipRemove: (item: iClassification) => void;
  classificationContainer: ClassificationContainer;
  title: string;
  filterProvider: FilterProvider | undefined;
}

export function ClassificationsDialog({
  readOnly,
  checkChildren,
  classificationContainer,
  title,
  filterProvider,
  onClose,
  onApply,
}: ClassificationsProps) {
  //https://dev.to/fabiobiondi/create-a-react-typescript-generic-component-2dal

  // const filterProvider: FilterProvider | undefined =
  //   props.filterProvider as FilterProvider;

  const SUB_ITEMS_FIELD: string = "items";
  const searchRef = React.useRef<any>();
  const [checkedItems, setCheckedItems] = React.useState<iClassificationItem[]>([]);
  const [selectedFilterClassifications, setSelectedFilterClassifications] = React.useState<iClassificationItem[]>([]);
  const [treeData, setTreeData] = React.useState<iClassificationItem[]>([]);
  const [filter, setFilter] = React.useState<FilterValues>({
    level: null,
    type: null,
    showOnlyNotIsUsed: filterProvider ? filterProvider.defaultValueNotIsUsed : false,
    LearningObjectiveClassifications: [],
  });

  const [check, setCheck] = React.useState<TreeViewCheckDescriptor>({
    ids: [],
    applyCheckIndeterminate: true,
    idField: "id",
  });

  // const [check, setCheck] = React.useState<TreeViewCheckDescriptor>({
  //   ids: classificationContainer?.selected.map((x) => x.id),
  //   applyCheckIndeterminate: true,
  //   idField: "id",
  // });

  const [expand, setExpand] = React.useState<any>({
    ids: [],
    idField: "id",
  });

  const getVisibleTree = () => {
    let tree: iClassificationItem[] = [];
    classificationContainer?.tree!.forEach((root) => {
      if (root.hidden) {
        tree = tree.concat(root.items);
      } else {
        tree.push(root);
      }
    });

    return tree;
  };
  // console.log("filter", filter, filterProvider);
  console.log("classificationContainer dialog", classificationContainer);

  useEffect(() => {
    if (!classificationContainer) {
      return;
    }

    let classificationItems = getVisibleTree();
    if (filterProvider && filterProvider.showOnlyNotIsUsed && filterProvider.defaultValueNotIsUsed) {
      classificationItems = classificationItems.filter((x: any) => x.isUsed === false) as iClassificationItem[];
    }
    setTreeData(classificationItems);
    const items: iClassificationItem[] = getCheckedItemsFromList(
      classificationContainer?.selected.map((x) => x.id)
    ) as iClassificationItem[];
    setCheckedItems(items);

    setCheck({
      ids: classificationContainer?.selected.map((x) => x.id),
      applyCheckIndeterminate: true,
      idField: "id",
    });
  }, [classificationContainer]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    event.item.expanded = !event.item.expanded;
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.id);

    index === -1 ? ids.push(event.item.id) : ids.splice(index, 1);
    setExpand({ ids, idField: "id" });
  };

  const getCheckedItemsFromList = (ids: any[] | undefined) => {
    return ids?.map((id: any) => {
      return classificationContainer.list!.find((x: any) => x.id === id);
    });
  };

  const onCheckChange = (event: TreeViewExpandChangeEvent) => {
    if (readOnly) {
      return;
    }
    const settings = {
      singleMode: false,
      checkChildren: checkChildren,
      checkParents: false,
    };
    var checkedItems = handleTreeViewCheckChange(event, check, treeData, settings) as TreeViewCheckDescriptor;

    setCheck(checkedItems);
    const items: iClassificationItem[] = getCheckedItemsFromList(checkedItems.ids) as iClassificationItem[];
    setCheckedItems(items);
    console.log("onCheckChange", checkedItems, items);
  };

  const onOkClicked = () => {
    onApply(checkedItems);
  };

  const handleChipRemove = (event: ChipRemoveEvent) => {
    let checkedIds = check.ids!.filter((x) => x !== event.target.props.dataItem.id);
    setCheck({ ...check, ids: checkedIds });
    setCheckedItems(getCheckedItemsFromList(checkedIds) as iClassificationItem[]);
  };

  const MyChip = (props: ChipProps) => <SelectedChipList {...props} onRemove={handleChipRemove} />;

  const handleSearch = (e: TextBoxChangeEvent) => {
    let value: string = e.target.value as string;

    //om vi har filter ska sökning göras på filtrerad data
    const searchTree = hasFilter() ? filterTree(getVisibleTree()) : getVisibleTree();
    // const searchTree = getVisibleTree();
    const foundItems = search(searchTree, value);
    setTreeData(foundItems);
  };

  const handleClearSelection = () => {
    setCheck({ ...check, ids: [] });
    setCheckedItems([]);
  };
  /*https://www.telerik.com/kendo-react-ui/components/knowledge-base/treeview-search/*/
  const search = (items: any, term: string) => {
    return items.reduce((acc: any, item: any) => {
      if (contains(item.name, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        let newItems = search(item.items, term);
        if (newItems && newItems.length > 0) {
          acc.push({
            name: item.name,
            items: newItems,
            expanded: item.expanded,
          });
        }
      }
      return acc;
    }, []);
  };
  const hasFilterValue = (item: any) => {
    let result: boolean = true;
    // if (filter.isNotUsed) {
    //   const isMatch = !item.isUsed;
    //   if (!isMatch) return false;
    // }
    if (filter.type) {
      const isMatch = item.typeId === filter.type;
      if (!isMatch) return false;

      // result = isTrue ? true : false;
    }
    if (filter.level) {
      const isMatch = item.levelId === filter.level;
      if (!isMatch) return false;
    }
    if (filter.LearningObjectiveClassifications.length > 0) {
      //behöver vi använda rootIds här?
      // const rootIds: string[] = [
      //   "511fe8ee-2751-43c4-980b-6f66ef4a1e73",
      //   "1d5305cf-09e2-45dc-adfe-fac74c17af56",
      //   "9620171b-a0f2-4fd9-9fd3-1fb9b0136121",
      // ];
      let indicatorClassifications = item.learningObjectiveClassifications.map((x: any) => x.classificationId);

      let found = indicatorClassifications.some((x: any) => filter.LearningObjectiveClassifications.includes(x));

      if (!found) {
        return false;
      }
    }
    return result;
  };
  const filterTree = (items: any) => {
    return items.reduce((acc: any, item: any) => {
      if (hasFilterValue(item)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        let newItems = filterTree(item.items);
        if (newItems && newItems.length > 0) {
          acc.push({
            name: item.name,
            items: newItems,
            expanded: item.expanded,
          });
        }
      }
      return acc;
    }, []);
  };

  const contains = (text: any, term: string) => {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  };

  const cancelDialog = () => {
    onClose();
  };

  const getItemStyle = (item: any) => {
    // if (!item.isUsed && item.typeId) {
    //   const found = classificationContainer.selected.find(
    //     (x) => x.identifier === item.identifier
    //   );
    //   return {
    //     border: found ? "none" : "solid 1.5px",
    //     borderRadius: "2px",
    //     borderColor: LearningObjectiveTypeColor[item.typeId],
    //     margin: "4px",
    //     padding: "2px",
    //   };
    // }
    return {};
  };

  const customItem = (treeItemProps: any) => {
    return (
      <>
        <span title={treeItemProps.item.name} style={getItemStyle(treeItemProps.item)}>
          {" "}
          {readOnly ? treeItemProps.item.name : trimString(treeItemProps.item.name, 90)}
        </span>
      </>
    );
  };

  const handleFilterClick = (filterValues: any) => {
    // console.log("filter values", filter);
    // console.log("handleFilterClick", getVisibleTree(), filterValues);
    let foundItems =
      filter.level || filter.type || filter.LearningObjectiveClassifications.length > 0
        ? filterTree(getVisibleTree())
        : getVisibleTree();
    if (filter.showOnlyNotIsUsed) {
      foundItems = foundItems.filter((x: iLearningObjectiveItem) => x.isUsed === false);
    }
    // if (searchRef.current!.value) {
    //   foundItems = search(foundItems, searchRef.current!.value);
    // }
    // setFilter(filterValues);
    setTreeData(foundItems);
  };
  const handleClearFilterClick = () => {
    // console.log("handleClearFilterClick");
    const newFilter = { ...filter };
    newFilter.level = null;
    newFilter.type = null;
    newFilter.LearningObjectiveClassifications = [];
    setFilter(newFilter);
  };
  const hasFilter = () => {
    if (!filterProvider) {
      return false;
    }
    if (!filterProvider.showLevel && !filterProvider.showType) {
      return false;
    }
    return true;
  };
  const countItemsInTree = (items: any) => {
    let count = 0;
    items.forEach((item: any) => {
      count++;
      if (item.items && item.items.length > 0) {
        count += countItemsInTree(item.items);
      }
    });
    return count;
  };

  const treeviewMinHeight: number = 100;
  const factorDialog: number = 0.95;
  const [dialogHeight, setDialogHeight] = useState(window.innerHeight * factorDialog);

  // fixed factor for treeview height (filter, search, antal, buttons)
  const fixedOccupiedHeight: number = hasFilter() ? 300 : 200;
  // const fixedOccupiedHeight: number = 200;
  const [treeviewHeight, setTreeviewHeight] = useState(dialogHeight - fixedOccupiedHeight);

  // Function to handle window resize
  const handleResize = () => {
    let newDialogHeight = window.innerHeight * factorDialog;

    // fixed
    let newTreeviewHeight = newDialogHeight - fixedOccupiedHeight;

    // console.log("handleResize", newDialogHeight, newTreeviewHeight);
    setDialogHeight(newDialogHeight);
    setTreeviewHeight(newTreeviewHeight);
  };
  const handleClassificationChange = (selectedItems: iClassificationItem[]) => {
    console.log("handleClassificationChange", selectedItems);
    setFilter({
      ...filter,
      LearningObjectiveClassifications: selectedItems.map((x) => x.identifier),
    });
  };
  return (
    <>
      <Dialog title={title ?? "Välj"} onClose={onClose} width="60vw" height={`${dialogHeight}px`}>
        {/* SÖK */}
        <div className="row mb-1">
          {filterProvider && filterProvider.showLevel && (
            <div className="col-2">
              <ComboBox
                name="Level"
                textField="name"
                dataItemKey="id"
                data={filterProvider.filterLevelData}
                label="Nivå"
                // {...getFilterAttribute("label", "Nivå")}
                onChange={(event: ComboBoxChangeEvent) =>
                  setFilter({
                    ...filter,
                    level: event.target.value ? event.target.value.id : undefined,
                  })
                }
                value={filterProvider.filterLevelData.find((x) => x.id === filter.level) ?? null}
                // value={getFilterValues()}
              />
            </div>
          )}

          {filterProvider && filterProvider.showType && (
            <div className="col-2">
              <ComboBox
                name="Type"
                textField="name"
                dataItemKey="id"
                data={filterProvider.filterTypeData}
                label="Typ"
                // {...getFilterAttribute("label", "Typ")}
                onChange={(event: ComboBoxChangeEvent) =>
                  setFilter({
                    ...filter,
                    type: event.target.value ? event.target.value.id : undefined,
                  })
                }
                value={filterProvider.filterTypeData.find((x) => x.id === filter.type) ?? null}
              />
            </div>
          )}
          {filterProvider && filterProvider.showEducation && (
            <div className="col" style={{ position: "relative" }}>
              {/* {props.filterControls && ( */}
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <ClassificationButton
                  title={"Utbildningar" + " (" + filter.LearningObjectiveClassifications.length + ")"}
                  configProvider={filterProvider.configClassificationProvider!}
                  filterProvider={undefined}
                  onOkClick={(selectedItems: iClassificationItem[]) => handleClassificationChange(selectedItems)}
                  classificationKey={filterProvider.classificationKey!}
                  configurationClassificationOptional={undefined}
                  readOnly={false}
                  selectedIds={filter.LearningObjectiveClassifications}
                  // filterControls={props.filterControls}
                  //  classificationContainer={props.filterClassification}
                  //  dialogTitle={"Utbildningar"}
                />
              </div>
              {/* )} */}
            </div>
          )}
          {filterProvider && filterProvider.showOnlyNotIsUsed && (
            <div className="col-4 mt-3" style={{ alignContent: "center" }}>
              <Checkbox
                label="Visa endast ej använda"
                defaultChecked={filter.showOnlyNotIsUsed}
                onChange={(event: CheckboxChangeEvent) => {
                  setFilter({ ...filter, showOnlyNotIsUsed: event.value });
                }}
              />
            </div>
          )}
        </div>

        {filterProvider && (
          <div className="row">
            <div className="col-12">
              <Button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleFilterClick(e)}
                className="ms-1 mt-1 mb-2"
                themeColor={"primary"}
              >
                Filtrera
              </Button>
              <Button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClearFilterClick()}
                className="ms-1 mt-1 mb-2"
                themeColor={"primary"}
              >
                Rensa filter
              </Button>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-6">
            <div className="mt-2 mb-1" style={{ display: "flex" }}>
              <div className="me-2" style={{ alignSelf: "center" }}>
                Sök:
              </div>
              <div style={{ flex: 1 }}>
                <TextBox
                  className="k-textbox"
                  onChange={(e: TextBoxChangeEvent) => handleSearch(e)}
                  onKeyDown={(e) => e.stopPropagation()}
                  rounded={"medium"}
                  ref={searchRef}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div
              className="mt-2 mb-1"
              style={{
                display: "inline-block",
              }}
            >
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={handleClearSelection}
              >
                Rensa val
              </button>
            </div>
          </div>
        </div>
        {/* SÖK slut */}

        {/* GUL BOX */}
        <div className="row">
          <div className={readOnly ? "col-12" : "col-6"}>
            <div
              style={{
                backgroundColor: "lightyellow",
                borderStyle: "dotted",
                borderWidth: "1px",
                borderRadius: 10,
                borderColor: "grey",
                overflow: "scroll",
                height: `${treeviewHeight}px`,
                maxHeight: `${treeviewHeight}px`,
                minHeight: `${treeviewMinHeight}px`,
              }}
              className=" mb-2"
            >
              {/* <span>hepp!</span> */}
              {treeData.length > 0 && (
                <TreeView
                  textField="name"
                  expandIcons={true}
                  checkboxes={!readOnly}
                  childrenField={SUB_ITEMS_FIELD}
                  onCheckChange={onCheckChange}
                  onExpandChange={onExpandChange}
                  data={processTreeViewItems(treeData, {
                    check: check,
                    expand: expand,
                  })}
                  onItemClick={onCheckChange}
                  item={customItem}
                />
              )}
            </div>
          </div>
          {/* GUL BOX slut */}

          {/* GRÖN BOX */}
          {!readOnly && (
            <div className="col-6">
              <div
                style={{
                  backgroundColor: "honeydew",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: 10,
                  borderColor: "grey",
                  overflowX: "scroll",
                  height: `${treeviewHeight}px`,
                  maxHeight: `${treeviewHeight}px`,
                  minHeight: `${treeviewMinHeight}px`,
                }}
              >
                <ChipList
                  selection="none"
                  chip={MyChip}
                  style={{ maxWidth: 200 }}
                  valueField="id"
                  data={checkedItems}
                />
              </div>
            </div>
          )}
          {/* GRÖN BOX slut */}
        </div>

        <div className="row">
          <div className="mb-2">Antal: {countItemsInTree(treeData)}</div>
        </div>

        <div className="row">
          <div className="align-bottom">
            <DialogActionsBar layout="end">
              <button
                style={{ width: "150px" }}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={onOkClicked}
              >
                Ok
              </button>
              <button
                style={{ width: "150px" }}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"
                onClick={cancelDialog}
              >
                Avbryt
              </button>
            </DialogActionsBar>
          </div>
        </div>
      </Dialog>
    </>
  );
}
