import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Pager, PageChangeEvent } from "@progress/kendo-react-data-tools";
import ImageSelector from "./ImageSelector";
import { Button } from "@progress/kendo-react-buttons";
import { appConfig } from "../../appConfig";
import { GetAssetResponse } from "../../api/optima_image/OptimaImageClient";
import axios from "axios";
import { iPageHeader } from "../../interfaces/iPageHeader";
import { AuthenticationResult } from "@azure/msal-browser";
import { getMesToken } from "../../auth/getAccessToken";
// import { iImageLink } from "../../interfaces/iMediaLink";
import { getMediaLinkAsJson } from "../question/edit/functions/questionDtoFunctions";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import VideoSelector from "./VideoSelector";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { iImageLink, iVideo } from "./interfaces";
import { MediaContainer } from "./types";

//import { ImagesDto } from '../../api/optima_image/OptimaImageClient';
const ImageDialog = (props: any) => {
  //TODO: Media Service
  /*const [newSelectedImages, setNewSelectedImages] = React.useState<ImagesDto[]>([]);*/
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [newSelectedImages, setNewSelectedImages] = React.useState<any[]>([]);

  const [images, setImages] = React.useState<GetAssetResponse[]>([]);
  const defaultHeader = {
    currentPage: 0,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0,
    hasNext: false,
    hasPrevious: false,
  };
  const [pageHeader, setPageHeader] = React.useState<iPageHeader>(defaultHeader);
  const showVideoSelector: boolean = props.showVideoSelector ?? false;
  const treeviewMinHeight: number = 100;
  const factorDialog: number = 0.95;
  const [dialogHeight, setDialogHeight] = useState(window.innerHeight * factorDialog);
  const fixedOccupiedHeight: number = 300;
  const [imageContainerHeight, setImageContainerHeight] = useState(dialogHeight - fixedOccupiedHeight);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount
  // Function to handle window resize
  const handleResize = () => {
    let newDialogHeight = window.innerHeight * factorDialog;

    // percent
    // let newTreeviewHeight = props.filterControls
    //   ? newDialogHeight * factorWithFilter
    //   : newDialogHeight * factorWithoutFilter;

    // fixed
    let newImageContainerHeight = newDialogHeight - fixedOccupiedHeight;

    // console.log("handleResize", newDialogHeight, newTreeviewHeight);
    setDialogHeight(newDialogHeight);
    setImageContainerHeight(newImageContainerHeight);
  };
  const onPageChange = (event: PageChangeEvent) => {
    let newPage: iPageHeader = { ...pageHeader };
    newPage.pageSize = event.take;
    newPage.currentPage = (event.skip + event.take) / event.take - 1;
    // console.log("event", newPage, event);
    setPageHeader(newPage);
    fetchImages(newPage);
  };

  //const getToken = async () => {
  //    const instance = App.pca;
  //    //const account = instance.getActiveAccount();
  //    const accounts = instance.getAllAccounts();
  //    console.log("accounts", accounts);

  //    const account = accounts[0];

  //    if (!account) throw new Error('No active account');

  //    const accessTokenRequest: SilentRequest = {
  //        scopes: protectedResources.apiMES.scopes,
  //        account: account
  //    };

  //    let accessToken: AuthenticationResult = await App.pca.acquireTokenSilent(accessTokenRequest)
  //    return accessToken.accessToken;
  //}

  const fetchImages = async (pageHeader: iPageHeader) => {
    let url = `${appConfig.REACT_APP_MEDIA_API_URL}/v1/Media/find?searchTerm=${searchValue}`;
    url += `&pageIndex=${pageHeader.currentPage}&pageSize=${pageHeader.pageSize}`;

    let token: AuthenticationResult = await getMesToken();

    // console.log(url);
    axios
      .get(url, { headers: { Authorization: `Bearer ${token.accessToken}` } })
      .then((response) => {
        // Access response headers
        const pageHeader: iPageHeader = JSON.parse(response.headers["x-pagination"]);
        setPageHeader(pageHeader);
        //console.log('pageHeader', pageHeader);

        // Access response data
        const images: GetAssetResponse[] = response.data;
        setImages(images);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  //const fetchImages = async () => {
  //    console.log("text", searchValue)
  //    let client = new OptimaImageClient(appConfig.REACT_APP_OPTIMA_MEDIA_API_URL);

  //    let result = await client.mediaFind(searchValue, undefined, undefined);
  //    console.log("result", result)

  //    setImages(result);
  //}

  const searchImages = async () => {
    if (!searchValue || searchValue.length === 0) {
      return;
    }

    await fetchImages(defaultHeader);
  };

  const onSearchChange = (e: TextBoxChangeEvent) => {
    const newValue = e.value as string;
    setSearchValue(newValue!);
  };

  const onImageClick = (image: GetAssetResponse) => {
    console.log(image);

    let mediaLink: iImageLink = getMediaLinkAsJson(image);

    // do stuff here..
    const newMediaContainer: MediaContainer = { imageLinks: [mediaLink], videos: undefined } as MediaContainer;
    props.onOkClick(newMediaContainer);
  };

  const onSearchKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchImages();
    }
  };

  //const handlePageChange = (event: PageChangeEvent) => {
  //    console.log(event);
  //    let newPage: iPageHeader = { ...pageHeader };
  //    newPage.currentPage = ((event.skip + event.take) / event.take) - 1;

  //    props.onPageChange(newPage);
  //}

  const imageSizes: number[] = [300, 600, 1200];
  const [selectedImageSize, setSelectedImageSize] = useState<number>(imageSizes[0]);
  const [selected, setSelected] = React.useState<number>(0);
  const [video, setVideo] = React.useState<iVideo>({ name: "", linkTypeId: 2, source: "" });
  const onImagSizeChange = (event: DropDownListChangeEvent) => {
    setSelectedImageSize(event.target.value as number);
  };
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };
  // console.log(
  //   "dialogHeight",
  //   dialogHeight,
  //   imageContainerHeight,
  //   window.innerHeight,
  //   factorDialog,
  //   fixedOccupiedHeight
  // );
  return (
    <>
      <Dialog title={"Välj media"} onClose={props.onClose} width="80vw" height={`${dialogHeight}px`}>
        <div
          style={{
            marginRight: 10,
            // maxHeight: "80vh",
          }}
        >
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="Bilder">
              <div className="row mb-2">
                <div className="col-12">
                  <div style={{ display: "inline-block" }}>
                    <div>Sök:</div>
                    <TextBox
                      onKeyDown={(e: any) => onSearchKeyDown(e)}
                      onChange={(e: TextBoxChangeEvent) => onSearchChange(e)}
                      value={searchValue}
                      style={{ width: "300px" }}
                      className="k-textbox"
                      rounded={"medium"}
                    />
                  </div>

                  <div style={{ display: "inline-block" }} className="ms-3">
                    <Button
                      onClick={() => searchImages()}
                      icon="search"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    />
                  </div>

                  <div style={{ display: "none" }} className="ms-3">
                    <div>Storlek:</div>
                    <DropDownList
                      data={imageSizes}
                      onChange={onImagSizeChange}
                      style={{ width: "150px" }}
                      value={selectedImageSize}
                    />
                  </div>
                </div>
              </div>
              {images.length > 0 && (
                <div
                  style={{
                    borderStyle: "dotted",
                    borderWidth: "1px",
                    borderRadius: 10,
                    borderColor: "grey",
                    overflow: "auto",
                    marginRight: 10,
                    // minWidth: "50vw",
                    // maxWidth: "70vw",
                    height: imageContainerHeight,
                  }}
                  className=" mb-2"
                >
                  <ImageSelector
                    selectedImageSize={selectedImageSize}
                    images={images}
                    onImageClick={onImageClick}
                    pageHeader={pageHeader}
                    onPageChange={onPageChange}
                    cols={dialogHeight < 800 ? 4 : 6}
                    {...props}
                  />
                </div>
              )}
            </TabStripTab>
            {showVideoSelector && (
              <TabStripTab title="Video">
                <div
                  style={{
                    borderStyle: "dotted",
                    borderWidth: "1px",
                    borderRadius: 10,
                    borderColor: "grey",
                    overflow: "auto",
                    marginRight: 10,
                    minWidth: "50vw",
                    maxWidth: "90vw",
                    height: "75vh",
                  }}
                >
                  <VideoSelector setVideo={setVideo} video={video} onOkClick={props.onOkClick} />
                </div>
              </TabStripTab>
            )}
          </TabStrip>
        </div>

        {/*<DialogActionsBar>*/}
        {/*    <button*/}
        {/*        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
        {/*        onClick={handleOkClick}*/}

        {/*    >*/}
        {/*        Ok*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"*/}
        {/*        onClick={props.onClose}*/}
        {/*    >*/}
        {/*        Avbryt*/}
        {/*    </button>*/}

        {/*</DialogActionsBar>*/}
        {selected === 0 && (
          <Pager
            skip={pageHeader.currentPage - 1}
            take={pageHeader.pageSize}
            total={pageHeader.totalCount}
            pageSizes={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        )}
      </Dialog>
    </>
  );
};

export default ImageDialog;
