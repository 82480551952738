import { DynamicLinqRequest, Parameter } from "../../api/learnable_search/SearchClient";

import { iInfo } from "../../interfaces/iInfo";
import { HIDDEN_ROOT_NODES } from "./Constants";
import { ClassificationType } from "./Enums";
import { iClassificationItem, iConfiguration, iConfigurationClassification } from "./classifications/interfaces";
import { ClassificationContainer } from "./classifications/types";

/*https://dev.to/danielbellmas/build-a-tree-array-from-a-flat-array-recursion-32jn*/
export const arrayToTree: any = (arr: any[], parent: number | null | undefined) => {
  return arr
    .filter((item) => item.parentId == parent)
    .map((child) => ({
      ...child,
      items: arrayToTree(arr, child.id),
    }));
};

// om man vill parent ska vara ett object
//export const arrayToTree: any = (arr: any[], parent: any) => {
//    return arr.filter(item => item.parentId === parent.id)
//        .map(child => ({
//            ...child, items: arrayToTree(arr, child)
//        }));
//}

export const rootNodeShouldBeHidden = (node: iClassificationItem) => {
  //console.log("rootNodeShouldBeHidden", hiddenRootNodes, node);
  if (HIDDEN_ROOT_NODES.includes(node.rootId!)) {
    return true;
  }

  return false;
};

// export const getNewSemanticVersion = (currentSemanticVersion: string | undefined) => {
//   if (appConfig.REACT_APP_ASSESSMENT_INCREMENT_SEMANTIC_VERSION === "false") {
//     return "1.0.0";
//   }

//   if (!currentSemanticVersion) {
//     return "1.0.0";
//   }

//   let parts = currentSemanticVersion.split(".");
//   let major = parseInt(parts[0]);
//   let minor = parseInt(parts[1]);
//   let patch = parseInt(parts[2]);
//   patch++;

//   // TODO: nedan �r korrekt men tillsvidare k�r vi bara patch
//   //if (patch < 9) {
//   //    patch++;
//   //}
//   //else if (minor < 9) {
//   //    minor++;
//   //    patch = 0;
//   //}
//   //else {
//   //    major++;
//   //    minor = 0;
//   //    patch = 0;
//   //}

//   return `${major}.${minor}.${patch}`;
// };

export const getClassificationRootIds = (configurationData: iConfiguration): string[] => {
  let rootIds: string[] = [];
  if (!configurationData.classifications) return rootIds;
  configurationData.classifications.forEach((classification: any) => {
    classification.rootIds?.forEach((rootId: string) => {
      rootIds.push(rootId);
    });
  });
  if (configurationData.learningObjectives) {
    rootIds.push(configurationData.learningObjectives.rootId!);
  }

  return rootIds;
};

export const getClassificationContainerCopyByRootIds = (
  rootIds: string[],
  classificationContainer: ClassificationContainer
): ClassificationContainer => {
  let copy: ClassificationContainer = { ...classificationContainer! };
  copy.tree = classificationContainer!.tree!.filter((x) => rootIds.includes(x.rootId!));

  copy.tree.forEach((root) => {
    root.hidden = rootNodeShouldBeHidden(root);
  });

  let filteredSelected = classificationContainer!.selected!.filter((x) => rootIds.includes(x.rootId!));
  copy.selected = filteredSelected!.map((x) => ({ ...x })) as iClassificationItem[];
  return copy;
};

/**
 * Returns the root ids for specific classification key stored in config table
 */
export const getConfigRootIdsByClassificationKey = (configData: iConfiguration, classificationKey: string) => {
  // console.log("getConfigRootIdsByClassificationKey", configData, classificationKey);
  if (!configData) return [];
  const configClassification = configData.classifications!.find((x) => x.key == classificationKey);
  return configClassification!.rootIds;
};

export const createClassificationsQuery = (
  configuration: iConfiguration,
  table: string,
  column: string,
  key: string,
  alias: string,
  searchRequest: DynamicLinqRequest
) => {
  let rootIds: string[] =
    configuration && configuration.classifications
      ? configuration.classifications.find((c: any) => c.key === key)?.rootIds!
      : [];
  let query: string = "";
  rootIds.forEach((r, index: number) => {
    let param: string = `${key}${index}`;

    if (query.length == 0) {
      query += `c.${table}.Where(w => w.${column} == @@${param}@@`;
    } else {
      query += ` || w.${column} == @@${param}@@`;
    }

    if (rootIds.length == index + 1) {
      query += `).Select(s => s.ClassificationId) as ${alias},`;
    }

    searchRequest.parameters![param] = new Parameter({
      guidValue: r,
    });
  });

  return query;
};

export const getCurrentPublishStatusText = (publishStatus: number | undefined) => {
  if (!publishStatus) {
    return "aldrig publicerad";
  }

  if (publishStatus === 0) {
    // None
    return "aldrig publicerad";
  } else if (publishStatus === 1) {
    // Dirty
    return "äldre version publicerad";
  } else if (publishStatus === 2) {
    // Pending
    return "publicering pågår..";
  } else if (publishStatus === 3) {
    // Success
    return "publicerad";
  } else if (publishStatus === 4) {
    // Failure
    return "publicering misslyckades";
  }

  console.log(`Unknown publish status ${publishStatus}`);
  return "okänd status";
};

export const getInfoCopy = (info: iInfo): iInfo => {
  let newInfo = { ...info! } as iInfo;
  newInfo.selectedItems = info!.selectedItems!.map((x) => ({ ...x })) as iClassificationItem[];
  newInfo.visibleElements = info!.visibleElements!.map((x) => x) as string[];

  return newInfo;
};

const translationDictionary: { [key: string]: any } = {};

export const getTranslation = (key: string): string => {
  if (Object.keys(translationDictionary).length > 0) {
    return translationDictionary[key];
  }

  translationDictionary["Unspecified"] = "";
  translationDictionary["SuccessFailure"] = "Success/Failure";
  translationDictionary["Responses"] = "Options";

  return translationDictionary[key];
};

export const getConfigurations = (
  configData: iConfiguration,
  type: ClassificationType
): iConfigurationClassification => {
  return configData?.classifications?.find((x) => x.key === ClassificationType[type]) as iConfigurationClassification;
};
