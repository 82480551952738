import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { cardActionsLayout } from "@progress/kendo-react-layout/dist/npm/card/interfaces/Enums";
import { Button } from "@progress/kendo-react-buttons";
import { ReactNode } from "react";

export interface WhereUsedDialogProps {
  resultPanel: ReactNode;
  onClose: () => void;
}

const WhereUsedDialog = ({ resultPanel, onClose }: WhereUsedDialogProps) => {
  return (
    <>
      <Dialog title={"Används i:"} minWidth="40vw" onClose={onClose}>
        <div>{resultPanel}</div>
        <DialogActionsBar layout={cardActionsLayout.END}>
          <Button
            themeColor={"primary"}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={onClose}
          >
            Stäng
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default WhereUsedDialog;
