import { useState, useEffect, useCallback } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ProseMirror } from "@progress/kendo-react-editor";
import {
  AddQuestionToInteractionComponent,
  GetHtmlFromInteractionComponent,
  GetInteractionComponentFromHTML,
  InteractionComponent,
  interactionClassName,
} from "./InteractionComponentTool";
import { Client, InteractionResponse } from "../../../../../api/Client";
import { Button } from "@progress/kendo-react-buttons";
import { openQuestionPreview } from "../../../../question/edit/functions/helperFunctions";
import { GetQuestionText, Templates, GetQuestionTypeName } from "../common/utilities";
import SelectInteractionDialog from "../../../../interaction/edit/SelectInteractionDialog";
import { appConfig } from "../../../../../appConfig";

const { DOMParser: DOMParserObj, DOMSerializer: DOMSerializerObj } = ProseMirror;

export const InteractionComponentDialog = (props: any) => {
  const [item, setItem] = useState<InteractionComponent | undefined>(undefined);
  const [ineractionResponses, setIneractionResponses] = useState<InteractionResponse[]>([]);
  const [isNew, setIsNew] = useState(false);
  const { nodeAndPos, editorSchema } = props;
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [validQuestion, setValidQuestion] = useState<boolean | undefined>(undefined);

  // Read
  useEffect(() => {
    const serializer = DOMSerializerObj.fromSchema(editorSchema);
    const htmlDom = serializer!.serializeNode(nodeAndPos.node);
    let s = new XMLSerializer();
    let htmlContent = s.serializeToString(htmlDom);
    let tagIsNew = false;
    let interactionComponent: InteractionComponent = new InteractionComponent();
    // New component
    if (
      !nodeAndPos.node.attrs?.class ||
      !nodeAndPos.node.attrs.class.split(" ").includes("str-component-" + interactionClassName)
    ) {
      if (nodeAndPos.node.type.isBlock) htmlContent = "";
      tagIsNew = true;
    } else {
      // Update component
      interactionComponent = GetInteractionComponentFromHTML(htmlContent);
    }
    setItem(interactionComponent);
    setIsNew(tagIsNew);
  }, [nodeAndPos, editorSchema]);

  // Select question
  const onSelectInteraction = useCallback(
    async (interactionRow: any) => {
      const client = new Client(appConfig.REACT_APP_LCMS_API_URL as string);
      //TODO: Ta bort hårdkodad id när vi testar med fungerande search
      const id = interactionRow.id;
      const interaction = await client.interactionComponentGet(id, undefined);
      const newItem = await AddQuestionToInteractionComponent(
        new InteractionComponent({ ...item, questionid: interaction.interactionStructure!.questionid })
      );
      newItem.id = interaction.interactionStructure!.id;
      newItem.subtype = interaction.interactionStructure!.subType;
      newItem.title = interaction.interactionStructure!.title;
      newItem.description = interaction.interactionStructure!.description;
      console.log("newItem", newItem);
      setItem(newItem);
      setAllowSubmit(true);
      onClose();
    },
    [item]
  );

  const onClose = () => {
    setVisibleDialog(false);
  };

  // Submit
  const handleSubmit = useCallback(async () => {
    const domParser = new DOMParser();
    // Create a new DOM node from the HTML string
    const domNode = domParser.parseFromString(await GetHtmlFromInteractionComponent(item!), "text/html");
    // Create a new `DOMParser` instance with the schema
    const parser = DOMParserObj.fromSchema(editorSchema);
    // Parse the DOM node and create a ProseMirror node from it
    const newNode = parser.parse(domNode);
    props.onSubmit({ newNode, nodeAndPos });
  }, [item, editorSchema, nodeAndPos]);

  // Delete
  const deleteClick = () => {
    props.onSubmit({ htmlContent: "", nodeAndPos });
  };

  return (
    <>
      <Dialog title={"Interaktion"} onClose={props.onCancel} width="80vw" height="80vh" style={{ overflowY: "scroll" }}>
        <fieldset className={"k-form-fieldset"}>
          <div className="row mb-2">
            <div className="col-12">
              <button
                type="button"
                className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
                onClick={() => setVisibleDialog(true)}
              >
                Interaktion...
              </button>
              {visibleDialog && <SelectInteractionDialog onSelectInteraction={onSelectInteraction} onClose={onClose} />}
            </div>
          </div>
          {item?.id && (
            <>
              <div className="row mb-2">
                <div className="col-1">Id</div>
                <div className="col-11">{item?.id}</div>
              </div>
              <div className="row mb-2">
                <div className="col-1">Interaktionstyp</div>
                <div className="col-11">
                  {Templates.find((x) => x.id === item?.questionDto?.interactionTypeId)?.name}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-1">Titel</div>
                <div className="col-11">{item?.title}</div>
              </div>
              <div className="row mb-2">
                <div className="col-1">Beskrivning</div>
                <div className="col-11">{item?.description}</div>
              </div>
              <div className="row mb-2">
                <div className="col-1">Fråga</div>
                <div className="col-11">
                  <Button
                    themeColor="primary"
                    style={{ marginRight: "10px" }}
                    size="small"
                    icon="preview"
                    hidden={!item?.questionDto?.identity}
                    onClick={() => openQuestionPreview(item?.questionDto?.identity!)}
                  />
                  {GetQuestionText(item?.questionDto)}
                  {GetQuestionTypeName(
                    item?.questionDto?.interactionTypeId,
                    item?.questionDto?.subQuestionTypeId,
                    true
                  )}
                </div>
              </div>
            </>
          )}
        </fieldset>
        <DialogActionsBar>
          <div style={{ textAlign: "right" }}>
            {!isNew && (
              <button
                type="button"
                className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-danger k-rounded-md"
                onClick={deleteClick}
              >
                Ta bort
              </button>
            )}

            <button
              type={"button"}
              disabled={!allowSubmit}
              className="k-button k-button-md k-button-rectangle k-primary k-button-solid k-button-solid-primary k-rounded-md"
              onClick={handleSubmit}
            >
              {isNew ? "Lägg till" : "Uppdatera"}
            </button>
            <button
              type="button"
              className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-base"
              onClick={props.onCancel}
            >
              Avbryt
            </button>
          </div>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
