import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { ReactNode, isValidElement } from "react";

function ErrorComponent({ error }: MsalAuthenticationResult) {
  return <p>An Error Occurred: {error?.errorMessage}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

/**
 * Authentication guard. Will render the children when user is authenticated, else trigger interaction to authenticate.
 */
export function AuthGuard({ children }: { children: ReactNode }) {
  const authRequest = {
    ...loginRequest,
  };

  // Check if children is an object and not a React element
  if (typeof children === "object" && !isValidElement(children)) {
    console.error("Invalid children prop in AuthGuard:", children);
    return null; // or return some fallback UI
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
}
