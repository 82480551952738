import { ContainerDto } from "../../../../api/Client";
import { iClassificationItem } from "../../../common/classifications/interfaces";
import { LEARNING_OBJECTIVES_ROOT_ID } from "../../../common/Constants";

export const getSelectedClassificationIds = (learningObjectContainer: ContainerDto): iClassificationItem[] => {
  // console.log("getSelectedClassificationIds", learningObjectContainer);
  let selectedItems: iClassificationItem[] = [];
  let containerClassificationDtos = learningObjectContainer!.containerClassificationDtos ?? [];

  const selectedClassifications = containerClassificationDtos.map((x) => {
    return { identifier: x.classificationId, rootId: x.classificationRoot } as iClassificationItem;
  });

  let selectedLearningObjectives = learningObjectContainer!.containerLearningObjectiveDtos!.map((x) => {
    return { identifier: x.learningObjectiveId, rootId: LEARNING_OBJECTIVES_ROOT_ID } as iClassificationItem;
  });

  selectedItems = selectedClassifications.concat(selectedLearningObjectives);
  // console.log("selectedItems", selectedItems);

  return selectedItems;
};
