import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../common/AppContext";
import { iInfo } from "../../../interfaces/iInfo";
import Info from "../../common/Info";
import LoadingPanel from "../../common/LoadingPanel";
import {
  KEY_INFO_COMMENT,
  KEY_INFO_CUSTOMER,
  KEY_INFO_DEPRECATED,
  KEY_INFO_STATUS,
  KEY_INFO_WHERE_USED_LEARNINGOBJECTS,
  LEARNING_OBJECTIVES_ROOT_ID,
  LEARNING_OBJECT_NEW_DEFAULT_ORGANISATION,
  QUESTION_OPTION_SOURCE_TYPE_ID,
} from "../../common/Constants";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { copyContainer, fetchConfiguration } from "../../../api/learnable_lcms/apiFunctions";
import NewQuestion from "../../question/edit/NewQuestion";
import LearningObjectEditor from "../../learningobject/edit/LearningObjectEditor";
import {
  Client,
  Component,
  ContainerDto,
  CustomerDto,
  GetInteractionContainerResponse,
  GetWhereUsedResponse,
  InteractionResponse,
  InteractionResponseObjectDto,
  InteractionStructure,
  LearningObjectDto,
  MediaObjectDto,
  PublishContainerResponse,
  QuestionObjectDto,
  SaveInteractionContainerRequest,
  TextObjectDto,
  WhereUsedDto,
} from "../../../api/Client";
import { appConfig } from "../../../appConfig";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { SaveInteractionRequest } from "../types";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea, TextBox } from "@progress/kendo-react-inputs";
import {
  ContainerSubTypeResponse,
  ContainerSubTypeInteraction,
  createInteractionResponse,
  tab,
  IInteractionMetaData,
} from "../utilities";
import { getUniqueOptionIdsSorted } from "../../question/edit/functions/questionDtoFunctions";
import { fetchQuestion, saveQuestionDto } from "../../../api/learnable_question/apiFunctions";
import { fetchInteraction } from "./interactionApi";
import { openInteraction } from "../../question/edit/functions/helperFunctions";
import { ClassificationContainer } from "../../common/classifications/types";
import { iConfiguration } from "../../common/classifications/interfaces";

const Interaction = (props: any) => {
  type EditorContent = {
    optionId: number;
    html: string;
    editorSchema: any;
    components?: Component[];
  };

  interface InteractionsEditor {
    success: EditorContent;
    failure: EditorContent;
    options: EditorContent[];
  }

  const appObject = useAppContext();
  const [metaData, setMetaData] = useState<IInteractionMetaData>({ title: "", description: "" });
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const incomingInteractionId: number = searchParams.get("id") ? parseInt(searchParams.get("id") as string) : 0;
  const expanded: boolean = incomingInteractionId === 0;
  const incomingSubType: number = searchParams.get("type") ? parseInt(searchParams.get("type") as string) : 0;
  const incomingQuestionId: number = searchParams.get("questionId")
    ? parseInt(searchParams.get("questionId") as string)
    : 0;
  const incomingQuestionTemplateId: number =
    incomingQuestionId === 0 && searchParams.get("templateId") ? parseInt(searchParams.get("templateId") as string) : 0;

  const [initDone, setInitDone] = useState<boolean>(false);

  const [classificationContainer, setClassificationContainer] = useState<ClassificationContainer | undefined>(
    undefined
  );
  const [selected, setSelected] = useState(0);
  const [editor, setEditor] = useState<InteractionsEditor | undefined>({
    success: { optionId: -1, html: "", editorSchema: undefined },
    failure: { optionId: -1, html: "", editorSchema: undefined },
    options: [],
  });
  const [saveInteractionRequest, setSaveInteractionRequest] = useState<SaveInteractionRequest | undefined>(undefined);

  const visibleInfoElements: string[] = [KEY_INFO_CUSTOMER, KEY_INFO_STATUS, KEY_INFO_DEPRECATED, KEY_INFO_COMMENT];

  useEffect(() => {
    init();
  }, []);

  const [questionKey, setQuestionKey] = useState(0);

  const init = async () => {
    setQuestionKey((prevQuestionKey) => prevQuestionKey + 1);

    let saveInteractionRequest: SaveInteractionRequest = {} as SaveInteractionRequest;
    let interactionResponseContainer: GetInteractionContainerResponse =
      incomingInteractionId > 0 ? await fetchInteraction(incomingInteractionId) : createEmptyInteractionContainer();
    let whereUsedItems: WhereUsedDto[] | undefined = undefined;
    try {
      const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
      const response: GetWhereUsedResponse = await client.whereUsedGetWhereUsed(incomingInteractionId);
      console.log("whereused", response);
      whereUsedItems = response.used;
    } catch (error) {
      console.log("error whereused", error);
      whereUsedItems = undefined;
    }
    // let interactionResponseContainer: GetInteractionContainerResponse = (await fetchInteraction(
    //   incomingInteractionId
    // )) as InteractionStructure;

    saveInteractionRequest.interactionStructure = interactionResponseContainer.interactionStructure;
    saveInteractionRequest.containerInteraction = interactionResponseContainer.interactionContainer;

    setMetaData({
      title: saveInteractionRequest.interactionStructure!.title!,
      description: saveInteractionRequest.interactionStructure!.description!,
    });

    syncEditorWithInteractionStructure(interactionResponseContainer.interactionStructure!);

    // setSaveInteractionRequest(saveInteractionRequest);

    // Configuration
    let configurationData: iConfiguration = await fetchConfiguration("Classifications");
    configurationData.learningObjectives = {
      rootId: LEARNING_OBJECTIVES_ROOT_ID,
      title: "Lärandemål",
      showSelected: true,
    };
    setConfigData(configurationData);

    // ClassificationContainer
    // const rootIds = getClassificationRootIds(configurationData);
    // let selectedIds: string[] = [];
    // let classificationContainer: ClassificationContainer = await createClassificationContainer(rootIds, selectedIds);
    // setClassificationContainer(classificationContainer);
    if (interactionResponseContainer.interactionStructure?.id !== 0) {
      visibleInfoElements.push(KEY_INFO_WHERE_USED_LEARNINGOBJECTS);
    }
    saveInteractionRequest.info = {
      customerId: saveInteractionRequest.containerInteraction?.customerDto.id
        ? saveInteractionRequest.containerInteraction?.customerDto.id!
        : LEARNING_OBJECT_NEW_DEFAULT_ORGANISATION,
      statusId: saveInteractionRequest.containerInteraction?.lifeCycleStatus! ?? 1,
      knowledgeLevel: -1,
      deprecated: saveInteractionRequest.containerInteraction?.deprecated ?? false,
      selectedItems: [],
      visibleElements: visibleInfoElements,
      subTypeId: 0,
      comment: saveInteractionRequest.containerInteraction?.comment! ?? "",
      whereUsed: whereUsedItems,
    };

    setSaveInteractionRequest(saveInteractionRequest);
    setInitDone(true);
  };

  useEffect(() => {
    if (saveInteractionRequest?.interactionStructure) {
      const newTitle = `I - ${
        saveInteractionRequest?.interactionStructure.id === 0
          ? "Ny interaktion"
          : saveInteractionRequest?.interactionStructure.id
      }`;
      if (document.title !== newTitle) {
        document.title = newTitle;
      }
    }
  }, [saveInteractionRequest]);

  const createEmptyInteractionContainer = () => {
    return new GetInteractionContainerResponse({
      interactionStructure: new InteractionStructure({
        id: 0,
        subType: incomingSubType,
        questionid: incomingQuestionId,
        title: "",
        description: "",
        success: createInteractionResponse(ContainerSubTypeResponse.Success, "0"),
        failure: createInteractionResponse(ContainerSubTypeResponse.Failure, "0"),
        options: [],
      }),
      interactionContainer: new ContainerDto(),
    });
  };

  //The following function is used to handle the selection of tabs in the interaction editor.
  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };
  const onPastedMetaData = (metaData: any) => {};

  const CreateInteractionContainerRequest = (): SaveInteractionContainerRequest => {
    let newContainerDto = new ContainerDto();

    newContainerDto.id = saveInteractionRequest?.interactionStructure?.id ?? 0;
    newContainerDto.lifeCycleStatus = saveInteractionRequest?.info?.statusId ?? 0;
    newContainerDto.customerDto = new CustomerDto(
      appObject.customers.find((x) => x.id == saveInteractionRequest!.info?.customerId)
    );
    newContainerDto.deprecated = saveInteractionRequest?.info?.deprecated;
    newContainerDto.title = saveInteractionRequest?.interactionStructure?.title;
    newContainerDto.description = saveInteractionRequest?.interactionStructure?.description;
    newContainerDto.comment = saveInteractionRequest?.info?.comment ?? "";
    newContainerDto.textObjectDto = new TextObjectDto();
    newContainerDto.textObjectDto.textDtos = [];
    newContainerDto.mediaObjectDto = new MediaObjectDto();
    newContainerDto.mediaObjectDto.mediaDtos = [];
    newContainerDto.questionObjectDto = new QuestionObjectDto();
    newContainerDto.questionObjectDto.questionDtos = [];
    newContainerDto.learningObjectDto = new LearningObjectDto();
    newContainerDto.learningObjectDto.data = "";
    newContainerDto.interactionResponseObjectDto = new InteractionResponseObjectDto({
      optionKey: "0",
      id: 0,
      responseType: 0,
    });

    newContainerDto.deprecated = saveInteractionRequest?.info?.deprecated;
    return new SaveInteractionContainerRequest({
      interactionStructure: saveInteractionRequest?.interactionStructure,
      interactionContainer: newContainerDto,
    });
  };

  const getInteractionEditorCopy = (editor: InteractionsEditor): InteractionsEditor => {
    let newEditor: InteractionsEditor = {
      success: { ...editor.success },
      failure: { ...editor.failure },
      options: [],
    };

    let newOptions: EditorContent[] = editor.options.map((x) => ({ ...x })) as EditorContent[];

    for (let i = 0; i < newOptions.length; i++) {
      newOptions[i].components = newOptions[i].components?.map((x) => new Component(x)) as Component[];
    }

    newEditor.options = newOptions;

    return newEditor;
  };

  const syncEditorWithInteractionStructure = (
    interactionStructure: InteractionStructure,
    questionDto?: iQuestionDtoExtended
  ) => {
    let newEditor: InteractionsEditor = {
      success: { optionId: -1, html: "", editorSchema: undefined },
      failure: { optionId: -1, html: "", editorSchema: undefined },
      options: [],
    } as InteractionsEditor;

    if (interactionStructure.subType === (ContainerSubTypeInteraction.Responses as number)) {
      const questionOptions = questionDto ? getUniqueOptionIdsSorted(questionDto, QUESTION_OPTION_SOURCE_TYPE_ID) : [];
      if (questionOptions.length > 0) {
        for (let i = 0; i < questionOptions.length; i++) {
          const found = interactionStructure.options!.find((option) => option.optionId === questionOptions[i]);

          let newOption: EditorContent = {
            optionId: parseInt(questionOptions[i]),
            html: "",
            editorSchema: undefined,
            components: found ? found.components : [],
          };
          newEditor.options.push(newOption);
        }
      } else {
        interactionStructure.options!.map((option) => {
          let newEditorContent: EditorContent = {
            optionId: parseInt(option.optionId!),
            html: "",
            editorSchema: undefined,
            components: option.components as Component[],
          };
          newEditor.options.push(newEditorContent);
        });
      }
    }
    // console.log("syncEditorWithInteractionStructure ffff", newEditor);

    if (interactionStructure.subType === (ContainerSubTypeInteraction.SuccessFailure as number)) {
      newEditor.success.components = interactionStructure.success!.components;
      newEditor.failure.components = interactionStructure.failure!.components;
    }

    console.log("newEditor", newEditor);
    setEditor(newEditor);
  };

  /*
      This function is used to sync the editor with the questionDto. 
      When the questionDto is changed, the editor should be updated with the new options.
      This is used when the interaction is of type "Responses"
  */
  const updateEditorOnQuestionChange = (
    saveInteractionRequest: SaveInteractionRequest,
    questionDto: iQuestionDtoExtended
  ) => {
    let newEditor: InteractionsEditor = getInteractionEditorCopy(editor!);

    if (saveInteractionRequest.interactionStructure!.subType !== (ContainerSubTypeInteraction.Responses as number)) {
      return newEditor;
    }

    if (!questionDto) {
      return newEditor;
    }

    const newOptions = [];
    const uniqueSourceOptionIdsSorted: string[] = getUniqueOptionIdsSorted(questionDto!, 3);

    if (uniqueSourceOptionIdsSorted.length > 0) {
      for (let i = 0; i < uniqueSourceOptionIdsSorted.length; i++) {
        const found = newEditor.options.find((option) => option.optionId === parseInt(uniqueSourceOptionIdsSorted[i]));
        if (found) {
          newOptions.push(found);
        } else {
          let newOption: EditorContent = {
            optionId: parseInt(uniqueSourceOptionIdsSorted[i]),
            html: "",
            editorSchema: undefined,
            components: [],
          };
          newOptions.push(newOption);
        }
      }
    }
    newEditor.options = newOptions;

    console.log("syncOptionsEditor", newEditor);
    setEditor(newEditor);
    return newEditor;
  };

  // change events
  const onQuestionChanged = (questionCopy: iQuestionDtoExtended) => {
    // console.log("onQuestionChanged", questionCopy);

    // varför skulle detta hända?
    if (!questionCopy) {
      return;
    }

    // Options = Responses??
    if (saveInteractionRequest?.interactionStructure!.subType === (ContainerSubTypeInteraction.Responses as number)) {
      const newEditor: InteractionsEditor = updateEditorOnQuestionChange(saveInteractionRequest!, questionCopy);

      //om antal svar har minskat och tabben är vald, välj sista tabben
      if (newEditor.options.length < selected + 1) {
        setSelected(newEditor.options.length - 1);
      }
    }

    setSaveInteractionRequest({
      ...saveInteractionRequest!,
      question: questionCopy,
      isModified: questionCopy.isModified,
    });
  };

  const onInfoChanged = (newInfo: iInfo) => {
    setSaveInteractionRequest({ ...saveInteractionRequest!, info: newInfo, isModified: true });
  };

  const onEditorBlur = (components: Component[], optionId: number, subType: number) => {
    let newEditor: InteractionsEditor = getInteractionEditorCopy(editor!);

    if (subType === ContainerSubTypeResponse.Success) {
      newEditor.success!.components = components;
    }
    if (subType === ContainerSubTypeResponse.Failure) {
      newEditor.failure!.components = components;
    }
    if (subType === ContainerSubTypeResponse.Option) {
      const index = newEditor.options.findIndex((option) => option.optionId === optionId);
      newEditor.options[index].components = components;
    }
    setSaveInteractionRequest({ ...saveInteractionRequest!, isModified: true });
    setEditor(newEditor);
  };

  const handleOnTitleChange = (newValue: string) => {
    setMetaData({ ...metaData, title: newValue });
    setSaveInteractionRequest({ ...saveInteractionRequest!, isModified: true });
  };

  const handleOnDescriptionChange = (newValue: string) => {
    setMetaData({ ...metaData, description: newValue });
    setSaveInteractionRequest({ ...saveInteractionRequest!, isModified: true });
  };
  /// change events

  const getEditorHeight = () => {
    return expanded ? "47vh" : "60vh";
  };

  const getSuccessFailureTabs = (saveInteractionRequest: SaveInteractionRequest, editor: InteractionsEditor) => {
    return [
      {
        id: 1,
        title: "Success",
        content: (
          <LearningObjectEditor
            hideSaveCancelButtons={true}
            hideInteractionButton={true}
            height={getEditorHeight()}
            key={"success"}
            learningObjectId={0}
            info={saveInteractionRequest!.info!}
            components={editor.success.components}
            dirty={false}
            setDirty={() => {}}
            onPastedMetaData={onPastedMetaData}
            onSave={onSave}
            onCancel={onCancel}
            onEditorBlur={(components) => onEditorBlur(components, -1, ContainerSubTypeResponse.Success)}
            onPublish={onPublish}
            onCancelPublish={onCancelPublish}
          />
        ),
      },
      {
        id: 2,
        title: "Failure",
        content: (
          <LearningObjectEditor
            hideSaveCancelButtons={true}
            hideInteractionButton={true}
            height={getEditorHeight()}
            key={"failure"}
            learningObjectId={0}
            info={saveInteractionRequest!.info!}
            components={editor.failure.components}
            dirty={false}
            setDirty={() => {}}
            onPastedMetaData={onPastedMetaData}
            onSave={onSave}
            onCancel={onCancel}
            onEditorBlur={(components) => onEditorBlur(components, -1, ContainerSubTypeResponse.Failure)}
            onPublish={onPublish}
            onCancelPublish={onCancelPublish}
          />
        ),
      },
    ];
  };

  const getOptionsTabs = () => {
    let newTabs: tab[] = []; //interactionTabs.length === 0 ? ([] as tab[]) : [...interactionTabs];
    for (let i = 0; i < editor!.options.length; i++) {
      let newTab: tab = {
        id: editor!.options[i].optionId,
        title: "Svar " + (i + 1).toString(),
        content: (
          <LearningObjectEditor
            hideSaveCancelButtons={true}
            hideInteractionButton={true}
            height={getEditorHeight()}
            key={"options" + editor!.options[i].optionId}
            learningObjectId={0}
            info={saveInteractionRequest!.info!}
            components={editor!.options[i].components}
            dirty={false}
            setDirty={() => {}}
            onPastedMetaData={onPastedMetaData}
            onSave={onSave}
            onCancel={onCancel}
            onEditorBlur={(components) =>
              onEditorBlur(components, editor!.options[i].optionId, ContainerSubTypeResponse.Option)
            }
            onPublish={onPublish}
            onCancelPublish={onCancelPublish}
          />
        ),
      };
      newTabs.push(newTab);
    }

    //console.log("getOptionsTabs", newTabs, editor);
    return newTabs;
  };

  const getTabs = () => {
    // if (editor === undefined) return [];
    if (
      saveInteractionRequest?.interactionStructure?.subType === (ContainerSubTypeInteraction.SuccessFailure as number)
    ) {
      return getSuccessFailureTabs(saveInteractionRequest, editor!);
    }
    if (saveInteractionRequest?.interactionStructure?.subType === (ContainerSubTypeInteraction.Responses as number)) {
      return getOptionsTabs();
    }
  };

  const [saveStatus, setSaveStatus] = useState({
    success: false,
    error: false,
    text: "",
  });

  const showSaveStatus = (success: boolean, text: string) => {
    setSaveStatus({ success: success, error: !success, text: text });

    setTimeout(() => {
      setSaveStatus({ success: false, error: false, text: "" });
    }, 3000);
  };

  const saveInteraction = async (questionDto: iQuestionDtoExtended) => {
    let saveInteractionContainerRequest = CreateInteractionContainerRequest();
    saveInteractionContainerRequest.interactionStructure!.questionid = questionDto.id;
    saveInteractionContainerRequest.interactionStructure!.description = metaData.description;
    saveInteractionContainerRequest.interactionStructure!.title = metaData.title;

    syncComponents(saveInteractionContainerRequest.interactionStructure!);

    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    // console.log("saveInteractionContainerRequest", saveInteractionContainerRequest);
    if (saveInteractionContainerRequest.interactionStructure!.id === 0) {
      const newId: number = await client.interactionComponentCreateComponent(saveInteractionContainerRequest);
      saveInteractionContainerRequest.interactionStructure!.id = newId;
    } else {
      let result = await client.interactionComponentUpdateComponent(saveInteractionContainerRequest);
      if (!result) {
        return 0;
      }
    }
    return saveInteractionContainerRequest.interactionStructure!.id!;
  };

  const syncComponents = (newInteractionStructure: InteractionStructure) => {
    if (newInteractionStructure.subType === (ContainerSubTypeInteraction.SuccessFailure as number)) {
      newInteractionStructure!.success!.components = editor!.success.components;
      newInteractionStructure!.failure!.components = editor!.failure.components;
      newInteractionStructure!.options = undefined;
    }

    if (newInteractionStructure.subType !== (ContainerSubTypeInteraction.Responses as number)) {
      return;
    }

    if (editor!.options.length === 0) {
      return;
    }

    let newInteractionResponses: InteractionResponse[] = [];
    for (let i = 0; i < editor!.options.length; i++) {
      if (editor!.options[i].components!.length === 0) {
        continue;
      }

      //create new option
      let found = newInteractionStructure.options!.find(
        (option) => option.optionId === editor?.options[i].optionId.toString()
      );

      if (found) {
        let newInteractionResponse = createInteractionResponse(ContainerSubTypeResponse.Option, found.optionId!);
        newInteractionResponse.components = editor?.options[i].components;
        newInteractionResponses.push(newInteractionResponse);
      } else {
        let newOptionId = editor?.options[i].optionId.toString();
        if (Math.sign(parseInt(newOptionId!)) === -1) {
          newOptionId = Math.abs(parseInt(newOptionId!)).toString();
        }
        let newInteractionResponse = createInteractionResponse(ContainerSubTypeResponse.Option, newOptionId);
        newInteractionResponse.components = editor?.options[i].components;
        newInteractionResponses.push(newInteractionResponse);
      }

      newInteractionStructure!.options! = newInteractionResponses;
      console.log("syncoptions", newInteractionStructure!.options![i]);
    }

    newInteractionStructure.success = undefined;
    newInteractionStructure.failure = undefined;
  };

  const saveInteractionDebug = async () => {
    let saveInteractionContainerRequest = CreateInteractionContainerRequest();
    saveInteractionContainerRequest.interactionStructure!.questionid = 99;
    saveInteractionContainerRequest.interactionStructure!.description = metaData.description;
    saveInteractionContainerRequest.interactionStructure!.title = metaData.title;

    syncComponents(saveInteractionContainerRequest.interactionStructure!);
  };

  // button events
  const onCancel = () => {
    init();
  };

  const onSaveDebug = async () => {
    await saveInteractionDebug();
  };
  const onPublish = async (): Promise<PublishContainerResponse> => {
    return new PublishContainerResponse();
  };
  const onCancelPublish = async () => {};
  const onSave = async () => {
    // await onSaveDebug();
    // console.log("after onSaveDebug", saveInteractionRequest);
    // return;

    let success: boolean = false;
    try {
      setLoading(true);

      //spara fråga
      let savedQuestionId: number = saveInteractionRequest?.question?.id!;
      if (saveInteractionRequest?.question!.isModified || saveInteractionRequest?.question!.id === 0) {
        savedQuestionId = await saveQuestionDto(saveInteractionRequest?.question!);
      }

      const questionDto: iQuestionDtoExtended = await fetchQuestion(savedQuestionId);
      // console.log("saved question", questionDto);
      success = questionDto && questionDto.id! > 0;

      //om spara frågan lyckades sparar vi interaktionen
      // success = true;
      let savedInteractionId: number = 0;
      if (success) {
        savedInteractionId = await saveInteraction(questionDto);
        console.log("savedInteractionId", savedInteractionId);
        success = savedInteractionId > 0;
      }

      //allt verkar ha gått vägen. Visa meddelande och ladda om interaktionen
      if (success) {
        setLoading(false);
        showSaveStatus(true, "Sparad!");

        // borde vi inte kunna utnyttja init här och undvika redundant kod?
        const newGetInteractionContainerResponse: GetInteractionContainerResponse = await fetchInteraction(
          savedInteractionId!
        );

        syncEditorWithInteractionStructure(newGetInteractionContainerResponse.interactionStructure!, questionDto);
        let newInfo: iInfo = {
          deprecated: newGetInteractionContainerResponse.interactionContainer?.deprecated!,
          customerId: newGetInteractionContainerResponse.interactionContainer?.customerDto.id!,
          statusId: newGetInteractionContainerResponse.interactionContainer?.lifeCycleStatus!,
          comment: newGetInteractionContainerResponse.interactionContainer?.comment!,
          knowledgeLevel: -1,
          selectedItems: [],
          subTypeId: 0,
          visibleElements: visibleInfoElements,
        };

        setSaveInteractionRequest({
          info: newInfo,
          containerInteraction: newGetInteractionContainerResponse.interactionContainer,
          interactionStructure: newGetInteractionContainerResponse.interactionStructure,
          question: questionDto,
          isModified: false,
        });
      } else {
        showSaveStatus(false, "Misslyckades att spara!");
      }
    } catch (error) {
      showSaveStatus(false, "Ett fel inträffade!");
      console.error("Error saving new interaction", error);
    }
  };

  const copyInteractionButtonClick = async (interactionId: number) => {
    const newContainerId: number | undefined = await copyContainer(interactionId);
    let success: boolean = newContainerId > 0;
    if (success) {
      openInteraction({ interactionId: newContainerId, interactionType: 0, questionId: 0, templateId: 0 });
    } else {
      showSaveStatus(false, "Interaktionen kunde inte kopieras");
    }
  };

  /// button events
  const validateValue = (val: string) => {
    // Your validation logic here (e.g., check for empty string)
    return val.trim() !== "";
  };

  return (
    <>
      {(!initDone || loading) && LoadingPanel}

      {initDone &&
        saveInteractionRequest?.interactionStructure &&
        saveInteractionRequest?.interactionStructure!.id! > 0 && (
          <div className="row mt-2 mb-2">
            <div className="col-12">
              <span title="Id" style={{ fontWeight: "bold" }}>
                {saveInteractionRequest.interactionStructure!.id! > 0
                  ? saveInteractionRequest.interactionStructure!.id
                  : incomingInteractionId}
              </span>
              <Button
                className="ms-2"
                themeColor="primary"
                size="small"
                title="kopiera"
                icon="copy"
                onClick={() => copyInteractionButtonClick(saveInteractionRequest?.interactionStructure!.id!)}
              ></Button>
            </div>
          </div>
        )}

      {initDone && (
        <div className="row m-2">
          <div>
            <div>
              Titel
              <TextBox
                className={validateValue(metaData.title) ? "" : "emptyTextBoxError"}
                id={"title"}
                value={metaData.title}
                onChange={(event) => handleOnTitleChange(event.value!.toString())}
              />
              <div className={validateValue(metaData.title) ? "hidden" : "emptyTextError"}>Titel är obligatoriskt</div>
            </div>
            Beskrivning
            <TextArea
              id={"description"}
              value={metaData.description}
              onChange={(event) => handleOnDescriptionChange(event.value!.toString())}
            />
            <hr />
            {saveInteractionRequest?.info && (
              <Info
                expanded={expanded}
                title={"Metadata"}
                infoData={saveInteractionRequest!.info!}
                configuration={configData}
                onInfoChanged={onInfoChanged}
                classificationContainer={classificationContainer}
              />
            )}
            <hr />
            <NewQuestion
              key={questionKey} // to force re-render
              id={saveInteractionRequest!.interactionStructure!.questionid}
              templateId={
                saveInteractionRequest?.interactionStructure?.questionid === 0 ? incomingQuestionTemplateId : 0
              }
              showQuestionPanel={true}
              questionPanelTitle={
                saveInteractionRequest?.interactionStructure?.questionid === 0
                  ? "Ny fråga"
                  : "Fråga " + saveInteractionRequest?.interactionStructure?.questionid
              }
              onQuestionChanged={onQuestionChanged}
            />
            <hr />
          </div>

          <div className="mt-2">
            {saveInteractionRequest && (
              <div className="row">
                <TabStrip selected={selected} onSelect={handleSelect}>
                  {getTabs()!.map((tab) => (
                    <TabStripTab title={tab.title} key={tab.id}>
                      <div>{tab.content}</div>
                    </TabStripTab>
                  ))}
                </TabStrip>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <div className="me-2" style={{ float: "right" }}>
                  <Button
                    className="m-2"
                    onClick={onSave}
                    themeColor={"primary"}
                    style={{ width: 100 }}
                    size="medium"
                    disabled={!saveInteractionRequest || !saveInteractionRequest.isModified || !metaData.title}
                  >
                    Spara
                  </Button>
                  <Button
                    disabled={!saveInteractionRequest || !saveInteractionRequest!.isModified}
                    themeColor={"primary"}
                    style={{ width: 100 }}
                    onClick={onCancel}
                    size="medium"
                  >
                    Avbryt
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <NotificationGroup
        style={{
          left: 0,
          bottom: 0,
          alignItems: "flex-start",
          flexWrap: "wrap-reverse",
        }}
      >
        <Fade>
          {saveStatus.error && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setSaveStatus({ error: false, success: false, text: "" })}
            >
              <span>{saveStatus.text}</span>
            </Notification>
          )}
        </Fade>

        <Fade>
          {saveStatus.success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setSaveStatus({ error: false, success: false, text: "" })}
            >
              <span>{saveStatus.text}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  );
};

export default Interaction;
