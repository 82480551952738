import { Dialog } from "@progress/kendo-react-dialogs";
import Interactions from "../Interactions";

const SelectInteractionDialog = (props: any) => {
  const onClose = () => {
    props.onClose();
  };

  const onSelectInteraction = (interactionId: number) => {
    props.onSelectInteraction(interactionId);
  };

  return (
    <>
      <Dialog title={"Välj interaktion"} height={"90%"} width={"90%"} onClose={onClose}>
        <Interactions onSelectInteraction={onSelectInteraction}></Interactions>
      </Dialog>
    </>
  );
};

export default SelectInteractionDialog;
