import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TemplateDto } from "../../api/learnable_question/QuestionClient";
import { ComboBoxChangeEvent, DropDownList, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { useAppContext } from "../common/AppContext";
import {
  interactionParams,
  openInteraction,
  templateMultiColumnComboBoxColumns,
} from "../question/edit/functions/helperFunctions";
import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import SelectQuestionDialog from "../question/edit/SelectQuestionDialog";
import { CONTAINER_TYPE_INTERACTION, INTERACTION_TYPE_ID_SINGLE_CHOICE } from "../common/Constants";
import { fetchContainerSubTypes } from "../../api/learnable_classification/apiFunctions";
import { iContainerSubTypeDtoExtended } from "../../interfaces/iContainerSubTypeDtoExtended";

const InteractionSelector = (props: any) => {
  const appObject = useAppContext();
  const [template, setTemplate] = React.useState<TemplateDto | undefined>(undefined);

  const toggleDialog = () => {
    props.setShowInteractionDialog(false);
  };

  const handleChange = (event: ComboBoxChangeEvent) => {
    if (event) {
      setTemplate(event.target.value);
    }
  };

  const [containerSubTypes, setContainerSubTypes] = useState<iContainerSubTypeDtoExtended[] | undefined>([]);

  const init = async () => {
    let subTypes: iContainerSubTypeDtoExtended[] = await fetchContainerSubTypes(CONTAINER_TYPE_INTERACTION);
    subTypes = subTypes.filter((x) => x.id != 0);

    setContainerSubTypes(subTypes);
    setSelectedInteractionType(subTypes[0]);
  };

  useEffect(() => {
    //console.log(appObject.templates);

    init();
  }, []);

  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [selectedInteractionType, setSelectedInteractionType] = React.useState<any>(undefined);
  const [selectedValue, setSelectedValue] = React.useState("new");

  const handleRadioButtonChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const [questionRow, setQuestionRow] = React.useState<any | undefined>(undefined);
  const onSelectQuestion = (questionRow: any) => {
    console.log("onSelectQuestion", questionRow);
    setQuestionRow(questionRow);
    onClose();
  };

  const onClose = () => {
    setVisibleDialog(false);
  };

  const okButtonDisabled = () => {
    if (selectedValue == "new") {
      return template == undefined;
    } else {
      return questionRow == undefined;
    }
  };

  const onOkClick = () => {
    const params: interactionParams = {
      interactionId: 0,
      interactionType: selectedInteractionType.id,
      questionId: questionRow != undefined ? questionRow.id : 0,
      templateId: template != undefined ? template.id! : 0,
    };

    if (selectedValue == "new") {
      params.questionId = 0;
    } else {
      params.templateId = 0;
    }

    // om man klickar i gridden behöver vi skicka interaction id, annars question och template id??
    openInteraction(params);
    toggleDialog();
  };

  return (
    <div>
      <Dialog title={"Ny interaktion"} onClose={toggleDialog} width={350}>
        <div className="row">
          <div className="col-12">Interaktionstyp:</div>
        </div>

        <div className="row">
          <div className="col-12">
            <DropDownList
              value={selectedInteractionType}
              onChange={(e) => {
                setSelectedInteractionType(e.target.value);
              }}
              data={containerSubTypes}
              textField="text"
              dataItemKey="id"
              style={{ width: "300px" }}
              title={"Välj interaktionstyp"}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-4">
            <RadioButton
              name="group1"
              value="new"
              checked={selectedValue === "new"}
              label="Ny fråga"
              onChange={handleRadioButtonChange}
            />
          </div>
          <div className="col-4">
            <RadioButton
              name="group1"
              value="existing"
              checked={selectedValue === "existing"}
              label="Välj fråga"
              onChange={handleRadioButtonChange}
            />
          </div>
        </div>

        {selectedValue == "new" && (
          <>
            <div className="row mt-2">
              <div className="col-12">Mallar:</div>
            </div>
            <div className="row">
              <div className="col-12">
                <MultiColumnComboBox
                  value={template}
                  data={appObject.templates.filter((x) => x.interactionTypeId == INTERACTION_TYPE_ID_SINGLE_CHOICE)}
                  columns={templateMultiColumnComboBoxColumns}
                  textField={"name"}
                  style={{ width: "300px" }}
                  placeholder="Välj..."
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}

        {selectedValue == "existing" && (
          <>
            <div className="row mt-2">
              <div className="col-12">
                <Button className="me-2" themeColor="primary" onClick={() => setVisibleDialog(true)}>
                  Välj fråga
                </Button>
                {questionRow && questionRow.id + " " + questionRow.fragestallning}
                {visibleDialog && (
                  <SelectQuestionDialog
                    allowInteractionTypes={[1]}
                    onSelectQuestion={onSelectQuestion}
                    onClose={onClose}
                  />
                )}
              </div>
            </div>
          </>
        )}

        <DialogActionsBar layout={"center"}>
          <Button
            disabled={okButtonDisabled()}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={onOkClick}
          >
            Ok
          </Button>
          <Button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"
            onClick={toggleDialog}
          >
            Cancel
          </Button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};
export default InteractionSelector;
