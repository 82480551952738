import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Interaction } from "../../../../../api/Client";
import { EditorView } from "@progress/kendo-editor-common";
import { GetParentNode, ComponentMode } from "../../../../common/RichTextEditorTools";
import { Button } from "@progress/kendo-react-buttons";
import {
  AreAllPropertiesUndefined,
  CreateComponentHtml,
  GetObjectFromHtml,
  GetOptionsInOrder,
  GetQuestionText,
  GetQuestionTypeName,
  IsEmptyInteractionResponse,
  MatchInteractionResponsesWithQuestionOptions,
  Templates,
} from "../common/utilities";
import { InteractionComponentDialog } from "./InteractionComponentDialog";
import { QuestionClient, QuestionDto } from "../../../../../api/learnable_question/QuestionClient";
import { appConfig } from "../../../../../appConfig";
import { ContentVersionListResponse } from "../../../../../api/optima_CDS/CdsClient";

export const interactionClassName = "interaction";

export class InteractionComponent extends Interaction {
  questionDto?: QuestionDto;
  constructor(data?: any) {
    super(data);
    this.questionDto = data ? data.questionDto : undefined;
  }
  static fromJS(data: any): InteractionComponent {
    data = typeof data === "object" ? data : {};
    const instance = new InteractionComponent(Interaction.fromJS(data));
    instance.questionDto = data["questionDto"] ? QuestionDto.fromJS(data["questionDto"]) : undefined;
    return instance;
  }
  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["questionDto"] = this.questionDto ? this.questionDto.toJSON() : null;
    return super.toJSON(data);
  }
}

// Add question to component
export const AddQuestionToInteractionComponent = async (item: InteractionComponent): Promise<InteractionComponent> => {
  if (item.questionid && (!item.questionDto || !item.questionDto.id || item.questionDto.id !== item.questionid)) {
    const questionApiUrl = appConfig.REACT_APP_QUESTION_API_URL as string;
    const questionClient = new QuestionClient(questionApiUrl);
    const question = await questionClient.questionGet(item.questionid);
    // Make sure options are sorted
    question.options = GetOptionsInOrder(question);
    item.questionDto = question;
  }
  return item;
};

// Create component
export const GetInteractionComponentFromHTML = (html: string): InteractionComponent => {
  const reply = InteractionComponent.fromJS(GetObjectFromHtml(html));
  return reply;
};

export const GetHtmlFromInteractionComponent = async (item: InteractionComponent): Promise<string> => {
  const visualTemplate =
    "<p>Fråga @question@ @type@</p><p>Interaktionstyp: @template@</p><p>@title@<p><p>@description@</p>";
  const comp = await AddQuestionToInteractionComponent(item);
  let html = "";
  console.log("comp", comp);
  const questionText = GetQuestionText(comp.questionDto);
  const templateName = Templates.find((x) => x.id === comp.subtype)?.name;
  html = visualTemplate
    .replace("@question@", questionText)
    .replace("@template@", templateName || "")
    .replace("@title@", comp.title || "")
    .replace("@description@", comp.description || "")
    .replace(
      "@type@",
      GetQuestionTypeName(comp.questionDto?.interactionTypeId, comp.questionDto?.subQuestionTypeId, true)
    );
  return CreateComponentHtml(interactionClassName, comp, html);
};

// Tool Button
export const EditNodeToolInteractionComponent = (props: any) => {
  const [editorView, setEditorView] = useState<EditorView | undefined>(undefined);
  const [editorSchema, setEditorSchema] = useState<any>(undefined);
  const [editInteractionComponent, setEditInteractionComponent] = useState<any>(null);
  const { view } = props;
  useEffect(() => {
    if (view) {
      setEditorView(view);
      const schema = view.state.schema;
      setEditorSchema(schema);
    }
  }, [view]);
  const mode = ComponentMode(view, interactionClassName);
  const handleSubmitEditInteractionComponent = (props: any) => {
    const { newNode, nodeAndPos } = props;
    const state = editorView!.state;
    const tr = state.tr.replaceWith(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize, newNode);
    editorView!.dispatch(tr);
    setEditInteractionComponent(null);
  };
  const handleCancelEditInteractionComponent = () => {
    setEditInteractionComponent(null);
  };
  return (
    <>
      <Button
        onClick={() => {
          const state = view.state;
          const tr = state.tr;
          const nodeAndPos = GetParentNode(
            tr.doc,
            tr.selection.from,
            tr.selection.to,
            "str-component-" + interactionClassName,
            false
          );
          if (nodeAndPos) {
            setEditInteractionComponent({ nodeAndPos });
          }
        }}
        togglable={true}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={mode === 1 ? "Ändra interaktion" : "Infoga interaktion"}
        icon="question"
        selected={mode === 1}
        disabled={mode === -1}
      />
      {editInteractionComponent &&
        createPortal(
          <InteractionComponentDialog
            {...editInteractionComponent}
            editorSchema={editorSchema}
            onSubmit={handleSubmitEditInteractionComponent}
            onCancel={handleCancelEditInteractionComponent}
          />,
          document.body
        )}
    </>
  );
};
