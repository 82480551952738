import { Dialog } from "@progress/kendo-react-dialogs";
import Questions from "../Questions";
import { useState } from "react";
import CustomDialog from "../../common/CustomDialog";

const SelectQuestionDialog = (props: any) => {
  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);
  const allowInteractionTypes: number[] = props.allowInteractionTypes ?? [];

  const questionIsLegal = (question: any) => {
    if (allowInteractionTypes.length === 0) {
      return true;
    }
    return allowInteractionTypes.includes(question.interactionTypeId);
  };

  const onClose = () => {
    props.onClose();
  };

  const onSelectQuestion = (questionRow: any) => {
    if (questionIsLegal(questionRow)) {
      props.onSelectQuestion(questionRow);
      return;
    }

    setShowCustomDialog({
      title: "Ogiltig fråga",
      message: "Denna fråga är inte giltig för interaktioner. Vänligen välj en annan fråga.",
      onClose: () => setShowCustomDialog(null),
    });
  };

  return (
    <>
      <Dialog title={"Välj fråga"} height={"90%"} width={"90%"} onClose={onClose}>
        <Questions onSelectQuestion={onSelectQuestion}></Questions>
      </Dialog>

      {showCustomDialog && <CustomDialog {...showCustomDialog} />}
    </>
  );
};

export default SelectQuestionDialog;
