import { useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import React from "react";
import { cardActionsLayout } from "@progress/kendo-react-layout/dist/npm/card/interfaces/Enums";

const PublishDialog = (props: any) => {
  const currentVersion: string = props.semanticVersion ?? "1.0.0";
  const publishResponse: any | undefined = props.publishResponse;
  // console.log("publishResponse", publishResponse);
  enum VersionType {
    Patch,
    Minor,
    Major,
  }

  const [newVersion, setNewVersion] = React.useState<any>({
    major: `${parseInt(currentVersion.split(".")[0]) + 1}.${parseInt(currentVersion.split(".")[1])}.${0}`,
    minor: `${parseInt(currentVersion.split(".")[0])}.${parseInt(currentVersion.split(".")[1]) + 1}.${0}`,
    patch: `${parseInt(currentVersion.split(".")[0])}.${parseInt(currentVersion.split(".")[1])}.${
      parseInt(currentVersion.split(".")[2]) + 1
    }`,
  });

  const [selectedVersionType, setSelectedVersionType] = React.useState<VersionType>(VersionType.Patch);

  const getNewVersion = (): string => {
    if (isFirstPublish()) {
      return currentVersion;
    }

    if (selectedVersionType === VersionType.Patch) {
      return newVersion.patch;
    } else if (selectedVersionType === VersionType.Minor) {
      return newVersion.minor;
    } else {
      return newVersion.major;
    }
  };

  const majorRef = React.useRef<any>(null);
  const minorRef = React.useRef<any>(null);
  const patchRef = React.useRef<any>(null);

  const versionChanged = (event: NumericTextBoxChangeEvent) => {
    setNewVersion({
      major: `${parseInt(currentVersion.split(".")[0]) + majorRef.current.value}.${parseInt(
        currentVersion.split(".")[1]
      )}.${parseInt(currentVersion.split(".")[2])}`,
      minor: `${parseInt(currentVersion.split(".")[0])}.${
        parseInt(currentVersion.split(".")[1]) + minorRef.current.value
      }.${parseInt(currentVersion.split(".")[2])}`,
      patch: `${parseInt(currentVersion.split(".")[0])}.${parseInt(currentVersion.split(".")[1])}.${
        parseInt(currentVersion.split(".")[2]) + patchRef.current.value
      }`,
    });
  };

  const versionTypeChanged = (event: RadioButtonChangeEvent) => {
    setSelectedVersionType(event.value);
  };

  const getBackgroudColor = (versionType: VersionType) => {
    return selectedVersionType === versionType ? "yellow" : "white";
  };

  const onOkClick = () => {
    let newVersion: string = getNewVersion();

    // console.log("newVersion", newVersion);
    props.onOkClick(newVersion);
    // props.onCancelClick();
  };

  useEffect(() => {}, []);

  const isFirstPublish = () => {
    let semanticVersion: string | undefined = props.semanticVersion;
    return !semanticVersion;
  };

  return (
    <div>
      <Dialog title={"Publicering - ny version"} onClose={props.onCancelClick} width={350}>
        <div className="row">
          <div className="col-12">
            {isFirstPublish() ? "Ny " : "Aktuell "} version: {currentVersion}
          </div>
        </div>

        <div style={{ display: isFirstPublish() ? "none" : "block" }}>
          <div
            className="row mt-4"
            style={{ backgroundColor: getBackgroudColor(VersionType.Patch), display: "flex", alignItems: "center" }}
          >
            <div className="col-4">
              <RadioButton
                name="versionButtons"
                value={VersionType.Patch}
                checked={selectedVersionType === VersionType.Patch}
                label="Detalj"
                onChange={versionTypeChanged}
              />
            </div>
            <div className="col-3">{newVersion.patch}</div>

            {/* Dölj tillsvidare */}
            <div className="col-4" style={{ display: "none" }}>
              <NumericTextBox
                ref={patchRef}
                onChange={versionChanged}
                disabled={selectedVersionType !== VersionType.Patch}
                width={70}
                min={1}
                defaultValue={1}
                format="n0"
              />
            </div>

            <div className="col-2">
              <span className="k-icon k-font-icon k-i-question" style={{ cursor: "pointer" }}></span>
            </div>
          </div>
          <div
            className="row mt-2"
            style={{ backgroundColor: getBackgroudColor(VersionType.Minor), display: "flex", alignItems: "center" }}
          >
            <div className="col-4">
              <RadioButton
                name="versionButtons"
                value={VersionType.Minor}
                checked={selectedVersionType === VersionType.Minor}
                label="Mindre"
                onChange={versionTypeChanged}
              />
            </div>
            <div className="col-3">{newVersion.minor}</div>

            {/* Dölj tillsvidare */}
            <div className="col-4" style={{ display: "none" }}>
              <NumericTextBox
                ref={minorRef}
                onChange={versionChanged}
                disabled={selectedVersionType !== VersionType.Minor}
                width={70}
                min={1}
                defaultValue={1}
                format="n0"
              />
            </div>

            <div className="col-2">
              <span className="k-icon k-font-icon k-i-question" style={{ cursor: "pointer" }}></span>
            </div>
          </div>
          <div
            className="row mt-2"
            style={{ backgroundColor: getBackgroudColor(VersionType.Major), display: "flex", alignItems: "center" }}
          >
            <div className="col-4">
              <RadioButton
                name="versionButtons"
                value={VersionType.Major}
                checked={selectedVersionType === VersionType.Major}
                label="Stor"
                onChange={versionTypeChanged}
              />
            </div>
            <div className="col-3">{newVersion.major}</div>

            {/* Dölj tillsvidare */}
            <div className="col-4" style={{ display: "none" }}>
              <NumericTextBox
                ref={majorRef}
                onChange={versionChanged}
                disabled={selectedVersionType !== VersionType.Major}
                width={70}
                min={1}
                defaultValue={1}
                format="n0"
              />
            </div>

            <div className="col-2">
              <span className="k-icon k-font-icon k-i-question" style={{ cursor: "pointer" }}></span>
            </div>
          </div>
        </div>
        {publishResponse && !publishResponse.success && (
          <div className="row mt-4">
            <div className="col-12">
              <div className="alert alert-danger" role="alert">
                Publiceringen misslyckades.
              </div>
            </div>
          </div>
        )}

        <DialogActionsBar layout={cardActionsLayout.END}>
          <button
            disabled={props.loading || (publishResponse && !publishResponse?.success)}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={onOkClick}
          >
            Publicera
          </button>
          <button
            disabled={props.loading}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary"
            onClick={props.onCancelClick}
          >
            Avbryt
          </button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default PublishDialog;
