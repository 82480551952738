import {
  Client,
  ContainerDto,
  GetWhereUsedResponse,
  IConfigDto,
  WhereUsedDto,
  WhereUsedQuestionRequest,
} from "../../../api/Client";
import {
  ClassificationDto,
  GetClassificationsRequest,
} from "../../../api/learnable_classification/ClassificationClient";
import {
  fetchLearningObjectiveIndicatorClassifications,
  fetchLearningObjectives,
} from "../../../api/learnable_classification/apiFunctions";
import { QuestionClient } from "../../../api/learnable_question/QuestionClient";
import { appConfig } from "../../../appConfig";
import { iCustomFilterDtoExtended } from "../../../interfaces/iCustomFilterDtoExtended";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { openEducation } from "../../question/edit/functions/helperFunctions";
import {
  CONFIG_AMNEN_CLASSIFICATION_KEY,
  CONFIG_BEHORIGHET_CLASSIFICATION_KEY,
  CONFIG_FRAGETYP_CLASSIFICATION_KEY,
  LEARNING_OBJECTIVES_ROOT_ID,
} from "../Constants";
import { ClassificationKey, Service } from "./enums";
import { iChipItem, iClassificationItem, iConfigurationClassification, iLearningObjectiveItem } from "./interfaces";

// import { LearningObjectiveDto } from "../../../api/learningObjective/LearningObjectiveClient";

import { ClassificationContainer, ConfigProvider } from "./types";

export const getEmptyClassificationContainer = (): ClassificationContainer => {
  return {
    selected: [],
    list: [],
    tree: [],
  };
};

export const getSelectedItems = (selectedCustomFilter: iCustomFilterDtoExtended, classificationType: string) => {
  switch (classificationType) {
    case CONFIG_AMNEN_CLASSIFICATION_KEY:
      return selectedCustomFilter.amneGuids ?? [];
    case CONFIG_BEHORIGHET_CLASSIFICATION_KEY:
      return selectedCustomFilter.behorighetGuids ?? [];
    case CONFIG_FRAGETYP_CLASSIFICATION_KEY:
      return selectedCustomFilter.fragetypGuids ?? [];
    case "learningObjectives":
      return selectedCustomFilter.larandemalGuids ?? [];
    default:
      return [];
  }
};

export const createClassificationRequest = (rootIds: string[]): GetClassificationsRequest => {
  let getClassificationsRequest = new GetClassificationsRequest();
  getClassificationsRequest.classificationIds = rootIds;
  getClassificationsRequest.includeDescendants = true;
  getClassificationsRequest.cultureName = "sv-SE";
  return getClassificationsRequest;
};

export const createClassificationContainerFromClassificationDtos = async (
  classifications: ClassificationDto[][],
  selectedIds: string[],
  rootIds: string[]
): Promise<ClassificationContainer> => {
  let newClassificationContainer: ClassificationContainer = {
    selected: [],
    list: [],
    tree: [],
  };
  // console.log("classflat", classifications.flat() as iClassificationItem[]);
  // newClassificationContainer.list =
  //   classifications.flat() as iClassificationItem[];
  let currentRootName: string | undefined = "";
  let currentRootId: string | undefined = "";
  newClassificationContainer.list = classifications.flat().map((x) => {
    if (x.parentId == null) {
      currentRootName = x.name!;
      currentRootId = x.identifier!;
    }
    let item: iClassificationItem = {
      id: x.id as number,
      identifier: x.identifier as string,
      name: x.name as string,
      expanded: false,
      items: [],
      rootName: currentRootName,
      rootId: currentRootId,
      hidden: false,
      parentId: x.parentId as number,
      learningObjectiveClassifications: [],
      learningObjectiveIndicators: [],
      quota: 0,
      numberOfQuestions: 0,
      metadata: {},
    };
    return item;
  });
  console.log("classifications", classifications);

  syncClassificationContainer(newClassificationContainer, selectedIds);
  newClassificationContainer.tree = arrayToTree(newClassificationContainer.list, null);

  // //console.log("classifications fetch", classifications)
  // //loadClassifications();

  // if (rootIds.includes(LEARNING_OBJECTIVES_ROOT_ID)) {
  //   let learningObjectives = await fetchLearningObjectivesAsClassificationItems();
  //   //console.log("functions learningObjectives", learningObjectives);
  //   classifications = classifications.concat(learningObjectives);
  // }

  // classificationContainer.list = getClassificationByRootIds(classifications, rootIds) as iClassificationItem[];
  // syncClassificationContainer(classificationContainer, selectedIds, true);

  //old code
  //let currentRootName: string | undefined = "";
  //let currentRootId: string | undefined = "";
  //classificationContainer.list.forEach(x => {
  //    //if (typeof x.parentId !== 'undefined' && x.parentId == null) {
  //    if (x.parentId == null) {
  //        currentRootName = x.name!;
  //        currentRootId = x.identifier!;
  //    }

  //    let found: boolean = false;
  //    x.rootId = currentRootId;
  //    x.rootName = currentRootName;
  //    x.expanded = x.parentId == null ? true : found;
  //    x.items = [];
  //});

  //classificationContainer.tree = arrayToTree(classificationContainer.list, null);

  return newClassificationContainer;
};
// export const createClassificationContainerFromLearningObjectiveDtos = (
//   learningObjectives: LearningObjectiveDto2[],
//   selectedIds: string[],
//   rootIds: string[]
// ): ClassificationContainer => {
//   let newClassificationContainer: ClassificationContainer = {
//     selected: [],
//     list: [],
//     tree: [],
//   };
//   // console.log("classflat", classifications.flat() as iClassificationItem[]);
//   // newClassificationContainer.list =
//   //   classifications.flat() as iClassificationItem[];
//   newClassificationContainer.list = learningObjectives.map(
//     (learningObjective) => {
//       let item: iLearningObjectiveItem = {
//         id: learningObjective.id as number,
//         identifier: learningObjective.identifier as string,
//         name: learningObjective.name as string,
//         isUsed: learningObjective.isUsed!,
//         expanded: false,
//         items: [],
//         rootName: "",
//         rootId: undefined,
//         hidden: false,
//         parentId: undefined,
//         typeId: learningObjective.typeId as number, // learningObjective.typeDto!.id as LearningObjectiveType,
//         levelId: learningObjective.levelId as number,
//       };
//       return item as iClassificationItem;
//     }
//   );
//   // console.log("classifications", classifications);

//   syncClassificationContainer(newClassificationContainer, selectedIds);
//   newClassificationContainer.tree = arrayToTree(
//     newClassificationContainer.list,
//     null
//   );

// //console.log("classifications fetch", classifications)
// //loadClassifications();

// if (rootIds.includes(LEARNING_OBJECTIVES_ROOT_ID)) {
//   let learningObjectives = await fetchLearningObjectivesAsClassificationItems();
//   //console.log("functions learningObjectives", learningObjectives);
//   classifications = classifications.concat(learningObjectives);
// }

// classificationContainer.list = getClassificationByRootIds(classifications, rootIds) as iClassificationItem[];
// syncClassificationContainer(classificationContainer, selectedIds, true);

//old code
//let currentRootName: string | undefined = "";
//let currentRootId: string | undefined = "";
//classificationContainer.list.forEach(x => {
//    //if (typeof x.parentId !== 'undefined' && x.parentId == null) {
//    if (x.parentId == null) {
//        currentRootName = x.name!;
//        currentRootId = x.identifier!;
//    }

//    let found: boolean = false;
//    x.rootId = currentRootId;
//    x.rootName = currentRootName;
//    x.expanded = x.parentId == null ? true : found;
//    x.items = [];
//});

//classificationContainer.tree = arrayToTree(classificationContainer.list, null);

//   return newClassificationContainer;
// };
export const syncClassificationContainer_new = (
  classificationContainer: ClassificationContainer,
  selectedIdentifiers: string[] | undefined = undefined
  // convertArrayToTree: boolean = true
) => {
  // let currentRootName: string | undefined = "";
  // let currentRootId: string | undefined = "";
  // let handledRootIds: string[] = [];
  classificationContainer.selected = [];

  let newSelected: iClassificationItem[] = [];
  if (selectedIdentifiers && selectedIdentifiers.length > 0) {
    selectedIdentifiers.forEach((selectedId) => {
      let foundItem = classificationContainer.list.find((x) => x.identifier === selectedId);
      if (foundItem) {
        newSelected.push(foundItem);
      }
    });
    classificationContainer.selected = newSelected;
  }
};

export const syncClassificationContainer = (
  classificationContainer: ClassificationContainer,
  questionClassificationsIdentifiers: string[] | undefined = undefined,
  convertArrayToTree: boolean = true
) => {
  let currentRootName: string | undefined = "";
  let currentRootId: string | undefined = "";
  let handledRootIds: string[] = [];
  classificationContainer.selected = [];
  //console.log("syncClassificationContainer", classificationContainer.list);
  classificationContainer.list.forEach((x) => {
    /*if (typeof x.parentId !== 'undefined' && x.parentId == null) {*/
    if (x.parentId == null) {
      currentRootName = x.name!;
      currentRootId = x.identifier!;
      handledRootIds.push(currentRootId);
    }
    if (questionClassificationsIdentifiers) {
      let found: boolean =
        questionClassificationsIdentifiers.find((classificationId) => classificationId === x.identifier) != null;
      if (found) {
        classificationContainer.selected.push(x);
      }
      x.expanded = x.parentId == null ? true : found;
    }
    if (x.rootName !== LEARNING_OBJECTIVES_ROOT_ID) {
      x.rootName = currentRootName;
      x.rootId = currentRootId;
    }
    x.items = [];
  });

  let trees: iClassificationItem[] = [];
  handledRootIds?.forEach((rootId, index) => {
    const classificationList = classificationContainer.list.filter((y) => y.rootId === rootId);
    if (classificationList.length > 0) {
      const tree = arrayToTree(classificationList, null);
      trees.push(tree[0]);
    }
  });
  //console.log("trees", trees);

  classificationContainer.tree = convertArrayToTree
    ? trees //arrayToTree(classificationContainer.list, null)
    : classificationContainer.list;
};

export const getClassificationByRootIds = (classifications: ClassificationDto[][], rootIds: string[]) => {
  let classificatioDtos: ClassificationDto[] = [];
  for (let i = 0; i < classifications.length; i++) {
    //TODO: Remove this state filter hack and implement it in the API
    if (rootIds.includes(classifications[i][0].identifier!)) {
      const filteredClassifications = classifications[i]; //.filter(x => x.stateDto!.id !== 3);
      for (let j = 0; j < filteredClassifications.length; j++) {
        classificatioDtos.push(filteredClassifications[j]);
      }
    }
  }
  return classificatioDtos;
};

export const getLearningObjectives = async () => {
  const data = await fetchLearningObjectives();
  const dataIndicators = await fetchLearningObjectiveIndicatorClassifications();

  data.forEach((x) => {
    const itemIndicatorIds: string[] = x.indicators.map((i: any) => i.id);
    const itemIndicatorClassifications = dataIndicators.filter((x: any) => itemIndicatorIds.includes(x.indicatorId));
    x.metadata = {
      Level: x.levelId,
      Type: x.typeId,
      LearningObjectiveClassifications: itemIndicatorClassifications,
    };
  });

  return data;
};

export const createClassificationContainerFromLearningObjectiveDtos = (
  learningObjectives: any[],
  selectedIds: string[]
): ClassificationContainer => {
  let newClassificationContainer: ClassificationContainer = {
    selected: [],
    list: [],
    tree: [],
  };
  // console.log("classflat", classifications.flat() as iClassificationItem[]);

  newClassificationContainer.list = learningObjectives.map((learningObjective) => {
    let item: iLearningObjectiveItem = {
      id: learningObjective.id as number,
      identifier: learningObjective.identifier as string,
      name: learningObjective.name as string,

      expanded: false,
      items: [],
      rootName: LEARNING_OBJECTIVES_ROOT_ID,
      rootId: LEARNING_OBJECTIVES_ROOT_ID,
      hidden: false,
      parentId: undefined,
      typeId: learningObjective.metadata ? learningObjective.metadata.Type : 0, // learningObjective.typeId as number, // learningObjective.typeDto!.id as LearningObjectiveType,
      levelId: learningObjective.metadata ? learningObjective.metadata.Level : 0, // learningObjective.levelId as number,
      learningObjectiveClassifications: learningObjective.metadata
        ? learningObjective.metadata.LearningObjectiveClassifications
        : [],
      learningObjectiveIndicators: [],
    };
    return item as iClassificationItem;
  });
  // console.log("classifications", classifications);

  syncClassificationContainer(newClassificationContainer, selectedIds);
  newClassificationContainer.tree = arrayToTree(newClassificationContainer.list, null);

  return newClassificationContainer;
};

const arrayToTree: any = (arr: any[], parent: number | null | undefined) => {
  return arr
    .filter((item) => item.parentId == parent)
    .map((child) => ({
      ...child,
      items: arrayToTree(arr, child.id),
    }));
};

export const getClassificationContainerCopy = (
  classificationContainer: ClassificationContainer
): ClassificationContainer => {
  let copy: ClassificationContainer = { ...classificationContainer! };
  copy.selected = classificationContainer!.selected!.map((x) => ({
    ...x,
  })) as iClassificationItem[];
  return copy;
};

//used for trim long classification names and learning objective names
export const trimString = (name: string, maxLength: number) => {
  if (name.length > maxLength) {
    return name.substring(0, maxLength) + "...";
  }
  return name;
};

export const getChipListItems = (
  classificationItems: iClassificationItem[],
  whereUsed: WhereUsedDto[],
  includeLink?: boolean
) => {
  const items: iChipItem[] = [];
  let isUsed = false;
  let isUsedId = -1;

  console.log("classificationItems", classificationItems);
  classificationItems.forEach((i) => {
    if (whereUsed) {
      let found = whereUsed.find((x) => x.classificationIds?.includes(i.identifier));

      isUsed = found ? true : false;
      if (found) {
        isUsedId = found.id!;
      }
    }
    let newItem: iChipItem = {
      text: i.name,
      value: i.identifier!.toString(),
      isUsed: isUsed,
    };
    if (isUsed && includeLink) {
      // newItem.chipColor = "#03fc39";
      newItem.onClick = openEducation.bind(null, isUsedId, undefined);
    }

    items.push(newItem);
  });

  return items;
};

export const getWhereUsedQuestion = async (question: iQuestionDtoExtended): Promise<WhereUsedDto[]> => {
  if (!question.contentId) {
    console.log("error whereused", "no contentId");
    return [];
  }

  try {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    const classificationIds: string[] = question.classifications
      ? (question.classifications!.map((x) => x.classificationId) as string[])
      : [];
    const whereUsedQuestionRequest: WhereUsedQuestionRequest = new WhereUsedQuestionRequest();
    whereUsedQuestionRequest.id = question.id;
    whereUsedQuestionRequest.contentId = question.contentId;
    whereUsedQuestionRequest.versionId = question.versionId;
    whereUsedQuestionRequest.classificationIds = classificationIds;
    let result: GetWhereUsedResponse = await client.whereUsedGetWhereUsedQuestion(whereUsedQuestionRequest);

    return result.used ?? [];
  } catch (error) {
    console.log("error whereused", error);
    return [];
  }
};

export const getWhereUsedContainer = async (container: ContainerDto): Promise<WhereUsedDto[]> => {
  try {
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let result: GetWhereUsedResponse = await client.whereUsedGetWhereUsed(container.id);

    return result.used ?? [];
  } catch (error) {
    console.log("error whereused", error);
    return [];
  }
};

export const configProviderClassificationLcms: ConfigProvider = {
  service: Service.lcms,
  url: appConfig.REACT_APP_LCMS_API_URL as string,
  key: "classifications",
};

export const fetchConfigurationClassifications = async (
  configProvider: ConfigProvider,
  classificationKey: ClassificationKey
): Promise<iConfigurationClassification | undefined> => {
  let result: IConfigDto | undefined = undefined;

  if (configProvider!.service === Service.lcms) {
    const client = new Client(configProvider!.url);
    result = await client.configGet(configProvider!.key);
  }
  if (configProvider!.service === Service.question) {
    const client = new QuestionClient(configProvider!.url);
    result = await client.configGet(configProvider!.key);
  }

  // const result: IConfigDto = await client.configGet(props.configKey);
  const configuration = JSON.parse(result!.value!);
  const classifications: iConfigurationClassification[] = configuration.classifications;
  const classification = classifications.find((x) => x.key === ClassificationKey[classificationKey]);

  return classification ?? undefined;
};
