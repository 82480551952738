import { EditorPasteEvent, EditorUtils, PasteCleanupSettings } from "@progress/kendo-react-editor";
import * as cheerio from "cheerio";
import { InfoComponent, GetHtmlFromInfoComponent } from "./tools/InfoComponent/InfoComponentTool";
import { ImageComponent, GetHtmlFromImageComponent } from "./tools/ImageComponent/ImageComponentTool";
import { appConfig } from "../../../appConfig";
import axios from "axios";
import { GetAssetResponse } from "../../../api/optima_image/OptimaImageClient";
import { AuthenticationResult } from "@azure/msal-browser";
import { getMesToken } from "../../../auth/getAccessToken";

const { pasteCleanup, sanitize, sanitizeClassAttr, sanitizeStyleAttr, removeAttribute, replaceImageSourcesFromRtf } =
  EditorUtils;

export interface IMetaDataFromDocument {
  title: string;
  learningObjective: string;
}

const pasteSettings: PasteCleanupSettings = {
  convertMsLists: true,
  // stripTags: 'span|font'
  attributes: {
    class: sanitizeClassAttr,
    style: sanitizeStyleAttr,

    // keep `width`, `height` and `src` attributes
    width: () => {},
    height: () => {},
    src: () => {},
    // Removes `lang` attribute
    // lang: removeAttribute,

    // removes other (unspecified above) attributes
    "*": removeAttribute,
  },
};

// Used ChatGPT to create this code. Conversation found here:
// https://chat.openai.com/share/95bd5ff1-1a2c-4d50-b721-3866f69f920c
// Example of modifying the HTML
//$('h1,h2,h3').each((index, element) => {
//    console.log("element", element);
//    const $heading = $(element); // Convert the DOM element to a Cheerio object
//    const headingText = $heading.text();
//    // Modify the heading text
//    $heading.text(`${headingText} (${index + 1})`);

//    // Add a class to the heading
//    $heading.addClass('modified-heading');
//});
//$('p').replaceWith(function () {
//    return $('<div>').addClass('brodtext').text($(this).text());
//});
//End of example

const fixText = (text: string): string => {
  return text.replaceAll("\n", " ").trim();
};

const fetchImage = async (fileName: string): Promise<GetAssetResponse | null> => {
  let reply: GetAssetResponse | null = null;
  let url = `${appConfig.REACT_APP_MEDIA_API_URL}/v1/Media/find?searchTerm=${fileName}`;
  url += `&pageIndex=${0}&pageSize=${2}`;

  let token: AuthenticationResult = await getMesToken();

  try {
    let response = await axios.get(url, { headers: { Authorization: `Bearer ${token.accessToken}` } });
    reply = response.data;
    const images: GetAssetResponse[] = response.data;
    if (images.length === 1) reply = images[0];
  } catch (error) {
    console.error("Error:", error);
  }
  return reply;
  // let response = await axios.get(url, { headers: { "Authorization": `Bearer ${token.accessToken}` } });

  // axios.get(url, { headers: { "Authorization": `Bearer ${token.accessToken}` } })
  //         .then(response => {
  //             // Access response data
  //             const images: GetAssetResponse[] = response.data;
  //             if (images.length == 1)
  //                 return images[0];
  //         })
  //         .catch(error => {
  //             console.error('Error:', error);
  //             return null;
  //         });
};

const getMetaData = ($: cheerio.CheerioAPI): IMetaDataFromDocument | undefined => {
  let metaData: IMetaDataFromDocument = { title: "", learningObjective: "" };
  let inName = false;
  let inLearningObjective = false;
  let foundMetaData = false;
  $("p").each((index, element) => {
    const $paragraph = $(element); // Convert the DOM element to a Cheerio object

    // Check if the <p> tag contains the start tag for the name
    if (!inLearningObjective && $paragraph.text().includes("##NAMN") && !$paragraph.text().includes("##NAMNSLUT")) {
      inName = true;
      $paragraph.remove();
    } else if ($paragraph.text().includes("##NAMNSLUT")) {
      inName = false;
      $paragraph.remove();
    } else if (inName) {
      //Store the name in the metaData object
      if (metaData.title.length > 0) metaData.title += " ";
      metaData.title += fixText($paragraph.text());
      foundMetaData = true;
      $paragraph.remove();
    }
    // Check if the <p> tag contains the start tag for the learning objective
    if (!inName && $paragraph.text().includes("##LÄRANDEMÅL") && !$paragraph.text().includes("##LÄRANDEMÅLSLUT")) {
      inLearningObjective = true;
      $paragraph.remove();
    } else if ($paragraph.text().includes("##LÄRANDEMÅLSLUT")) {
      inLearningObjective = false;
      $paragraph.remove();
    } else if (inLearningObjective) {
      //Store the earning objective in the metaData object
      if (metaData.learningObjective.length > 0) metaData.learningObjective += " ";
      metaData.learningObjective += fixText($paragraph.text());
      foundMetaData = true;
      $paragraph.remove();
    }
  });
  return foundMetaData ? metaData : undefined;
};

const handleInfoBoxes = ($: cheerio.CheerioAPI) => {
  let infobox: InfoComponent | undefined = undefined;
  $("p").each((index, element) => {
    const $paragraph = $(element); // Convert the DOM element to a Cheerio object

    // Check if the <p> tag contains the start tag
    if ($paragraph.text().includes("##INFOSTART")) {
      // console.log("$paragraph.text() Start",$paragraph.text());
      infobox = new InfoComponent({ title: "", text: "" });
      // Remove the <p> tag and its content
      $paragraph.remove();
    } else if ($paragraph.text().includes("##INFOSLUT")) {
      // console.log("$paragraph.text() Slut",$paragraph.text());
      // Remove the <p> tag and its content
      // Add the infobox to the HTML
      if (infobox) {
        // Get the HTML for the infobox
        // console.log("infobox", infobox);
        const infoboxHtml = GetHtmlFromInfoComponent(infobox);
        // Replace the <p> tag with the infobox HTML
        $paragraph.replaceWith(infoboxHtml);
        infobox = undefined;
      }
    } else if (infobox) {
      //Store the title and text in the infobox object
      // console.log("$paragraph.text() Rad",$paragraph.text());
      if (!infobox.title) {
        infobox.title = fixText($paragraph.text());
      } else {
        if (infobox.text!.length > 0) {
          infobox.text += "<br>";
        }
        infobox.text += fixText($paragraph.text());
      }
      $paragraph.remove();
    }
  });
};

const extractPortfolioId = (input: string): string => {
  const startIndex = input.indexOf(":") + 1;
  const endIndex = input.indexOf(",");
  if (startIndex < 0 || endIndex < 0 || startIndex >= endIndex) {
    console.error("Incorrect image id format: " + input);
    return ""; // Return an empty string if the format is incorrect
  }
  const reply = input.substring(startIndex, endIndex).trim();
  return reply;
};

const handleImages = async ($: cheerio.CheerioAPI) => {
  let image: ImageComponent | undefined = undefined;
  for (const element of $("p")) {
    const $paragraph = $(element); // Convert the DOM element to a Cheerio object

    // Check if the <p> tag contains the start tag
    if ($paragraph.text().includes("##BILDSTART")) {
      // console.log("$paragraph.text() Start",$paragraph.text());
      image = new ImageComponent({ link: "", imageText: "" });
      // Remove the <p> tag and its content
      $paragraph.remove();
    } else if ($paragraph.text().includes("##BILDSLUT")) {
      // console.log("$paragraph.text() Slut",$paragraph.text());
      // Remove the <p> tag and its content
      // Add the infobox to the HTML
      if (image) {
        // Get the HTML for the infobox
        // console.log("image", image);
        const imageHtml = await GetHtmlFromImageComponent(image);
        // Replace the <p> tag with the infobox HTML
        $paragraph.replaceWith(imageHtml);
        image = undefined;
      }
    } else if (image) {
      //Store the title and text in the infobox object
      // console.log("$paragraph.text() Rad",$paragraph.text());
      if (image.link!.length === 0) {
        image.link = fixText($paragraph.text());
        if (image.link.startsWith("##")) {
          // Lookup via portfolio id'n and get link string with contentId and versionId
          const portfolioId = extractPortfolioId(image.link);
          let imageExists = false;
          if (portfolioId.length > 0) {
            const foundImage = await fetchImage(extractPortfolioId(image.link));
            if (foundImage) {
              imageExists = true;
              image.link = JSON.stringify({
                contentId: foundImage.contentId,
                versionId: foundImage.versionId,
                src: foundImage.url,
              });
              image.fileName = foundImage.properties?.find((p) => p.name === "Filename")?.value || "";
            }
          }
          if (!imageExists) {
            image.link = " ";
          }
        }
      } else {
        if (image.imageText!.length > 0) {
          image.imageText += "\n";
        }
        image.imageText += fixText($paragraph.text());
      }
      $paragraph.remove();
    }
  }
  // $('p').each(async (index, element) => {
  //     const $paragraph = $(element); // Convert the DOM element to a Cheerio object

  //     // Check if the <p> tag contains the start tag
  //     if ($paragraph.text().includes('##BILDSTART')) {
  //         // console.log("$paragraph.text() Start",$paragraph.text());
  //         image = { link: "", imageText: ""};
  //         // Remove the <p> tag and its content
  //         $paragraph.remove();
  //     } else if ($paragraph.text().includes('##BILDSLUT')) {
  //         // console.log("$paragraph.text() Slut",$paragraph.text());
  //         // Remove the <p> tag and its content
  //         // Add the infobox to the HTML
  //         if (image) {
  //             // Get the HTML for the infobox
  //             // console.log("image", image);
  //             const imageHtml = await GetHtmlFromImageComponent(image);
  //             // Replace the <p> tag with the infobox HTML
  //             $paragraph.replaceWith(imageHtml);
  //             image = undefined;
  //         }
  //     } else if (image) {
  //         //Store the title and text in the infobox object
  //         // console.log("$paragraph.text() Rad",$paragraph.text());
  //         if (image.link.length === 0) {
  //             image.link = fixText($paragraph.text());
  //         }
  //         else {
  //             if (image.imageText.length > 0) {
  //                 image.imageText += "\n";
  //             }
  //             image.imageText += fixText($paragraph.text());
  //         }
  //         $paragraph.remove();
  //     }
  // });
};

const removeImages = ($: cheerio.CheerioAPI) => {
  $("img").each((index, element) => {
    $(element).remove();
  });
};

const removeTables = ($: cheerio.CheerioAPI) => {
  $("table").each((index, element) => {
    $(element).remove();
  });
};

const removeStyles = ($: cheerio.CheerioAPI) => {
  $("*").removeAttr("style");
};

const removeEmptyParagraphs = ($: cheerio.CheerioAPI) => {
  $("body > *").each((index, element) => {
    const content = fixText($(element).text());
    if (content === "") {
      $(element).remove();
    }
  });

  // $('p').each(function() {
  //     const content = $(this!).html()!.trim().replace(/&nbsp;/, '');
  //     if (content === '') {
  //       $(this).remove();
  //     }
  //   });
};

// export const HtmlFilter2 = (event: EditorPasteEvent, onMetadata: (metaData:IMetaDataFromDocument) => void) => {
//     let html = "<div>TEST</div>" + pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
//     return html;
// }

const removeDoubleSpaces = (input: string): string => {
  let reply = input.replaceAll("  ", " ");
  if (reply.length !== input.length) {
    reply = removeDoubleSpaces(reply);
  }
  return reply;
};

const preCleanup = (event: EditorPasteEvent) => {
  // console.log(">>>> before cleanup", event.pastedHtml);

  let html = "<div>&nbsp;</div>" + pasteCleanup(sanitize(event.pastedHtml), pasteSettings);

  // console.log(">>>> after cleanup", html);

  // Use Cheereo to parse the HTML and create components
  const $ = cheerio.load(html);

  removeImages($);
  removeTables($);
  removeStyles($);

  return $;
};

const postCleanup = ($: cheerio.CheerioAPI) => {
  removeEmptyParagraphs($);
  let reply = $.html();
  reply = reply.replace(/[\n\r]/g, " "); // Remove all line breaks
  reply = removeDoubleSpaces(reply);
  return reply;
};

export const HtmlFilterWithTags = async (
  event: EditorPasteEvent,
  onMetadata: (metaData: IMetaDataFromDocument) => void
) => {
  const $ = preCleanup(event);

  // Metadata
  const metaData = getMetaData($);
  if (metaData) {
    console.log("Found metaData", metaData);
    onMetadata(metaData);
  } else {
    console.log("No metaData found");
  }
  // Components
  handleInfoBoxes($);
  await handleImages($);

  return postCleanup($);
};

export const IsHTMLWithComponents = (event: EditorPasteEvent): boolean => {
  // Check if html contians any tag with class "str-component" since then should no cleanup be done
  let html = event.pastedHtml;
  let $ = cheerio.load(html);
  let found = false;
  $(".str-component").each((index, element) => {
    found = true;
  });
  return found;
};

export const HtmlFilterWithoutTags = (event: EditorPasteEvent) => {
  // Check if
  const $ = preCleanup(event);
  return postCleanup($);
};
