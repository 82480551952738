import React from "react";
import AddRemoveButton from "./AddRemoveButton";
import { Button } from "@progress/kendo-react-buttons";
import { MediaDto, QuestionMediaDto, TextAndMedia } from "../../../api/learnable_question/QuestionClient";
import { isQuestionOption } from "./functions/helperFunctions";
import ImageDialog from "../../optima_image/ImageDialog";
import QuestionMediaItem from "./QuestionMediaItem";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import { SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE } from "../../common/Constants";
import { getQuestionCopy, createQuestionMediaDto, createMediaDto } from "./functions/questionDtoFunctions";
import { MediaContainer } from "../../optima_image/types";

const QuestionMedia = (props: any) => {
  const question: iQuestionDtoExtended = props.question;
  const deleteMedium = props.deleteMedium;
  const optionId = "";
  const [visibleDialog, setVisibleDialog] = React.useState<boolean>(false);
  const { labelText, addElement, removeElement } = props;

  //const [selectedImages, setSelectedImages] = React.useState<ImagesDto[]>([]);

  // TODO: remove any
  const [selectedImages, setSelectedImages] = React.useState<any[]>([]);

  const questionMedia: QuestionMediaDto[] = props.questionMedia;
  const templateDataItem: TextAndMedia = props.templateDataItem;
  const templateDataItems: TextAndMedia[] = props.templateDataItems;

  // const onImageDialogClose = (link: string) => {
  //   // props.addMedium(templateDataItem, optionId, link);

  //   if (question?.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE) {
  //     handleAddMediumClickableImage(templateDataItem, optionId, link);
  //   } else {
  //     handleAddMedium(templateDataItem, optionId, link);
  //   }

  //   setVisibleDialog(false);
  // };
  const onImageDialogClose = (mediaContainer: MediaContainer) => {
    if (mediaContainer.imageLinks) {
      if (question?.subQuestionTypeId === SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE) {
        let mediaLinkAsString: string = JSON.stringify(mediaContainer.imageLinks[0]);
        handleAddMediumClickableImage(templateDataItem, optionId, mediaContainer);
      } else {
        handleAddMedium(templateDataItem, optionId, mediaContainer);
      }
    }

    if (mediaContainer.videos) {
      console.log("mediaContainer.videos", mediaContainer.videos[0]);
      handleAddMedium(templateDataItem, optionId, mediaContainer);
    }

    setVisibleDialog(false);
  };
  const handleAddMedium = async (templateDataItem: TextAndMedia, optionId: string, mediaContainer: MediaContainer) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    let newQuestionMedia: QuestionMediaDto = createQuestionMediaDto(newQuestion, templateDataItem, optionId);

    let mediaDto: MediaDto = createMediaDto(newQuestion, mediaContainer, templateDataItem);
    newQuestionMedia.media!.push(mediaDto);
    newQuestion!.questionMedia!.push(newQuestionMedia);

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);

    props.updateMediaLinks(newQuestion);
  };

  const handleAddMediumClickableImage = async (
    templateDataItem: TextAndMedia,
    optionId: string,
    mediaContainer: MediaContainer
  ) => {
    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);

    let foundQuestionMediaDto = newQuestion.questionMedia!.find(
      (x) => x.typeId === templateDataItem.textMediaTypeId && x.optionId === optionId
    );
    let newQuestionMedia: QuestionMediaDto =
      foundQuestionMediaDto ?? createQuestionMediaDto(newQuestion, templateDataItem, optionId);

    let mediaDto: MediaDto = createMediaDto(newQuestion, mediaContainer, templateDataItem);

    newQuestionMedia.media! = [mediaDto];
    //newQuestionMedia.media!.push(mediaDto);
    if (!foundQuestionMediaDto) {
      newQuestion!.questionMedia!.push(newQuestionMedia);
    }

    newQuestion.isModified = true;
    props.setQuestion(newQuestion!);

    props.updateMediaLinks(newQuestion);
  };

  const onDeleteMedium = (questionMedium: QuestionMediaDto, index: number) => {
    const newSelectedImages = selectedImages.filter((x) => x.thumbnailUri !== questionMedium.media![index].link);
    setSelectedImages(newSelectedImages);
    props.deleteMedium(questionMedium, index);
  };

  const getClassName = (): string => {
    let className =
      question.subQuestionTypeId === 3
        ? "col-10"
        : isQuestionOption(templateDataItem)
        ? "k-card-group col-9"
        : "k-card-group col-10";

    return className;
  };

  return (
    <React.Fragment key={"QuestionMedia2" + templateDataItem.textMediaTypeId!}>
      {!isQuestionOption(templateDataItem) && (
        <div className="col-2">
          <label>{labelText}</label>
        </div>
      )}

      <div className={isQuestionOption(templateDataItem) ? "col-7" : "col-8"}>
        {props.addMediaButtonVisible && <Button onClick={() => setVisibleDialog(true)}>Lägg till media</Button>}
      </div>

      <div className="col-1">
        <AddRemoveButton
          onClickAdd={() => addElement(templateDataItem)}
          onClickRemove={() => removeElement(templateDataItem, optionId)}
          removeVisible={props.minusButtonVisible}
          addVisible={props.plusButtonVisible}
        />
      </div>

      {questionMedia && (
        <div className="row">
          <div className={isQuestionOption(templateDataItem) ? "col-3" : "col-2"}></div>
          <div className={getClassName()}>
            {questionMedia.map((questionMedium: QuestionMediaDto, index: number) => (
              <div key={index} className="row">
                <QuestionMediaItem
                  mediaLinks={props.mediaLinks}
                  question={props.question}
                  setQuestion={props.setQuestion}
                  questionMedium={questionMedium}
                  templateDataItem={templateDataItem}
                  templateDataItems={templateDataItems}
                  deleteMedium={onDeleteMedium}
                  col={props.questionMediaItemCol}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {visibleDialog && (
        <ImageDialog
          setSelectedImages={setSelectedImages}
          selectedImages={selectedImages}
          questionMedia={questionMedia}
          onOkClick={onImageDialogClose}
          onClose={() => setVisibleDialog(false)}
          showVideoSelector={
            question?.subQuestionTypeId !== SUB_QUESTION_TYPE_ID_CLICKABLE_IMAGE &&
            templateDataItem.textMediaTypeId === 1
          }
        />
      )}
    </React.Fragment>
  );
};

export default QuestionMedia;
